import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MDBContainer, MDBTable, MDBTableHead, MDBTableBody, MDBProgress, MDBProgressBar, MDBSpinner, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';

const client = generateClient();

const UserCourseProgress = () => {
  const { userId } = useParams();
  const [courses, setCourses] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedCourses, setExpandedCourses] = useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchUserAndCourses();
  }, [userId]);

  const fetchUserAndCourses = async () => {
    try {
      const userData = await client.graphql({
        query: `
          query GetUser($id: ID!) {
            getUser(id: $id) {
              id
              firstName
              lastName
              email
              owner
            }
          }
        `,
        variables: { id: userId }
      });
      const user = userData.data.getUser;
      setUser(user);

      // Extract Cognito User ID
      const cognitoUserId = user.owner.split(':')[0].substring(0, 36); // Assumes UUID v4 length

      const coursesData = await client.graphql({
        query: `
          query ListCourses {
            listCourses {
              items {
                id
                name {
                  language
                  text
                }
                lectures {
                  items {
                    lecture {
                      id
                      title {
                        language
                        text
                      }
                      lengthInSeconds
                    }
                  }
                }
              }
            }
          }
        `
      });

      const videoProgressData = await client.graphql({
        query: `
          query VideoProgressByUser($userId: String!) {
            videoProgressByUser(userId: $userId) {
              items {
                lectureVideoProgressId
                totalWatchedInSeconds
              }
            }
          }
        `,
        variables: { userId: cognitoUserId }
      });

      const videoProgress = videoProgressData.data.videoProgressByUser.items.reduce((acc, item) => {
        acc[item.lectureVideoProgressId] = item.totalWatchedInSeconds;
        return acc;
      }, {});

      const coursesWithProgress = coursesData.data.listCourses.items.map(course => {
        const lecturesWithProgress = course.lectures.items.map(lectureItem => {
          const lecture = lectureItem.lecture;
          const watchedSeconds = videoProgress[lecture.id] || 0;
          const progress = lecture.lengthInSeconds > 0 ? (watchedSeconds / lecture.lengthInSeconds) * 100 : 0;
          return {
            ...lecture,
            progress: progress,
            watchedSeconds: watchedSeconds
          };
        });

        const totalSeconds = lecturesWithProgress.reduce((sum, lecture) => sum + lecture.lengthInSeconds, 0);
        const totalWatchedSeconds = lecturesWithProgress.reduce((sum, lecture) => sum + lecture.watchedSeconds, 0);
        const courseProgress = totalSeconds > 0 ? (totalWatchedSeconds / totalSeconds) * 100 : 0;

        return {
          ...course,
          lectures: lecturesWithProgress,
          progress: courseProgress
        };
      });

      setCourses(coursesWithProgress);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const getLocalizedText = (textArray) => {
    const localizedText = textArray?.find(item => item.language === i18n.language);
    return localizedText ? localizedText.text : textArray?.[0]?.text || '';
  };

  const toggleCourseExpand = (courseId) => {
    setExpandedCourses(prev => ({
      ...prev,
      [courseId]: !prev[courseId]
    }));
  };

  if (loading) {
    return (
      <MDBContainer className="text-center my-5">
        <MDBSpinner role="status">
          <span className="visually-hidden">{t("Loading")}</span>
        </MDBSpinner>
      </MDBContainer>
    );
  }

  return (
    <MDBContainer className="my-5">
      <h2>{t("Course Progress for")} {user.firstName} {user.lastName}</h2>
      <p>{t("Email")}: {user.email}</p>
      <MDBTable striped hover>
        <MDBTableHead>
          <tr>
            <th>{t("Course/Lecture Name")}</th>
            <th>{t("Progress")}</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {courses.map((course) => (
            <React.Fragment key={course.id}>
              <tr>
                <td>
                  <MDBBtn 
                    color='link' 
                    onClick={() => toggleCourseExpand(course.id)}
                    className="p-0 me-2"
                  >
                    <MDBIcon icon={expandedCourses[course.id] ? 'chevron-down' : 'chevron-right'} />
                  </MDBBtn>
                  {getLocalizedText(course.name)}
                </td>
                <td style={{width: '50%'}}>
                  <MDBProgress height="20">
                    <MDBProgressBar width={course.progress} valuemin={0} valuemax={100}>
                      {course.progress.toFixed(0)}%
                    </MDBProgressBar>
                  </MDBProgress>
                </td>
              </tr>
              {expandedCourses[course.id] && course.lectures.map((lecture) => (
                <tr key={lecture.id} style={{backgroundColor: '#f8f9fa' }}>
                  <td className="ps-5">{getLocalizedText(lecture.title)}
                    {/* <br/>{lecture.id} */}
                    </td>
                  <td>
                    <MDBProgress height="20">
                      <MDBProgressBar style={lecture.progress > 79 && {backgroundColor: '#14A44D'}} width={lecture.progress} valuemin={0} valuemax={100}>
                        {lecture.progress.toFixed(0)}%
                      </MDBProgressBar>
                    </MDBProgress>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </MDBTableBody>
      </MDBTable>
    </MDBContainer>
  );
};

export default UserCourseProgress;