import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generateClient} from "aws-amplify/api";
import { MDBBtn, MDBCard, MDBCardBody, MDBCollapse, MDBIcon, MDBListGroup, MDBListGroupItem, MDBSelect, MDBTypography } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const client = generateClient();

export default function Admin_CompanyEvents({companyId}) {
    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);

    const [openAddEventCollapse, setOpenAddEventCollapse] = useState(false);
    const [selectedEventToAdd, setSelectedEventToAdd] = useState(null);

    const getEvents = async () => {
        try {
            const res = await client.graphql({
                variables: { companyId: companyId },
                query: /* GraphQL */ `
                query CompaniesAttendingEventsByCompanyId(
                  $companyId: ID!
                  $sortDirection: ModelSortDirection
                  $filter: ModelCompaniesAttendingEventsFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  companiesAttendingEventsByCompanyId(
                    companyId: $companyId
                    sortDirection: $sortDirection
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                  ) {
                    items {
                      id
                      companyId
                      eventId
                      company {
                        id
                        name
                        industry
                        status
                        website
                        admins
                        x4Id
                        x4AdminId
                        createdAt
                        updatedAt
                        companyAdminId
                        __typename
                      }
                      event {
                        name {
                            language
                            text
                        }
                        id
                        status
                        date
                        startDate
                        endDate
                        x4Id
                        createdAt
                        updatedAt
                        __typename
                      }
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                }
              `
            });
            console.log("res",res);
            setEvents(res.data.companiesAttendingEventsByCompanyId.items.map(item => ({...item.event, connectionId: item.id})));
            setLoading(false);
        } catch (error) {
            console.warn("error fetching events", error);
        }
    }

    const getAllEvents = async () => {
        try {
            const res = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents(
                  $filter: ModelEventFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                      id
                      name {
                        language
                        text
                        __typename
                      }
                      status
                      image {
                        fileName
                        alt
                        x4UUID
                        __typename
                      }
                      date
                      startDate
                      endDate
                      location {
                        __typename
                      }
                      attendees {
                        nextToken
                        __typename
                      }
                      availableBundleBooths {
                        nextToken
                        __typename
                      }
                      lectures {
                        nextToken
                        __typename
                      }
                      x4Id
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                }
              `
            });
            console.log("res",res);
            return res.data.listEvents.items;
        } catch (error) {
            console.warn("error fetching events", error);
        }
    }

    useEffect(() => {
        if(!companyId) return;
        getEvents();
        return () => {
            setEvents([]);
        }
    }, [companyId]);

    const startAddEvent = async () => {
        try {
            setOpenAddEventCollapse(true);
            const allEventsRes = await getAllEvents();
            setAllEvents(allEventsRes);
        } catch (error) {
            console.warn("error", error);
        }

    }

    const addCompanyToEvent = async () => {
        try {
            setLoading(true);
            await client.graphql({
                variables: { input: { companyId: companyId, eventId: selectedEventToAdd } },
                query: /* GraphQL */ `
                mutation CreateCompaniesAttendingEvents(
                    $input: CreateCompaniesAttendingEventsInput!
                    $condition: ModelCompaniesAttendingEventsConditionInput
                    ) {
                    createCompaniesAttendingEvents(input: $input, condition: $condition) {
                    id
                    companyId
                    eventId
                    }
                }
                `
            });
        } catch (error) {
            console.warn("error", error);
        }
    }

    const handleAddCompanyToEvent = async () => {
        try {
            setLoading(true);
            await addCompanyToEvent();
            setEvents([...events, allEvents.find(event => event.id === selectedEventToAdd)]);
            setLoading(false);
            setOpenAddEventCollapse(false);
        } catch (error) {
            console.warn("error", error);
            setLoading(false);
        }
    }

    const removeCompanyFromEvent = async (eventId) => {
        try {
            setLoading(true);
            await client.graphql({
                variables: { input: { id: events.find(event => event.id === eventId)?.connectionId } },
                query: /* GraphQL */ `
                mutation DeleteCompaniesAttendingEvents(
                    $input: DeleteCompaniesAttendingEventsInput!
                    $condition: ModelCompaniesAttendingEventsConditionInput
                    ) {
                    deleteCompaniesAttendingEvents(input: $input, condition: $condition) {
                    id
                    companyId
                    eventId
                    }
                }
                `
            });
        } catch (error) {
            console.warn("error", error);
        }
    }

    const handleRemoveCompanyFromEvent = async (eventId) => {
        try {
            setLoading(true);
            await removeCompanyFromEvent(eventId);
            setEvents(events.filter(event => event.id !== eventId));
            setLoading(false);
        } catch (error) {
            console.warn("error", error);
            setLoading(false);
        }
    }


  return (
<>
<MDBCard>
    <MDBCardBody>
        <MDBTypography tag="h5">{t("Events")}<MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light' onClick={() => startAddEvent()}>{t("Add")}</MDBBtn></MDBTypography>
        <MDBCollapse open={openAddEventCollapse}>
            {allEvents.length > 0 && 
            <MDBSelect
                label='Select group'
                data={allEvents.map(event => { return { text: event.name?.find(name => name.language === i18n.language)?.text || event.name[0].text, value: event.id } }).filter(event => !events.find(e => e.id === event.value))}
                onChange={val => { setSelectedEventToAdd(val.value) }}
            />}
            <div className="my-1 float-end">
                <MDBBtn color='primary' size='sm' className="me-1" onClick={() => setOpenAddEventCollapse(!openAddEventCollapse)}>{t("Cancel")}</MDBBtn>
                <MDBBtn color='primary' size='sm' onClick={() => handleAddCompanyToEvent()}>{t("Add")}</MDBBtn>
            </div>
            
        </MDBCollapse>

        {loading.updateGroups ? <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div> :
        <MDBListGroup>
        {events?.map(event => {
            return (
                <MDBListGroupItem key={event.id}>
                {event.name?.find(name => name.language === i18n.language)?.text || event.name[0].text}
                <MDBIcon icon='times' className='float-end' onClick={() => handleRemoveCompanyFromEvent(event.id)} />
                </MDBListGroupItem>
            )
        })}
        </MDBListGroup>
        }
        <div  className="mt-3">
        <Link to={`/mycompany/${companyId}/select-booth/81f8ba3d-61b2-48c0-8d99-d2b785920450`}>{t("Book Booth for Lounges 2025 Karlsruhe")}</Link>
        {/* <br />
        <Link to={`/mycompany/${companyId}/select-booth/db09ba25-a2ad-4aa0-a164-9c2cf5f1eb09`}>{t("Book Booth for Lounges 2024 Wien")}</Link> */}
        </div>
        
    </MDBCardBody>
</MDBCard>
</>
  )
}
