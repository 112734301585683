
import { MDBCard, MDBCardBody, MDBCardTitle, MDBSticky } from "mdb-react-ui-kit";
import EditEmployeeProfile from "../../components-user/employee-profile/EditEmployeeProfile";

export default function EditCompany() {


    const employeeId = window.location.pathname.split("/")[4];

 
  return (
  <>
<MDBCard className="m-md-5 p-md-4">
  <MDBCardBody>
    <EditEmployeeProfile employeeId={employeeId} isActive={true} />
  </MDBCardBody>
</MDBCard>

</>
   
  )
}
