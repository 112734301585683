import { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import {
    MDBInput,
    MDBStepper,
    MDBStepperStep,
    MDBRow,
    MDBCol,
    MDBSelect,
    MDBCard,
    MDBCardBody,
    MDBautoComplete, 
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBChip,
    MDBTabsContent,
    MDBAutocomplete,
} from 'mdb-react-ui-kit';
import { MDBWysiwyg } from 'mdb-react-wysiwyg';
import { MDBFileUpload } from 'mdb-react-file-upload';
import { generateClient } from 'aws-amplify/api';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

import AddNewCompany from './AddNewCompany';

import './AccountSetup.css';

const client = generateClient();


  const getAvailableCountries = async () => {
    try {
      const countries = await client.graphql({
        query: queries.listCountries,
      });
      console.log(countries);
      return countries.data.listCountries.items;
    } catch (error) {
      console.warn("error fetching countries", error);
    }
  }
  
  export default function AccountSetup() {
    const { t, i18n } = useTranslation();

    // For Stepper
    const prevRef = useRef(null);
    const nextRef = useRef(null);


    // Country
    const [countries, setCountries] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        const fetchCountries = async () => {
        const countries = await getAvailableCountries();
        setCountries(countries);
        setIsDataLoaded(true);
        }
        fetchCountries();
    }, [isDataLoaded]);

    // Company selection
    const [selectedCompany, setSelectedCompany] = useState("");

    const dummyData = ["Apple", "Microsoft", "Google", "Facebook", "Amazon", "Inspire Gmbh"];
    const [data, setData] = useState(dummyData);
    const onSearch = (value) =>
    setData(dummyData.filter((item) => item.toLowerCase().startsWith(value.toLowerCase())));

    const onSelectCompany = (value) => {
        console.log(value);
        setSelectedCompany(value);
        
    };

    // Modal for new company
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => setShowModal(!showModal);


    // Interests selection
    const demoInterests = [
        { value: "analytics_and_quality", text: "Analytik und Qualitätssicherung" },
        { value: "automation", text: "Automatisierung" },
        { value: "business_development", text: "Business Development" },
        { value: "communication", text: "Kommunikation" },
        { value: "consulting", text: "Beratung" },
        { value: "customer_service", text: "Kundenservice" },
        { value: "design", text: "Design" },
        { value: "development", text: "Entwicklung" },
        { value: "finance", text: "Finanzen" },

    ];

    const [selectedInterests, setSelectedInterests] = useState([]);
    const [interests, setInterests] = useState(demoInterests);

    const selectInterest = (value) => {
        setSelectedInterests([...selectedInterests, value]);

    };

    const clickInterest = (value) => {
        // If the interest is already selected, remove it from the selected interests
        if (selectedInterests.includes(value)) {
            setSelectedInterests(selectedInterests.filter(interest => interest !== value));
        } else {
            selectInterest(value);
        }
    };

    // Helper
    const [minHeight, setMinHeight] = useState("900px");

    return (
    <>
      <MDBStepper
      style={{minHeight}}
      defaultStep={1}
      externalPrev={prevRef}
      externalNext={nextRef}
      >
        <MDBStepperStep headIcon={1} headText={t("Personal Profile")} itemId={1}>
        <MDBRow>
            <MDBCol size="12" className="mt-5 mt-md-0 mx-auto" style={{maxWidth: "700px"}}>
            <MDBCard>
              <MDBCardBody className="px-3 px-md-5">
                <h3 className="text-center mb-2 mb-md-5">{t("Personal Profile")}</h3>
                      <img
                      src='https://med.gov.bz/wp-content/uploads/2020/08/dummy-profile-pic.jpg'
                      className='rounded-circle shadow-1-strong mx-auto d-block mb-4'
                      alt=''
                      width="150" height="150"
                      />
                          {/* <MDBFileUpload acceptedExtensions='image/*' defaultMessage="" /> */}

                  <h5>{t("General Information")}</h5>
                  <MDBRow className="mb-3">
                    <MDBCol size="6">
                      <MDBInput className="mb-2 col col-6" label={t("First Name")} />
                    </MDBCol>
                    <MDBCol size="6">
                      <MDBInput className="mb-2 col col-6" label={t("Last Name")} />
                    </MDBCol>
                    <MDBCol size="6">
                      <MDBInput className="mb-2 col col-6" label={t("Gender")} />
                    </MDBCol>
                    <MDBCol size="6">
                      <MDBInput className="mb-2 col col-6" label={t("Birthday")} />
                    </MDBCol>
                  </MDBRow>
                  <h5 style={{marginBottom: "-1rem"}}>{t("About Me")}</h5>
                  <MDBWysiwyg></MDBWysiwyg>

                    {/* <div className="text-end mt-2 mt-md-3">
                        <ContinueButtonComponent />
                    </div> */}

                    

              </MDBCardBody>
            </MDBCard>
                
            </MDBCol>
        </MDBRow>
            
        </MDBStepperStep>

        <MDBStepperStep headIcon={2} headText={t("Address & Contact")} itemId={2} contentClassName="bg_cp_logo">
        <MDBRow>
            <MDBCol size="12" className="mt-5 mt-md-0 mx-auto" style={{maxWidth: "700px"}}>
            <MDBCard>
              <MDBCardBody className="px-3 px-md-5">
                <h3 className="text-center mb-2 mb-md-5">{t("Address & Contact")}</h3>
                <h5>{t("Address")}</h5>
                <MDBRow>
                    <MDBCol size="8">
                        <MDBInput className="mb-2 col col-6" label={t("Street")} />
                    </MDBCol>
                    <MDBCol size="4">
                        <MDBInput className="mb-2 col col-6" label={t("House number")} />
                    </MDBCol>
                </MDBRow>
                <MDBInput className="mb-2" label={t("Street addition (Optional)")} />
                <MDBRow>
                <MDBCol size="6">
                    <MDBInput className="mb-2" label={t("Zip")} />
                </MDBCol>
                <MDBCol size="6">
                    <MDBInput className="mb-2" label={t("City")} />
                </MDBCol>
                </MDBRow>
                <MDBSelect
                placeholder={t("Country")}
                data={isDataLoaded && countries?.length > 0 ? countries.map(country => ({text: country.name, value: country.handle})) : [{text: t("Loading Countries...")}]} />
                <h5 className="mt-2 mt-md-3">{t("Contact")}</h5>
                <MDBRow>
                    <MDBCol size="6">
                        <MDBInput className="mb-2 col col-6" label={t("Business Phone Number")} />
                    </MDBCol>
                    <MDBCol size="6">
                        <MDBInput className="mb-2 col col-6" label={t("Mobile Phone Number")} />
                    </MDBCol>
                </MDBRow>
                <MDBInput className="mb-2" label={t("E-Mail Address")} />

               
                
              </MDBCardBody>
            </MDBCard>
            </MDBCol>
        </MDBRow>
        </MDBStepperStep>

        <MDBStepperStep headIcon={3} headText={t("Company")} itemId={3}>
          <MDBRow>
            <MDBCol size="12" md="8" lg="6" xl="4" className="mx-auto text-center">
            <MDBAutocomplete
            data={data}
            label={t("Company name")}
            onSearch={onSearch}
            onSelect={onSelectCompany}
            />
            <MDBBtn className='mx-2' color='tertiary' rippleColor='light' onClick={toggleModal}>
                {t("Your company is not listed? Click here to add it.")}
            </MDBBtn>
            </MDBCol>
          </MDBRow>
          {selectedCompany && selectedCompany.length > 0 &&
          <MDBRow className="mt-4">
            <MDBCol size="12" md="8" lg="6" xl="4" className="mx-auto">
                <MDBInput className="mb-2" label={t("Position")} />
                <MDBInput className="mb-2" label={t("Department")} />
            </MDBCol>
          </MDBRow>
            }
        </MDBStepperStep>
  
        <MDBStepperStep headIcon={4} headText={t("Interests")} itemId={4}>
          <MDBRow>
            <MDBCol size="12" md="8" lg="6" className="mx-auto">
                <MDBCard>
                    <MDBCardBody>
                    {interests.map((interest, index) => <MDBChip key={interest.value} onClick={() => clickInterest(interest.value)} color={selectedInterests.find(i => i === interest.value) ? "success" : undefined} style={{justifyContent: "center", width: "fit-content", float: "left"}}>{interest.text}</MDBChip>)}
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>

          </MDBRow>
        </MDBStepperStep>
      </MDBStepper>
      <MDBRow style={{maxWidth: "700px"}} className="mx-auto">
        <MDBCol size="6" >
          <div className="text-start mt-2 mt-md-3">
          <MDBBtn ref={prevRef} color="secondary" style={{marginBottom: "1rem"}}>{t("Back")}</MDBBtn>
          </div>
        </MDBCol>
        <MDBCol size="6">
          <div className="text-end mt-2 mt-md-3">
          <MDBBtn ref={nextRef} color="secondary" style={{marginBottom: "1rem"}} onClick={() => setMinHeight("0px")} >{t("Continue")}</MDBBtn>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBModal open={showModal} onClose={() => setShowModal(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t("Add new company")}</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody><AddNewCompany /></MDBModalBody>

            {/* <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleModal}>
                Close
              </MDBBtn>
              <MDBBtn>Save changes</MDBBtn>
            </MDBModalFooter> */}
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
    );
  }