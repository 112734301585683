export const orderStates = [
    { value: 'pending', label: 'Open' },
    { value: 'completed', label: 'Completed' },
    { value: 'canceled', label: 'Canceled' }
]

export const orderPaymentStates = [
    { value: 'unpaid', label: 'Unpaid' },
    { value: 'paid', label: 'Paid' },
    { value: 'partially_paid', label: 'Partially payed' },
    { value: 'refunded', label: 'Refunded' }
]