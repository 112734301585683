// Lecture Type
// type Lecture
//   @model
//   @auth(rules: [
//     { allow: private, operations: [read] },
//     { allow: groups, groups: ["Admin, Moderator"], operations: [create, read, update, delete] }])
// {
//   id: ID!
//   startTime: AWSDateTime!
//   endTime: AWSDateTime!
//   event: Event @belongsTo
//   company: Company @hasOne
//   speakers: [Employee] @hasMany
//   session: String
//   room: String
//   title: [MultiLangString!]!
//   subjects: [MultiLangStringList]
//   content: MultiLangString
//   presentationLanguage: String
//   image: Image
//   pdfDe: File
//   pdfEn: File
// }

import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isoToGermanDate } from "../../utils/dateTools";
const client = generateClient();

export default function AllRecruitingLectures() {
    const { t, i18n } = useTranslation();

    const companyid = window.location.pathname.includes("mycompany")
    ? window.location.pathname.split("/")[2]
    : window.location.pathname.split("/")[3];

    const [lectures, setLectures] = useState([]);
    const [jobListings, setJobListings] = useState([]);
    const [tourRegistrations, setTourRegistrations] = useState([]);

    const getLectures = async () => {
        try {
            const lectures = await client.graphql({
                variables: {
                    limit: 10000,
                    filter: {
                        companyLecturesId: {
                          eq: companyid
                        },
                        createdAt: {
                            gt: "2023-10-01T00:00:00.000Z"
                        },
                        type: {
                            matchPhrase: "recruiting"
                        }            
                    }
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                      id
                      title {
                        language
                        text
                      }
                      topics {
                        items {
                          topic {
                            id
                            name {
                              language
                              text
                            }
                          }
                        }
                    }  
                      speakers {
                        items {
                            employee {
                                image {
                                  fileName
                                  alt
                                }
                              }
                        }
                      }
                      event {
                        name {
                          language
                          text
                        }
                        id
                      }
                      type
                      session
                      room
                      startTime
                      endTime
                      presentationLanguage
                      createdAt
                      updatedAt
                      companyLecturesId
                      eventLecturesId
                      lectureMainSpeakerId
                      __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            console.log(lectures.data.searchLectures.items)
            setLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})));
        } catch (error) {
            console.log("error on fetching lectures", error);
            if(error.data?.searchLectures?.items) setLectures(error.data.searchLectures.items);
        }
    };

    const fetchAllJobListings = async (nextToken = null, allJobListings = []) => {
        try {
            const response = await client.graphql({
                query: /* GraphQL */ `
                query ListCMSJobListings(
                  $filter: ModelCMSJobListingFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listCMSJobListings(filter: $filter, limit: $limit, nextToken: $nextToken) {
                            items {
                                id
                                createdAt
                                name {
                                    language
                                    text
                                }
                                status
                                employee {
                                    id
                                    image {
                                        fileName
                                        alt
                                    }
                                    user {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                            }
                            nextToken
                        }
                    }
                `,
                variables: { 
                    filter: { companyJobsId: { eq: companyid } },
                    nextToken: nextToken,
                    limit: 100 // Adjust the limit as needed
                }
            });
    
            const newsData = response.data.listCMSJobListings;
            allJobListings = allJobListings.concat(newsData.items);
    
            if (newsData.nextToken) {
                return fetchAllJobListings(newsData.nextToken, allJobListings);
            } else {
                return allJobListings.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            }
        } catch (error) {
            console.error('Error fetching news:', error);
            return []; // Return an empty array or handle the error as needed
        }
    };

    const fetchJobListings = async () => {
        try {
            const allNews = await fetchAllJobListings();
            setJobListings(allNews);
        } catch (error) {
            console.error('Failed to fetch all news:', error);
        }
    };

    const getTourStatus = async () => {
        try {
            const tourStatus = await client.graphql({
                variables: {
                    limit: 10,
                    filter: {
                        cMSTourCompanyRegistrationCompanyId: {
                          eq: companyid
                        }       
                    }
                },
                query: /* GraphQL */ `
                query ListCMSTourCompanyRegistrations(
                  $filter: ModelCMSTourCompanyRegistrationFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listCMSTourCompanyRegistrations(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                  ) {
                    items {
                      id
                      status
                      company {
                        id
                      }
                      tour {
                        id
                      }
                    }
                    nextToken
                }
            }
                `
                });
                console.log(tourStatus.data.listCMSTourCompanyRegistrations.items);
                setTourRegistrations(tourStatus.data.listCMSTourCompanyRegistrations.items);

    } catch (error) {
        console.log("error on fetching tour status", error);
        return [];
    }
    };

    const registerCompanyForTour = async () => {
        try {
            const tourRegistration = await client.graphql({
                variables: {
                    input:{
                        cMSTourTourCompanyRegistrationsId: "4cf0754e-f8b4-41e8-b3c7-34ca614b7300",
                        cMSTourCompanyRegistrationCompanyId: companyid}
                },
                query: /* GraphQL */ `
                mutation CreateCMSTourCompanyRegistration(
                    $input: CreateCMSTourCompanyRegistrationInput!
                    $condition: ModelCMSTourCompanyRegistrationConditionInput
                    ) {
                    createCMSTourCompanyRegistration(input: $input, condition: $condition) {
                        id
                        status
                        company {
                            id
                        }
                        tour {
                            id
                        }
                    }
                }
                `
            });
            console.log("registered for tour", tourRegistration);
            setTourRegistrations([...tourRegistrations, tourRegistration.data.createCMSTourCompanyRegistration]);
            setRegisterTourModal(false);
        } catch (error) {
            console.log("error on registering for tour", error);
        }
    };

    const removeCompanyFromTour = async (id) => {
        try {
            const deletedTourRegistration = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteCMSTourCompanyRegistration(
                    $input: DeleteCMSTourCompanyRegistrationInput!
                    $condition: ModelCMSTourCompanyRegistrationConditionInput
                    ) {
                    deleteCMSTourCompanyRegistration(input: $input, condition: $condition) {
                        id
                    }
                }
                `
            });
            console.log("deleted tour registration", deletedTourRegistration);
            setTourRegistrations(tourRegistrations.filter((tourRegistration) => tourRegistration.id !== id));
        } catch (error) {
            console.log("error on deleting tour registration", error);
        }
    };

    

    const deleteJobListing = async (id) => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteCMSJobListing($input: DeleteCMSJobListingInput!) {
                    deleteCMSJobListing(input: $input) {
                    id
                  }
                }
              `,
                variables: { input: { id } }
            });
            setTimeout(() => {
                fetchJobListings();
            }, 1000);
        } catch (error) {
            console.error('Error deleting job listing:', error);
        }
    };

    useEffect(() => {
        getLectures();
        fetchJobListings();
        getTourStatus();
    }, []);


    // Modals
    // Delete Lecture
    const [deleteLectureModal, setDeleteLectureModal] = useState(false);
    const toggleDeleteLectureModal = () => setDeleteLectureModal(!deleteLectureModal);
    const [selectedLectureRemove, setSelectedLectureRemove] = useState(null);

    // Register for Tour
    const [registerTourModal, setRegisterTourModal] = useState(false);
    const toggleRegisterTourModal = () => setRegisterTourModal(!registerTourModal);


    const deleteLecture = async (id) => {
        try {
            const deletedLecture = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteLecture(
                  $input: DeleteLectureInput!
                  $condition: ModelLectureConditionInput
                ) {
                  deleteLecture(input: $input, condition: $condition) {
                    id
                  }
                }
              `
            });
            console.log("deleted lecture", deletedLecture);
            setLectures(lectures.filter((lecture) => lecture.id !== id));
            setSelectedLectureRemove(null);
            toggleDeleteLectureModal();
        } catch (error) {
            console.log("error on deleting lecture", error);
        }
    }





  return (
<>

<MDBRow className="mb-3 mb-lg-5">
    {/* <MDBCol size="12" lg="4">
        <MDBCard className="mx-auto text-center">
            <MDBCardBody>
                <MDBCardTitle>
                    <h2>{t("Recruiting lecture")}</h2>
                </MDBCardTitle>
                <h5>{t("Register Recruiting lecture for Lounges 2024 now.")}</h5>
                <Link to={`/mycompany/${companyid}/register-recruiting-lecture`}>
                    <MDBBtn>{t("Register Recruiting lecture")}</MDBBtn>
                </Link>
            </MDBCardBody>
        </MDBCard>
    </MDBCol> */}
    <MDBCol size="12" lg="4">
        <MDBCard className="mx-auto text-center">
            <MDBCardBody>
                <MDBCardTitle>
                    <h2>{t("Job Listing")}</h2>
                </MDBCardTitle>
                <h5>{t("Show your open positions online and at the trade fair")}</h5>
                <Link to={`/mycompany/${companyid}/job-listing/create`}>
                    <MDBBtn>{t("Create Job Listing")}</MDBBtn>
                </Link>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    {/* <MDBCol size="12" lg="4">
        <MDBCard className="mx-auto text-center">
            <MDBCardBody>
                <MDBCardTitle>
                    <h2>{t("Guided-Tour")}</h2>
                </MDBCardTitle>
                <h5>{t("Register for Guided-Tour at Lounges 2024 now.")}</h5>
                {tourRegistrations.length > 0 ?
                <>
                <MDBBtn color="success" disabled>{t("Registered")}</MDBBtn>
                <MDBBtn color="link" onClick={() => removeCompanyFromTour(tourRegistrations[0].id)}>
                    <MDBIcon icon="times" />
                </MDBBtn>
                </>
                
                :
                    <MDBBtn onClick={toggleRegisterTourModal}>{t("Register for Guided-Tour")}</MDBBtn>
                }
            </MDBCardBody>
        </MDBCard>
    </MDBCol> */}
    {/* <MDBCol size="12" lg="4">
        <MDBCard className="mx-auto text-center h-100" style={{backgroundColor: "#c0c0c0"}}>
            <MDBCardBody>
                <MDBCardTitle>
                    <h2>{t("Lounges 2024 Wien")}</h2>
                </MDBCardTitle>
                <h5>{t("Registration will start from May 2024")}</h5>
                
            
            </MDBCardBody>
        </MDBCard>
    </MDBCol> */}
</MDBRow>



<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>
            {t("Recruiting lectures")}
        </MDBCardTitle>
        <MDBTable>
            <MDBTableHead>
                <tr>
                <th>{t("Event")}</th>
                <th>{t("Type")}</th>
                    <th>{t("Title")}</th>
                    <th>{t("Speakers")}</th>
                    <th>{t("Date, Time & Location")}</th>
                    <th>{t("Actions")}</th>

                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {lectures.length > 0 ? 
                    lectures.map((lecture) => (
                        <tr key={lecture.id}>
                        <td>Lounges 2024 Karlsruhe</td>
                            <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                            <td>{t(lecture.type)}</td>
                            <td>{lecture.speakers?.items?.map(speaker => <>
                            {speaker?.employee?.image?.fileName ?
                            <img
                                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    objectFit: "cover",
                                }}
                                alt={speaker.employee?.image.alt}
                            />
                            :
                            <MDBIcon
                                className="rounded-circle border"
                                style={{
                                    height: "50px",
                                    width: "50px",
                                    textAlign: "center",
                                    paddingTop: "12px",
                                    fontSize: "20px",
                                }}
                                fas
                                icon="user-alt"
                            />
                            }
                            </>)}</td>
                            <td>{lecture.startTime ? <>
                                {isoToGermanDate(lecture.startTime)}<br></br>
                                {lecture.startTime.substring(11, 16)} - {lecture.endTime.substring(11, 16)}<br></br>
                                {lecture.room ? t("Room") + ": " + lecture.room : t("not yet determined")}
                            </> : t("not yet determined")}</td>
                            
                            <td>
                            <MDBBtnGroup shadow="0">
                            {/* <MDBBtn color='link' style={{color: "black"}} className="px-2" >{t("Upload Documents")}</MDBBtn> */}
                            <Link to={`/mycompany/${companyid}/register-recruiting-lecture/${lecture.id}`}>
                                <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                            </Link>
                            <MDBBtn color='link' style={{color: "red"}} className="px-2" onClick={() => {setSelectedLectureRemove(lecture.id); toggleDeleteLectureModal()}}>{t("Delete")}</MDBBtn>
                            </MDBBtnGroup>
                            </td>
                        </tr>
                    ))
                :
                    <tr>
                        <td colSpan="7">
                            {t("No promotions found")}
                        </td>
                    </tr>
                }
            </MDBTableBody>
        </MDBTable>
    </MDBCardBody>
</MDBCard>
<MDBCard className="mt-2 mt-lg-4">
    <MDBCardBody>
    <MDBCardTitle>{t("Job Listings")}</MDBCardTitle>
        
        
        <MDBTable>
            <MDBTableHead>
                <tr>
                    <th>{t("Position")}</th>
                    <th>{t("Contact person")}</th>
                    <th>Status</th>
                    <th>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {jobListings.map(news => (
                    <tr key={news.id}>
                        <td>{news.name?.find(t => t.language === i18n.language)?.text}</td>
                        
                        <td>{news.employee && (
                        news.employee?.image?.fileName ?
                        <img
                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${news.employee.image.fileName}`}
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                            }}
                            alt={news.employee?.image.alt}
                        />
                        :
                        <MDBIcon
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                            }}
                            fas
                            icon="user-alt"
                        />            
                        )}
                        </td>
                        <td>{news.status || t("In review")}</td>
                        <td>
                            <Link to={`/mycompany/${companyid}/job-listing/${news.id}`}>
                            <MDBBtn className='mx-2 my-0 py-0' color='link'>{t("Edit")}</MDBBtn>
                            </Link>
                            <MDBBtn className='mx-2 my-0 py-0' color='link' style={{color: "red"}}  onClick={() => {
                            if (window.confirm(t("Are you sure you want to delete this job listing?"))) {
                                deleteJobListing(news.id);
                            }
                        }}>{t("Delete")}</MDBBtn>
                        </td>
                    </tr>
                ))}
            </MDBTableBody>
        </MDBTable>
        
    </MDBCardBody>
</MDBCard>
<MDBModal open={deleteLectureModal} onClose={() => setDeleteLectureModal(false)}>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Remove promotion")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteLectureModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
            <p>{t("Are you sure you want to remove this promotion?")}</p>
            <p className="fw-bold">{selectedLectureRemove && (lectures.find(l => l.id === selectedLectureRemove)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureRemove).title[0].text)}</p>
            <MDBBtn color='danger' onClick={() => deleteLecture(selectedLectureRemove)}>{t("Remove")}</MDBBtn>
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={registerTourModal} onClose={() => setRegisterTourModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Register for Guided-Tour")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleRegisterTourModal}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
            <p>{t("The guided tours offer your company the opportunity to present itself to a guided group of LOUNGES visitors consisting of students, graduates and job seekers. A unique opportunity to talk directly to potential new specialists.")}</p>
            <MDBBtn onClick={registerCompanyForTour}>{t("Register")}</MDBBtn>
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>	
</MDBModal>
</>
  )
}
