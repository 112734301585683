// utils.js
import i18n from 'i18next';

/**
 * Retrieves localized text based on the current language setting from react-i18next.
 * 
 * @param {Array} textArray - An array of text objects with language and text properties.
 * @returns {string} The text corresponding to the current language or a fallback text.
 * 
 * @example
 * // Text array structure
 * const texts = [
 *   { language: 'de', text: 'Hallo' },
 *   { language: 'en', text: 'Hello' }
 * ];
 * 
 * // Usage
 * const localizedText = getLocalizedText(texts);
 * console.log(localizedText); // Outputs 'Hello' if the current language is 'en'
 */
export const getLocalizedText = (textArray) => {
  const language = i18n.language;
  const localizedText = textArray?.find(item => item.language === language);
  return localizedText ? localizedText.text : textArray?.[0]?.text || '';
};

export const getAvailableLanguages = () => {
  return i18n.languages;
}