import { useEffect, useState } from 'react'
import { MDBCheckbox, MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBBtn, MDBIcon,
    MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
    MDBCollapse, MDBInput, MDBChip, MDBSelect 
 } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { Link } from "react-router-dom";
// import * as mutations from '../../../graphql/mutations';
//import { listCompanys } from '../../../graphql/queries';

const client = generateClient();

export default function User_List() {
    const { t, i18n } = useTranslation();

    const [companys, setCompanys] = useState([]);
    const [filteredCompanys, setFilteredCompanys] = useState([]);

    // Table Settings
    const [selectedElements, setSelectedElements] = useState([]);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState({ field: "name", order: "asc" });
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    const getCompanys = async (filter) => {
        try {
            const variables = { 
                limit: 5000,
                nextToken: null,
                filter: {
                    eventId: {eq: "882c49b6-ba4b-4c66-8a35-987d04af08a5"}
                },
                // Sort by x4Id
                //sort: {field: "id", direction: "asc"}

            };
            const companiesInEvent = await client.graphql({
                variables,
                query: /* GraphQL */ `
                query ListCompaniesAttendingEvents(
                  $filter: ModelCompaniesAttendingEventsFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listCompaniesAttendingEvents(
                    filter: $filter
                    limit: $limit
                    nextToken: $nextToken
                  ) {
                    items {
                        id
                    company {
                        id
                        name
                        status
                        image {
                          main {
                          alt
                          fileName
                          }
                      }
                        x4Id
                        x4AdminId
                        createdAt
                        updatedAt
                        companyAdminId
                        adminEmployees {
                            items {
                                contact {
                                    email
                                }
                                image {
                                    fileName
                                }
                              user {
                                hasCompletedOnboarding
                                email
                                firstName
                                lastName
                                middleName
                                title
                                salutation
                                image {
                                    fileName
                                }
                              }
                            }
                          }
                        lectures {
                          items {
                              id
                              createdAt
                              type
                          }
                      }
                      employees {
                        items {
                            user {
                                tickets {
                                    items {
                                        type
                                        visitorType
                                    }
                                }
                            }
                        }
                    }
                    orders {
                        items {
                          orderNumber
                          lineItems {
                            items {
                              product {
                                type
                                name {
                                    language
                                    text
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                      
                    }
                    nextToken
                    
                    __typename
                  }
                }
              `
            });
            console.log("company",companys);
            return {
                items: companiesInEvent.data.listCompaniesAttendingEvents.items.map((item) => ({...item.company, lectures: item.company.lectures.items.filter((lecture => Date.parse(lecture.createdAt) > Date.parse("2023-10-01T00:00:00.000Z")))})).sort((a, b) => a.name.localeCompare(b.name)),
                nextToken: companiesInEvent.data.listCompaniesAttendingEvents.nextToken,
                total: companiesInEvent.data.listCompaniesAttendingEvents.total
            };
        } catch (error) {
            console.warn("error fetching companys", error);
            if(error.data?.listCompaniesAttendingEvents?.items.length > 0) {
                return {
                    items: error.data.listCompaniesAttendingEvents.items.filter(company => !!company).map((item) => ({...item.company, lectures: item.company.lectures.items.filter((lecture => Date.parse(lecture.createdAt) > Date.parse("2023-10-01T00:00:00.000Z")))})).sort((a, b) => a.name.localeCompare(b.name)),
                    nextToken: error.data.listCompaniesAttendingEvents.nextToken,
                    total: error.data.listCompaniesAttendingEvents.total
                };
                }
            
        }
    }

    const buildTable = (companys) => {
        return companys.map((company) => {
            return (
                <tr key={company.id}>
                    <td><MDBCheckbox /></td>
                    <td>
                    <div className='d-flex align-items-center'>
                    {company.image?.main?.fileName ?
                        <img
                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+company.image.main.fileName}
                            className="rounded-circle border"
                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                            alt={company.image.alt}
                        />
                    :
                        <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                
                    }
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{company.name}</p>
                      <p className='text-muted mb-0'><a href={company.website} target="_blank" rel="noreferrer">{company.website}</a></p>
                    </div>
                    </div>
                    </td>
                    <td>
                    {/* Admins */}
                    {company.adminEmployees.items.map((employee) => {
                        return (
                            <div key={employee.user.email} className='d-flex align-items-center'>
                                <img
                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" + employee.image?.fileName || employee.user?.image?.fileName}
                                    className="rounded-circle border"
                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                    alt={employee.user.email}
                                />
                                <div className='ms-3'>
                                    <p className='fw-bold mb-1'>{employee.user.firstName} {employee.user.lastName}</p>
                                    <p className='text-muted mb-0'>{employee.user.email}</p>
                                </div>
                            </div>
                        )
                    })}
                    </td>
                    <td>
                    {/* {company.adminEmployees.items.find(employee => employee.user?.hasCompletedOnboarding) && <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Onboarded")}</MDBChip>} */}
                    {/* {company.status === "active" ? <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Active")}</MDBChip> : company.status === "deactivated" ? <MDBChip>{t("Deactivated")}</MDBChip> : company.status} */}
                   {/* IF companie has order with product type "event_booth" then show chip */}
                   {company.orders.items.find(order => order.lineItems.items.find(lineItem => lineItem.product.type === "event_booth" || lineItem.product.type === "event_booth_partner")) && 
                   <>
                    <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Booth booked")}</MDBChip>
                   <br></br>
                   {company.orders.items.map(order => order.lineItems.items.map(lineItem => <><span>{lineItem.product?.name.find(name => name.language === i18n.language)?.text || lineItem.product?.name?.[0]?.text}</span><br></br></>))}
                   </>
                  

                    }
                    {/* {company.lectures?.length > 0 && <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Lecture")}</MDBChip>}
                    {company.employees.items.find(employee => employee.user?.tickets.items.find(ticket => ticket.visitorType === "staff")) && <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Stand staff booked")}</MDBChip>} */}
                    </td>
                    
                    <td>
                   <Link to={"/admin/company/" + company.id}><MDBBtn size="sm" className="me-2"><MDBIcon fas icon="edit" /></MDBBtn></Link>
                    {/* <MDBBtn size="sm" color="danger"><MDBIcon fas icon="trash" /></MDBBtn> */}
                    </td>
                </tr>
            )
        })
    }

    useEffect(() => {
        const fetchCompanys = async () => {
            const companysFromAPI = await getCompanys(filter);
            console.log("companysFromAPI",companysFromAPI);
            setCompanys(companysFromAPI.items);
            setTotalPages(Math.ceil(companysFromAPI.total / rowsPerPage));
        }
        fetchCompanys();
    }, [rowsPerPage, filter]);

    // Table headers search collapse status

    const [tableCollapse, setTableCollapse] = useState({});

    const toggleCollapse = (field) => () => {
        setTableCollapse({ ...tableCollapse, [field]: !tableCollapse[field] });
    }

    const handleSearchSingleField = (fieldName, value) => {
        setFilter({ ...filter, [fieldName]: { matchPhrasePrefix: value } });
    }


    // Download CSV of all companies and admins (if multiple admins, add a new row for each admin), Fields: Company Name, HasBookedBooth, Admin Name, Admin Email
    const downloadCSV = async () => {
        const csv = [
            {
                companyName: "Company Name",
                hasBookedBooth: "Hat Stand gebucht",
                adminName: "Admin Name",
                adminEmail: "Admin Email"
            
            },
            ...companys.map((company) => {
                return company.adminEmployees.items.map((employee) => {
                    return {
                        companyName: company.name,
                        hasBookedBooth: company.orders.items.find(order => order.lineItems.items.find(lineItem => lineItem.product.type === "event_booth" || lineItem.product.type === "event_booth_partner")) ? "Yes" : "No",
                        adminName: employee.user.firstName + " " + employee.user.lastName,
                        adminEmail: employee.user.email
                    }
                })
            })
        ].flat().map((row) => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'companies.csv';
        a.click();
    }


    return (
        <>
<MDBBtn onClick={downloadCSV} color="primary" className="mb-3">{t("Download CSV")}</MDBBtn>
        <MDBTable>
            <MDBTableHead>
                <tr>
                    <th style={{verticalAlign: "top"}}><MDBCheckbox /></th>
                    <th style={{verticalAlign: "top"}}>{t("Company")}</th>
                    <th style={{verticalAlign: "top"}}>{t("Admins")}</th>
                    <th style={{verticalAlign: "top"}}>{t("Status")}</th>
                    <th style={{verticalAlign: "top"}}>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody style={{ verticalAlign: 'middle' }}>
            {companys.length > 0 ? buildTable(companys) : <tr><td colSpan="4">{t("Loading")}</td></tr> }
            </MDBTableBody>

        </MDBTable>
        {companys.length > 0 && 
        <>
            <nav aria-label='Page navigation'>
                <MDBPagination className='mx-auto' style={{width: "fit-content"}}>
                    <MDBPaginationItem>
                    <MDBPaginationLink  className="text-dark">Previous</MDBPaginationLink>
                    </MDBPaginationItem>{
                        totalPages > 0 && Array.from(Array(totalPages).keys()).slice(0,5).map((page) => {
                            return (
                                <MDBPaginationItem key={page} active={page + 1 === page}>
                                    <MDBPaginationLink href='#' className="text-dark">{page + 1}</MDBPaginationLink>
                                </MDBPaginationItem>
                            )
                        })
                    }
                    {
                        totalPages > 5 &&
                        <MDBDropdown>
                            <MDBDropdownToggle caret color="white" className="text-dark">
                                More
                            </MDBDropdownToggle>
                            <MDBDropdownMenu basic>
                                {
                                    Array.from(Array(totalPages).keys()).slice(5).map((page) => {
                                        return (
                                            <MDBDropdownItem link key={page} active={page + 1 === page} className="text-dark">
                                                {page + 1}
                                            </MDBDropdownItem>
                                        )
                                    })
                                }
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    }



                    <MDBPaginationItem>
                        <MDBPaginationLink  className="text-dark">Next</MDBPaginationLink>
                    </MDBPaginationItem>
                </MDBPagination>
            </nav>
            {/* Dropdown to select number of rows to show, from 10, 20, 50, 100, 200, 500 */}
            <MDBDropdown className="float-end">
                <MDBDropdownToggle caret color="white" className="text-dark">
                    {rowsPerPage}
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(10)} className="text-dark">
                        10
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(20)} className="text-dark">
                        20
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(50)} className="text-dark">
                        50
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(100)} className="text-dark">
                        100
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(200)} className="text-dark">
                        200
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(500)} className="text-dark">
                        500
                    </MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
            </>
        }

         </>
    )
}
