import { MDBIcon } from "mdb-react-ui-kit";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

export const convertHTMLtoDraft = (e, ContentState, EditorState, setEditorState) => {
    console.log(e.target.value)
    const contentBlock = htmlToDraft(e.target.value);
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
    }
}

export const convertHTMLtoDraftInit = (html, ContentState, EditorState, setEditorState) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
    }
}

export const ChangeHTMLDraft = (showDraft, setEditorModes) => {
if (showDraft) {
    setEditorModes({showDraft: true,showHTML: false})
} else {
    setEditorModes({showDraft: false,showHTML: true})
}
}

export const convertHTMlToRaw = (editorState) => draftToHtml(convertToRaw(editorState.getCurrentContent()))

export const ChangeDraftHTMLBtn = (props) => <div className="rdw-option-wrapper" onClick={() => ChangeHTMLDraft(props.showDraft, props.setEditorModes)}><MDBIcon icon="code" /></div>

