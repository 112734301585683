import { useEffect, useState, useMemo, useCallback } from 'react';
import { Eventcalendar, getJson, formatDate, localeDe, Draggable, Dropcontainer } from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession  } from "aws-amplify/auth";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCheckbox, MDBChip, MDBCol, MDBDatepicker, MDBIcon, MDBInput, MDBRow, MDBSelect, MDBTable, MDBTableBody, MDBTabs, MDBTabsItem, MDBTypography } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import moment from 'moment-timezone';

import getEventDays from '../../utils/getEventDays';
import usePrevious from "../../utils/usePrevious";

const client = generateClient();



export default function Scheduling() {

    const { t, i18n } = useTranslation();

    const config = {
        openTime: "10:00",
        closeTime: "18:00",
        rooms: 10
    }


    // States
    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [event, setEvent] = useState({});
    const [eventDays, setEventDays] = useState([]); // Event days
    const [rooms, setRooms] = useState([]); // Rooms
    const [lectures, setLectures] = useState([]); // Lectures
    const [shownLectures, setShownLectures] = useState([]);
    const [lecturesCalendar, setLecturesCalendar] = useState([]);
    const [totalLectures, setTotalLectures] = useState(0);
    const [topics, setTopics] = useState([]);
    const [topicGroups, setTopicGroups] = useState([]);

    // const [myEvents, setEvents] = useState([]); // Calendar events

    const [filters, setFilters] = useState({
      approved: null,
      createdAtStart: "2023-12-01T00:00:00.000Z",
      createdAtEnd: null,
      createdAtSort: "desc",
      status: null,
      type: null
    });
    const [filterTopicGroup, setFilterTopicGroup] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [useFilterInCalendar, setUseFilterInCalendar] = useState(false);


    const getEvents = async () => {
      try {
          const events = await client.graphql({
              query: /* GraphQL */ `
              query ListEvents {
                  listEvents {
                      items {
                          id
                          name {
                              text
                              language
                          }
                          startDate
                          endDate
                          status
                      }
                  }
              }
              `,
              // variables: {
              //     filter: {
              //         startDate: {
              //             ge: new Date().toISOString().substring(0, 10)
              //         }
              //     }
              // }
          });
          setEvents(events.data.listEvents.items.filter(event => event.status === "active"));
          return events.data.listEvents.items;
      } catch (error) {
          console.log("error on fetching events", error);
      }
  }
    // Get Event
    const getEvent = async () => {
        try {
            setIsLoading(true);
            const eventData = await client.graphql({
                query: /* GraphQL */ `
                query GetEvent($id: ID!) {
                  getEvent(id: $id) {
                    id
                    name {
                      language
                      text
                      __typename
                    }
                    startTime
                    endTime
                    lectureRooms
                    status
                    image {
                      fileName
                      alt
                      x4UUID
                      __typename
                    }
                    date
                    startDate
                    endDate
                    location {
                      __typename
                    }
                    attendees {
                      nextToken
                      __typename
                    }
                    availableBundleBooths {
                      nextToken
                      __typename
                    }  
                    x4Id
                    createdAt
                    updatedAt
                    __typename
                  }
                }
              `,
                variables: {
                    id: selectedEventId
                }
            });
            setEvent(eventData.data.getEvent);
            setEventDays(getEventDays(eventData.data.getEvent));
            // Generate rooms array. Number of rooms is defined in the event data (eventData.data.getEvent.lectureRooms = Int). content for each room is {id: index + 1, name: "Room" + index + 1, color: random color}
            const newRooms = Array.from({length: eventData.data.getEvent.lectureRooms}, (_, index) => ({id: index + 1, name: "Room " + (index + 1), color: `#${Math.floor(Math.random()*16777215).toString(16)}`}));
            setRooms(newRooms);
            console.log("eventdays", getEventDays(eventData.data.getEvent))
            console.log("rooms", newRooms);

            
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getEvents();
      
    }, []);

    useEffect(() => {
        if (selectedEventId) {
            getEvent();
        }
    }, [selectedEventId]);
    

     // Topics data

  const getTopics = async () => {
    try {
      const topicGroupsWithTopics = await client.graphql({
        query: /* GraphQL */ `
        query ListTopicGroups(
          $filter: ModelTopicGroupFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              color
              name {
                language
                text
              }
              topics {
                items {
                  id
                  name {
                    language
                    text
                  }
                  topicGroup {
                    color
                  }
                  topicGroupTopicsId
                }
              }
            }
            nextToken
            __typename
          }
        }
      `
        });
        setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items);
        const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
        setTopics(topics);

    } catch (error) {
        console.log("error on fetching topics", error);
    }
}

useEffect(() => {
    if (topics.length === 0) {
        getTopics();
    }
}, [topics]);
    

   
    const extractExistingParams = (searchParams) => {
        return Array.from(searchParams.entries()).reduce((acc, [key, value]) => {
            if (value === 'true') {
                acc[key] = true;
            } else if (value === 'false') {
                acc[key] = false;
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
    }

    

    const getLectures = async () => {
        try {
            setIsLoading(true);
            const searchLectureFilter = {
            eventLecturesId: { eq: selectedEventId }
            };
            const searchLectureSort = {};
            if (typeof filters.approved === "boolean") {
                searchLectureFilter.approved = { eq: filters.approved };
            }
            if (filters.createdAtStart && filters.createdAtEnd) {
                searchLectureFilter.and = [{createdAt: {gt: filters.createdAtStart}}, {createdAt: {lt: filters.createdAtEnd}}];
            } else if (filters.createdAtStart) {
                searchLectureFilter.createdAt = { gt: filters.createdAtStart };
            } else if (filters.createdAtEnd) {
                searchLectureFilter.createdAt = { lt: filters.createdAtEnd };
            }
            if (filters.createdAtSort) {
                searchLectureSort.field = "createdAt";
                searchLectureSort.direction = filters.createdAtSort;
            }
            if (filters.status) {
                searchLectureFilter.status = { eq: filters.status };
            }
            if (filters.type) {
                searchLectureFilter.type = { eq: filters.type };
            } 
            else {
                searchLectureFilter.dateTimes = { exists: false };
            }

            const lectures = await client.graphql({
                variables: {
                    filter: searchLectureFilter,
                    sort: searchLectureSort,
                    limit: 1000
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                      lengthInSeconds
                        title {
                            language
                            text
                        }
                        speakers {
                            items {
                                employee {
                                    image {
                                    fileName
                                    alt
                                    }
                                }
                            }
                        }
                        subjects {
                            id
                            name {
                                language
                                text
                            }
                        } 
                        topics {
                            items {
                              topic {
                                id
                                topicGroupTopicsId
                                name {
                                  language
                                  text
                                }
                              }
                            }
                        }
                        adminTopic {
                          id
                        }    
                        content {
                            language
                            text
                        }
                        conditions {
                            name
                            accepted
                        }
                        image {
                            fileName
                            alt
                        }
                        pdfDe {
                            url
                            name   
                        }
                        pdfEn {
                            url
                            name   
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        conditions {
                            name
                            accepted
                        }
                        id
                        session
                        startTime
                        endTime
                        room
                        type
                        presentationLanguage
                        createdAt
                        updatedAt
                        companyLecturesId
                        eventLecturesId
                        lectureMainSpeakerId
                        approved
                        status
                        __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            setLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})).sort((a, b) => {if (a.company.name < b.company.name) return -1;if (a.company.name > b.company.name) return 1;return a.createdAt < b.createdAt ? -1 : 1;}))
            setShownLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})).filter(lecture => !lecture.startTime).sort((a, b) => {if (a.company.name < b.company.name) return -1;if (a.company.name > b.company.name) return 1;return a.createdAt < b.createdAt ? -1 : 1;}));
            setTotalLectures(lectures.data.searchLectures.total);
            setIsLoading(false);
        } catch (error) {
            console.log("error on fetching lectures", error);
            if (error.data?.searchLectures?.items) {
                setLectures(error.data.searchLectures.items);
                setShownLectures(error.data.searchLectures.items);
            }
            setIsLoading(false);
        }
    };

    const [filtersInitialized, setFiltersInitialized] = useState(false);

    useEffect(() => {
        // Beim ersten Render die Filter aus den searchParams extrahieren und setzen
        const extractedParams = extractExistingParams(searchParams);
        setFilters(oldFilters => ({ ...extractedParams, createdAtStart: extractedParams.createdAtStart || oldFilters.createdAtStart , createdAtSort: extractedParams.createdAtSort || oldFilters.createdAtSort }));
        setFiltersInitialized(true); // Setze auf true, sobald die Initialisierung abgeschlossen ist
    }, []); // Leere Abhängigkeiten, um den Hook nur einmal auszuführen

    useEffect(() => {
        if (filtersInitialized && selectedEventId) {
            // Aktualisiere die searchParams und rufe getLectures auf, nachdem die Filter initialisiert wurden
            const params = Object.entries(filters).reduce((acc, [key, value]) => {
                if (value !== null) {
                    acc[key] = value;
                }
                return acc;
            }, {});

            setSearchParams(params);
            getLectures();
        }
    }, [filters, filtersInitialized, selectedEventId]); // Abhängigkeit von den Filters und filtersInitialized

    
    const searchLectures = (searchTerm) => {
      if (searchTerm.length > 0) {
          setShownLectures(lectures.filter(lecture => {
              if (lecture.company?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                  return true;
              }
              if (lecture.title?.find(t => t.language === i18n.language)?.text?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                  return true;
              }
              return false;
          }).filter(lecture => !lecture.startTime));
          if(useFilterInCalendar) {
              setLecturesCalendar(lectures.filter(lecture => {
                  if (lecture.company?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                      return true;
                  }
                  if (lecture.title?.find(t => t.language === i18n.language)?.text?.toLowerCase()?.includes(searchTerm.toLowerCase())) {
                      return true;
                  }
                  return false;
              }));
          }
      } else {
          setShownLectures(lectures.filter(lecture => !lecture.startTime));
      }
  }

  const filterByTopicGroup = async (topicGroupId) => {
      if (topicGroupId) {
          setShownLectures(lectures.filter(lecture => topics.find(topic => topic.id === lecture.adminTopic?.id)?.topicGroupTopicsId === topicGroupId).filter(lecture => !lecture.startTime));
      } else {
          setShownLectures(lectures.filter(lecture => !lecture.startTime));
      }
  }

  useEffect(() => {
      if (filterTopicGroup) {
          filterByTopicGroup(filterTopicGroup);
      }
  }, [filterTopicGroup]);






  const [selectedConditions, setSelectedConditions] = useState([]); // selectedConditions = ["ECV", "Podcast"
  const prevConditions = usePrevious(selectedConditions);

  const filterByConditions = async () => { // selectedConditions = ["ECV", "Podcast"]
      console.log(selectedConditions);
     
      if (selectedConditions.length > 0) {
         
          setShownLectures(shownLectures.filter(lecture => {
              if(lecture.conditions?.length > 0) {
              if (selectedConditions.includes("ECV") && selectedConditions.includes("Podcast")) {
                  return (lecture.conditions?.find(condition => condition.name === "ecv")?.accepted && lecture.conditions?.find(condition => condition.name === "podcast")?.accepted);
              } else if (selectedConditions.includes("ECV")) {
                  return lecture.conditions?.find(condition => condition.name === "ecv")?.accepted;
              } else if (selectedConditions.includes("Podcast")) {
                  return lecture.conditions?.find(condition => condition.name === "podcast")?.accepted;
              }
              return false;
          } else {
              return false;
          }
          }).filter(lecture => !lecture.startTime));
      } else {
          setShownLectures(lectures.filter(lecture => !lecture.startTime));
      }
  }

  useEffect(() => {
     // If something changed in the selectedConditions, filter the lectures
       if (prevConditions !== selectedConditions) {
      filterByConditions(selectedConditions);
      }
  }, [selectedConditions, shownLectures]);

    // Calendar components


    function Task({lecture, id}) {
      const [draggable, setDraggable] = useState();
      const setDragElm = useCallback((elm) => {
        setDraggable(elm);
      }, []);
      if(!lecture.title) console.log("lecture has no title", lecture.id);
      let event = {
        id: lecture.id,
        title: lecture.title?.find((title) => title.language === i18n.language)?.text,
        length: "0.5 h",//lecture.lengthInSeconds ? lecture.lengthInSeconds + ' m' : '30 m',
        start: "10:00",
        end: lecture.lengthInSeconds ? new Date(new Date(`1970-01-01T10:00:00Z`).getTime() + (lecture.lengthInSeconds || 30) * 1000).toISOString().substr(11, 5) : "10:30",
        color: topics.find(topic => topic.id === lecture.adminTopic?.id)?.topicGroup?.color || "fff",
        cssClass: "external-drop-task",
        lecture
      };
      return (
        <div>
          {!event.hide && (
            <div ref={setDragElm} className="external-drop-task mb-2">
            <MDBCard className={"shadow-3" + (lecture.type?.includes("promotion") ? " border border-dark" : "")}  style={{backgroundColor: topics.find(topic => topic.id === lecture.adminTopic?.id)?.topicGroup?.color || "fff", cursor: "grab" }}>
              <MDBCardBody className="p-2">
              <div className='d-flex align-items-center'>
              
                {lecture.company?.image?.main?.fileName ?
                    <div className='d-flex align-items-center'>
                        {lecture.company.image?.main?.fileName ?
                            <img
                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                                className="rounded-circle"
                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                alt={lecture.company.image.alt}
                            />
                        :
                            <MDBIcon className="rounded-circle" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                    
                        }
                        
                    </div>
                    :
                    <MDBIcon
                        className="rounded-circle"
                        style={{
                            height: "50px",
                            width: "50px",
                            textAlign: "center",
                            paddingTop: "12px",
                            fontSize: "20px",
                        }}
                        fas
                        icon="user-alt"
                    />
                }
               <div className="ms-2">
               <span className="fw-bold" style={{lineHeight: "1", display: "block", fontSize: "15px"}}>{(lecture.title.find((title) => title.language === i18n.language)?.text || lecture.title[0]?.text).substring(0, 50)}</span>
               <span className="d-block mt-2" style={{lineHeight: "1", fontSize: "10px"}}>{lecture.company.name}</span>
              </div>
               </div>
              </MDBCardBody>
            </MDBCard>
            <Draggable dragData={event} element={draggable} />
            </div>
          )}
        </div>
      );
    }

    const customScheduleEvent = useCallback((data) => {
      const lecture = data.original.lecture;
      if(!lecture) return null;
        return (
          <MDBCard className="shadow-3 h-100" style={{backgroundColor: data.color }} onDoubl>
              <MDBCardBody className="p-1">
              <div className='d-flex align-items-center'>
              
                {lecture.company?.image?.main?.fileName ?
                    <div className='d-flex align-items-center'>
                        {lecture.company.image?.main?.fileName ?
                            <img
                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                                className="rounded-circle"
                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                alt={lecture.company.image.alt}
                            />
                        :
                            <MDBIcon className="rounded-circle" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                    
                        }
                        
                    </div>
                    :
                    <MDBIcon
                        className="rounded-circle"
                        style={{
                            height: "50px",
                            width: "50px",
                            textAlign: "center",
                            paddingTop: "12px",
                            fontSize: "20px",
                        }}
                        fas
                        icon="user-alt"
                    />
                }
               <div className="ms-2">
               <span className="fw-bold" style={{lineHeight: "1", display: "block", fontSize: "12px", color: "#2b2b2b"}}>{(lecture.title.find((title) => title.language === i18n.language)?.text || lecture.title[0]?.text).substring(0, 50)}</span>
               <span className="d-block mt-1" style={{lineHeight: "1", fontSize: "10px", color: "#2b2b2b"}}>{lecture.company.name.substring(0, 50)}</span>
              </div>
               </div>
              </MDBCardBody>
            </MDBCard>
        );
      
    }, []);

    const renderCustomResource = (resource) => {
        return <div className="header-resource-template-content">
                <img className="header-resource-avatar" src={resource.img} />
                <div className="header-resource-name">{resource.name}</div>
        </div>;
    }
  
      const [dropCont, setDropCont] = useState();
  const setDropElm = useCallback((elm) => {
    setDropCont(elm);
  }, []);

  const updateLecture = async (lecture) => {
    try {
      const updatedLecture = await client.graphql({
        query: /* GraphQL */ `
        mutation UpdateLecture(
          $input: UpdateLectureInput!
          $condition: ModelLectureConditionInput
        ) {
          updateLecture(input: $input, condition: $condition) {
            id
          }
        }
      `,
        variables: {
          input: {
            id: lecture.id,
            room: lecture.room,
            startTime: lecture.startTime,
            endTime: lecture.endTime
          }
        }
      });
      // console.log("updatedLecture", updatedLecture);
    } catch (error) {
      console.log("error on updating lecture", error);
    }
  }



  const onEventCreate = useCallback(
    async (args) => {
      console.log("args create", args)
      try {
        const startTime = moment(args.event.start).tz('Europe/Berlin').format();
        const endTime = moment(args.event.end).tz('Europe/Berlin').format();
        const updatedLecture = updateLecture({...args.event.lecture, startTime, endTime, room: args.event.resource});
        // console.log("updatedLecture", updatedLecture);


        setLectures(lectures.map(lecture => {
          if (lecture.id === args.event.id) {
              return {
                ...lecture,
                startTime: args.event.start.toISOString(),
                endTime: args.event.end.toISOString(),
                room: args.event.resource
              };
          }
          return lecture;
        }));

        setShownLectures(oldShownLectures => oldShownLectures.filter(lecture => lecture.id !== args.event.id && lecture !== null && !lecture.startTime).sort((a, b) => {if (a.company.name < b.company.name) return -1;if (a.company.name > b.company.name) return 1;return a.createdAt < b.createdAt ? -1 : 1;}));
        
        setLecturesCalendar(oldLecturesCalendar => [...oldLecturesCalendar, {...args.event.lecture, startTime, endTime, room: args.event.resource}] );
      } catch (error) {
        console.log("error on updating lecture", error);
      }
    },
    [lectures],
  );

  const onEventDelete = useCallback(async (args) => {
    console.log("args delete", args)
    try {
      const updatedLecture = updateLecture({...args.event.lecture, startTime: null, endTime: null, room: null});
      console.log("updatedLecture", updatedLecture);
      setLectures(oldLectures => oldLectures.map(lecture => {
        if (lecture.id === args.event.id) {
            return {
                ...lecture,
                startTime: null,
                endTime: null,
                room: null
            };
        }
        return lecture;
      }));
    
      setShownLectures(oldShownLectures => [...oldShownLectures, {...args.event.lecture, startTime: null, endTime: null, room: null}].sort((a, b) => {if (a.company.name < b.company.name) return -1;if (a.company.name > b.company.name) return 1;return a.createdAt < b.createdAt ? -1 : 1;}));
    
      setLecturesCalendar(oldLecturesCalendar => oldLecturesCalendar.filter(lecture => lecture.id !== args.event.lecture.id));
      
    } catch (error) {
      console.log("error on updating lecture", error);
    }
   
  }, []);

  const onEventUpdate = useCallback((args) => {
    console.log("args update", args)
    try {
      const startTime = moment(args.event.start).tz('Europe/Berlin').format();
      const endTime = moment(args.event.end).tz('Europe/Berlin').format();
      const updatedLecture = updateLecture({...args.event.lecture, startTime, endTime, room: args.event.resource});
      setLectures(oldLectures => oldLectures.map(lecture => {
        if (lecture.id === args.event.id) {
            return {
                ...lecture,
                startTime,
                endTime,
                room: args.event.resource
            };
        }
        return lecture;
    }));
      console.log("updatedLecture", updatedLecture);
    } catch (error) {
      console.log("error on updating lecture", error);
    }
  }, []);


  const onItemDrop = (args) => {
    console.log("args drop", args)
  }
   

    return (
        
        <>
        <MDBCard className="w-auto">
            <MDBCardBody className="flex-column">
                    <MDBRow className="mb-2">
                        <MDBCol size="12" md="6" xl="3">
                        <MDBSelect label={t("Event")}
                            onChange={event => setSelectedEventId(event.value)}
                            value={selectedEventId}
                            data={
                                [
                                    { text: t("All"), value: null },
                                    ...events.map(event => ({ text: event.name.find(n => n.language === i18n.language)?.text || event.name[0]?.text, value: event.id }))
                                ]
                            }
                        />
                        </MDBCol>
                      </MDBRow>
                    <MDBRow>
                        <MDBCol md="6">
                        <MDBInput type="text" onChange={e => searchLectures(e.target.value)} label={t("Search")} />
                        </MDBCol>
                        <MDBCol size="6" md="3">
                        <MDBDatepicker
                            label={t("Start Date")}
                            inline
                            disableFuture
                            defaultValue={filters.createdAtStart && new Date(filters.createdAtStart).toLocaleDateString('en-GB')}
                            value={filters.createdAtStart && new Date(filters.createdAtStart).toLocaleDateString('en-GB')}
                            onChange={date => date && setFilters({ ...filters, createdAtStart: new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).toISOString()})}
                          />
                        </MDBCol>
                        <MDBCol size="6" md="3">
                        <MDBDatepicker
                            label={t("End Date")}
                            inline
                            disableFuture
                            defaultValue={filters.createdAtEnd && new Date(filters.createdAtEnd).toLocaleDateString('en-GB')}
                            value={filters.createdAtEnd && new Date(filters.createdAtEnd).toLocaleDateString('en-GB')}
                            onChange={date => date && setFilters({ ...filters, createdAtEnd: new Date(date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')).toISOString()})}
                          />
                        </MDBCol>
                    </MDBRow>
                    
                    
                    <MDBRow className="mt-2 mt-xl-3">
                        <MDBCol md="3">
                            <MDBSelect label={t("Type")}
                                onChange={type => type && setFilters({ ...filters, type: type.value})}
                                value={filters.type}
                                data={
                                    [
                                        { text: t("All"), value: null },
                                        { text: t("Marketing"), value: "marketing" },
                                        { text: t("Technical"), value: "technical" },
                                        { text: t("Educational"), value: "educational" },
                                        { text: t("Recruiting"), value: "recruiting" },
                                        { text: t("Promotion Marketing"), value: "promotion_marketing" },
                                        { text: t("Promotion Technical"), value: "promotion_technical" }
                                    ]
                                }
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBSelect label={t("Status")}
                                onChange={selected => selected && setFilters({ ...filters, status: selected.value})}
                                value={filters.status}
                                data={
                                    [   
                                        { text: t("All"), value: null },
                                        { text: t("Open"), value: "created" },
                                        { text: t("Approved"), value: "checked" },
                                        { text: t("Not Approved"), value: "not_approved" },
                                        { text: t("In Planning"), value: "in_planning" },
                                        { text: t("Committed"), value: "committed" },
                                    ]
                                }
                            />
                        </MDBCol>
                        
                        <MDBCol md="1">
                            <MDBCheckbox label="ECV" onChange={e => setSelectedConditions(e.target.checked ? [...selectedConditions, "ECV"] : selectedConditions.filter(c => c !== "ECV"))} />
                        </MDBCol>
                        <MDBCol md="1">
                            <MDBCheckbox label="Podcast" onChange={e => setSelectedConditions(e.target.checked ? [...selectedConditions, "Podcast"] : selectedConditions.filter(c => c !== "Podcast"))} />
                        </MDBCol>
                        <MDBCol md="1">
                        <MDBIcon className="float-end" fas icon="sync-alt" onClick={() =>  getLectures()} />
                        </MDBCol>
                        <MDBCol md="3">
                              <MDBCheckbox label={t("Filter in Calendar")} onChange={e => setUseFilterInCalendar(e.target.checked)} />
                        </MDBCol>

                        
                    </MDBRow>
                    <div className="d-flex mt-3">
                    <MDBChip key="actions" className="me-2 mb-2" color='dark' onClick={() => setFilterTopicGroup(null)} >
                      {t("All")}
                      </MDBChip>
                    {topicGroups.map((topicGroup, index) => (
                          <MDBChip key={index} className="me-2 mb-2" style={{backgroundColor: topicGroup.color}} onClick={() => setFilterTopicGroup(topicGroup.id)} >
                          {topicGroup.name.find((name) => name.language === i18n.language)?.text}
                          </MDBChip>
                      ))}
                      
                    </div>
            </MDBCardBody>
        </MDBCard>
        <MDBRow className="mt-4">
            <MDBCol size="3" style={{maxHeight: "95vh", overflowY: "scroll", overflowX: "hidden"}}>
            <div className="mbsc-col-sm-3 external-drop-cont" ref={setDropElm}>
            <Dropcontainer onItemDrop={onItemDrop} element={dropCont}>
                { isLoading ? <tr><td colSpan="8" className="text-center"><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></td></tr>
                  : shownLectures.length > 0 ?
                      shownLectures.map((lecture, index) => {
                          return (
                            <Task
                            key={lecture.id}
                            lecture={lecture} />
                            
                          )
                      })
                      :
                      <tr>
                          <td colSpan="7">
                              {t("No lectures found")}
                          </td>
                      </tr>
                  }
            </Dropcontainer>
            </div>
            </MDBCol>
            <MDBCol size="9" style={{maxHeight: "95vh", overflowY: "scroll", overflowX: "hidden"}}>

            {eventDays.map((day, index) => (
                <div key={index} style={{height: "1250px", overflowX: "scroll", overflowY: "hidden"}}>
                <Eventcalendar
                    style={{width: "1200px"}}
                
                    theme="ios"
                    themeVariant="light"
                    data={useFilterInCalendar ? lecturesCalendar.filter(lecture => lecture.startTime && lecture.endTime && moment(lecture.startTime).format("YYYY-MM-DD") === day).map(lecture => {
                        return {
                            id: lecture.id,
                            title: lecture.title.find((title) => title.language === i18n.language)?.text,
                            start: new Date(lecture.startTime),
                            end: new Date(lecture.endTime),
                            resource: lecture.room,
                            color: topics.find(topic => topic.id === lecture.adminTopic?.id)?.topicGroup?.color || "fff",
                            lecture
                        }
                    } )
                    
                    : lectures.filter(lecture => lecture.startTime && lecture.endTime && moment(lecture.startTime).format("YYYY-MM-DD") === day).map(lecture => {
                        return {
                            id: lecture.id,
                            title: lecture.title.find((title) => title.language === i18n.language)?.text,
                            start: new Date(lecture.startTime),
                            end: new Date(lecture.endTime),
                            resource: lecture.room,
                            color: topics.find(topic => topic.id === lecture.adminTopic?.id)?.topicGroup?.color || "fff",
                            lecture
                        }
                    } )
                    }
                    clickToCreate={true}
                    dragToCreate={true}
                    dragToMove={true}
                    dragToResize={true}
                    eventDelete={true}
                    externalDrop={true}
                    externalDrag={true}
                    onEventCreate={onEventCreate}
                    onEventDelete={onEventDelete}
                    onEventUpdate={onEventUpdate}
                    locale={localeDe}
                    invalid={[{
                        start: '00:00',
                        end: config.openTime,
                        recurring: {
                            repeat: 'daily'
                        }
                    }, {
                        start: config.closeTime,
                        end: '23:59',
                        recurring: {
                            repeat: 'daily'
                        }
                    }]}
                    colors={[
                      {
                        start: '12:00',
                        end: '13:00',
                        background: '#dddbff85',
                        recurring: {
                          repeat: 'daily'
                        },
                      },
                    ]}
                    view={{
                        schedule: {
                            type: 'day',
                            timeCellStep: 60,
                            timeLabelStep: 30,
                            startTime: config.openTime,
                            endTime: config.closeTime
                        }
                    }}
                    resources={rooms}
                    renderScheduleEvent={customScheduleEvent}
                    groupBy="date"
                    selectedDate={day}
                />
                </div>
            ))}
       
            </MDBCol>
        </MDBRow>
           
      
        </>
    ); 
}
