import React, { useState, useEffect } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { generateClient } from 'aws-amplify/api';

const client = generateClient();

export default function SpeakersTab({ seminarData, setSeminarData }) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSpeakers, setSelectedSpeakers] = useState([]);

  // Table Settings
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({});
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (seminarData.speakers?.items) {
      setSelectedSpeakers(seminarData.speakers.items.map(item => item.user));
    }
  }, [seminarData.speakers]);

  useEffect(() => {
    if (search.length > 0) {
      setFilter({
        or: [
          { firstName: { regexp: `.*${search.toLowerCase()}.*` }},
          { lastName: { regexp: `.*${search.toLowerCase()}.*` }},
          { email: { regexp: `.*${search}.*` }},
          { email: { eq: search }}
        ]
      });
    } else {
      setFilter({});
    }
  }, [search]);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const variables = {
        limit: rowsPerPage,
        from: (page - 1) * rowsPerPage,
        filter,
        sort: [{ field: 'lastName', direction: 'asc' }]
      };

      const { data } = await client.graphql({
        query: /* GraphQL */ `
          query SearchUsers(
            $filter: SearchableUserFilterInput
            $sort: [SearchableUserSortInput]
            $limit: Int
            $from: Int
          ) {
            searchUsers(
              filter: $filter
              sort: $sort
              limit: $limit
              from: $from
            ) {
              items {
                id
                firstName
                lastName
                email
                image {
                  fileName
                  alt
                }
                title
              }
              total
            }
          }
        `,
        variables
      });

      setUsers(data.searchUsers.items);
      setTotalPages(Math.ceil(data.searchUsers.total / rowsPerPage));
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      fetchUsers();
    }
  }, [openModal, page, rowsPerPage, filter]);

  const handleSelectSpeaker = (user) => {
    const isAlreadySelected = selectedSpeakers.some(s => s.id === user.id);
    
    if (isAlreadySelected) {
      setSelectedSpeakers(selectedSpeakers.filter(s => s.id !== user.id));
    } else {
      setSelectedSpeakers([...selectedSpeakers, user]);
    }
  };

  const handleSaveSpeakers = () => {
    setSeminarData(prev => ({
      ...prev,
      speakers: selectedSpeakers.map(speaker => ({
        user: speaker,
        userId: speaker.id
      }))
    }));
    setOpenModal(false);
  };

  const handleRemoveSpeaker = (speakerId) => {
    setSelectedSpeakers(selectedSpeakers.filter(s => s.id !== speakerId));
    setSeminarData(prev => ({
      ...prev,
      speakers: prev.speakers.filter(s => s.userId !== speakerId)
    }));
  };

  return (
    <div className="p-4">
      <MDBRow>
        <MDBCol size="12">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <MDBTypography tag="h5">{t("Speakers")}</MDBTypography>
            <MDBBtn onClick={() => setOpenModal(true)}>
              <MDBIcon fas icon="plus" className="me-2" />
              {t("Add Speaker")}
            </MDBBtn>
          </div>

          {/* Selected Speakers List */}
          <MDBCard>
            <MDBCardBody>
              {selectedSpeakers.length === 0 ? (
                <div className="text-center p-4">
                  <MDBIcon far icon="user-circle" size="3x" className="mb-3" />
                  <p className="mb-0">{t("No speakers selected")}</p>
                </div>
              ) : (
                <MDBTable>
                  <MDBTableHead>
                    <tr>
                      <th>{t("Image")}</th>
                      <th>{t("Name")}</th>
                      <th>{t("Actions")}</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {selectedSpeakers.map((speaker) => (
                      <tr key={speaker.id}>
                        <td style={{ width: '50px' }}>
                          {speaker.image?.fileName ? (
                            <img
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.image.fileName}`}
                              alt={`${speaker.firstName} ${speaker.lastName}`}
                              className="rounded-circle"
                              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                            />
                          ) : (
                            <MDBIcon
                              fas
                              icon="user-alt"
                              className="rounded-circle border"
                              style={{ height: '40px', width: '40px', textAlign: 'center', paddingTop: '12px', fontSize: '20px' }}
                            />
                          )}
                        </td>
                        <td>
                          {speaker.title ? speaker.title + " " : ""}{speaker.firstName} {speaker.lastName}
                        </td>
                        <td>
                          <MDBBtn
                            color="danger"
                            floating
                            size="sm"
                            onClick={() => handleRemoveSpeaker(speaker.id)}
                          >
                            <MDBIcon fas icon="times" />
                          </MDBBtn>
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      {/* User Selection Modal */}
      <MDBModal open={openModal} setOpen={setOpenModal} size="lg">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t("Select Speakers")}</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={() => setOpenModal(false)}
              />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                type="text"
                label={t("Search users")}
                className="mb-3"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />

              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th style={{width: '50px'}}></th>
                    <th>{t("Name")}</th>
                    <th>{t("Email")}</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="3" className="text-center">{t("Loading")}</td>
                    </tr>
                  ) : (
                    users.map((user) => (
                      <tr
                        key={user.id}
                        onClick={() => handleSelectSpeaker(user)}
                        style={{ cursor: 'pointer' }}
                        className={selectedSpeakers.some(s => s.id === user.id) ? 'table-active' : ''}
                      >
                        <td>
                          {user.image?.fileName ? (
                            <img
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${user.image.fileName}`}
                              alt={`${user.firstName} ${user.lastName}`}
                              className="rounded-circle"
                              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                            />
                          ) : (
                            <MDBIcon
                              fas
                              icon="user-alt"
                              className="rounded-circle border"
                              style={{ height: '40px', width: '40px', textAlign: 'center', paddingTop: '12px', fontSize: '20px' }}
                            />
                          )}
                        </td>
                        <td>{user.title ? user.title + " " : ""}{user.firstName} {user.lastName}</td>
                        <td>{user.email}</td>
                      </tr>
                    ))
                  )}
                </MDBTableBody>
              </MDBTable>

              {users.length > 0 && (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <MDBPagination className='mb-0'>
                    <MDBPaginationItem>
                      {page !== 1 && 
                        <MDBPaginationLink onClick={() => setPage(page - 1)}>
                          {t("Previous")}
                        </MDBPaginationLink>
                      }
                    </MDBPaginationItem>
                    {[...Array(totalPages)].slice(0, 5).map((_, i) => (
                      <MDBPaginationItem key={i} active={i + 1 === page}>
                        <MDBPaginationLink onClick={() => setPage(i + 1)}>
                          {i + 1}
                        </MDBPaginationLink>
                      </MDBPaginationItem>
                    ))}
                    <MDBPaginationItem>
                      {page !== totalPages && 
                        <MDBPaginationLink onClick={() => setPage(page + 1)}>
                          {t("Next")}
                        </MDBPaginationLink>
                      }
                    </MDBPaginationItem>
                  </MDBPagination>

                  <MDBDropdown>
                    <MDBDropdownToggle color="white" className="text-dark">
                      {rowsPerPage}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      {[10, 20, 50, 100].map(size => (
                        <MDBDropdownItem
                          key={size}
                          link
                          onClick={() => setRowsPerPage(size)}
                        >
                          {size}
                        </MDBDropdownItem>
                      ))}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => setOpenModal(false)}>
                {t("Cancel")}
              </MDBBtn>
              <MDBBtn onClick={handleSaveSpeakers}>
                {t("Save")}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
}