import React from "react";
import { useState, useEffect } from "react";
import {
  MDBCol,
  MDBRow,
  MDBTypography,
  MDBIcon,
  MDBContainer,
  MDBListGroup,
  MDBListGroupItem,
  MDBCheckbox,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInputGroup,
  MDBTable,
  MDBTableBody,
  MDBAlert,
  MDBSwitch,
  MDBInput,
} from "mdb-react-ui-kit";

import AddEmployees from "../../../../../manage-company/AddEmployees"
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

//props: isMarketing: boolean

export default function ChooseSpeaker({ lecture, handleChange, handleNextClick, employees, reloadEmployees }) {
  const { t, i18n } = useTranslation();

  const companyid = window.location.pathname.includes("mycompany") ? window.location.pathname.split("/")[2] : window.location.pathname.split("/")[3];

  const [alertDanger, setAlertDanger] = useState(false);

  const isMarketing = lecture?.type === "marketing";

  
  
  

  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employeeSearchInput, setEmployeeSearchInput] = useState("");

  const filterEmployeesBySearchInput = () => {
    if (employeeSearchInput === "") {
      setFilteredEmployees([])
    } else {
      const filteredEmployees = employees.filter((employee) => {
        const fullName = `${employee.user.title ? employee.user.title + " " : ""}${employee.user.firstName} ${employee.user.middleName || ""} ${employee.user.lastName}`;
        return fullName.toLowerCase().includes(employeeSearchInput.toLowerCase());
      });
      setFilteredEmployees(filteredEmployees);
    }
  };


  const handleChangeSearchEmployeeInput = (e) => {
    setEmployeeSearchInput(e.target.value);
    filterEmployeesBySearchInput()
  };

  

  //Handle Modal
  const [addEmployeesModal, setAddEmployeesModal] = useState(false);
  const toggleAddEmployeesModal = () => {
    reloadEmployees();
    setAddEmployeesModal(!addEmployeesModal);
  }



  //onClick -> selectEmployee
  const selectEmployee = (employeeId) => {
    if (lecture?.speakers?.length === 0) {
      handleChange({target: {
        value: employeeId,
        attributes: {
          datapath: {
            value: "mainSpeaker"
          }}
      }})
    }
      handleChange({target: {
        value: employeeId,
        attributes: {
          datapath: {
            value: lecture?.speakers?.length > 0 ? "speakers[" + lecture.speakers.length + "]" : "speakers[0]"
          }}
      }})
      // If no speakers are selected, set the first speaker as main speaker
     
    }

  //onClick -> unselectEmployee
  const unselectEmployee = (employeeId) => {
    handleChange({target: {
      value: null,
      attributes: {
        datapath: {
          value: "speakers[" + lecture.speakers.findIndex((id) => id === employeeId) + "]"
        }}
    }})
  };



  //create selected employee HTML elements
  const selectedEmployeeElements = lecture?.speakers?.map(
    (selectedEmployeeId, index) => {
      console.log("employees in choose speaker", employees)
      console.log("selectedEmployeeId", selectedEmployeeId)
      const employee = employees.find((employee) => employee.id === selectedEmployeeId);
      if(!employee) return null;
      const fullName = `${employee.user.title ? employee.user.title + " " : ""}${employee.user.firstName} ${employee.user.middleName || ""} ${employee.user.lastName}`;
      const department = employee.department?.items?.[0]?.department.name[0].text;

      return (
        <MDBListGroupItem
          key={index}
          noBorders
          className="d-flex justify-content-between align-items-center p-4"
        >
          <div className="d-flex gap-4 align-items-center">
            {employee.image?.fileName ? (
              <img
                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                className="rounded-circle border"
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
                alt={employee.image.alt}
              />
            ) : (
              <MDBIcon
                className="rounded-circle border"
                style={{
                  height: "50px",
                  width: "50px",
                  textAlign: "center",
                  paddingTop: "12px",
                  fontSize: "20px",
                }}
                fas
                icon="user-alt"
              />
            )}
            <div>
              <h6>{fullName}</h6>
              <span>{department}
              <MDBSwitch
              id='switchMainSpeaker'
              label={t("Main speaker")}
              checked={lecture?.lectureMainSpeakerId === selectedEmployeeId}
              dataPath="lectureMainSpeakerId"
              onChange={e => handleChange({
                target: {
                  value: e.target.checked ? selectedEmployeeId : null,
                  attributes: {
                    datapath: {
                      value: "lectureMainSpeakerId"
                    }
                  }
                }
              })}
              />
              </span>
              {/*
              {index === 0 && setMainRef(selectedEmployee.id)}
              <MDBSwitch
                checked={selectedEmployee.id === mainRef}
                id={index}
                label="Haupt-Refferent"
                onChange={() => handleMainRefChange(selectedEmployee.id)}
              />*/}
            </div>
          </div>
          <MDBBtn
            color="tertiary"
            onClick={() => unselectEmployee(employee.id)}
          >
            <MDBIcon fas icon="trash-alt" color="danger" style={{fontSize: "1.2rem"}} />
          </MDBBtn>
        </MDBListGroupItem>
      );
    }
  );
  
  
  
  const handleContinue = () => {
    handleNextClick()
  };


  return (
    <>
      <MDBContainer style={{minHeight: "700px"}}>
        <MDBTypography tag="h3">{t("Select presenting persons")}</MDBTypography>
        <p className="mb-5 text-muted">{t("You can select multiple presenting persons. If the person is not listed in your employees list, you have to add them to your company first.")}</p>
        <MDBRow className="justify-content-evenly">
          <MDBCol size="12" lg="6" xxl="5" className="bg-white p-3 rounded-4 shadow-4">
            <MDBInputGroup className="mb-3">
              <MDBIcon fas icon="search" className="input-group-text pt-2" />
              <MDBInput
                placeholder={t("Search employee")}
                type="text"
                onChange={handleChangeSearchEmployeeInput}
              />
              
            </MDBInputGroup>
            <MDBTable responsive style={{overflow: "scroll", maxHeight: "500px"}}>
              <MDBTableBody>{employees?.length > 0 && 
              (filteredEmployees.length > 0 ? 
                filteredEmployees.map((employee) => {
                  if (lecture?.speakers.find((id) => id === employee.id)) {
                    return null;
                  }

                  return (
                    <tr onClick={() => selectEmployee(employee.id)}>
                      <td key={employee.id} className="d-flex gap-3">
                        <div className="d-flex gap-4 align-items-center">
                          {employee.image?.fileName ? (
                            <img
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                              className="rounded-circle border"
                              style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                              }}
                              alt={employee.image.alt}
                            />
                          ) : (
                            <MDBIcon
                              className="rounded-circle border"
                              style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                              }}
                              fas
                              icon="user-alt"
                            />
                          )}
                          <div>
                            <h6>{`${employee.user.title ? employee.user.title + " " : ""}${employee.user.firstName} ${employee.user.middleName || ""} ${employee.user.lastName}`}</h6>
                            <span>{employee.department?.items?.[0]?.department.name[0].text}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <MDBBtn color="tertiary">{t("Select")}</MDBBtn>
                      </td>
                    </tr>
                  );
                })
                .filter(Boolean)
               : 
               employees.map((employee) => {
                  if (lecture?.speakers?.find((id) => id === employee.id)) {
                    return null;
                  }

                  return (
                    <tr onClick={() => selectEmployee(employee.id)}>
                      <td key={employee.id} className="d-flex gap-3">
                        <div className="d-flex gap-4 align-items-center">
                          {employee.image?.fileName ? (
                            <img
                              src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                              className="rounded-circle border"
                              style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                              }}
                              alt={employee.image.alt}
                            />
                          ) : (
                            <MDBIcon
                              className="rounded-circle border"
                              style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                              }}
                              fas
                              icon="user-alt"
                            />
                          )}
                          <div>
                            <h6>{`${employee.user.title ? employee.user.title + " " : ""}${employee.user.firstName} ${employee.user.middleName || ""} ${employee.user.lastName}`}</h6>
                            <span>{employee?.department?.items?.[0]?.department.name[0].text}</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <MDBBtn color="tertiary">{t("Select")}</MDBBtn>
                      </td>
                    </tr>
                  );
                })
                .filter(Boolean)
              )
              }</MDBTableBody>
            </MDBTable>

            <MDBBtn
              onClick={toggleAddEmployeesModal}
              className="m-2"
              color="tertiary"
              rippleColor="light"
            >
              {t("Add new employee")}
            </MDBBtn>
            
          </MDBCol>
          <MDBCol size="12" lg="6" xxl="5" className="bg-white p-3 rounded-4 shadow-4">
            <MDBTypography tag="h4">
              {t("Selected persons")}
            </MDBTypography>
            <MDBListGroup style={{ minWidthL: "22rem" }}>
              {employees?.length > 0 && selectedEmployeeElements}
            </MDBListGroup>
          </MDBCol>
        </MDBRow>
        <div className="mt-5 w-100">
        <MDBCol size="12" xxl="6" className="float-start mb-2 mb-lg-4">
            {/* <MDBCheckbox
              name={"speaker condition"}
              value={lecture?.conditions?.speakers}
              label={
                isMarketing ? t("The people presenting are experts in the topic of the presentation and will offer a qualitative presentation in terms of content and didactics") : t("The people presenting are experts in the topic of the lecture, will offer a qualitative lecture in terms of content and didactics and have no problem presenting in front of a camera")
              }
              datapath="conditions.speakers"
              onChange={handleChange}
            /> */}
            
          </MDBCol>
          <MDBCol size="12" className="d-flex text-start mt-3">
            <MDBBtn onClick={handleContinue} className="rounded-4 mb-2 mb-lg-4">
              {t("Continue")}
            </MDBBtn>
          </MDBCol>
        </div>
      </MDBContainer>
      
      <MDBModal open={addEmployeesModal} onClose={() => setAddEmployeesModal(false)} staticBackdrop>
          <MDBModalDialog centered size="lg" style={{zIndex: "9"}}>
              <MDBModalContent>
              <MDBModalHeader>
                  <MDBModalTitle>{t("Add Person")}</MDBModalTitle>
                  <MDBBtn className='btn-close' color='none' onClick={toggleAddEmployeesModal}></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
              <AddEmployees companyId={companyid} isActive={addEmployeesModal} toggleAddEmployeesModal={toggleAddEmployeesModal} />
              </MDBModalBody>
              </MDBModalContent>
          </MDBModalDialog>
      </MDBModal>
    </>
  );
}
