export const fullCompanyQuery = /* GraphQL */ `
                query GetCompany($id: ID!) {
                  getCompany(id: $id) {
                    id
                    name
                    description {
                      language
                      text
                    }
                    status
                    contact {
                      phone
                      fax
                      email
                      address {
                        street
                        street2
                        streetNumber
                        contactPerson
                        city
                        zip
                        countryCode
                      }
                    }
                    website
                    image {
                        main {
                        alt
                        fileName
                        }
                    }
                    banner {
                        alt
                        fileName
                    }
                    departments {
                      nextToken
                    }
                    attendingEvents {
                      nextToken
                    }
                    employees {
                      nextToken
                    }
                    admin {
                      id
                      email
                      firstName
                      middleName
                      lastName
                      salutation
                      title
                      birthday
                      personalIndustry
                      personalDepartment
                      x4Id
                      pw_hash
                      createdAt
                      updatedAt
                      owner
                      __typename
                    }
                    x4Id
                    x4AdminId
                    news {
                      nextToken
                      __typename
                    }
                    createdAt
                    updatedAt
                    companyAdminId
                    topics {
                      items {
                        topic {
                          id
                          name {
                            language
                            text
                          }
                          topicGroupTopicsId
                          topicGroup {
                            id
                          }
                        }
                      }
                      nextToken
                      __typename
                    }
                    __typename
                  }
                }
              `

export const fullCompanyMutation = /* GraphQL */ `
mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
      id
      name
      description {
        language
        text
        __typename
      }
      status
      contact {
        phone
        fax
        email
        address {
          street
          street2
          streetNumber
          contactPerson
          city
          zip
          countryCode
        }
      }
      website
      image {
          main {
          alt
          fileName
          }
      }
      banner {
        alt
        fileName
    }
      departments {
        nextToken
        __typename
      }
      attendingEvents {
        nextToken
        __typename
      }
      employees {
        nextToken
        __typename
      }
      admin {
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        birthday
        personalIndustry
        personalDepartment
        x4Id
        pw_hash
        createdAt
        updatedAt
        owner
        __typename
      }
      x4Id
      x4AdminId
      news {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyAdminId
      __typename
    
  }
}
`