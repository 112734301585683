import Schema from 'validate'



const contentLength = str => str.length >= 430;
const isTrue = boolean => boolean === true;

const lectureSchema = new Schema({

   title: {
      required: true,
      type: Array,
      each: {
         language: {
            required: true,
            type: String,
            message: "Titel"
         },
         text: {
            required: true,
            type: String,
            message: "Titel"
         },
         message: "Titel"
      },
      length: { min: 2 },
      message: "Titel"
   },

   speakers: {
      required: true,
      type: Array,
      
      length: { min: 1 },
      message: "Referenten"
   },

   subjects: {
      each: {
         required: true,
         type: Object,
         name: {
            type: Array,
            length: { min: 2 },
            each: {
               language: {
                  type: String,
                  message: "Unterpunkte"
               },
               text: {
                  type: String,
                  message: "Unterpunkte"
               },
               message: "Unterpunkte"
            },
            message: "Unterpunkte"
         },
         
         message: "Unterpunkte"
      },
      length: { min: 3 },
      message: "Unterpunkte"
   },

   content: {
      required: true,
      type: Array,
      each: {
         language: {
            required: true,
            type: String,
            message: "Beschreibung"
         },
         text: {
            required: true,
            type: String,
            use: { contentLength },
            message: "Beschreibung"
         }
      },
      length: { min: 2 },
      message: "Beschreibung"
   },

   conditions: {
      type: Array,
      required: true,
      each: {
         name: String,
         accepted: {
            type: Boolean,
            use: { isTrue },
            message: "Bedingungen"
         },
      },
      length: { min: 4 },
      message: "Bedingungen"
   },

   image: {
      fileName: {
         type: String,
         required: true,
         message: "Bild"
      },
      message: "Bild"
   },

   // pdfDe: {
   //    url: {
   //       required: true,
   //       type: String,
   //       message: "PDF"
   //    },
   //    name: {
   //       required: true,
   //       type: String,
   //       message: "PDF"
   //    },
   //    message: "PDF"
   // },

   // pdfEn: {
   //    url: {
   //       required: true,
   //       type: String,
   //       message: "PDF"
   //    },
   //    name: {
   //       required: true,
   //       type: String,
   //       message: "PDF"
   //    },
   //    message: "PDF"

   // },
   topics: {
      required: true,
      type: Array,
      length: { min: 1 },
      message: "Themenbereich"
   },

}, {strip: false})

export default lectureSchema;