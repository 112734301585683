import React, { useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { 
  MDBCard, 
  MDBCardBody, 
  MDBTypography, 
  MDBTabs, 
  MDBTabsItem, 
  MDBTabsLink, 
  MDBTabsContent, 
  MDBTabsPane, 
  MDBSpinner,
  MDBBtn,
  MDBToast,
  MDBIcon
} from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';

import GeneralTab from './GeneralTab';
import LocationTab from './LocationTab';
import CarpetTab from './CarpetTab';
import TextsTab from './TextsTab';
import { useEventData } from './hooks/useEventData';
import { useIndustries } from './hooks/useIndustries';

const EditEvent = () => {
  const { eventId } = useParams();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('general');
  const [showToast, setShowToast] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  
  const { 
    event, 
    isLoading: isEventLoading,
    updateEvent,
    handleInputChange,
    handleImageUpload,
    handleBadgePreviewUpload,
    handleFileUpload,
    handleRemoveFile,
    handleFileDownload
  } = useEventData(eventId);

  const initialSelectedIndustryIds = useMemo(() => 
    event?.industries?.items?.map(item => item.industry.id) || [],
    [event?.industries?.items]
  );

  const {
    industries,
    selectedIndustryIds,
    setSelectedIndustryIds,
    isLoading: isIndustriesLoading,
    updateEventIndustries
  } = useIndustries(eventId, initialSelectedIndustryIds);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      // First save the event
      await updateEvent();
      // Then update the industries if we have an eventId
      if (eventId) {
        await updateEventIndustries(eventId);
      }
      setShowToast(true);
    } catch (error) {
      console.error('Error saving event:', error);
    } finally {
      setIsSaving(false);
    }
  };

  if (isEventLoading || isIndustriesLoading) {
    return <MDBSpinner />;
  }

  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <MDBTypography tag="h2">
              {eventId ? t('Edit Event') : t('Create Event')}
            </MDBTypography>
            <MDBBtn 
              onClick={handleSave} 
              disabled={isSaving}
              className="ms-2"
            >
              {isSaving ? (
                <>
                  <MDBSpinner size='sm' className='me-2' />
                  {t('Saving...')}
                </>
              ) : (
                t('Save')
              )}
            </MDBBtn>
          </div>
          
          <MDBTabs className="mb-3">
            <MDBTabsItem>
              <MDBTabsLink onClick={() => setActiveTab("general")} active={activeTab === 'general'}>
                {t("General")}
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => setActiveTab("texts")} active={activeTab === 'texts'}>
                {t("Texts")}
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => setActiveTab("location")} active={activeTab === 'location'}>
                {t("Location")}
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => setActiveTab("carpet")} active={activeTab === 'carpet'}>
                {t("Carpet")}
              </MDBTabsLink>
            </MDBTabsItem>
            <li class="nav-item" role="presentation" style={{cursor: "pointer"}}>
              <Link to={`/admin/product/${event.ticketProduct?.id}`} class="nav-link">Ticket Product <MDBIcon fas icon="external-link-alt" /></Link>
            </li>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane open={activeTab === 'general'}>
              <GeneralTab 
                event={event}
                industries={industries}
                selectedIndustryIds={selectedIndustryIds}
                setSelectedIndustryIds={setSelectedIndustryIds}
                onInputChange={handleInputChange}
                onImageUpload={handleImageUpload}
                onBadgePreviewUpload={handleBadgePreviewUpload}
                onFileUpload={handleFileUpload}
                onRemoveFile={handleRemoveFile}
                onDownloadFile={handleFileDownload}
                isLoading={isEventLoading || isIndustriesLoading}
              />
            </MDBTabsPane>
            
            <MDBTabsPane open={activeTab === 'texts'}>
              <TextsTab 
                event={event}
                onInputChange={handleInputChange}
              />
            </MDBTabsPane>
            
            <MDBTabsPane open={activeTab === 'location'}>
              <LocationTab 
                event={event}
                onInputChange={handleInputChange}
              />
            </MDBTabsPane>
            
            <MDBTabsPane open={activeTab === 'carpet'}>
              <CarpetTab 
                eventId={eventId}
                event={event}
              />
            </MDBTabsPane>
          </MDBTabsContent>
        </MDBCardBody>
      </MDBCard>

      <MDBToast
        color='success'
        open={showToast}
        offset={50}
        onClose={() => setShowToast(false)}
        autohide
        delay={30000}
        position='top-right'
        appendToBody
        bodyContent={<>
                    {t('Event saved successfully!')}
                  </>}
      >
        
      </MDBToast>
    </>
  );
};

export default EditEvent;