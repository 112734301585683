import React from "react";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardImage,
  MDBCardHeader,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardFooter,
  MDBCardTitle,
  MDBIcon,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import lectureData from "./lectureDummyData.json";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";

export default function CheckLectureInformation({ backToEdit, handleChange, lecture, employees, createLectureHandler, isSaving }) {
  const { t, i18n } = useTranslation();

  const [missingInputs, setMissingInputs] = useState([]);

  const checkAllInputs = () => {
    let missingInputsNew = [];
    if (!lecture?.speakers?.length) missingInputsNew.push("Referenten");
    if (!lecture?.title?.[0].text) missingInputsNew.push("Titel");
    if (lecture?.topics ? (Object.keys(lecture.topics).length < 1) : false) missingInputsNew.push("Themenbereiche");
    // if (!lecture?.subjects?.length) missingInputsNew.push("Unterpunkte");
    if (!lecture?.content?.length) missingInputsNew.push("Beschreibung");
    if (!lecture?.image?.fileName) missingInputsNew.push("Bild");

    setMissingInputs(missingInputsNew);
  }

  useEffect(() => {
    checkAllInputs()
  }, [lecture])
  

  return (

<>
{
  missingInputs.length > 0 &&

<>
        <MDBCol size="12" className="text-center">
          <MDBTypography tag="h3" color="danger">{t("Your promotion is incomplete, but can be saved. It will not yet be taken into account for planning.")}</MDBTypography>
          <MDBTypography tag="h4" color="danger">{t("Please fill the following fields:")}</MDBTypography>
        </MDBCol>
        <MDBListGroup style={{ width: "fit-content" }} light small className="text-center mx-auto">
        {missingInputs.map((input) => (
          <MDBListGroupItem className="px-4">{input}</MDBListGroupItem>
        ))}
        </MDBListGroup>

</>
}
      <MDBRow>
        <MDBCol size="12" className="d-flex justify-content-center">
          <MDBTypography tag="h2">{t("Promotion Preview")}</MDBTypography>
        </MDBCol>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="10" sizeXl="8" sizeXXL="6" className="mt-4">
            <MDBCard>
              {/* 
              ------HIER MUSS DAS LECTURE BILD EINGEBUNDEN WERDEN--------------
              <MDBCardImage
                src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/lectureImages/${lectureData.image.fileName}"
                alt="lectureData.image.alt"
                position="top"
                style={{ maxHeight: "350px" }}
              />
              */}
              <div style={{aspectRatio: "2/1", width: "100%"}}>
                <MDBCardImage
                  src={lecture?.image?.fileName ? `https://iec-cp-public.s3.eu-central-1.amazonaws.com/lectureImages/${lecture.image.fileName}` : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
                  alt="..."
                  position="top"
                  className="img-thumbnail"
                  style={{ height: "auto", width: "100%", objectFit: "cover" }}
                />
              </div>


              <MDBCardHeader>
                <MDBCardTitle className="mt-2">{lecture?.title?.find(t => t.language === i18n.language)?.text || lecture?.title?.[0].text}</MDBCardTitle>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBCardText>{t("Sub-points")}</MDBCardText>

                <MDBRow>
                  <MDBCol size="12" md="6">
                    <MDBListGroup>
                      {lecture?.subjects?.map((subject, index) => (
                        <MDBListGroupItem>
                          {subject.name?.find(s => s.language === i18n.language)?.text}
                        </MDBListGroupItem>
                      ))}
                    </MDBListGroup>
                  </MDBCol>

                  <MDBCol size="12" md="6" className="mt-3">
                    <MDBListGroup>
                      
                  {lecture?.speakers?.map((id, index) => {
                    const employee = employees.find((employee) => employee.id === id);
                    if (!employee) return null;
                    return  <MDBListGroupItem key={index}>
                      <div className="d-flex gap-4 align-items-center">
                      {employee.image?.fileName ? 
                        <img
                          src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                          className="rounded-circle border"
                          style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "contain",
                          }}
                          alt={employee.image.alt}
                        />
                       : 
                        <MDBIcon
                          className="rounded-circle border"
                          style={{
                            height: "50px",
                            width: "50px",
                            textAlign: "center",
                            paddingTop: "12px",
                            fontSize: "20px",
                          }}
                          fas
                          icon="user-alt"
                        />
                      }
                      <div>
                        <h6>{`${employee.user.title ? employee.user.title + " " : ""}${employee.user.firstName}${employee.user.middleName ? " " + employee.user.middleName : ""} ${employee.user.lastName}`}</h6>
                        <span>{employee.department?.items?.[0]?.department?.name?.find(n => n.language === i18n.language)?.text || employee.department?.items?.[0]?.department?.name?.[0]?.text || ""}</span>
                      </div>
                      </div>
                      
                    </MDBListGroupItem>
                  }
                   
                      )}
                     

                      
                    </MDBListGroup>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
          <MDBCol size="12" className="d-flex justify-content-center mb-4">
          {isSaving ? <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
              </div> :
              <MDBBtn onClick={() => createLectureHandler()}>
              {t("Save Promotion")}
            </MDBBtn>
              }
            
            <br></br>
            
          </MDBCol>
        </MDBRow>
        <div className="w-100 text-center">
        <p>{t("You can edit the promotion again later. Only complete promotions will be taken into account in the planning")}</p>
        </div>
        
      </MDBRow>
    </>
  );
}
