import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBCard, MDBCardBody, MDBIcon, MDBCardTitle } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from "react-i18next";

const client = generateClient();


// GraphQL queries


// GraphQL mutations
const deleteProductMutation = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteCMSProductInput!) {
    deleteCMSProduct(input: $input) {
      id
    }
  }
`;

export default function AllProducts() {
    const { t, i18n } = useTranslation();
    const [productsList, setProductsList] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    

    const companyid = window.location.pathname.includes("admin") ? window.location.pathname.split('/')[3] : window.location.pathname.split('/')[2];

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchAllProducts = async (nextToken = null, allProducts = []) => {
    try {
        const response = await client.graphql({
            query: /* GraphQL */ `
                query ListCMSProducts(
                    $filter: ModelCMSProductFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listCMSProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            createdAt
                            images {
                                main {
                                    fileName
                                    alt
                                }
                            }
                            name {
                                language
                                text
                            }
                            status
                            employee {
                                id
                                image {
                                    fileName
                                    alt
                                }
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                        nextToken
                    }
                }
            `,
            variables: { 
                filter: { companyProductsId: { eq: companyid } },
                nextToken: nextToken,
                limit: 1000
            }
        });

        const productsData = response.data.listCMSProducts;
        allProducts = allProducts.concat(productsData.items);

        if (productsData.nextToken) {
            return fetchAllProducts(productsData.nextToken, allProducts);
        } else {
            return allProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
    } catch (error) {
        console.error('Error fetching Products:', error);
        return []; // Return an empty array or handle the error as needed
    }
};

    const fetchProducts = async () => {
        try {
            const allProducts = await fetchAllProducts();
            setProductsList(allProducts);
        } catch (error) {
            console.error('Failed to fetch all products:', error);
        }
    };

    const deleteProducts = async () => {
        try {
            await client.graphql({
                query: deleteProductMutation,
                variables: { input: { id: selectedProduct } }
            });
            setDeleteModal(false);
            setTimeout(() => {
                fetchProducts();
            }, 1000);
        } catch (error) {
            console.error('Error deleting Products:', error);
        }
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    return (
<> 
    <MDBCard>
    <MDBCardBody>
    <MDBCardTitle>{t("Products")}<Link to={`/mycompany/${companyid}/product/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create product")}</MDBBtn></Link></MDBCardTitle>
    
        
        <MDBTable>
            <MDBTableHead>
                <tr>
                    <th>{t("Date")}</th>
                    <th>{t("Image")}</th>
                    <th>Name</th>
                    <th>{t("Employee")}</th>
                    <th>Status</th>
                    <th>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {productsList.map(Products => (
                    <tr key={Products.id}>
                        <td>{new Date(Products.createdAt).toLocaleDateString("de-DE")}</td>
                        <td>
                        {Products.images?.main?.fileName ?
                        <img
                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/${Products.images.main.fileName}`}
                            className="rounded border"
                            style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                            }}
                            alt={Products.images.main.alt}
                        />
                        :
                        <MDBIcon
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                            }}
                            fas
                            icon="image"
                        />
                        }
                        </td>
                        <td>{Products.name?.find(t => t.language === i18n.language)?.text}</td>
                        
                        <td>{Products.employee && (
                        Products.employee?.image?.fileName ?
                        <img
                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${Products.employee.image.fileName}`}
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                            }}
                            alt={Products.employee?.image.alt}
                        />
                        :
                        <MDBIcon
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                            }}
                            fas
                            icon="user-alt"
                        />            
                        )}
                        </td>
                        <td>{Products.status || t("In review")}</td>
                        <td>
                            <Link to={`/mycompany/${companyid}/product/${Products.id}`}>
                            <MDBBtn className='mx-2 my-0 py-0' color='link'>{t("Edit")}</MDBBtn>
                            </Link>
                            <MDBBtn className='mx-2 my-0 py-0' color='link' style={{color: "red"}} onClick={() => {
                                setSelectedProduct(Products.id);
                                toggleDeleteModal();
                            }}>{t("Delete")}</MDBBtn>
                        </td>
                    </tr>
                ))}
            </MDBTableBody>
        </MDBTable>
        
    </MDBCardBody>
</MDBCard>
<MDBModal open={deleteModal} onClose={() => toggleDeleteModal(false)} staticBackdrop>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Delete product")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleDeleteModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                {t("Are you sure you want to delete this product?")}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color='secondary' onClick={toggleDeleteModal}>Cancel</MDBBtn>
                <MDBBtn color='danger' onClick={deleteProducts}>Delete</MDBBtn>
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
    );
}
