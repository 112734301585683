import { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCheckbox,
  MDBTypography,
  MDBAlert,
  MDBBtn,
} from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

export default function AcceptConditions({ handleChange, lecture, handleNextClick, event }) {
  const { t, i18n } = useTranslation();

  const [alertDanger, setAlertDanger] = useState(false);

  const areAllConditionsChecked = () => {
    return (
      lecture?.conditions?.findIndex((c) => c.name === "adCondition") > -1 &&
      lecture?.conditions?.findIndex((c) => c.name === "recordCondition") > -1 &&
      lecture?.conditions?.findIndex((c) => c.name === "rightsContition") > -1 &&
      lecture?.conditions?.findIndex((c) => c.name === "deadlineContition") > -1
    );
  };

  const handleContinue = () => {
    if (!areAllConditionsChecked()) {
      setAlertDanger(true);
    } else {
      handleNextClick()
    }
  };

  const wrapperRef = useRef(null);

  return (
    <>
      <MDBContainer ref={wrapperRef}>
        <MDBTypography tag="h3" className="mb-5">{t("Please agree to our conditions")}</MDBTypography>
        <MDBRow className="mb-2 mb-md-4">
          <div className="px-1 px-md-3" style={{maxWidth: "800px"}}>
            <MDBCheckbox
              name="adCondition"
              id="adContition"
              label={t("All content is absolutely ad-free")}
              checked={lecture?.conditions?.findIndex(c => c.name === "adCondition") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "adCondition")].accepted : false}
              onChange={e => handleChange({target: {
              value: {
                name: "adCondition",
                accepted: e.target.checked,
              },
              attributes: {
                datapath: {
                  value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "adCondition") > -1 ? lecture?.conditions?.findIndex(c => c.name === "adCondition") : lecture?.conditions?.length) + "]" : "conditions[0]"
                }}
            }})}
            />
          </div>
          <div className="px-1 px-md-3" style={{maxWidth: "800px"}}>
            <MDBCheckbox
              name="recordCondition"
              checked={lecture?.conditions?.findIndex(c => c.name === "recordCondition") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "recordCondition")].accepted : false}
              onChange={e => handleChange({target: {
              value: {
                name: "recordCondition",
                accepted: e.target.checked,
              },
              attributes: {
                datapath: {
                  value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "recordCondition") > -1 ? lecture?.conditions?.findIndex(c => c.name === "recordCondition") : lecture?.conditions?.length) + "]" : "conditions[0]"
                }}
            }})}
              id="recordContition"
              label={t("The lecture will be recorded via audio and video")}
            />
          </div>
          <div className="px-1 px-md-3" style={{maxWidth: "800px"}}>
            <MDBCheckbox
              name="rightsCondition"
              id="rightsContition"
              label={t("The rights to the recording belong to Inspire GmbH and the recording will be published on the trade fair platform shortly after the lecture.")}
              checked={lecture?.conditions?.findIndex(c => c.name === "rightsContition") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "rightsContition")].accepted : false}
              onChange={e => handleChange({target: {
              value: {
                name: "rightsContition",
                accepted: e.target.checked,
              },
              attributes: {
                datapath: {
                  value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "rightsContition") > -1 ? lecture?.conditions?.findIndex(c => c.name === "rightsContition") : lecture?.conditions?.length) + "]" : "conditions[0]"
                }}
            }})}
            />
          </div>
          <div className="px-1 px-md-3" style={{maxWidth: "800px"}}>
            <MDBCheckbox
              name="deadlineContition"
              id="deadlineContition"
              label={t("I accept that corrections to the content of the recording of the lecture are only possible up to and including: ") + new Date(event?.lectureRecordingCorrectionEndDate).toLocaleDateString({year: "numeric", month: "2-digit", day: "2-digit"})}
              checked={lecture?.conditions?.findIndex(c => c.name === "deadlineContition") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "deadlineContition")].accepted : false}
              onChange={e => handleChange({target: {
                value: {
                  name: "deadlineContition",
                  accepted: e.target.checked,
                },
                attributes: {
                  datapath: {
                    value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "deadlineContition") > -1 ? lecture?.conditions?.findIndex(c => c.name === "deadlineContition") : lecture?.conditions?.length) + "]" : "conditions[0]"
                  }}
              }})}
            />
          </div>

         
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" className="d-flex justify-content-start mb-3">
            <MDBBtn onClick={handleContinue}>{t("Continue")}</MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBAlert
        color="danger"
        autohide
        position="bottom-right"
        delay={2000}
        appendToBody
        open={alertDanger}
        containerRef={wrapperRef}
        onClose={() => setAlertDanger(false)}
      >
        {t("Please accept all conditions")}
      </MDBAlert>
      </MDBContainer>
     
    </>
  );
}
