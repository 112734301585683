import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBCard, MDBCardBody, MDBIcon, MDBCardTitle } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from "react-i18next";

const client = generateClient();




// GraphQL mutations
const deleteNewsMutation = /* GraphQL */ `
  mutation DeleteNews($input: DeleteCMSNewsInput!) {
    deleteCMSNews(input: $input) {
      id
    }
  }
`;

export default function AllNews() {
    const { t, i18n } = useTranslation();
    const [newsList, setNewsList] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);

    const companyid = window.location.pathname.includes("admin") ? window.location.pathname.split('/')[3] : window.location.pathname.split('/')[2];

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchAllNews = async (nextToken = null, allNews = []) => {
    try {
        const response = await client.graphql({
            query: /* GraphQL */ `
                query ListCMSNews(
                    $filter: ModelCMSNewsFilterInput
                    $limit: Int
                    $nextToken: String
                ) {
                    listCMSNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                            createdAt
                            name {
                                language
                                text
                            }
                            status
                            employee {
                                id
                                image {
                                    fileName
                                    alt
                                }
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                        nextToken
                    }
                }
            `,
            variables: { 
                filter: { companyNewsId: { eq: companyid } },
                nextToken: nextToken,
                limit: 100 // Adjust the limit as needed
            }
        });

        const newsData = response.data.listCMSNews;
        allNews = allNews.concat(newsData.items);

        if (newsData.nextToken) {
            return fetchAllNews(newsData.nextToken, allNews);
        } else {
            return allNews.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
    } catch (error) {
        console.error('Error fetching news:', error);
        return []; // Return an empty array or handle the error as needed
    }
};

const fetchNews = async () => {
    try {
        const allNews = await fetchAllNews();
        setNewsList(allNews);
    } catch (error) {
        console.error('Failed to fetch all news:', error);
    }
};


    const deleteNews = async () => {
        try {
            await client.graphql({
                query: deleteNewsMutation,
                variables: { input: { id: selectedNews } }
            });
            setDeleteModal(false);
            setTimeout(() => {
                fetchNews();
            }, 1000);
        } catch (error) {
            console.error('Error deleting news:', error);
        }
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    return (
<> 
    <MDBCard>
    <MDBCardBody>
    <MDBCardTitle>{t("Company News")}<Link to={`/mycompany/${companyid}/news/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create news")}</MDBBtn></Link></MDBCardTitle>
        
        
        <MDBTable>
            <MDBTableHead>
                <tr>
                    <th>{t("Date")}</th>
                    <th>Name</th>
                    <th>{t("Employee")}</th>
                    <th>Status</th>
                    <th>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {newsList.map(news => (
                    <tr key={news.id}>
                        <td>{new Date(news.createdAt).toLocaleDateString("de-DE")}</td>
                        <td>{news.name?.find(t => t.language === i18n.language)?.text}</td>
                        
                        <td>{news.employee && (
                        news.employee?.image?.fileName ?
                        <img
                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${news.employee.image.fileName}`}
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                            }}
                            alt={news.employee?.image.alt}
                        />
                        :
                        <MDBIcon
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                            }}
                            fas
                            icon="user-alt"
                        />            
                        )}
                        </td>
                        <td>{news.status || t("In review")}</td>
                        <td>
                            <Link to={`/mycompany/${companyid}/news/${news.id}`}>
                            <MDBBtn className='mx-2 my-0 py-0' color='link'>{t("Edit")}</MDBBtn>
                            </Link>
                            <MDBBtn className='mx-2 my-0 py-0' color='link' style={{color: "red"}} onClick={() => {
                                setSelectedNews(news.id);
                                toggleDeleteModal();
                            }}>{t("Delete")}</MDBBtn>
                        </td>
                    </tr>
                ))}
            </MDBTableBody>
        </MDBTable>
        
    </MDBCardBody>
</MDBCard>
<MDBModal open={deleteModal} onClose={() => toggleDeleteModal(false)} staticBackdrop>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>Delete News</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleDeleteModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                Are you sure you want to delete this news?
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color='secondary' onClick={toggleDeleteModal}>Cancel</MDBBtn>
                <MDBBtn color='danger' onClick={deleteNews}>Delete</MDBBtn>
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
    );
}
