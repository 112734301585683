
import React, { useEffect, useState } from 'react';
import EmployeeSelector from "../../components-user/news/EmployeeSelector";
import { MDBInput, MDBCheckbox, MDBBtn, MDBRow, MDBCol, MDBTypography, MDBInputGroup, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBChip, MDBCard, MDBCardBody, MDBCardTitle, MDBFile, MDBSpinner, MDBIcon } from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertFromHTML} from 'draft-js';
import {convertHTMlToRaw} from "../../utils/HTMLDraft";
import SelectTopics from "../../components-user/topics/SelectTopics";
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from "react-router-dom";


const client = generateClient();

const CreateProductPage = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const companyId = window.location.pathname.includes("mycompany")
      ? window.location.pathname.split("/")[2]
      : window.location.pathname.split("/")[3];

      let productId = window.location.pathname.includes("mycompany")
      ? window.location.pathname.split("/")[4]
      : window.location.pathname.split("/")[5];

      productId = productId === "create" ? null : productId;

    const { t, i18n } = useTranslation();
    const [productData, setProductData] = useState({
        name: [],
        content: [],
        images: {},
        file: {},
        cMSProductEmployeeId: "",
        cMSProductTopicId: "",
        conditions: [],
    });

    const getProductData = async () => {
      try {
        const productData = await client.graphql({
          variables: { id: productId },
          query: /* GraphQL */ `
          query GetCMSProduct($id: ID!) {
            getCMSProduct(id: $id) {
              id
              type
              company {
                id
                name
                status
                createdAt
                companyAdminId
              }
              employee {
                id
                contact {
                  email
                  phone
                  mobile
                  fax
                  address {
                    street
                    streetNumber
                    company
                    zip
                    city
                    countryCode
                  }
                }
                user {
                  id
                  email
                  firstName
                  middleName
                  lastName
                  salutation
                  title
                  status
                  createdAt

                } 
              }
              topic {
                id
                name {
                  language
                  text
                }
                color
                topicGroup {
                  id
                  color
                }
                topicGroupTopicsId
              }
              name {
                language
                text
              }
              images {
                main {
                  fileName
                  alt
                }
              }
              previewImage {
                fileName
                alt
              }
              excerpt {
                language
                text
              }
              interests {
                name {
                  language
                  text
                }
                id
              }
              content {
                language
                text
              }
              file {
                url
                s3Path
                fileName
                type
                name
              }
              conditions {
                name
                accepted
              }
              status
              createdAt
              updatedAt
              companyProductsId
              cMSProductEmployeeId
              cMSProductTopicId
              owner
              __typename
            }
          }
        `,
        });
        console.log(productData);
       
        return {
          ...productData.data.getCMSProduct,
          topics: productData.data.getCMSProduct.topics?.items?.map(topic => topic.topicId) || []};
      } catch (error) {
        console.warn("Error getting product data", error);
      }
    };

  const intialLoadProduct = async () => {
    console.log("loading product");
    setIsLoading(true);
    const dbProduct = await getProductData();
    setProductData(dbProduct);
    if(dbProduct.content) {
      const deContent = dbProduct.content.find(c => c.language === "de")?.text;
      const enContent = dbProduct.content.find(c => c.language === "en")?.text;
      setEditorState({
        de: deContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(deContent)
                        )
                      ) : EditorState.createEmpty(),
        en: enContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(enContent)
                        )
                      ) : EditorState.createEmpty(),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    console.log("productId", productId, "companyId", companyId)
    if (!companyId) {
      console.warn("No company id provided");
      return;
    } else if (!productId) {
      console.log("No product id provided");
      return;
    } else {
      intialLoadProduct();
    }
  }, []);

 

  const [validationErrors, setValidationErrors] = useState([]);

  
  const validateProduct = () => {
    const errors = [];
    if (productData.name.length !== 2) {
      errors.push(t("Please enter a title in both languages"));
    }
    // if(productData.content.find(t => t.language === "de")?.text?.length < 1000 || productData.content.find(t => t.language === "en")?.text?.length < 1000) {
    //   errors.push(t("The content must not be longer than 1000 characters"));
    // }
    if(!productData.cMSProductTopicId) {
      errors.push(t("Please select a topic"));
    }
    if(productData.content.length !== 2) {
      errors.push(t("Please enter content in both languages"));
    }
    if(!productData.cMSProductEmployeeId) {
      errors.push(t("Please select an employee"));
    }
    if(!productData.images?.main?.fileName) {
      errors.push(t("Please upload an image"));
    }
    if(productData?.images?.main?.fileName && !productData.conditions?.find(c => c.name === "image")?.accepted) {
      errors.push(t("Please agree to the image conditions"));
    }
    if(productData.file?.fileName && !productData.conditions?.find(c => c.name === "file")?.accepted) {
      errors.push(t("Please agree to the file conditions"));
    }

    setValidationErrors(errors);
    return errors.length === 0;
  }

  const createProduct = async () => {
    try {
      if(!validateProduct()) {
        return;
      }
      setIsLoading(true);
      const response = await client.graphql({
        query: productId ? /* GraphQL */ `
        mutation UpdateCMSProduct($input: UpdateCMSProductInput!) {
          updateCMSProduct(input: $input) {
            id  
          }
        }
        ` : /* GraphQL */ `
        mutation CreateCMSProduct(
          $input: CreateCMSProductInput!
          $condition: ModelCMSProductConditionInput
        ) {
          createCMSProduct(input: $input, condition: $condition) {
              id
            }
          }
        `,
        variables: {
          input: {
            companyProductsId: companyId,
            name: productData.name,
            content: productData.content,
            images: productData.images,
            file: productData.file,
            cMSProductEmployeeId: productData.cMSProductEmployeeId,
            cMSProductTopicId: productData.cMSProductTopicId,
            conditions: productData.conditions,
            status: "created",
            ...productId ? {id: productId} : {},
          },
        },
      });
      console.log("response", response);
      setTimeout(() => {
        navigate(`/mycompany/${companyId}/products`);
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

    // Topics
  // Select Topics 
  const [topics, setTopics] = useState([]);
  const [topicGroups, setTopicGroups] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState(productData.topics || []);


  const [selectTopicsModal, setSelectTopicsModal] = useState(false);
  const toggleSelectTopicsModal = () => setSelectTopicsModal(!selectTopicsModal);

  const handleSetSelectedTopics = (topics) => {
    setSelectedTopics(topics);
    setProductData(oldData => ({...oldData, cMSProductTopicId: topics[0]}));
    // setLectureData(oldLecture => ({...oldLecture, topics}));
  }

  // Topics data

  const getTopics = async () => {
      try {
        const topicGroupsWithTopics = await client.graphql({
          query: /* GraphQL */ `
          query ListTopicGroups(
            $filter: ModelTopicGroupFilterInput
            $limit: Int
            $nextToken: String
          ) {
            listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                color
                name {
                  language
                  text
                }
                topics {
                  items {
                    id
                    name {
                      language
                      text
                    }
                    topicGroupTopicsId
                  }
                }
              }
              nextToken
              __typename
            }
          }
        `
          });
          setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items);
          const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
          setTopics(topics);

      } catch (error) {
          console.log("error on fetching topics", error);
      }
  }

  useEffect(() => {
      if (topics.length === 0) {
          getTopics();
      }
  }, [topics]);

  const Topics = ({topics, topicGroups, selectedTopics}) => {

    // First sort the topics by topicGroup (new array with objects for eacht group, inside object the key: topics, inside the topics for this group), then return for each group a col, inside the col the title of the group, then the topics as chips
    if(topics?.length === 0 || topicGroups?.length === 0 || selectedTopics?.length === 0) {
        return null;
    }
    let sortedTopics = [];
    selectedTopics.forEach((selectedTopic) => {
        const topicData = topics.find((topic) => topic.id === selectedTopic);
        const topicGroup = topicGroups.find((topicGroup) => topicGroup.id === topicData.topicGroupTopicsId);
        const topicGroupIndex = sortedTopics.findIndex((sortedTopic) => sortedTopic.id === topicGroup.id);
        if (topicGroupIndex === -1) {
            sortedTopics.push({...topicGroup, topics: [topicData]});
        } else {
            sortedTopics[topicGroupIndex].topics.push(topicData);
        }
    });
    return (
        <MDBRow className="my-2">
            {sortedTopics.map((topicGroup) => {
                return (
                    <MDBCol size="12" xxl="6" key={topicGroup.id} className="mb-3 mb-lg-4">
                        <h6>{topicGroup.name?.find(n => n.language === i18n.language)?.text || topicGroup.name?.[0]?.text}</h6>
                        <MDBRow>
                            {topicGroup.topics.map((topic) => {
                                return (
                                    <MDBCol size="12" key={topic.id}>
                                        <MDBChip className="m-1 float-start" bgColor={topicGroup.color} textColor="white">{topic?.name?.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}</MDBChip>
                                    </MDBCol>
                                )
                            })}
                        </MDBRow>
                    </MDBCol>
                )
            })}
        </MDBRow>
    )
}

// Select Employee
const [employee, setEmployee] = useState();
const [selectEmployeeModal, setSelectEmployeeModal] = useState(false);
const toggleSelectEmployeeModal = () => setSelectEmployeeModal(!selectEmployeeModal);

const getEmployee = async () => {
    try {
      const employee = await client.graphql({
        query: /* GraphQL */ `
        query GetEmployee($id: ID!) {
            getEmployee(id: $id) {
              id
              user {
                id
                firstName
                lastName
                title
              }
              image {
                fileName
                alt
              }
            }
          }
        `,
        variables: { id: productData.cMSProductEmployeeId },
      });
      setEmployee(employee.data.getEmployee);
    } catch (error) {
      console.log("error on fetching employee", error);
    }
  };


useEffect(() => {
    if (productData.cMSProductEmployeeId) {
        getEmployee();
    }
}, [productData.cMSProductEmployeeId]);

// Editor
// Editor
const [editorState, setEditorState] = useState({
    de: EditorState.createEmpty(),
    en: EditorState.createEmpty(),
})
const onEditorStateChange = (editorState, language ) => {
  setEditorState(oldState => ({...oldState, [language]: editorState}));
  setProductData(oldData => ({...oldData, content: [...oldData.content.filter(c => c.language !== language), {language, text: convertHTMlToRaw(editorState)}] }));

} 

// Image and Script

const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState(false);
const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState(null);

const handleSetFile = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
if(file) {
  try {
    setIsUploadingLectureFiles(true);
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onerror = error => {
    console.log('Error: ', error);
    setUploadingLecturesErrors(error);
  }
  reader.onload = async () => {
   try {
    const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
    const response = await client.graphql({
      query: /* GraphQL */ `
        mutation UploadLectureFile($base64FileBuffer: String!, $companyId: String!) {
          uploadLectureFile(base64FileBuffer: $base64FileBuffer, companyId: $companyId) {
            message
            fileName
          }
        }
      `,
      variables: {
        base64FileBuffer,
        companyId,
      },
    });
    console.log("response upload file", response);
    setProductData(oldData => ({...oldData, file: {fileName: response.data.uploadLectureFile.fileName, type: "productFile"}}));
    
   } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles(false);
    setUploadingLecturesErrors(error.errors[0].message);
   }
  };
  
  setIsUploadingLectureFiles(false);
  setUploadingLecturesErrors(null);
  } catch (error) {
    console.log("error", error);
    setIsUploadingLectureFiles(false);
    setUploadingLecturesErrors(error.errors[0].message);
  }
  
}
  
}

const [isUploadingLectureImage, setIsUploadingLectureImage] = useState(false);
const [uploadingLectureImageError, setUploadingLectureImageError] = useState(null);

const handleSetImage = (file) => {
  // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
  if(file) {
    try {
      setIsUploadingLectureImage(true);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => {
      console.log('Error reader: ', error);
      setUploadingLectureImageError(error);
    }
    reader.onload = async () => {
      try {
        console.log(reader.result)
        const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
        const response = await client.graphql({
          query: /* GraphQL */ `
          mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
            uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
          }
        `,
          variables: {
            imageBuffer: base64FileBuffer,
            imageType: "productImage",
          },
        });
        console.log("response", response);
        const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
        setProductData(oldData => ({...oldData, images: {main:{fileName, alt: "Lecture Image"}}}));
        
        } catch (error) {
          console.log("error", error);
          setIsUploadingLectureImage(false);
          setUploadingLectureImageError(error.errors[0].message);
        }
    }
    setIsUploadingLectureImage(false);
    setUploadingLectureImageError(null);
    } catch (error) {
      console.log("error", error);
      setIsUploadingLectureImage(false);
      setUploadingLectureImageError(error.errors[0].message);
    }
  }
}


return (
<>
<MDBRow className="h-auto d-flex justify-content-center">
<MDBCol size="12" xl="12" xxl="11">
<MDBCard>
<MDBCardBody className="px-1 px-md-5">
<MDBCardTitle>{t("Create Product")}</MDBCardTitle>

{/* Topics */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
            {t("Select topic")}
            </MDBTypography>
            <span className="text-muted">
            {t("Select the topic that best describe your product.")}
            </span>
        </div>
        <div className="w-100 text-center">
        <MDBBtn onClick={() => toggleSelectTopicsModal()} color="primary" outline>
            {t("Select Topic")}
        </MDBBtn>
        <MDBRow className="w-100">
            <MDBCol>
            {topics.length > 0 && topicGroups.length > 0 && productData.cMSProductTopicId && (
                <Topics
                topics={topics}
                topicGroups={topicGroups}
                selectedTopics={[productData.cMSProductTopicId] || []}
                setSelectedTopics={handleSetSelectedTopics}
                />
            )}
            </MDBCol>
        </MDBRow>
      </div>
    </MDBCol>
</MDBRow>
{/* Topics END */}

{/* TITEL */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="mb-1 mb-xl-2" />
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
                {t("Enter the title of the product (max. 60 characters)")}
            </MDBTypography>
            <span className="text-muted">
            {t("The title of the product should clearly convey what it is about and at the same time justify why the topic is interesting and relevant.")}
            </span>
        </div>
        <MDBInputGroup className="mb-1" textAfter={<span style={productData.name?.find((t) => t.language === "de")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((productData?.name?.length > 0? productData.name.find((t) => t.language === "de")?.text: "")?.length || "0") + "/60"}</span>}>
            <div className="input-group-text">
            <ReactCountryFlag
                countryCode="de"
                svg
                style={{
                width: "20px",
                height: "20px",
                }}
                title={i18n.language}
            /> 
            </div>
            <MDBInput
            label={t("Title German")}
            type="text"
            maxLength="60"
            value={productData?.name?.find((t) => t.language === "de")?.text || ""}
            onChange={(e) => setProductData({...productData, name: [{language: "de", text: e.target.value}, ...productData.name.filter((t) => t.language !== "de")]})}
            />
        </MDBInputGroup>
        <MDBInputGroup className="mb-1" textAfter={<span style={productData.name?.find((t) => t.language === "en")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((productData?.name?.length > 0? productData.name.find((t) => t.language === "en")?.text: "")?.length || "0") + "/60"}</span>}>
            <div className="input-group-text">
            <ReactCountryFlag
                countryCode="gb"
                svg
                style={{
                width: "20px",
                height: "20px",
                }}
                title={i18n.language}
            />
            </div>
            <input
            className="form-control"
            placeholder={t("Title English")}
            type="text"
            maxLength="60"
            value={productData?.name?.find((t) => t.language === "en")?.text || ""}
            onChange={(e) => setProductData({...productData, name: [{language: "en", text: e.target.value}, ...productData.name.filter((t) => t.language !== "en")]})}
            
            />
        </MDBInputGroup>
    </MDBCol>
</MDBRow>
{/* TITEL END */}

{/* LECTURE DESCRIPTION */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
        <MDBTypography tag="h5">
        {t("Product description. (Min. 500 characters)")}
        </MDBTypography>
    </div>
    <ReactCountryFlag
        countryCode="de"
        svg
        style={{
        width: "20px",
        height: "20px",
        }}
        title={i18n.language}
    /> 
    <Editor
        editorState={editorState["de"]}
        editorClassName="md-textarea form-control"
        onEditorStateChange={e => onEditorStateChange(e, "de")}
      />
      <br></br>
      <ReactCountryFlag
        countryCode="gb"
        svg
        style={{
        width: "20px",
        height: "20px",
        }}
        title={i18n.language}
    />
    <Editor
        editorState={editorState["en"]}
        editorClassName="md-textarea form-control"
        onEditorStateChange={e => onEditorStateChange(e, "en")}
      />
    </MDBCol>
</MDBRow>
{/* LECTURE DESCRIPTION END */}

{/* LECTURE IMAGE */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
  <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
      <MDBTypography tag="h5">
      {t("Upload an image for the product article")}
      </MDBTypography>

    </div>
    <div className="w-100 text-center mb-2 mb-lg-4">
    <img
      src={productData?.images?.main?.fileName ? 
      "https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/" + productData.images.main.fileName: "https://via.placeholder.com/600x400"}
      className="img-thumbnail"
      style={{ height: "200px", width: "400px", objectFit: "cover" }}
      alt='...' />
    </div>
    <MDBFile id='fileDE' onChange={e => handleSetImage(e.target.files[0])} />
    
    
   
    <div className="mt-3">
      <MDBCheckbox
        name="image-condition"
        id="image-condition"
        label={t("I agree that the image will be made public and we have the rights to use the image.")}
        checked={productData?.conditions?.findIndex(c => c.name === "image") > -1 ? productData?.conditions[productData?.conditions?.findIndex(c => c.name === "image")].accepted : false}
        onChange={(e) => {setProductData(oldData => ({...oldData, conditions: [{name: "image", accepted: e.target.checked}, ...oldData.conditions?.filter(c => c.name !== "image")]}))}}
      />
    </div>
  </MDBCol>
 
</MDBRow>
{/* LECTURE IMAGE END */}

{/* LECTURE SCRIPT */}
<MDBRow className="d-flex justify-content-evenly">
  <MDBCol size="12" lg="10" xl="8" xxl="7">
  <hr className="my-4" />
    <div className="mb-2 mb-lg-4">
      <MDBTypography tag="h5">
      {t("Optional: Upload a file for the product")}
      </MDBTypography>
    </div>
    {productData?.file?.fileName && (
      <p className="text-muted">{t("File uploaded successfully")}</p>
    )
    }
    <MDBFile id='fileDE' onChange={e => handleSetFile(e.target.files[0])} />
      
    <div className="mt-3">
      <MDBCheckbox
        name="script-condition"
        id="script-condition"
        label={t("I confirm that I have the rights to use the file and that it can be made public.")}
        checked={productData?.conditions?.findIndex(c => c.name === "file") > -1 ? productData?.conditions[productData?.conditions?.findIndex(c => c.name === "file")].accepted : false}
        onChange={(e) => {setProductData(oldData => ({...oldData, conditions: [{name: "file", accepted: e.target.checked}, ...oldData.conditions?.filter(c => c.name !== "file")]}))}}
      />
    </div>
    
  </MDBCol>
  
</MDBRow>
{/* LECTURE SCRIPT END */}

{/* Employee */}
<MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
    <MDBCol size="12" lg="10" xl="8" xxl="7">
    <hr className="my-4" />
        <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
            {t("Select contact person")}
            </MDBTypography>
            <span className="text-muted">
            {t("Choose a contact person who interested parties can contact regarding the product")}
            </span>
        </div>
        <div className="w-100 text-center">
        <MDBBtn onClick={() => toggleSelectEmployeeModal()} color="primary" outline>
            {t("Select employee")}
        </MDBBtn>
        {employee && (
          <div className="d-flex gap-4 align-items-center">
                  {employee.image?.fileName ? (
                    <img
                      src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                      className="rounded-circle border"
                      style={{
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                      }}
                      alt={employee.image.alt}
                    />
                  ) : (
                    <MDBIcon
                      className="rounded-circle border"
                      style={{
                        height: "50px",
                        width: "50px",
                        textAlign: "center",
                        paddingTop: "12px",
                        fontSize: "20px",
                      }}
                      fas
                      icon="user-alt"
                    />
                  )}
                  <div>
                    <h6>{`${employee.user?.title ? employee.user?.title + " ": ""}${employee.user?.firstName} ${employee.user?.middleName || ""} ${employee.user?.lastName}`}</h6>
                    <span>{employee.department?.items?.[0]?.department.name[0].text}</span>
                  </div>
                </div>
        )}
      </div>
    </MDBCol>
</MDBRow>
{/* Employee END */}
<hr className="my-4" />
<MDBRow className="d-flex justify-content-evenly">
<MDBCol size="12" lg="10" xl="8" xxl="7">
{validationErrors.length > 0 && (
  <div className="alert alert-danger" role="alert">
    {validationErrors.map((error) => (
      <p key={error}>{error}</p>
    ))}
  </div>
)}
{
  isLoading ? (
    <div className="text-center">
      <MDBSpinner grow color="primary" />
    </div>
  ) : (
    <MDBBtn color="primary" className="w-100" onClick={() => createProduct()}>{productId ? t("Update") : t("Create")}</MDBBtn>
  )
}

</MDBCol>
</MDBRow>

</MDBCardBody>
</MDBCard>
</MDBCol>
</MDBRow>

<MDBModal open={selectTopicsModal} onClose={() => setSelectTopicsModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent >
          <MDBModalHeader>
              <MDBModalTitle>Select Topics</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectTopicsModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <SelectTopics
                topics={topics}
                topicGroups={topicGroups}
                isActive={selectTopicsModal}
                toggleActive={toggleSelectTopicsModal}
                selectedTopics={selectedTopics}
                setSelectedTopics={handleSetSelectedTopics}
                parentTopics={topics}
                parentTopicGroups={topicGroups}
                maxSelect={1}
                />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectTopicsModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
<MDBModal open={selectEmployeeModal} onClose={() => setSelectEmployeeModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent >
          <MDBModalHeader>
              <MDBModalTitle>{t("Select employee")}</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectEmployeeModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <EmployeeSelector
              companyId={companyId}
              selectedEmployees={productData.cMSProductEmployeeId ? [productData.cMSProductEmployeeId]: []}
              setSelectedEmployees={(employees) => setProductData({...productData, cMSProductEmployeeId: employees[0]})}
              maxSelect={1}
              />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectEmployeeModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>

</>
);
};

export default CreateProductPage;
