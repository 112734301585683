import { useEffect, useState } from 'react'
import { MDBCheckbox, MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBBtn, MDBIcon,
    MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
    MDBCollapse, MDBInput, MDBChip, MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBCardImage, MDBCardText, MDBModal, MDBModalTitle, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody 
 } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { Link } from "react-router-dom";
const client = generateClient();
export default function CompanyDetail() {
    const { t, i18n } = useTranslation();

    // Get company id from URL which is in format /companies/:id
    const companyId = window.location.pathname.split("/")[2];

    const [company, setCompany] = useState({});

    useEffect(() => {
        const getCompany = async () => {
            try {
                const company = await client.graphql({
                    query: /* GraphQL */ `
                    query GetCompany($id: ID!) {
                        getCompany(id: $id) {
                            id
                            name
                            status
                            website
                            contact {
                                address {
                                    city
                                    countryCode
                                }
                            }
                            image {
                                main {
                                alt
                                fileName
                                }
                                
                            }
                            banner {
                                alt
                                fileName
                                }
                            x4Id
                            x4AdminId
                            description {
                                language
                                    text
                            }
                            createdAt
                            updatedAt
                            companyAdminId
                        }
                    }
                    `,
                    variables: { id: companyId },
                });
                setCompany(company.data.getCompany);
            } catch (error) {
                console.log(error);
            }
        };
        getCompany();
    }, []);


    // Connect Modal
    const [connectModal, setConnectModal] = useState(false);

  const toggleShowConnectModal = () => setConnectModal(!connectModal);

  return (
<>
<MDBCard>
    <MDBCardImage src={company.banner?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + company.banner?.fileName : "https://iec-cp-public.s3.eu-central-1.amazonaws.com/defaultImages/horizontal.jpg"} alt={company.banner?.alt} position='top' />
    <MDBCardBody>
        <MDBCardTitle>{company.name}</MDBCardTitle>
        <MDBBtn outline color="success" className="float-end" style={{marginTop :"-2.5rem"}} onClick={toggleShowConnectModal}><MDBIcon fas icon="check-double" /> {t("Connect")}</MDBBtn>
        <hr></hr>
        <MDBCardText dangerouslySetInnerHTML={{__html: company.description?.length > 0 && company.description.find(x => x.language === i18n.language)?.text}}>
        </MDBCardText>
    </MDBCardBody>
</MDBCard>
<MDBModal open={connectModal} onClose={() => setConnectModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Connect to Company")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleShowConnectModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
                <MDBBtn outline size="lg" className="mb-3 px-5"><MDBIcon far icon="comments" /> {t("Send message")}</MDBBtn>
                <br></br>
                <Link to={"/company/" +  company.id + "/request-affiliation"}>
                    <MDBBtn outline><MDBIcon fas icon="users" /> {t("Request Affiliation")}</MDBBtn>
                </Link>
                
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>

</>
  )
}
