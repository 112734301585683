import { MDBAlert, MDBBtn, MDBCard, MDBCardBody, MDBIcon } from "mdb-react-ui-kit"
import React from 'react'
import { useTranslation } from "react-i18next"

export default function Payment({handleClickPrev, handleClickNext}) {
  const { t, i18n } = useTranslation()
  return (
    <MDBCard className="mt-2 mt-lg-4 col-lg-10 col-xl-7 mx-auto border-none shadow-none text-center">
    <MDBCardBody>
    <h4>{t("Payment method")}</h4>
    <MDBCard className="shadow-5 mx-0 mt-4">
        <MDBCardBody>
            <div className='d-flex align-items-center'>
                <MDBIcon fas icon="file-invoice-dollar" size="2x" />
                    <div className='ms-3 text-start'>
                    <p className='fw-bold mb-1'>Rechnung</p>
                    <p className='mb-0'>{t("You will receive an invoice after completing the order")}</p>
                    </div>
            </div>
            
        </MDBCardBody>
    </MDBCard>
    {/* <div className="alert alert-info text-center mt-3">
        {t("Other payment methods will be available soon")}
    </div> */}
    <div className="text-center mt-4">
        <MDBBtn color="primary" outline className="me-3" onClick={handleClickPrev}>{t("Back")}</MDBBtn>
        <MDBBtn color="primary" onClick={handleClickNext}>{t("Next")}</MDBBtn>
    </div>
    </MDBCardBody>
    </MDBCard>
  )
}
