import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateClient } from "aws-amplify/api";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBIcon, MDBBadge } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { transformMoney } from "../../utils/money";
import { isoToGermanDate } from "../../utils/dateTools";

export default function AllSeminars() {
    const { t, i18n } = useTranslation();
    const client = generateClient();
    const [isLoading, setIsLoading] = useState(true);
    const [allSeminars, setAllSeminars] = useState([]);

    const fetchAllSeminars = async () => {
        setIsLoading(true);
        try {
            const { data } = await client.graphql({
                query: /* GraphQL */ `
                query ListSeminars(
                  $filter: ModelSeminarFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listSeminars(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                      id
                      name {
                        language
                        text
                      }
                      seminarDays {
                        date
                        startTime
                        endTime
                      }
                      product {
                        id 
                        price
                      }
                      speakers {
                        items {
                          user {
                            firstName
                            lastName
                            image {
                              fileName
                            }
                          }
                        }
                      }
                      academyTopics {
                        items {
                          topic {
                            name {
                              language
                              text
                            }
                          }
                        }
                      }
                      bookingStartDate
                      bookingEndDate
                      status
                      createdAt
                    }
                    nextToken
                  }
                }
                `
            });

            // Sortiere nach Datum des ersten Seminartags
            const sortedSeminars = data.listSeminars.items.sort((a, b) => {
                const aDate = a.seminarDays?.[0]?.date || a.createdAt;
                const bDate = b.seminarDays?.[0]?.date || b.createdAt;
                return new Date(aDate) - new Date(bDate);
            });

            setAllSeminars(sortedSeminars);
        } catch (error) {
            console.error("Error fetching seminars:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllSeminars();
    }, []);

    const handleDeleteSeminar = async (id) => {
        if (window.confirm(t("Are you sure you want to delete this seminar?"))) {
            try {
                const seminar = allSeminars.find(s => s.id === id);
                
                // Erst das Produkt löschen
                if (seminar.product?.id) {
                    await client.graphql({
                        query: /* GraphQL */ `
                        mutation DeleteProduct($input: DeleteProductInput!) {
                            deleteProduct(input: $input) {
                                id
                            }
                        }
                        `,
                        variables: {
                            input: {
                                id: seminar.product.id
                            }
                        }
                    });
                }

                // Dann das Seminar löschen
                await client.graphql({
                    query: /* GraphQL */ `
                    mutation DeleteSeminar($input: DeleteSeminarInput!) {
                        deleteSeminar(input: $input) {
                            id
                        }
                    }
                    `,
                    variables: {
                        input: {
                            id
                        }
                    }
                });

                setAllSeminars(allSeminars.filter(seminar => seminar.id !== id));
            } catch (error) {
                console.error("Error deleting seminar:", error);
            }
        }
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 'DRAFT':
                return 'warning';
            case 'PUBLISHED':
                return 'success';
            case 'ENDED':
                return 'secondary';
            case 'CANCELLED':
                return 'danger';
            default:
                return 'primary';
        }
    };

    const formatDates = (seminarDays) => {
        if (!seminarDays || seminarDays.length === 0) return t('No dates set');
        
        return seminarDays.map(day => {
            let dateStr = isoToGermanDate(day.date);
            if (day.startTime && day.endTime) {
                dateStr += ` ${day.startTime} - ${day.endTime}`;
            }
            return dateStr;
        }).join(', ');
    };

    if (isLoading) {
        return (
            <MDBCard>
                <MDBCardBody className="text-center">
                    <MDBIcon fas icon="spinner" spin /> {t("Loading")}
                </MDBCardBody>
            </MDBCard>
        );
    }

    return (
        <MDBRow>
            <MDBCol>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCardTitle className="d-flex justify-content-between align-items-center">
                            <span>{t("Seminars")}</span>
                            <Link to="/admin/academy/seminar/create">
                                <MDBBtn className='my-0'>
                                    <MDBIcon fas icon="plus" className="me-2" />
                                    {t("Create Seminar")}
                                </MDBBtn>
                            </Link>
                        </MDBCardTitle>
                        <MDBTable className="align-middle">
                            <MDBTableHead>
                                <tr>
                                    <th>{t("Name")}</th>
                                    <th>{t("Dates")}</th>
                                    <th>{t("Topics")}</th>
                                    <th>{t("Price")}</th>
                                    <th>{t("Status")}</th>
                                    <th>{t("Booking")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {allSeminars.map((seminar) => (
                                    <tr key={seminar.id}>
                                        <td>
                                            <div>
                                                <p className="fw-bold mb-1">
                                                    {seminar.name.find(n => n.language === i18n.language)?.text || seminar.name[0]?.text}
                                                </p>
                                                <div className="mt-2">
                                                    {seminar.speakers?.items.map((speaker, idx) => (
                                                        <img
                                                            key={idx}
                                                            src={speaker.user.image?.fileName 
                                                                ? `https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.user.image.fileName}`
                                                                : "https://via.placeholder.com/30x30"}
                                                            className="rounded-circle me-2"
                                                            style={{width: "30px", height: "30px", objectFit: "cover"}}
                                                            title={`${speaker.user.firstName} ${speaker.user.lastName}`}
                                                            alt=""
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{formatDates(seminar.seminarDays)}</td>
                                        <td>
                                            {seminar.academyTopics?.items.map((item, idx) => (
                                                <span key={idx} className="me-1">
                                                    {item.topic.name.find(n => n.language === i18n.language)?.text}
                                                    {idx < seminar.academyTopics.items.length - 1 ? ', ' : ''}
                                                </span>
                                            ))}
                                        </td>
                                        <td>{transformMoney(seminar.product?.price)}</td>
                                        <td>
                                            <MDBBadge color={getStatusBadgeColor(seminar.status)} pill>
                                                {t(seminar.status)}
                                            </MDBBadge>
                                        </td>
                                        <td>
                                            <small>
                                                {t("Start")}: {isoToGermanDate(seminar.bookingStartDate)}<br/>
                                                {t("End")}: {isoToGermanDate(seminar.bookingEndDate)}
                                            </small>
                                        </td>
                                        <td>
                                            <Link to={`/admin/seminar/${seminar.id}`}>
                                                <MDBBtn floating color='info' size='sm' className="me-2">
                                                    <MDBIcon fas icon='edit' />
                                                </MDBBtn>
                                            </Link>
                                            <MDBBtn floating color='danger' size='sm' onClick={() => handleDeleteSeminar(seminar.id)}>
                                                <MDBIcon fas icon='trash' />
                                            </MDBBtn>
                                        </td>
                                    </tr>
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
}