import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCheckbox, MDBChip, MDBCol, MDBDateTimepicker, MDBDatepicker, MDBFile, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRadio, MDBRow, MDBSelect, MDBSpinner, MDBTextArea, MDBTypography } from "mdb-react-ui-kit";
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { generateClient, get } from 'aws-amplify/api';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import EmployeeSelector from "../../components-user/news/EmployeeSelector";
import SelectTopics from "../../components-user/topics/SelectTopics";
import ReactCountryFlag from "react-country-flag";
import deepUpdateObject from "../../utils/deepUpdateObject";
import { getUrl, remove, uploadData } from "aws-amplify/storage";
import { fetchAuthSession } from "aws-amplify/auth";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML} from 'draft-js';
import {convertHTMlToRaw} from "../../utils/HTMLDraft";
import { pdf } from "@react-pdf/renderer";


export default function AdminEditLecture() {
  let navigate = useNavigate();
  const client = generateClient();
  const { companyid, lectureid } = useParams();
  const { t, i18n } = useTranslation();

  // States
  const [lecture, setLecture] = useState({});
  const [company, setCompany] = useState({});
  const [employees, setEmployees] = useState(null);
  const [topics, setTopics] = useState(null);
  const [topicGroups, setTopicGroups] = useState([]);

  const [isUploadingLectureImage, setIsUploadingLectureImage] = useState({De: false, En: false});
  const [uploadingLectureImageError, setUploadingLectureImageError] = useState(null);
  const [isUploadingLectureFiles, setIsUploadingLectureFiles] = useState({De: false, En: false});
  const [uploadingLecturesErrors, setUploadingLecturesErrors] = useState({De: null, En: null});

  const [isSaving, setIsSaving] = useState(false);

  // Get lecture and company data
  const getLectureData = async () => {
    try {
      const lecture = await client.graphql({
        variables: { id: lectureid },
        query: /* GraphQL */ `
        query GetLecture($id: ID!) {
          getLecture(id: $id) {
            id
            type
            startTime
            endTime
            dateTimes
            event {
              id
              name {
                text
                language
              }
              status
              date
              lectureDocumentsEndDate
              x4Id
              
              
            }
            company {
              id
              name
              image {
                main {
                  fileName
                  alt
                }
              }
              industry
              status
              website
              admins
              x4Id
              x4AdminId
              companyAdminId
              adminEmployees {
                items {
                  status
                  image {
                    fileName
                    alt
                  }
                  user {
                    id
                    lastName
                    firstName
                    title
                    contact {
                      email
                      phone
                    }
                  }
                }
              }
          
            }
            speakers {
              items {
                employeeId
              }
            }
            session
            mainSpeaker {
              id
              role
              permissions {
                resource
                actions
              }
              status
              x4Id
              WPJetEngineId
              permissions {
                resource
                actions
              }
              companyEmployeesId
              userEmployeeProfilesId
              
            }
            room
            title {
              language
              text
              
            }
            subjects {
              id
              name {
                language
                text
              }
              
            }
            topics {
              items {
                topicId
              }
            }
            content {
              language
              text
              
            }
            conditions {
              name
              accepted
              
            }
            presentationLanguage
            image {
              fileName
              alt
              x4UUID
              
            }
            pdfDe {
              url
              fileName
              s3Path
              
            }
            pdfEn {
              url
              fileName
              s3Path
              
            }
            videoS3Key {
              s3Path
            }
            approvedInsight
            createdAt
            updatedAt
            companyLecturesId
            companyBooth
            eventLecturesId
            lectureMainSpeakerId
            
            adminComment
            adminTopic {
              id
            }
            approved
            lengthInSeconds
            status
          }
        }
      `,
      });
      return {
        ...lecture.data.getLecture,
        speakers: lecture.data.getLecture.speakers?.items?.map(speaker => speaker.employeeId) || [],
        topics: lecture.data.getLecture.topics?.items?.map(topic => topic.topicId) || [],
        tempSubjects: lecture.data.getLecture.subjects?.map(subject => ({de: subject.name.find(name => name.language === "de").text, en: subject.name.find(name => name.language === "en").text})) || [],
        adminTopic: lecture.data.getLecture.adminTopic?.id || null,
      };
    } catch (error) {
      console.warn("Error getting lecture data", error);
    }
  };

  // Get employees
  const getEmployees = async () => {
    try {
        console.log("company", companyid)
        const employees = await client.graphql({
            variables: { filter: { companyEmployeesId: { eq: companyid } } },
            query: /* GraphQL */ `
            query SearchEmployees(
                $filter: SearchableEmployeeFilterInput
                $sort: [SearchableEmployeeSortInput]
                $limit: Int
                $nextToken: String
                $from: Int
                $aggregates: [SearchableEmployeeAggregationInput]
              ) {
                searchEmployees(
                  filter: $filter
                  sort: $sort
                  limit: $limit
                  nextToken: $nextToken
                  from: $from
                  aggregates: $aggregates
                ) {
                    nextToken
                    items {
                        id
                        user {
                                lastName
                                middleName
                                firstName
                                title
                        }
                        department {
                            items {
                                department {
                                    name {
                                        language
                                        text
                                    }
                                }
                            }
                        }
                        image {
                            alt
                            fileName
                          }
                      }
                }
            }
            `
        });
        //console.log("employees", employees)
        let employeesArray = employees.data.searchEmployees.items;
        return(employeesArray);
    } catch (err) {
        console.log("error fetching employees", err);
    }
  };

  // Get topics
  const getTopics = async () => {
    try {
      const topicGroupsWithTopics = await client.graphql({
        query: /* GraphQL */ `
        query ListTopicGroups(
          $filter: ModelTopicGroupFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
              id
              color
              name {
                language
                text
              }
              topics {
                items {
                  id
                  name {
                    language
                    text
                  }
                  topicGroupTopicsId
                }
              }
            }
            nextToken
            __typename
          }
        }
      `
        });
        return {
          topicGroups: topicGroupsWithTopics.data.listTopicGroups.items,
          topics: topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat(),
        }

    } catch (error) {
        console.log("error on fetching topics", error);
    }
}

  useEffect(() => {
    const fetchData = async () => {
    const [lectureData, employeesData, topicsData] = await Promise.all([getLectureData(), getEmployees(), getTopics()]);
    setLecture(lectureData);
    setCompany(lectureData.company);
    setEmployees(employeesData);
    setTopics(topicsData.topics);
    setTopicGroups(topicsData.topicGroups);
    if(lectureData.content) {
      const deContent = lectureData.content.find(c => c.language === "de")?.text;
      const enContent = lectureData.content.find(c => c.language === "en")?.text;
      setEditorState({
        de: deContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(deContent)
                        )
                      ) : EditorState.createEmpty(),
        en: enContent ? EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                          convertFromHTML(enContent)
                        )
                      ) : EditorState.createEmpty(),
      });
    }
    };
    fetchData();
  }, []);

  // Select Employee
  const [selectEmployeeModal, setSelectEmployeeModal] = useState(false);
  const toggleSelectEmployeeModal = () => setSelectEmployeeModal(!selectEmployeeModal);

  // Select Topics
  const [selectTopicsModal, setSelectTopicsModal] = useState(false);
  const toggleSelectTopicsModal = () => setSelectTopicsModal(!selectTopicsModal);
  const handleSetSelectedTopics = (topics) => {setLecture(oldLecture => ({...oldLecture, topics}))}

  // Select Admin Topic
  const [selectAdminTopicModal, setSelectAdminTopicModal] = useState(false);
  const toggleSelectAdminTopicModal = () => setSelectAdminTopicModal(!selectAdminTopicModal);
  const handleSetSelectAdminTopicModal = (topics) => {setLecture(oldLecture => ({...oldLecture, adminTopic: topics[0]}))}

  // Save lecture
  const updateLecture = async () => {
    try {
      setIsSaving(true);
      let lectureDataForLecture = {
        conditions: lecture?.conditions,
        content: lecture?.content,
        subjects: lecture?.tempSubjects?.map(subjectTexts => ({
          id: uuidv4(),
          name: [
            {
              language: "de",
              text: subjectTexts.de
            },
            {
              language: "en",
              text: subjectTexts.en
            },
          ]
        })).filter(Boolean) || lecture?.subjects || null,
        title: lecture?.title,
        type: lecture?.type,
        companyLecturesId: companyid,
        adminComment: lecture?.adminComment || "",
        status: lecture?.status || "created",
        lectureAdminTopicId: lecture?.adminTopic || null,
        ...(lecture.pdfDe && {pdfDe: lecture.pdfDe}),
        ...(lecture.pdfEn && {pdfEn: lecture.pdfEn}),
        ...(lecture?.companyBooth && {companyBooth: lecture?.companyBooth}),
        ...(lecture?.dateTimes && {dateTimes: lecture?.dateTimes}),
        presentationLanguage: lecture?.presentationLanguage || "DE",
      };
      if(lecture?.lectureMainSpeakerId) lectureDataForLecture.lectureMainSpeakerId = lecture?.lectureMainSpeakerId;
      if(lecture?.image?.fileName) lectureDataForLecture.image = lecture?.image;
      if(lecture?.lengthInSeconds) lectureDataForLecture.lengthInSeconds = lecture?.lengthInSeconds;
      if(lecture.hasOwnProperty("approved")) {lectureDataForLecture.approved = lecture?.approved} else {lectureDataForLecture.approved = null};
      if(lecture.hasOwnProperty("approvedInsight")) {lectureDataForLecture.approvedInsight = lecture?.approvedInsight} else {lectureDataForLecture.approvedInsight = null};
      console.log("lectureDataForLecture", lecture)
      const updatedLecture = await client.graphql({
        variables: { input: lectureid ? {...lectureDataForLecture, id: lectureid} :lectureDataForLecture },
        query: 
        /* GraphQL */ `
        mutation UpdateLecture(
          $input: UpdateLectureInput!
          $condition: ModelLectureConditionInput
        ) {
            updateLecture(input: $input, condition: $condition) {
              title {
                language
                text
            }
            speakers {
                items {
                    employee {
                        image {
                        fileName
                        alt
                        }
                    }
                }
            }
            subjects {
                id
                name {
                    language
                    text
                }
            } 
            topics {
                items {
                  topic {
                    id
                    name {
                      language
                      text
                    }
                  }
                }
            }        
            content {
                language
                text
            }
            conditions {
                name
                accepted
            }
            image {
                fileName
                alt
            }
            pdfDe {
                url
                name   
            }
            pdfEn {
                url
                name   
            }
            videoS3Key {
              s3Path
            }
            approvedInsight
            company {
                id
                name
                image {
                    main {
                        fileName
                        alt
                    }
                }
            }
            conditions {
                name
                accepted
            }
            id
            session
            dateTimes
            companyBooth
            room
            type
            presentationLanguage
            createdAt
            updatedAt
            companyLecturesId
            eventLecturesId
            lectureMainSpeakerId
            approved
            status
            __typename
            }
          }
        `
      });
      //console.log(lecture);

      // Speakers

      let dataForSpeaker = [];
        if(lecture?.speakers?.length > 0  && !lectureid) {
          lecture?.speakers.forEach(speaker => {
            dataForSpeaker.push({
              id: speaker,
              speakerLectureId: lecture.data.createLecture.id
            })
          })
          console.log("dataForSpeaker", dataForSpeaker)
          await Promise.all(dataForSpeaker.map(async speaker => {
            console.log("speaker", speaker)
            const speakerData = await client.graphql({
              variables: { input: {
                lectureId: speaker.speakerLectureId,
                employeeId: speaker.id
              } },
              query: /* GraphQL */ `
              mutation CreateLecturesSpeakers(
                $input: CreateLecturesSpeakersInput!
                $condition: ModelLecturesSpeakersConditionInput
              ) {
                createLecturesSpeakers(input: $input, condition: $condition) {
                  id
                  lectureId
                  employeeId
                }
              }
            `,
            });
            console.log("speakerData", speakerData);
          }
        ))} else if(lectureid) {
          console.log("updating speakers")
          async function updateLectureSpeakers(lectureData, lectureid, client) {
            const existingSpeakers = await fetchExistingSpeakers(lectureid, client);
            const newSpeakerIds = lectureData.speakers || [];
            const existingSpeakerIds = existingSpeakers.map(s => s.employeeId);
        
            const speakersToAdd = newSpeakerIds.filter(id => !existingSpeakerIds.includes(id));
            const speakersToRemove = existingSpeakers.filter(s => !newSpeakerIds.includes(s.employeeId));
        
            // Füge neue Speaker hinzu
            await Promise.all(speakersToAdd.map(async id => {
              await addSpeaker({ id, speakerLectureId: lectureid }, client);
            }));
        
            // Entferne überflüssige Speaker
            await Promise.all(speakersToRemove.map(async speaker => {
              await removeSpeaker(speaker.id, client);
            }));

          }
          
          async function fetchExistingSpeakers(lectureId, client) {
            const query = /* GraphQL */ `
            query ListLecturesSpeakers(
              $filter: ModelLecturesSpeakersFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listLecturesSpeakers(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                    id
                    employeeId
                  }
                }
              }
            `;
          
            const response = await client.graphql({
              query,
              variables: { filter: { lectureId: { eq: lectureId } }, limit: 10000 }
            });
          
            return response.data.listLecturesSpeakers.items || [];
          }
          
          
          
          function compareSpeakers(newSpeakers, existingSpeakers) {
            const speakersToAdd = newSpeakers.filter(speaker => !existingSpeakers.includes(speaker));
            const speakersToRemove = existingSpeakers.filter(speaker => !newSpeakers.includes(speaker));
            return { speakersToAdd, speakersToRemove };
          }
          
          async function addSpeaker(speaker, client) {
            // GraphQL-Mutation, um einen Speaker hinzuzufügen
            const mutation = /* GraphQL */ `
            mutation CreateLecturesSpeakers(
              $input: CreateLecturesSpeakersInput!
              $condition: ModelLecturesSpeakersConditionInput
            ) {
              createLecturesSpeakers(input: $input, condition: $condition) {
                  id
                }
              }
            `;
            await client.graphql({
              query: mutation,
              variables: { input: { lectureId: speaker.speakerLectureId, employeeId: speaker.id } }
            });
          }
          
          async function removeSpeaker(lectureSpeakerId, client) {
            const mutation = /* GraphQL */ `
            mutation DeleteLecturesSpeakers(
              $input: DeleteLecturesSpeakersInput!
              $condition: ModelLecturesSpeakersConditionInput
            ) {
              deleteLecturesSpeakers(input: $input, condition: $condition) {
                  id
                }
              }
            `;
          
            await client.graphql({
              query: mutation,
              variables: { input: { id: lectureSpeakerId } }
            });
          }
          

          await updateLectureSpeakers(lecture, lectureid, client);
        }

     
          if(lecture?.topics?.length > 0  && !lectureid) {
        console.log("creating topics", lecture?.topics)
        let dataForTopics = [];
        lecture?.topics.forEach(topicId => {
          dataForTopics.push({
            topicId: topicId,
            lectureId: lecture.data.createLecture.id
          })
        })
        console.log("dataForTopics", dataForTopics)
        await Promise.all(dataForTopics.map(async topic => {
          console.log("topic", topic)
          const topicData = await client.graphql({
            variables: { input: {
              lectureId: topic.lectureId,
              topicId: topic.topicId
            } },
            query: /* GraphQL */ `
            mutation CreateLecturesTopics(
              $input: CreateLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              createLecturesTopics(input: $input, condition: $condition) {
                id
                lectureId
                topicId
              }
            }
          `,
          });
          console.log("topicData", topicData);
        }))
      } else if(lectureid) {
        async function updateLectureTopics(lectureData, lectureid, client) {

            // Aktualisieren von Topics für eine bestehende Lecture
            console.log("updating topics");
            const existingTopics = await fetchExistingTopics(lectureid, client);
            const newTopicIds = lectureData.topics || [];
            console.log("newTopicIds", newTopicIds)
            const existingTopicIds = existingTopics.map(t => t.topicId);

            const topicsToAdd = newTopicIds.filter(id => !existingTopicIds.includes(id));
            const topicsToRemove = existingTopics.filter(t => !newTopicIds.includes(t.topicId));

            // Füge neue Topics hinzu
            await Promise.all(topicsToAdd.map(async id => {
              await addTopic({ topicId: id, lectureId: lectureid }, client);
            }));

            // Entferne überflüssige Topics
            await Promise.all(topicsToRemove.map(async topic => {
              await removeTopic(topic.id, client);
            }));
          
        }

        async function fetchExistingTopics(lectureId, client) {
          const query = /* GraphQL */ `
            query ListLecturesTopics(
              $filter: ModelLecturesTopicsFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listLecturesTopics(
                filter: $filter
                limit: $limit
                nextToken: $nextToken
              ) {
                items {
                  id
                  topicId
                }
              }
            }
          `;

          const response = await client.graphql({
            query,
            variables: { filter: { lectureId: { eq: lectureId } }, limit: 10000 }
          });

          return response.data.listLecturesTopics.items || [];
        }

        async function addTopic(topic, client) {
          const mutation = /* GraphQL */ `
            mutation CreateLecturesTopics(
              $input: CreateLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              createLecturesTopics(input: $input, condition: $condition) {
                id
              }
            }
          `;
          await client.graphql({
            query: mutation,
            variables: { input: { lectureId: topic.lectureId, topicId: topic.topicId } }
          });
        }

        async function removeTopic(lectureTopicId, client) {
          const mutation = /* GraphQL */ `
            mutation DeleteLecturesTopics(
              $input: DeleteLecturesTopicsInput!
              $condition: ModelLecturesTopicsConditionInput
            ) {
              deleteLecturesTopics(input: $input, condition: $condition) {
                id
              }
            }
          `;

          await client.graphql({
            query: mutation,
            variables: { input: { id: lectureTopicId } }
          });
        }

        // Verwenden Sie updateLectureTopics, um Topics zu verwalten
        await updateLectureTopics(lecture, lectureid, client);

      }
       await new Promise(resolve => setTimeout(async () => {
        const newLectureData = await getLectureData();
        setLecture(newLectureData);
        resolve();
      }, 2000));
      
    } catch (error) {
      console.warn("Error updating lecture", error);
    }
  };

  // Editor
const [editorState, setEditorState] = useState({
  de: EditorState.createEmpty(),
  en: EditorState.createEmpty(),
})
const onEditorStateChange = (editorState, language ) => {
setEditorState(oldState => ({...oldState, [language]: editorState}));
setLecture(oldNewsData => ({...oldNewsData, content: [...oldNewsData.content?.filter(c => c.language !== language) ?? [], {language, text: convertHTMlToRaw(editorState)}] }));

} 

  // Handlers
  const handleChange = (e, additionalInstructions) => {
    console.log("change", e.target.attributes.datapath.value, e.target.checked, e.target.value);
    console.log("additionalInstructions", additionalInstructions);
    console.log(e)
    
    // Get values from input
    const datapath = e.target.attributes.datapath.value;
    let value;

    // Check if the input is a checkbox
    if (e.target.type === 'checkbox') {
        value = e.target.checked; // Use 'checked' for checkboxes
    } else {
        value = e.target.value; // Use 'value' for other input types
    }

    // Update current company object
    const newLecture = deepUpdateObject(lecture, datapath, value, additionalInstructions);
    console.log("new lecture", JSON.stringify(newLecture));
    setLecture(newLecture);
  };

  const handleUpdateLecture = async () => {
    setIsSaving(true);
    await updateLecture();
    setIsSaving(false);
  }

  const handleSetFile = (file, language) => {
    // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
  if(file) {
    try {
      console.log("filename", file.name);
      setIsUploadingLectureFiles({...isUploadingLectureFiles, [language]: true});
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => {
      console.log('Error: ', error);
      setUploadingLecturesErrors({...uploadingLecturesErrors, [language]: error});
    }
    reader.onload = async () => {
     try {
      const result = await uploadData({
        key: file.name,
        data: file,
        options: {
          accessLevel: 'protected', // defaults to `guest` but can be 'private' | 'protected' | 'guest'
          // onProgress // Optional progress callback.
        }
      }).result;
      console.log('Succeeded: ', result);
      const fileURL = await getUrl({ key: result.key, options: { level: 'protected' } });
      console.log('fileURL', fileURL); 
      handleChange({
        target: {
          value: {
            fileName: result.key,
            name: result.key,
            s3Path: "protected/" + (await fetchAuthSession()).identityId + "/" + result.key,
  
          },
          attributes: {
            datapath: {
              value: "pdf" + language
            }
          },
        },
      });
      // const restOperation = put({
      //   apiName: 'CPRESTAPI',
      //   path: '/uploadLectureFile',
      //   options: {
      //     body: {
      //       originalFileName: file.name,
      //       companyId,
      //       base64FileBuffer: reader.result,
      //     },
      //   }
      // });
      // const response = await (await restOperation.response).body.json();
      // console.log("response", response);
      
      setIsUploadingLectureFiles({...isUploadingLectureFiles, [language]: false});
    setUploadingLecturesErrors({...uploadingLecturesErrors, [language]: null});
     } catch (error) {
      console.log("error", error);
      setIsUploadingLectureFiles({...isUploadingLectureFiles, [language]: false});
      setUploadingLecturesErrors({...uploadingLecturesErrors, [language]: error.errors?.[0].message || error.message});
     }
    };
    
    
    } catch (error) {
      console.log("error", error);
      setIsUploadingLectureFiles({...isUploadingLectureFiles, [language]: false});
      setUploadingLecturesErrors({...uploadingLecturesErrors, [language]: error.errors?.[0].message || error.message});
    }
    
  }
    
  }

  const downloadFile = async (fileName, path) => {
    const regex = /protected\/(eu-central-1:[a-z0-9-]+)\//i;
    const match = path.match(regex);
    const identity = match[1];
    const getUrlResult = await getUrl({
      key: fileName,
      options: {
        accessLevel: 'protected' ,
        targetIdentityId: identity,
      },
    });
    console.log('signed URL: ', getUrlResult.url);
    window.open(getUrlResult.url, '_blank');
  }

  const removeFile = async (fileName, path, language) => {
    try {
      const regex = /protected\/(eu-central-1:[a-z0-9-]+)\//i;
      const match = path.match(regex);
      const identity = match[1];
      await remove({ key: fileName, options: { accessLevel: 'protected', targetIdentityId: identity, } });
      handleChange({
        target: {
          value: null,
          attributes: {
            datapath: {
              value: "pdf" + language
            }
          },
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleSetImage = (file) => {
    // upload file using mutation: uploadLectureFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadLectureFiles-${env}")
    if(file) {
      try {
        setIsUploadingLectureImage(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onerror = error => {
        console.log('Error reader: ', error);
        setUploadingLectureImageError(error);
      }
      reader.onload = async () => {
        try {
          console.log(reader.result)
          const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
          //console.log("base64FileBuffer")
          const response = await client.graphql({
            query: /* GraphQL */ `
            mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
              uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
            }
          `,
            variables: {
              imageBuffer: base64FileBuffer,
              imageType: "lectureImage",
            },
          });
          console.log("response", response);
          if(response.data.uploadCompanyImage.includes("error=")) {
            setIsUploadingLectureImage(false);
            setUploadingLectureImageError(response.data.uploadCompanyImage.replace(/{|}|error=/g, ''));
          } else {
            const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
            handleChange({
              target: {
                value: {
                  fileName,
                  alt: "Lecture Image",
                },
                attributes: {
                  datapath: {
                    value: "image"
                  }
                },
              },
            });
          }
          } catch (error) {
            console.log("error", error);
            setIsUploadingLectureImage(false);
            setUploadingLectureImageError(error.errors?.[0]?.message || error.message);
          }
      }
      setIsUploadingLectureImage(false);
      setUploadingLectureImageError(null);
      } catch (error) {
        console.log("error", error);
        setIsUploadingLectureImage(false);
        setUploadingLectureImageError(error.errors[0].message);
      }
    }
  }
  
  // Sessions 
  const [newSession, setNewSession] = useState("");

  // Components
  const Topics = ({topics, topicGroups, selectedTopics}) => {
    console.log("topics",topics);
    console.log("topicGroups",topicGroups);
    console.log("selectedTopics",selectedTopics);
    // First sort the topics by topicGroup (new array with objects for eacht group, inside object the key: topics, inside the topics for this group), then return for each group a col, inside the col the title of the group, then the topics as chips
    if(topics?.length === 0 || topicGroups?.length === 0 || selectedTopics?.length === 0) {
        return null;
    }
    let sortedTopics = [];
    selectedTopics.forEach((selectedTopic) => {
        const topicData = topics.find((topic) => topic.id === selectedTopic);
        const topicGroup = topicGroups.find((topicGroup) => topicGroup.id === topicData.topicGroupTopicsId);
        const topicGroupIndex = sortedTopics.findIndex((sortedTopic) => sortedTopic.id === topicGroup.id);
        if (topicGroupIndex === -1) {
            sortedTopics.push({...topicGroup, topics: [topicData]});
        } else {
            sortedTopics[topicGroupIndex].topics.push(topicData);
        }
    });
    console.log("sortedTopics",sortedTopics);
    return (
        <MDBRow className="my-3 my-lg-4">
            {sortedTopics.map((topicGroup) => {
                return (
                    <MDBCol size="12" xxl="6" key={topicGroup.id} className="mb-3 mb-lg-4">
                        <h6>{topicGroup.name?.find(n => n.language === i18n.language)?.text || topicGroup.name?.[0]?.text}</h6>
                        <MDBRow>
                            {topicGroup.topics.map((topic) => {
                                return (
                                    <MDBCol size="12" key={topic.id}>
                                        <MDBChip className="m-1 float-start" bgColor={topicGroup.color} textColor="white">{topic?.name?.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}</MDBChip>
                                    </MDBCol>
                                )
                            })}
                        </MDBRow>
                    </MDBCol>
                )
            })}
        </MDBRow>
    )
}

  // Back to top button
  let mybutton;
  window.onscroll = function () {
    mybutton = document.getElementById("btn-back-to-top");
    if (mybutton) scrollFunction(mybutton);
  };

  function scrollFunction(mybutton) {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  return (
<>
<MDBBtn
  onClick={backToTop}
  id='btn-back-to-top'
  style={{
    position: "fixed",
    bottom: "20px",
    right: "20px",
    display: "none",
    zIndex: "99",
  }}
  className='btn-floating'
  color='primary'
  size='lg'>
  <MDBIcon fas icon="arrow-up" />
</MDBBtn>
    <MDBCard>
      <MDBCardBody>
        <MDBRow>
          <MDBCol size="12" lg="6" xl="4">
            <MDBTypography tag="h5">{t("Event")}</MDBTypography>
            <MDBTypography tag="h6">Lounges 2024 - {lecture.type}</MDBTypography>
            <br></br>
            <MDBTypography tag="h5">{t("Company")}</MDBTypography>
            <>
              {company?.image?.main?.fileName ?
                  <div className='d-flex align-items-center'>
                      {company.image?.main?.fileName ?
                          <img
                              src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+company.image.main.fileName}
                              className="rounded-circle border"
                              style={{ height: "50px", width: "50px", objectFit: "contain" }}
                              alt={company.image.alt}
                          />
                      :
                          <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                  
                      }
                      <div className='ms-3'>
                      <Link to={`/admin/company/${company.id}`}>
                      <p className='fw-bold mb-1 text-body'>{company.name}</p>
                      </Link>
                      {/* <p className='text-muted mb-0'><a href={company.website} target="_blank" rel="noreferrer">{company.website}</a></p> */}
                      </div>
                  </div>
                  :
                  <div className='d-flex align-items-center'>
                    <MDBIcon
                        className="rounded-circle border"
                        style={{
                            height: "50px",
                            width: "50px",
                            textAlign: "center",
                            paddingTop: "12px",
                            fontSize: "20px",
                        }}
                        fas
                        icon="user-alt"
                    />
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{company.name}</p>
                      <p className='text-muted mb-0'><a href={company.website} target="_blank" rel="noreferrer">{company.website}</a></p>
                    </div>
                  </div>
              }
            </>
            <MDBTypography tag="h5" className="mt-2 mt-xl-3">{t("Company Admins")}</MDBTypography>
            {lecture?.company?.adminEmployees?.items?.length > 0 && lecture?.company?.adminEmployees?.items.map((admin, index) => (
              <div className='d-flex align-items-center'>
              {admin.image?.fileName ?
                          <img
                              src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+ admin.image.fileName}
                              className="rounded-circle border"
                              style={{ height: "50px", width: "50px", objectFit: "contain" }}
                              alt={admin.image.alt}
                          />
                          :
                          <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                          
              }
                <div className='ms-3'>
                <Link to={`/admin/user/${admin.user.id}`}>
                <p className='fw-bold mb-1 text-body'>{admin.user.title ? admin.user.title + " " : ""}{admin.user.firstName} {admin.user.middleName} {admin.user.lastName}{admin.status === "pending" && <MDBChip className="bg-warning text-white bg-opacity-75 inline" style={{maxWidth: "fit-content"}}>{t("Pending")}</MDBChip>}</p>
                </Link>
                <p className='text-muted mb-0'>{admin.user.contact?.email}</p>
                <p className='text-muted mb-0'>{admin.user.contact?.phone}</p>
                </div>
              </div>
            ))}
            
          </MDBCol>
          <MDBCol size="12" lg="6" xl="4">
            <MDBTypography tag="h5">{t("Erstellung & Update")}</MDBTypography>
            <MDBTypography tag="h6">{t("Erstellt")}: {new Date(lecture?.createdAt).toLocaleString("de-de")}</MDBTypography>
            <MDBTypography tag="h6">{t("Zuletzt aktualisiert")}: {new Date(lecture?.updatedAt).toLocaleString("de-de")}</MDBTypography>
            <MDBTypography tag="h5">{t("Comment")}</MDBTypography>
            <MDBTextArea datapath="adminComment" value={lecture?.adminComment || ""} onChange={handleChange} />
            <MDBTypography tag="h5" className="mt-3">{t("Sessions")}</MDBTypography>
            {lecture?.dateTimes?.length > 0 && lecture.dateTimes.map((dateTime, index) => (
              <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6" key={index}>{new Date(dateTime).toLocaleString("de-de")}</MDBTypography>
              <MDBBtn size="sm" color="danger" onClick={() => setLecture({...lecture, dateTimes: lecture.dateTimes.filter((date, i) => i !== index)})}>{t("Remove")}</MDBBtn>
              </div>
              
            ))}
            <div className='d-flex justify-content-between mt-2'>
            <MDBTypography tag="h6">{t("Add Date")}</MDBTypography>: 
            <input
              type="datetime-local"
              value={newSession || new Date("2024-04-23T08:00").toISOString().slice(0, 16)}
              onChange={(e) => setNewSession(e.target.value)}
              />
            <MDBBtn color="primary" onClick={() => setLecture({...lecture, dateTimes: [...(lecture.dateTimes || []), moment.tz(newSession, "Europe/Berlin").toISOString()]})}>{t("Add")}</MDBBtn>
            </div>
            <Link to={`/admin/promotiontimes`} target="_blank" rel="noopener noreferrer">
              <MDBBtn color="primary" size="sm" className="mt-3">{t("Promotions Calendar")}</MDBBtn>
            </Link>
          </MDBCol>
          <MDBCol size="12" lg="6" xl="4">
          {/* Company Booth */}
          <MDBRow>
            <MDBCol size="6">
             <MDBTypography tag="h6">{t("Company Booth")}</MDBTypography>
            </MDBCol>
            <MDBCol size="6">
              <MDBInput 
                  label={t("Company Booth")}
                  size="sm"
                  datapath="companyBooth"
                  value={lecture?.companyBooth || ""}
                  onChange={e => handleChange({...e, target: {value: e.target.value,attributes: {datapath: {value: "companyBooth"}}}} )}
                  />
            </MDBCol>
          </MDBRow>
              
         
           {/* Type */}
           <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6">{t("Type")}</MDBTypography>
              <MDBSelect
                size="sm"
                data={[
                  { text: t("All"), value: null },
                  { text: t("Marketing"), value: "marketing" },
                  { text: t("Technical"), value: "technical" },
                  { text: t("Educational"), value: "educational" },
                  { text: t("Recruiting"), value: "recruiting" },
                  { text: t("Promotion Marketing"), value: "promotion_marketing" },
                  { text: t("Promotion Technical"), value: "promotion_technical" },
                  { text: t("Wien"), value: "lecture_wien" },
                ]}
                datapath="status"
                value={lecture?.type}
                onChange={e => handleChange({...e, target: {value: e?.value,attributes: {datapath: {value: "type"}}}} )}
              />
            </div>
            {/* Topic */}
            <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6">{t("Topic")}</MDBTypography>
              {lecture?.adminTopic && (
                <MDBChip closeIcon onDelete={() => setLecture({...lecture, adminTopic: null})} className="m-0" bgColor="grey" textColor="white">{topics?.find(topic => topic.id === lecture.adminTopic)?.name?.find(name => name.language === i18n.language)?.text}</MDBChip>
              )
              }
              <MDBBtn size="sm" className="py-0" style={{height: "30px"}} onClick={() => toggleSelectAdminTopicModal()} color="primary" outline>
                {t("Select Topic")}
              </MDBBtn>
            </div>
            {/* Length */}
            <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6">{t("Length in seconds")}</MDBTypography>
              <MDBSelect
                size="sm"
                data={[
                  { text: "30 " + t("Minutes"), value: 30 },
                  { text: "45 " + t("Minutes"), value: 45 },
                  { text: "60 " + t("Minutes"), value: 60 },
                ]}
                datapath="lengthInSeconds"
                value={lecture?.lengthInSeconds}
                onChange={e => handleChange({...e, target: {value: parseInt(e.value),attributes: {datapath: {value: "lengthInSeconds"}}}} )}
              />
            </div>
            {/* Status */}
            <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6">{t("Status")}</MDBTypography>
              <MDBSelect
                size="sm"
                data={[
                  { text: t("Created"), value: "created" },
                  { text: t("Approved"), value: "checked" },
                  { text: t("Not Approved"), value: "not_approved" },
                  { text: t("In Planning"), value: "in_planning" },
                  { text: t("Committed"), value: "committed" },
                ]}
                datapath="status"
                value={lecture?.status}
                onChange={e => handleChange({...e, target: {value: e.value,attributes: {datapath: {value: "status"}}}} )}
              />
            </div>
            {/* Approved */}
            <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6">{t("Approved")}</MDBTypography>
              <div>

                  <MDBCheckbox
                    name='approved'
                    id='approved_yes'
                    label={t("Yes")}
                    inline
                    checked={lecture?.approved === true}
                    onChange={(e) => handleChange({...e, target: {checked: lecture?.approved === true ? null : true, value: lecture?.approved === true ? null : true, attributes: {datapath: {value: "approved"}}}})}
                  />

                  <MDBCheckbox
                    name='approved'
                    id='approved_no'
                    label={t("No")}
                    inline
                    checked={lecture?.approved === false}
                    onChange={(e) => handleChange({...e, target: {checked: lecture?.approved === false ? null : false, value: lecture?.approved === false ? null : false, attributes: {datapath: {value: "approved"}}}})}
                  />
              </div>
            </div>
            {/* Approved Insight */}
            <div className='d-flex justify-content-between mt-2'>
              <MDBTypography tag="h6">{t("Approved Insight")}</MDBTypography>
              <div>
                
                  <MDBCheckbox
                    name='approvedInsight'
                    id='approvedInsight_yes'
                    label={t("Yes")}
                    inline
                    checked={lecture?.approvedInsight === true}
                    onChange={(e) => handleChange({...e, target: {checked: lecture?.approvedInsight === true ? null : true, value: lecture?.approvedInsight === true ? null : true, attributes: {datapath: {value: "approvedInsight"}}}})}
                  />

                  <MDBCheckbox
                    name='approvedInsight'
                    id='approvedInsight_no'
                    label={t("No")}
                    inline
                    checked={lecture?.approvedInsight === false}
                    onChange={(e) => handleChange({...e, target: {checked: lecture?.approvedInsight === false ? null : false, value: lecture?.approvedInsight === false ? null : false, attributes: {datapath: {value: "approvedInsight"}}}})}
                  />
              </div>
            </div>


          </MDBCol>
        </MDBRow>
        {
          isSaving ? (
            <MDBSpinner color="primary" className="float-end mt-3" />
          )
          :
          <div className="float-end mt-3">
          <MDBBtn color="primary" onClick={() => handleUpdateLecture()}>{t("Save")}</MDBBtn>
          <br></br>
          <MDBBtn color="link" onClick={() => navigate(-1)}>{t("Back")}</MDBBtn>
          </div>
          
          
        }
        <br></br>
        
      </MDBCardBody>
    </MDBCard>
    <MDBCard className="mt-2 mt-xl-4">
      <MDBCardBody>
        {/* LECTURE Conditions */}
        {
          (lecture?.type !== "marketing" && lecture?.type !== "lecture_wien" && lecture?.type !== "promotion_marketing" && lecture?.type !== "promotion_technical" && lecture?.type !== "recruiting") && <>
          <MDBRow className="d-flex justify-content-start">
        <div className="mb-2 mb-lg-4">
                    <MDBTypography tag="h5">
                    {t("Conditions")}
                    </MDBTypography>
                    
                </div>
          <div className="px-1 px-md-3" style={{maxWidth: "800px"}}>
            <MDBCheckbox
              name="adCondition"
              id="adContition"
              label={t("All content is absolutely ad-free")}
              checked={lecture?.conditions?.findIndex(c => c.name === "adCondition") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "adCondition")].accepted : false}
              onChange={e => handleChange({target: {
              value: {
                name: "adCondition",
                accepted: e.target.checked,
              },
              attributes: {
                datapath: {
                  value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "adCondition") > -1 ? lecture?.conditions?.findIndex(c => c.name === "adCondition") : lecture?.conditions?.length) + "]" : "conditions[0]"
                }}
            }})}
            />
          </div>
          <div className="px-1 px-md-3" style={{maxWidth: "800px"}}>
            <MDBCheckbox
              name="recordCondition"
              checked={lecture?.conditions?.findIndex(c => c.name === "recordCondition") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "recordCondition")].accepted : false}
              onChange={e => handleChange({target: {
              value: {
                name: "recordCondition",
                accepted: e.target.checked,
              },
              attributes: {
                datapath: {
                  value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "recordCondition") > -1 ? lecture?.conditions?.findIndex(c => c.name === "recordCondition") : lecture?.conditions?.length) + "]" : "conditions[0]"
                }}
            }})}
              id="recordContition"
              label={t("The lecture will be recorded via audio and video")}
            />
          </div>
          <div className="px-1 px-md-3" style={{maxWidth: "800px"}}>
            <MDBCheckbox
              name="rightsCondition"
              id="rightsContition"
              label={t("The rights to the recording belong to Inspire GmbH and the recording will be published on the trade fair platform shortly after the lecture.")}
              checked={lecture?.conditions?.findIndex(c => c.name === "rightsContition") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "rightsContition")].accepted : false}
              onChange={e => handleChange({target: {
              value: {
                name: "rightsContition",
                accepted: e.target.checked,
              },
              attributes: {
                datapath: {
                  value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "rightsContition") > -1 ? lecture?.conditions?.findIndex(c => c.name === "rightsContition") : lecture?.conditions?.length) + "]" : "conditions[0]"
                }}
            }})}
            />
          </div>
          <div className="px-1 px-md-3" style={{maxWidth: "800px"}}>
            <MDBCheckbox
              name="deadlineContition"
              id="deadlineContition"
              label={t("I accept that corrections to the content of the recording of the lecture are only possible up to and including May 5, 2024.")}
              checked={lecture?.conditions?.findIndex(c => c.name === "deadlineContition") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "deadlineContition")].accepted : false}
              onChange={e => handleChange({target: {
                value: {
                  name: "deadlineContition",
                  accepted: e.target.checked,
                },
                attributes: {
                  datapath: {
                    value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "deadlineContition") > -1 ? lecture?.conditions?.findIndex(c => c.name === "deadlineContition") : lecture?.conditions?.length) + "]" : "conditions[0]"
                  }}
              }})}
            />
          </div>

         
        </MDBRow>
        
          </>
        }
        {/* LECTURE Conditions marketing */}
        {
        (lecture?.type !== "promotion_marketing" && lecture?.type !== "promotion_technical" && lecture?.type !== "recruiting") && <>
        <MDBRow className="mt-4">
          <MDBCol size="12" className="mx-auto">
          <MDBTypography tag="h5">{t("Marketing")}</MDBTypography>
          <MDBCheckbox
            label={t("Would you like to publish the contents of the presentation in the ECV's Cleanroom Processes magazine?")}
            checked={lecture?.conditions?.findIndex(c => c.name === "ecv") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "ecv")].accepted : false}
            onChange={e => handleChange({target: {
                value: {
                  name: "ecv",
                  accepted: e.target.checked,
                },
                attributes: {
                  datapath: {
                    value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "ecv") > -1 ? lecture?.conditions?.findIndex(c => c.name === "ecv") : lecture?.conditions?.length) + "]" : "conditions[0]"
                  }}
              }})}
          />

          <MDBCheckbox
          label={t("Would you like to produce a podcast or an interview to market the lecture?")}
          checked={lecture?.conditions?.findIndex(c => c.name === "podcast") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "podcast")].accepted : false}
          onChange={e => handleChange({target: {
                value: {
                  name: "podcast",
                  accepted: e.target.checked,
                },
                attributes: {
                  datapath: {
                    value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "podcast") > -1 ? lecture?.conditions?.findIndex(c => c.name === "podcast") : lecture?.conditions?.length) + "]" : "conditions[0]"
                  }}
              }})}
          />
          </MDBCol>
        </MDBRow>
        </>}
        <hr className="my-4" />

        {/* Lecture Speakers */}
        <MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
            <MDBCol size="12">
                <div className="mb-2 mb-lg-4">
                    <MDBTypography tag="h5">
                    {t("Speakers")}
                    </MDBTypography>
                    
                </div>
                <div className="w-100 text-center">
                <MDBBtn onClick={() => toggleSelectEmployeeModal()} color="primary" outline>
                    {t("Select employees")}
                </MDBBtn>
                </div>
                {lecture?.speakers && employees && lecture.speakers.map(employeeId => {
                  const employee = employees.find(employee => employee.id === employeeId);
                  return (
                  <div className="d-flex gap-4 align-items-center">
                    {employee.image?.fileName ? (
                      <img
                        src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${employee.image.fileName}`}
                        className="rounded-circle border"
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "cover",
                        }}
                        alt={employee.image.alt}
                      />
                    ) : (
                      <MDBIcon
                        className="rounded-circle border"
                        style={{
                          height: "50px",
                          width: "50px",
                          textAlign: "center",
                          paddingTop: "12px",
                          fontSize: "20px",
                        }}
                        fas
                        icon="user-alt"
                      />
                    )}
                    <div>
                      <h6>{`${employee.user?.title ? employee.user?.title + " ": ""}${employee.user?.firstName} ${employee.user?.middleName || ""} ${employee.user?.lastName}`}</h6>
                      <span>{employee.department?.items?.[0]?.department.name[0].text}</span>
                    </div>
                  </div>
              )
                })}
                  
            </MDBCol>
        </MDBRow>
        <hr className="my-4" />

        {/* LECTURE Topics */}
        


        <MDBRow className="d-flex justify-content-evenly">
          <MDBCol size="12">
            <div className="mb-2 mb-lg-4">
              <MDBTypography tag="h5">
                {t("Topics")}
              </MDBTypography>
            </div>
            <div className="w-100 text-center">
              <MDBBtn onClick={() => toggleSelectTopicsModal()} color="primary" outline>
                {t("Select Topics")}
              </MDBBtn>
              <MDBRow className="w-100">
                  <MDBCol>
                    <Topics
                      topics={topics || []}
                      topicGroups={topicGroups || []}
                      selectedTopics={lecture?.topics || []}
                      setSelectedTopics={handleSetSelectedTopics}
                      />
                  </MDBCol>
              </MDBRow>
            </div>
          </MDBCol>
        </MDBRow>
        <hr className="my-4" />

        <MDBRow className="d-flex justify-content-center text-center">
  <MDBCol size="10" sizeXl="8" sizeXXL="6" className="my-4">

        <MDBTypography tag="h5">
          {t("Lecture language")}
        </MDBTypography>
        <MDBRadio name='language' id='language' value='DE' label={t("German")} inline
        checked={lecture.presentationLanguage === "DE" || !lecture.presentationLanguage}
        onChange={() => handleChange({target: {
          value: "DE",
          attributes: {
            datapath: {
              value: "presentationLanguage"
            }
          }
        }})}
         />
        <MDBRadio name='language' id='language' value='EN' label={t("English")} inline
        checked={lecture.presentationLanguage === "EN"}
        onChange={() => handleChange({target: {
          value: "EN",
          attributes: {
            datapath: {
              value: "presentationLanguage"
            }
          }
        }})}
        />

  </MDBCol>
</MDBRow>
        <hr className="my-4" />
        {/* LECTURE Title */}
        <MDBRow className="d-flex justify-content-evenly">
          <MDBCol size="12">
          <div className="mb-2 mb-lg-4">
            <MDBTypography tag="h5">
              {t("Title")}
            </MDBTypography>
          </div>
           
            <MDBInputGroup className="mb-1" textAfter={<span style={lecture?.title?.find((t) => t.language === "de")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((lecture?.title?.length > 0? lecture.title.find((t) => t.language === "de")?.text: "")?.length || "0") + "/60"}</span>}>
              <div className="input-group-text">
                <ReactCountryFlag
                  countryCode="de"
                  svg
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  title={i18n.language}
                /> 
              </div>
              <MDBInput
                label={t("Title German")}
                type="text"
                maxLength="60"
                value={
                  lecture?.title?.length > 0
                    ? lecture.title.find((t) => t.language === "de")?.text
                    : ""
                }
                datapath={
                  "title[" +
                  (lecture?.title?.length > 0
                    ? lecture.title.findIndex(
                        (t) => t.language === "de"
                      ) === -1
                      ? lecture.title.length
                      : lecture.title.findIndex((t) => t.language === "de")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "de"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
              />
            </MDBInputGroup>
            <MDBInputGroup className="mb-1" textAfter={<span style={lecture?.title?.find((t) => t.language === "en")?.text?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{((lecture?.title?.length > 0? lecture.title.find((t) => t.language === "en")?.text: "")?.length || "0") + "/60"}</span>}>
              <div className="input-group-text">
                <ReactCountryFlag
                  countryCode="gb"
                  svg
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  title={i18n.language}
                />
              </div>
              <input
                className="form-control"
                placeholder={t("Title English")}
                type="text"
                maxLength="60"
                value={
                  lecture?.title?.length > 0
                    ? lecture.title.find((t) => t.language === "en")?.text
                    : ""
                }
                datapath={
                  "title[" +
                  (lecture?.title?.length > 0
                    ? lecture.title.findIndex(
                        (t) => t.language === "en"
                      ) === -1
                      ? lecture.title.length
                      : lecture.title.findIndex((t) => t.language === "en")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "en"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
              />
            </MDBInputGroup>
           
            <div className="mt-3 mb-1">

                

            </div>

          
          </MDBCol>
        </MDBRow>
        <hr className="my-4" />

        {/* LECTURE Subjects */}
        {(lecture?.type !== "recruiting") && <>
        <MDBRow className="d-flex justify-content-evenly" style={{fontSize: "0.9rem"}}>
          <MDBCol size="12">
            <div className="mb-5">
              <MDBTypography tag="h5">
                {t("Sub-Points")}
              </MDBTypography>
            </div>
            {[...Array(5)].map((item, index) => 
              <MDBRow className="w-100">
                <MDBCol size="12" lg="6" className="px-1">
                  <MDBInputGroup className="mb-1 mb-md-3" textAfter={<span style={lecture?.tempSubjects?.[index]?.de?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{(lecture?.tempSubjects?.[index]?.de?.length || "0") + "/60"}</span>}>
                    <div className="input-group-text">
                      <ReactCountryFlag
                        countryCode="de"
                        svg
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>
                    <MDBInput
                      type="text"

                      maxLength="60"
                      label={t("Sub-Point") + " " + (index + 1) + " " +  t("Geman")}
                      value={lecture?.tempSubjects?.[index]?.de}
                      datapath={"tempSubjects[" + index + "].de"}
                      onChange={handleChange}
                    />
                  </MDBInputGroup>
                </MDBCol>
                <MDBCol size="12" lg="6" className="px-1">
                  <MDBInputGroup className="mb-3" textAfter={<span style={lecture?.tempSubjects?.[index]?.en?.length === 60 ? {color: "red", fontWeight: "bold"} : {}}>{(lecture?.tempSubjects?.[index]?.en?.length || "0") + "/60"}</span>}>
                    <div className="input-group-text">
                      <ReactCountryFlag
                        countryCode="gb"
                        svg
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>
                    <MDBInput
                      type="text"
                      maxLength="60"
                      label={t("Sub-Point") + " " + (index + 1) + " " +  t("English")}
                      value={lecture?.tempSubjects?.[index]?.en}
                      datapath={"tempSubjects[" + index + "].en"}
                      onChange={handleChange}
                    />
                  </MDBInputGroup>
                </MDBCol>
              </MDBRow>
            )
            }
          </MDBCol>
            
        </MDBRow>
        <hr className="my-4" />
        </>}

            {/* Recruiting DESCRIPTION */}
            {lecture.type === "recruiting" ? <>
             <MDBRow className="d-flex justify-content-evenly mt-2 mt-xl-4">
            <MDBCol size="12" lg="10" xl="8" xxl="7">
            <hr className="my-4" />
            <div className="mb-2 mb-lg-4">
                <MDBTypography tag="h5">
                {t("Content (Min. 1000 characters)")}
                </MDBTypography>
            </div>
            <ReactCountryFlag
                countryCode="de"
                svg
                style={{
                width: "20px",
                height: "20px",
                }}
                title={i18n.language}
            /> 
            <Editor
                editorState={editorState["de"]}
                editorClassName="md-textarea form-control"
                onEditorStateChange={e => onEditorStateChange(e, "de")}
              />
              <br></br>
              <ReactCountryFlag
                countryCode="gb"
                svg
                style={{
                width: "20px",
                height: "20px",
                }}
                title={i18n.language}
            />
            <Editor
                editorState={editorState["en"]}
                editorClassName="md-textarea form-control"
                onEditorStateChange={e => onEditorStateChange(e, "en")}
              />
            </MDBCol>
        </MDBRow>
        </> : <>
        <MDBRow className="d-flex justify-content-evenly">
          <MDBCol size="12">
          <div className="mb-5">
              <MDBTypography tag="h5">
                {t("Content")}
              </MDBTypography>
            </div>
          

            <MDBInputGroup className="mb-3" textAfter={<span style={(lecture?.content?.length > 0 ? lecture.content.find((t) => t.language === "de")?.text: "")?.length < 500 ? {color: "red", fontWeight: "bold"} : {color: "green"}}>{((lecture?.content?.length > 0 ? lecture.content.find((t) => t.language === "de")?.text: "")?.length || "0") + "/500"}</span>}>
              <div className="input-group-text">
                <ReactCountryFlag
                  countryCode="de"
                  svg
                  style={{ width: "20px", height: "20px" }}
                  title={i18n.language}
                />
              </div>
              <MDBTextArea
                label={t("Lecture description German")}
                id='descriptionde'
                rows={4}
                cols={60}
                value={
                  lecture?.content?.length > 0
                    ? lecture.content.find((t) => t.language === "de")?.text
                    : ""
                }
                datapath={
                  "content[" +
                  (lecture?.content?.length > 0
                    ? lecture.content.findIndex(
                        (t) => t.language === "de"
                      ) === -1
                      ? lecture.content.length
                      : lecture.content.findIndex((t) => t.language === "de")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "de"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
              />
              
            </MDBInputGroup>

            <MDBInputGroup className="mb-3" textAfter={<span style={(lecture?.content?.length > 0 ? lecture.content.find((t) => t.language === "en")?.text: "")?.length < 500 ? {color: "red", fontWeight: "bold"} : {color: "green"}}>{((lecture?.content?.length > 0 ? lecture.content.find((t) => t.language === "en")?.text: "")?.length || "0") + "/500"}</span>}>
              <div className="input-group-text">
                <ReactCountryFlag
                  countryCode="gb"
                  svg
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  title={i18n.language}
                />
              </div>
              <MDBTextArea
                label={t("Lecture description English")}
                id='descriptionde'
                cols={60}
                rows={4}
                value={
                  lecture?.content?.length > 0
                    ? lecture.content.find((t) => t.language === "en")?.text
                    : ""
                }
                datapath={
                  "content[" +
                  (lecture?.content?.length > 0
                    ? lecture.content.findIndex(
                        (t) => t.language === "en"
                      ) === -1
                      ? lecture.content.length
                      : lecture.content.findIndex((t) => t.language === "en")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "en"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
              />
            </MDBInputGroup>
            
           
          </MDBCol>
         
        </MDBRow>
        </>}
        <hr className="my-4" />

        {/* LECTURE Image */}
        <MDBRow className="d-flex justify-content-evenly">
          <MDBCol size="12">
            <div className="mb-5">
              <MDBTypography tag="h5">
              {t("Image")}
              </MDBTypography>
            </div>
            <div className="w-100 text-center mb-2 mb-lg-4">
            <img
              src={lecture?.image?.fileName ? 
              `https://iec-cp-public.s3.eu-central-1.amazonaws.com/${lecture.type === "recruiting" ? "newsImages" : "lectureImages"}/${lecture.image.fileName}`: "https://via.placeholder.com/600x400"}
              className="img-thumbnail"
              style={{ height: "200px", width: "400px", objectFit: "cover" }}
              alt='...' />
            </div>
            <MDBFile id='fileDE' onChange={e => handleSetImage(e.target.files[0])} />
            {
              isUploadingLectureImage.De && <span><MDBSpinner role="status" /></span>
            }
            {
              uploadingLectureImageError && <span className="mt-3 text-danger fw-bold">
              {uploadingLectureImageError}
            </span>
            }
          
            <div className="mt-3">
              <MDBCheckbox
                name="image-condition"
                id="image-condition"
                label={t("I confirm that I have the rights to use the image.")}
                checked={lecture?.conditions?.findIndex(c => c.name === "image") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "image")].accepted : false}
                onChange={e => handleChange({target: {
                    value: {
                      name: "image",
                      accepted: e.target.checked,
                    },
                    attributes: {
                      datapath: {
                        value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "image") > -1 ? lecture?.conditions?.findIndex(c => c.name === "image") : lecture?.conditions?.length) + "]" : "conditions[0]"
                      }}
                  }})}
              />
            </div>
          </MDBCol>
        </MDBRow>
        <hr className="my-4" />

        {/* LECTURE PDF */}
        <MDBRow className="d-flex justify-content-evenly">
          <MDBCol size="12">
            <div className="mb-5">
              <MDBTypography tag="h5">
              {t("Script (PDF)")}
              </MDBTypography>
            </div>
            <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag
                    countryCode="de"
                    svg
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </div>
                {
                  lecture?.pdfDe?.fileName && 
                  <div className="text-center">
                  <MDBBtn color="link" onClick={() => downloadFile(lecture.pdfDe.fileName, lecture.pdfDe.s3Path)} size="sm" className="mb-0">
                    {lecture.pdfDe.fileName}
                  </MDBBtn>
                  <br />
                  <MDBBtn color="link" style={{color: "red"}}  size="sm" onClick={() => removeFile(lecture.pdfDe.fileName, lecture.pdfDe.s3Path, "De")}>
                    {t("Remove")}
                  </MDBBtn>
                  </div>
                  
                }
                <MDBFile id='fileDe'
                onChange={e => handleSetFile(e.target.files[0], "De")}

                />
                  
                  
                {
                  isUploadingLectureFiles.De && <span><MDBSpinner role="status" /></span>
                }
                {
                  uploadingLecturesErrors.De && <span className="mt-3 text-danger fw-bold">
                  {uploadingLecturesErrors.De}
                </span>
                }
              </MDBInputGroup>
              
              <MDBInputGroup className="mb-3">
                <div className="input-group-text">
                  <ReactCountryFlag
                    countryCode="gb"
                    svg
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </div>
                {
                  lecture?.pdfEn?.fileName && 
                  <div className="text-center">
                  <MDBBtn color="link" onClick={() => downloadFile(lecture.pdfEn.fileName, lecture.pdfEn.s3Path)} size="sm" className="mb-0">
                    {lecture.pdfEn.fileName}
                  </MDBBtn>
                  <br />
                  <MDBBtn color="link" style={{color: "red"}}  size="sm" onClick={() => removeFile(lecture.pdfEn.fileName, lecture.pdfEn.s3Path, "En")}>
                    {t("Remove")}
                  </MDBBtn>
                  </div>
                  
                }
                <MDBFile id='fileEn' label=""
                onChange={e => handleSetFile(e.target.files[0], "En")}

                />
                {
                  isUploadingLectureFiles.En && <span><MDBSpinner role="status" /></span>
                }
                {
                  uploadingLecturesErrors.En && <span className="mt-3 text-danger fw-bold">
                  {uploadingLecturesErrors.En}
                </span>
                }
              </MDBInputGroup>
             
            <div className="mt-3">
              <MDBCheckbox
                name="script-condition"
                id="script-condition"
                label={t("I agree that the lecture documents will be made available to the participants of the event. The presentation documents must be submitted by: ") + new Date(lecture.event?.lectureDocumentsEndDate).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                checked={lecture?.conditions?.findIndex(c => c.name === "script") > -1 ? lecture?.conditions[lecture?.conditions?.findIndex(c => c.name === "script")].accepted : false}
                onChange={e => handleChange({target: {
                    value: {
                      name: "script",
                      accepted: e.target.checked,
                    },
                    attributes: {
                      datapath: {
                        value: lecture?.conditions?.length > 0 ? "conditions[" + (lecture?.conditions?.findIndex(c => c.name === "script") > -1 ? lecture?.conditions?.findIndex(c => c.name === "script") : lecture?.conditions?.length) + "]" : "conditions[0]"
                      }}
                  }})}
              />
            </div>
            
          </MDBCol>
          
        </MDBRow>
        
      </MDBCardBody>
    </MDBCard>

<MDBModal open={selectTopicsModal} onClose={() => setSelectTopicsModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent>
          <MDBModalHeader>
              <MDBModalTitle>Select Topics</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectTopicsModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <SelectTopics
                topics={topics || []}
                topicGroups={topicGroups || []}
                isActive={selectTopicsModal}
                toggleActive={toggleSelectTopicsModal}
                selectedTopics={lecture?.topics || []}
                setSelectedTopics={handleSetSelectedTopics}
                parentTopics={topics || []}
                parentTopicGroups={topicGroups || []}
                maxSelect={3}
                />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectTopicsModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
<MDBModal open={selectAdminTopicModal} onClose={() => setSelectAdminTopicModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent>
          <MDBModalHeader>
              <MDBModalTitle>Select Topics</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectAdminTopicModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <SelectTopics
                topics={topics || []}
                topicGroups={topicGroups || []}
                isActive={selectAdminTopicModal}
                toggleActive={toggleSelectAdminTopicModal}
                selectedTopics={lecture?.adminTopic ? [lecture.adminTopic] : []}
                setSelectedTopics={handleSetSelectAdminTopicModal}
                parentTopics={topics || []}
                parentTopicGroups={topicGroups || []}
                maxSelect={1}
                />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectAdminTopicModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
<MDBModal open={selectEmployeeModal} onClose={() => setSelectEmployeeModal(false)} staticBackdrop>
  <MDBModalDialog centered size="xl">
      <MDBModalContent >
          <MDBModalHeader>
              <MDBModalTitle>{t("Select employee")}</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={toggleSelectEmployeeModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
              <EmployeeSelector
              companyId={company?.id}
              selectedEmployees={lecture?.speakers || []}
              setSelectedEmployees={(employees) => setLecture({...lecture, speakers: employees})}
              maxSelect={5}
              />
          </MDBModalBody>
          <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleSelectEmployeeModal}>{t("Close")}</MDBBtn>
          </MDBModalFooter>
      </MDBModalContent>
  </MDBModalDialog>
</MDBModal>
</>
  )
}
