export default function getEventDays(event) {
    const startDate = new Date(event.startDate);
    const endDate = new Date(event.endDate);
    const oneDay = 24 * 60 * 60 * 1000; // Millisekunden in einem Tag
    const daysArray = [];
  
    for (let date = startDate; date <= endDate; date = new Date(date.getTime() + oneDay)) {
      // Formatierung des Datums als "YYYY-MM-DD"
      const formattedDate = date.toISOString().split('T')[0];
      daysArray.push(formattedDate);
    }
  
    return daysArray;
  }