import { useEffect, useState } from 'react'
import { MDBCheckbox, MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBBtn, MDBIcon,
    MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
    MDBCollapse, MDBInput, MDBChip, MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBSelect,
    MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody
} from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { Link } from "react-router-dom";

const client = generateClient();

export default function Admin_Companies_List() {
    const { t, i18n } = useTranslation();

    const [companys, setCompanys] = useState([]);
    const [events, setEvents] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [companyToDelete, setCompanyToDelete] = useState(null);
    const [companyRelations, setCompanyRelations] = useState(null);
    const [isCheckingRelations, setIsCheckingRelations] = useState(false);

    // Table Settings
    const [selectedElements, setSelectedElements] = useState([]);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState({ field: "createdAt", direction: "desc" });
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [attendingEventFilter, setAttendingEventFilter] = useState(null);
    const [statusAdminFilter, setStatusAdminFilter] = useState(null);

    const checkCompanyRelations = async (companyId) => {
        setIsCheckingRelations(true);
        try {
            const { data } = await client.graphql({
                query: /* GraphQL */ `
                query GetCompany($id: ID!) {
                    getCompany(id: $id) {
                        departments {
                            items {
                                id
                            }
                        }
                        attendingEvents {
                            items {
                                id
                            }
                        }
                        employees {
                            items {
                                id
                            }
                        }
                        employmentRequests {
                            items {
                                id
                            }
                        }
                        todos {
                            items {
                                id
                            }
                        }
                        news {
                            items {
                                id
                            }
                        }
                        products {
                            items {
                                id
                            }
                        }
                        jobs {
                            items {
                                id
                            }
                        }
                        topics {
                            items {
                                id
                            }
                        }
                        lectures {
                            items {
                                id
                            }
                        }
                        orders {
                            items {
                                id
                            }
                        }
                        tourRegistrations {
                            items {
                                id
                            }
                        }
                        tourVisitorRegistrations {
                            items {
                                id
                            }
                        }
                        invitationCodes {
                            items {
                                id
                            }
                        }
                        stockReservations {
                            items {
                                id
                            }
                        }
                        bookedBooths {
                            items {
                                id
                            }
                        }
                    }
                }
                `,
                variables: {
                    id: companyId
                }
            });

            const relations = data.getCompany;
            setCompanyRelations(relations);
        } catch (error) {
            console.warn("Error checking company relations:", error);
            setCompanyRelations(null);
        }
        setIsCheckingRelations(false);
    };

    const handleDeleteCompany = async (companyId) => {
        setCompanyToDelete(companyId);
        setShowDeleteModal(true);
        await checkCompanyRelations(companyId);
    };

    const confirmDelete = async () => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteCompany($input: DeleteCompanyInput!) {
                    deleteCompany(input: $input) {
                        id
                    }
                }
                `,
                variables: {
                    input: {
                        id: companyToDelete
                    }
                }
            });
            setCompanys(companys.filter(company => company.id !== companyToDelete));
            setShowDeleteModal(false);
            setCompanyToDelete(null);
            setCompanyRelations(null);
        } catch (error) {
            console.warn("Error deleting company:", error);
        }
    };

    const hasRelations = (relations) => {
        if (!relations) return false;
        return (
            relations.departments?.items?.length > 0 ||
            relations.attendingEvents?.items?.length > 0 ||
            relations.employees?.items?.length > 0 ||
            relations.employmentRequests?.items?.length > 0 ||
            relations.todos?.items?.length > 0 ||
            relations.news?.items?.length > 0 ||
            relations.products?.items?.length > 0 ||
            relations.jobs?.items?.length > 0 ||
            relations.topics?.items?.length > 0 ||
            relations.lectures?.items?.length > 0 ||
            relations.orders?.items?.length > 0 ||
            relations.tourRegistrations?.items?.length > 0 ||
            relations.tourVisitorRegistrations?.items?.length > 0 ||
            relations.invitationCodes?.items?.length > 0 ||
            relations.stockReservations?.items?.length > 0 ||
            relations.bookedBooths?.items?.length > 0
        );
    };
    
    const filterByAttendingEvent = (companies) => {
        return companies.filter(company => company.attendingEvents.items.find(event => event.id === attendingEventFilter));
    }

    const filterByStatusAdmin = (companies) => {
        return companies.filter(company => company.adminEmployees.items.every(employee => statusAdminFilter ? (employee.user.hasCompletedOnboarding === statusAdminFilter) : !employee.user.hasCompletedOnboarding));
    }

    const getCompanys = async (filter) => {
        try {
            const variables = { 
                limit: rowsPerPage,
                nextToken: null,
                from: (page - 1) * rowsPerPage, 
                filter,
                sort
            };
            const companys = await client.graphql({
                query: /* GraphQL */ `
                query SearchCompanies(
                  $filter: SearchableCompanyFilterInput
                  $sort: [SearchableCompanySortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableCompanyAggregationInput]
                ) {
                  searchCompanies(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                      id
                      name
                      status
                      website
                      image {
                        main {
                        alt
                        fileName
                        }
                    }
                      x4Id
                      x4AdminId
                      createdAt
                      updatedAt
                      companyAdminId
                      adminEmployees {
                        items {
                          user {
                            hasCompletedOnboarding
                          }
                        }
                      }
                      attendingEvents {
                        items {
                          id
                        }
                      }
                      __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `,
            variables: variables
            });
            console.log("company",companys);
            return {
                items: companys.data.searchCompanies.items,
                nextToken: companys.data.searchCompanies.nextToken,
                total: companys.data.searchCompanies.total
            };
        } catch (error) {
            console.warn("error fetching companys", error);
        }
    }

    const getEvents = async () => {
        try {
            const events = await client.graphql({
                query: /* GraphQL */ `
                query ListEvents(
                  $filter: ModelEventFilterInput
                  $limit: Int
                  $nextToken: String
                ) {
                  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    items {
                      id
                      name {
                        language
                        text
                        __typename
                      }
                      description
                      status
                      image {
                        fileName
                        alt
                        x4UUID
                        __typename
                      }
                      date
                      startDate
                      endDate
                      location {
                        __typename
                      }
                      attendees {
                        nextToken
                        __typename
                      }
                      availableBundleBooths {
                        nextToken
                        __typename
                      }
                      lectures {
                        nextToken
                        __typename
                      }
                      x4Id
                      createdAt
                      updatedAt
                      __typename
                    }
                    nextToken
                    __typename
                  }
                }
              `
            });
            console.log("events",events);
            setEvents(events.data.listEvents.items);
        } catch (error) {
            console.warn("error fetching events", error);
        }
    }

    useEffect(() => {
        getEvents();
    }, []);

    const buildTable = (companys) => {
        return companys.map((company) => {
            return (
                <tr key={company.id}>
                    <td><MDBCheckbox /></td>
                    <td>
                    <div className='d-flex align-items-center'>
                    {company.image?.main?.fileName ?
                        <img
                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+company.image.main.fileName}
                            className=""
                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                            alt={company.image.alt}
                        />
                    :
                        <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                
                    }
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{company.name}</p>
                      <p className='text-muted mb-0'><a href={company.website} target="_blank" rel="noreferrer">{company.website}</a></p>
                    </div>
                    </div>
                    </td>
                    <td>
                    {new Date(company.createdAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                    </td>
                    <td>
                    {company.status === "active" ? <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Active")}</MDBChip> : company.status === "deactivated" ? <MDBChip>{t("Deactivated")}</MDBChip> : company.status}
                    {company.adminEmployees.items.some(employee => employee.user?.hasCompletedOnboarding) ? <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Confirmed")}</MDBChip> : <MDBChip  style={{maxWidth: "fit-content"}}>{t("Not confirmed")}</MDBChip>}
                    </td>
                    <td>
                        <Link to={"/admin/company/" + company.id}><MDBBtn size="sm" className="me-2"><MDBIcon fas icon="edit" /></MDBBtn></Link>
                        <MDBBtn size="sm" color="danger" onClick={() => handleDeleteCompany(company.id)}><MDBIcon fas icon="trash" /></MDBBtn>
                    </td>
                </tr>
            )
        })
    }

    useEffect(() => {
        const fetchCompanys = async () => {
            let companysFromAPI = await getCompanys(filter);

            if(attendingEventFilter || typeof statusAdminFilter === "boolean") {
                if(attendingEventFilter) {
                    companysFromAPI.items = filterByAttendingEvent(companysFromAPI.items);
                    companysFromAPI.total = companysFromAPI.items.length;
                }
                if(typeof statusAdminFilter === "boolean") {
                    companysFromAPI.items = filterByStatusAdmin(companysFromAPI.items);
                    companysFromAPI.total = companysFromAPI.items.length;
                }
            }

            setCompanys(companysFromAPI.items);
            setTotalPages(Math.ceil(companysFromAPI.total / rowsPerPage));
        }
        fetchCompanys();
    }, [rowsPerPage, filter, page, sort, attendingEventFilter, statusAdminFilter]);

    useEffect(() => {
        if(search.length > 0) {
            setFilter({ ...filter, name: { regexp: `.*${search.toLowerCase()}.*` } });
        } else {
            setFilter({});
        }
    }, [search]);

    return (
        <>
            <MDBCard>
                <MDBCardBody>
                    <MDBCardTitle>
                        {t("Companies")}
                        <Link to="/admin/create-company">
                            <MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>
                                {t("Create new")}
                            </MDBBtn>
                        </Link>
                    </MDBCardTitle>

                    <MDBRow>
                        <MDBCol size="12">
                            <MDBInput type="text" onChange={e => setSearch(e.target.value)} label={t("Search")} />
                        </MDBCol>
                    </MDBRow>
                    
                    <MDBRow className="mt-2 mt-xl-3">
                        <MDBCol md="3">
                            <MDBSelect label={t("Attending Event")}
                                onChange={type => type && setAttendingEventFilter(type.value)}
                                data={[
                                    { text: t("All"), value: null },
                                    ...events.map(event => ({ 
                                        text: event.name.find(name => name.language === i18n.language)?.text || event.name[0]?.text, 
                                        value: event.id 
                                    }))
                                ]}
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBSelect label={t("Status")}
                                data={[
                                    { text: t("All"), value: null },
                                    { text: t("Active"), value: "active" },
                                    { text: t("Disabled"), value: "disabled" }
                                ]}
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <MDBSelect label={t("Status") + " Admin"}
                                onChange={type => type && setStatusAdminFilter(type.value)}
                                data={[
                                    { text: t("All"), value: null },
                                    { text: t("Confirmed"), value: true },
                                    { text: t("Not confirmed"), value: false }
                                ]}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBTable>
                        <MDBTableHead>
                            <tr>
                                <th style={{verticalAlign: "top"}}><MDBCheckbox /></th>
                                <th style={{verticalAlign: "top"}}>{t("Company")}</th>
                                <th style={{verticalAlign: "top"}}>{t("Created Date")}</th>
                                <th style={{verticalAlign: "top"}}>{t("Status")} | {t("Status")} Admin</th>
                                <th style={{verticalAlign: "top"}}>{t("Actions")}</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody style={{ verticalAlign: 'middle' }}>
                            {companys.length > 0 ? buildTable(companys) : <tr><td colSpan="5">{t("Loading")}</td></tr>}
                        </MDBTableBody>
                    </MDBTable>

                    {companys.length > 0 && 
                    <>
                        <nav aria-label='Page navigation' className="d-flex">
                            <MDBPagination className='mx-auto' style={{width: "fit-content"}}>
                                <MDBPaginationItem>
                                    {page !== 1 && <MDBPaginationLink className="text-dark" onClick={() => setPage(page - 1)}>{t("Previous")}</MDBPaginationLink>}
                                </MDBPaginationItem>
                                {totalPages > 0 && Array.from(Array(totalPages).keys()).slice(0,5).map((pageIndex) => (
                                    <MDBPaginationItem key={pageIndex} active={pageIndex + 1 === page}>
                                        <MDBPaginationLink href='#' className="text-dark" onClick={() => setPage(pageIndex+1)}>{pageIndex + 1}</MDBPaginationLink>
                                    </MDBPaginationItem>
                                ))}
                                {totalPages > 5 &&
                                    <MDBDropdown dropup>
                                        <MDBDropdownToggle caret color="white" className="text-dark">
                                            {t("More")}
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu basic>
                                            {Array.from(Array(totalPages).keys()).slice(5).map((pageIndex) => (
                                                <MDBDropdownItem link key={pageIndex} active={pageIndex + 1 === page} className="text-dark" onClick={() => setPage(pageIndex+1)}>
                                                    {pageIndex + 1}
                                                </MDBDropdownItem>
                                            ))}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                }
                                <MDBPaginationItem>
                                    {page !== totalPages && <MDBPaginationLink className="text-dark" onClick={() => setPage(page + 1)}>{t("Next")}</MDBPaginationLink>}
                                </MDBPaginationItem>
                            </MDBPagination>
                            <MDBDropdown className="float-end">
                                <MDBDropdownToggle caret color="white" className="text-dark">
                                    {rowsPerPage}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu basic>
                                    {[10, 20, 50, 100, 200, 500].map(size => (
                                        <MDBDropdownItem key={size} link onClick={() => setRowsPerPage(size)} className="text-dark">
                                            {size}
                                        </MDBDropdownItem>
                                    ))}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </nav>
                    </>
                    }
                </MDBCardBody>
            </MDBCard>

            {/* Delete Confirmation Modal */}
            <MDBModal open={showDeleteModal} setOpen={setShowDeleteModal}>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{t("Delete Company")}</MDBModalTitle>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {isCheckingRelations ? (
                                <div className="text-center">
                                    <MDBIcon fas icon="spinner" spin /> {t("Checking company relations...")}
                                </div>
                            ) : companyRelations && hasRelations(companyRelations) ? (
                                <div>
                                    <p className="text-danger">
                                        {t("This company has associated data and cannot be deleted. Please remove the following items first:")}
                                    </p>
                                    <ul>
                                        {companyRelations.departments?.items?.length > 0 && (
                                            <li>{t("Departments")}: {companyRelations.departments.items.length}</li>
                                        )}
                                        {companyRelations.attendingEvents?.items?.length > 0 && (
                                            <li>{t("Event Registrations")}: {companyRelations.attendingEvents.items.length}</li>
                                        )}
                                        {companyRelations.employees?.items?.length > 0 && (
                                            <li>{t("Employees")}: {companyRelations.employees.items.length}</li>
                                        )}
                                        {companyRelations.employmentRequests?.items?.length > 0 && (
                                            <li>{t("Employment Requests")}: {companyRelations.employmentRequests.items.length}</li>
                                        )}
                                        {companyRelations.todos?.items?.length > 0 && (
                                            <li>{t("Todos")}: {companyRelations.todos.items.length}</li>
                                        )}
                                        {companyRelations.news?.items?.length > 0 && (
                                            <li>{t("News")}: {companyRelations.news.items.length}</li>
                                        )}
                                        {companyRelations.products?.items?.length > 0 && (
                                            <li>{t("Products")}: {companyRelations.products.items.length}</li>
                                        )}
                                        {companyRelations.jobs?.items?.length > 0 && (
                                            <li>{t("Jobs")}: {companyRelations.jobs.items.length}</li>
                                        )}
                                        {companyRelations.topics?.items?.length > 0 && (
                                            <li>{t("Topics")}: {companyRelations.topics.items.length}</li>
                                        )}
                                        {companyRelations.lectures?.items?.length > 0 && (
                                            <li>{t("Lectures")}: {companyRelations.lectures.items.length}</li>
                                        )}
                                        {companyRelations.orders?.items?.length > 0 && (
                                            <li>{t("Orders")}: {companyRelations.orders.items.length}</li>
                                        )}
                                        {companyRelations.tourRegistrations?.items?.length > 0 && (
                                            <li>{t("Tour Registrations")}: {companyRelations.tourRegistrations.items.length}</li>
                                        )}
                                        {companyRelations.tourVisitorRegistrations?.items?.length > 0 && (
                                            <li>{t("Tour Visitor Registrations")}: {companyRelations.tourVisitorRegistrations.items.length}</li>
                                        )}
                                        {companyRelations.invitationCodes?.items?.length > 0 && (
                                            <li>{t("Invitation Codes")}: {companyRelations.invitationCodes.items.length}</li>
                                        )}
                                        {companyRelations.stockReservations?.items?.length > 0 && (
                                            <li>{t("Stock Reservations")}: {companyRelations.stockReservations.items.length}</li>
                                        )}
                                        {companyRelations.bookedBooths?.items?.length > 0 && (
                                            <li>{t("Booked Booths")}: {companyRelations.bookedBooths.items.length}</li>
                                        )}
                                    </ul>
                                </div>
                            ) : (
                                <p>{t("Are you sure you want to delete this company?")}</p>
                            )}
                        </MDBModalBody>
                        <div className="modal-footer">
                            <MDBBtn 
                                color="secondary"
                                onClick={() => setShowDeleteModal(false)}
                            >
                                {t("Close")}
                            </MDBBtn>
                            <MDBBtn
                                color="danger"
                                onClick={confirmDelete}
                                disabled={isCheckingRelations || (companyRelations && hasRelations(companyRelations))}
                            >
                                {t("Delete")}
                            </MDBBtn>
                        </div>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}