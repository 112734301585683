import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBFile, MDBIcon, MDBInput, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import deepUpdateObject from "../../utils/deepUpdateObject";
const client = generateClient();

export default function EditEmployeeProfile({ employeeId, isActive, toggleSaveChanges, handleChangeComplete, toggle }) {

    const { t, i18n } = useTranslation();

    const [employee, setEmployee] = useState(null);

    const getEmployee = async () => {
        try {
            const employee = await client.graphql({
                query: /* GraphQL */ `
                query GetEmployee($id: ID!) {
                    getEmployee(id: $id) {
                        id
                        role
                        permissions {
                            resource
                            actions
                        }
                        image {
                            fileName
                            alt
                        }
                        user {
                            email
                            lastName
                            middleName
                            image {
                            fileName
                            alt
                            }
                            id
                            firstName
                            title
                            personalDepartment
                            personalIndustry
                            contact {
                                email
                                phone
                                address {
                                    street
                                    street2
                                    streetNumber
                                    company
                                    contactPerson
                                    city
                                    zip
                                    countryCode
                                }
                            }
                        }
                        company {
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                            contact {
                                email
                                phone
                                address {
                                    street
                                    street2
                                    streetNumber
                                    company
                                    contactPerson
                                    city
                                    zip
                                    countryCode
                                }
                            }
                        }
                        department {
                           items {
                                department {
                                    id
                                    name {
                                        language
                                        text
                                    }
                                }
                           }
                        }
                        contact {
                            email
                            phone
                            address {
                                street
                                street2
                                streetNumber
                                company
                                contactPerson
                                city
                                zip
                                countryCode
                            }
                        }
                    }
                }
                `,
                variables: { id: employeeId },
            });
            setEmployee(employee.data.getEmployee);
            console.log("employee", employee);
        } catch (error) {
            console.log("error on fetching employee", error);
        }
    };

    useEffect(() => {
        if(isActive) getEmployee();
    }, [isActive]);


    // Handle Updates
    const handleChange = (e, additionalInstructions) => {
        console.log("change", e.target.attributes.datapath.value, e.target.value);
    console.log("additionalInstructions", additionalInstructions);
    
    // Get values from input
    const datapath = e.target.attributes.datapath.value;
    let value;

    // Check if the input is a checkbox
    if (e.target.type === 'checkbox') {
        value = e.target.checked; // Use 'checked' for checkboxes
    } else {
        value = e.target.value; // Use 'value' for other input types
    }

    // Update current company object
        const newEmployee = deepUpdateObject(employee, datapath, value, additionalInstructions);
        setEmployee(newEmployee);
        //  const newdiff = diff(newUser, initialUser)
        //  console.log("diff", newdiff)
         //setChanges(diff(initialUser, newUser));
        
    }

    const [isUpdating, setIsUpdating] = useState(false);
    const [errorUpdating, setErrorUpdating] = useState(false);

    const handleUpdateProfile = async () => {

        try {
            setIsUpdating(true);
            const employeeRes = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateEmployee(
                    $input: UpdateEmployeeInput!
                    $condition: ModelEmployeeConditionInput
                ) {
                    updateEmployee(input: $input, condition: $condition) {
                        id
                        role
                        permissions {
                            resource
                            actions
                        }
                        image {
                            fileName
                            alt
                        }
                        user {
                            email
                            lastName
                            middleName
                            image {
                            fileName
                            alt
                            }
                            id
                            firstName
                            title
                            personalDepartment
                            personalIndustry
                        }
                        company {
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        department {
                           items {
                                department {
                                    id
                                    name {
                                        language
                                        text
                                    }
                                }
                           }
                        }
                        contact {
                            email
                            phone
                            address {
                                street
                                street2
                                streetNumber
                                company
                                contactPerson
                                city
                                zip
                                countryCode
                            }
                        }
                  }
                }
              `,
                variables: { input: {
                    id: employee.id,
                    contact: employee.contact,
                    image: employee.image
                } }
            });
            console.log("user", employeeRes);
            setEmployee(employeeRes.data.updateEmployee);
            setIsUpdating(false);
            if(toggleSaveChanges) handleChangeComplete("employeeProfile");
            if(toggle) toggle();
        } catch (error) {
            console.warn("error updating user", error);
            setIsUpdating(false);
            setErrorUpdating(true);
            setTimeout(() => {
                setErrorUpdating(false);
            }, 10000);
        }
    }

    // User Image
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [uploadingImageError, setUploadingImageError] = useState(null);
    
    const handleSetImage = (file) => {
      // upload file using mutation: uploadFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadFiles-${env}")
      if(file) {
        try {
          setIsUploadingImage(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = error => {
          console.log('Error reader: ', error);
          setUploadingImageError(error);
        }
        reader.onload = async () => {
          try {
            console.log(reader.result)
            const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
            const response = await client.graphql({
              query: /* GraphQL */ `
              mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
                uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
              }
            `,
              variables: {
                imageBuffer: base64FileBuffer,
                imageType: "userProfileImage",
              },
            });
            console.log("response", response);
            const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
            handleChange({
              target: {
                value: {
                  fileName,
                  alt: " Image of " + employee.user.firstName + " " + employee.user.lastName,
                },
                attributes: {
                  datapath: {
                    value: "image"
                  }
                },
              },
            });
            } catch (error) {
              console.log("error", error);
              setIsUploadingImage(false);
              setUploadingImageError(error.errors[0].message);
            }
        }
        setIsUploadingImage(false);
        setUploadingImageError(null);
        } catch (error) {
          console.log("error", error);
          setIsUploadingImage(false);
          setUploadingImageError(error.errors[0].message);
        }
      }
    }

    const handleTakeAddressFromCompany = () => {
        handleChange({
            target: {
                value: {
                    ...employee.company.contact.address,
                    company: employee.company.name
                },
                attributes: {
                    datapath: {
                        value: "contact.address"
                    }
                }
            }
        })
    }

    const handleTakeContactFromUser = () => {
        handleChange({
            target: {
                value: {...employee.user.contact, email: employee.user.email},
                attributes: {
                    datapath: {
                        value: "contact"
                    }
                }
            }
        })
    }

    useEffect(() => {
        if(toggleSaveChanges) {
          console.log("Saving changes to company profile")
          handleUpdateProfile();
        }
      }, [toggleSaveChanges])


  return (
<>
    {employee && (
        <>
        <MDBCardTitle className="fw-bold mb-3 mb-lg-5">
        <span className="float-start">{t("Employee profile at company ") + employee.company.name}</span>
        {employee?.company?.image?.main?.fileName &&
        <img
            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+employee.company.image.main.fileName}
            className="rounded-circle border float-end"
            style={{ height: "75px", width: "75px", objectFit: "contain" }}
            alt={employee.company.image.main.alt}
        />
        }
        </MDBCardTitle>
        <MDBRow className="mx-0 mx-lg-3 w-100">
            <MDBCol size="12" md="2" className="text-center mb-3">
            <div className="w-100 text-center mb-2 mb-lg-4">
            <img
            src={employee?.image?.fileName ? 
            "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" + employee.image.fileName: "https://via.placeholder.com/400x400"}
            className="img-thumbnail rounded-circle border"
            style={{ height: "150px", width: "150px", objectFit: "cover" }}
            alt='...' />
            </div>
            <MDBBtn color="primary" onClick={() => document.getElementById("image_employee").click()}>{t("Upload Image")}</MDBBtn>
            
            <MDBFile className="d-none" id='image_employee' onChange={e => handleSetImage(e.target.files[0])} />
            {
            isUploadingImage.De && <span><MDBSpinner role="status" /></span>
            }
            {
            uploadingImageError && <span className="mt-3 text-danger fw-bold">
            {uploadingImageError}
            </span>
            }
            </MDBCol>
            <MDBCol size="12" md="4">
            <p className='fw-bold'>{employee.user.title ? employee.user.title + " " : ""}{employee.user.firstName} {employee.user.middleName} {employee.user.lastName}</p>
            <MDBBtn color="primary" onClick={() => handleTakeContactFromUser()}>{t("Load contact from personal profile")}</MDBBtn>
            <h6 className="mt-3">{t("Contact details")}</h6>
            
            <MDBInput type='email' autoComplete="off" datapath="contact.email" label={t("E-mail address")} style={{minWidth: "300px"}} value={employee.contact?.email|| ""} onChange={handleChange} />
            <MDBInput className="mt-2" type='phone' autoComplete="off" datapath="contact.phone" label={t("Phone number")} style={{minWidth: "300px"}} value={employee.contact?.phone|| ""} onChange={handleChange} />
            </MDBCol>
            <MDBCol size="12" md="6">
            <MDBBtn color="primary" onClick={() => handleTakeAddressFromCompany()}>{t("Load address from company")}</MDBBtn>
            <h6 className="mt-3">{t("Company address")}</h6>
            <div className="w-100 text-center">
            
            </div>
            <MDBRow>
                <MDBCol className="pe-1 pe-lg-3" size="9">
                <MDBInput className="mt-2" type='text' autoComplete="off" datapath="contact.address.street" label={t("Street")} style={{minWidth: "300px"}} value={employee.contact?.address?.street|| ""} onChange={handleChange} />
                </MDBCol>
                <MDBCol className="ps-0 ps-lg-3" size="3">
                <MDBInput className="mt-2" type='text' autoComplete="off" datapath="contact.address.streetNumber" label={t("Number")} style={{minWidth: "300px"}} value={employee.contact?.address?.streetNumber|| ""} onChange={handleChange} />
                </MDBCol>
            </MDBRow>
            <MDBInput className="mt-2" type='text' autoComplete="off" datapath="contact.address.street2" label={t("Street additional")} style={{minWidth: "300px"}} value={employee.contact?.address?.street2|| ""} onChange={handleChange} />
            <MDBInput className="mt-2" type='text' autoComplete="off" datapath="contact.address.company" label={t("Firma")} style={{minWidth: "300px"}} value={employee.contact?.address?.company|| ""} onChange={handleChange} />
            <MDBRow>
                <MDBCol className="pe-1 pe-lg-3" size="6">
                <MDBInput className="mt-2" type='text' autoComplete="off" datapath="contact.address.zip" label={t("Zip")} style={{minWidth: "300px"}} value={employee.contact?.address?.zip|| ""} onChange={handleChange} />
                </MDBCol>
                <MDBCol className="ps-1 ps-lg-3" size="6">
                <MDBInput className="mt-2" type='text' autoComplete="off" datapath="contact.address.city" label={t("City")} style={{minWidth: "300px"}} value={employee.contact?.address?.city|| ""} onChange={handleChange} />
            
                </MDBCol>
            </MDBRow>
            <MDBInput className="mt-2" type='text' autoComplete="off" datapath="contact.address.countryCode" label={t("Country")} style={{minWidth: "300px"}} value={employee.contact?.address?.countryCode|| ""} onChange={handleChange} />
            {
                typeof toggleSaveChanges !== "boolean" &&
                <MDBRow className="mt-2 mt-lg-3 text-end">
                <MDBCol>
                {
                    isUpdating ?
                    <MDBBtn color='light' rippleColor='dark' disabled>
                    {t("Saving...")}
                    </MDBBtn>
                    :
                    errorUpdating ?
                    <MDBBtn color='danger' rippleColor='dark' disabled>
                    {t("Error Saving")}
                    </MDBBtn>
                    :
                    <MDBBtn onClick={() => handleUpdateProfile()} color="success" outline>
                        {t("Save")}
                    </MDBBtn>
                }
                </MDBCol>
            </MDBRow>
            }
            
            </MDBCol>
        </MDBRow>
           
        </>
    )}
</>
  )
}
