import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem
} from "mdb-react-ui-kit";
 
export default function LangSelect ({style}) {
    const { i18n } = useTranslation();

    const [lngs, setLngs] = useState({ en: { nativeName: 'English' }});

    useEffect(() => {
      if (i18n?.services?.backendConnector?.backend) {
        i18n.services.backendConnector.backend.getLanguages((err, ret) => {
            if (err) return; // TODO: handle err...
            //console.log("RES", ret)
            setLngs(ret);
        });
    }
    }, [i18n.services]);
    //console.log("LNGS", lngs)

    const handleChangeLang = lng => {
      i18n.changeLanguage(lng)
    }

    return <MDBDropdown className="langSelect" style={style}>
      <MDBDropdownToggle nav caret>
        
        
        <ReactCountryFlag
                countryCode={i18n.language === "en" || i18n.language === "en-US" ? "gb" : i18n.language}
                svg
                style={{
                    width: '20px',
                    height: '20px',
                }}
                title={i18n.language}
            />
      </MDBDropdownToggle>
      <MDBDropdownMenu>
      { 
        Object.keys(lngs).map((lng) => (
          <MDBDropdownItem link
          key={lng}
          style={{ fontWeight: i18n.language === lng ? 'bold' : 'normal' }}
          onClick={() => handleChangeLang(lng)}>
            <ReactCountryFlag
                countryCode={lng === "en" ? "gb" : lng}
                svg
                style={{
                    width: '20px',
                    height: '20px',
                }}
                title={i18n.language}
            /> {lngs[lng].nativeName}
          </MDBDropdownItem>
        ))
      }
      </MDBDropdownMenu>
    </MDBDropdown>
}
