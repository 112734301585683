import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const locizeOptions = {
    projectId: '23f3f6d3-6d3f-45d5-bc4a-c6fad33f0eae',
    apiKey: '511f0f33-7de8-45d8-a8db-8e9d18eb2456', // YOU should not expose your apps API key to production!!!
    referenceLng: 'en',
  };

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: {
      "de-DE": ["de"],
      "en-US": ["en"],
      "en-GB": ["en"],
      default: ['en']
    },
    saveMissingTo: "public",
    debug: false,
    saveMissing: true,
    updateMissing: true,
    backend: locizeOptions,
    ns: ["public"],
    defaultNS: "public",
    react: {
        useSuspense: false
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;