import React, { useState, useEffect, useCallback } from "react";
import { generateClient } from 'aws-amplify/api';
import { diff } from 'deep-object-diff';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBTextArea, MDBBtn, MDBSticky, MDBRipple, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBModalTitle, MDBSpinner, MDBFile   } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import adjustChanges from "../../utils/adjustDiffChanges";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {convertHTMLtoDraft, convertHTMLtoDraftInit, ChangeHTMLDraft, ChangeDraftHTMLBtn, convertHTMlToRaw} from "../../utils/HTMLDraft";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from "react-router-dom";

import { fullCompanyQuery, fullCompanyMutation } from "../../graphql-custom/companyQueryies";

// File upload
import { MDBFileUpload } from 'mdb-react-file-upload';
import {useDropzone} from 'react-dropzone';
import {v4 as uuid} from "uuid";

import SelectTopics from "../../components-user/topics/SelectTopics";

const client = generateClient();
const StateProofImage = React.memo(function Image({ src, alt, className, style }) {
    return <img src={src} alt={alt} className={className} style={style} />;
});

const deepUpdateObject = (object, path, value) => {
    const pathParts = path.split('.');
    let newObject = {...object};
  
    pathParts.reduce((o, p, i) => {
      if (i === pathParts.length - 1) {
        o[p] = value; // Setzt den Wert am Ende des Pfades
      } else {
        o[p] = o[p] ? {...o[p]} : {}; // Erstellt eine Kopie des nächsten Objekts im Pfad, falls es existiert, sonst ein neues Objekt
      }
      return o[p];
    }, newObject);
  
    return newObject;
  };
  

export default function EditCompany_General({presetCompanyId, toggleSaveChanges, handleChangeComplete}) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    // Company data
    const [initialCompany, setInitialCompany] = useState({
        topics: [],
    });
    const [company, setCompany] = useState({
        topics: [],
    });
    const [changes, setChanges] = useState({});
    
    const [loading, setLoading] = useState(true);

    
    // Changes and form handling

    const handleChange = (e) => {
        //console.log("change",e.target.attributes.datapath.value, e.target.value)

        // Get values from input
        const datapath = e.target.attributes.datapath.value;
        const value = e.target.value;

        // Update current company object
        const newCompany = deepUpdateObject(company, datapath, value)
        setCompany(newCompany);
        setChanges(diff(initialCompany, newCompany));
    }

    
    const extractUpdatedTopLevelObjects = (company, changes) => {
        const updatedObjects = {};
      
        Object.keys(changes).forEach(topLevelKey => {
          if (company.hasOwnProperty(topLevelKey)) {
            updatedObjects[topLevelKey] = company[topLevelKey];
          }
        });
      
        return updatedObjects;
      };
    // Update company
    const [isUpdating, setIsUpdating] = useState(false);
    const updateCompany = async () => {
        if(Object.keys(changes).length === 0) return;
        try {
            console.log("changes",changes)
           const newCompany = {...company, status: "active"}
            const topics = newCompany.topics;
            delete newCompany.topics;
           
            const createdCompany = await client.graphql({
                query: /* GraphQL */ `
                mutation CreateCompany(
                  $input: CreateCompanyInput!
                  $condition: ModelCompanyConditionInput
                ) {
                  createCompany(input: $input, condition: $condition) {
                    name 
                    id
                    createdAt
                    updatedAt
                    __typename
                  }
                }
              `,
                variables: {
                    input: newCompany
                }
            });
          
            console.log("CreatedCompany");
            
            setChanges({});
            setTimeout(() => {
              navigate(`/admin/company/${createdCompany.data.createCompany.id}`);
            }, 500);

        } catch (error) {
            console.warn("error updating company", error);
        }
    }

    const handleUpdateCompany = async () => {
        setIsUpdating(true);
        await updateCompany();
        setIsUpdating(false);
        if(toggleSaveChanges) handleChangeComplete("companyProfile");
    }

    const handleResetChanges = () => { 
        setCompany(initialCompany);
        setChanges({});
    }
    


    const uploadImage = async (file) => {
        const filename = file.type === "image/png" ? uuid() + ".png" : file.type === "image/jpeg" && uuid() + ".jpg"
        // await Storage.put(filename, file, {
        //     level: "protected",
        //     contentType: file.type
        //   });
        }

    const MainImage = (props) => {
        const onDrop = useCallback(acceptedFiles => {
            uploadImage(acceptedFiles[0])
          }, [])
          const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
       
      
        return (
          <section className="container" style={
            {
                top: "50px",
                position: "absolute",
                left: "100px",
                width: "fit-content",
                zIndex: "1"
            }
          } >
            <div {...getRootProps({className: 'dropzone text-center'})}>
              <input {...getInputProps()} />
              <StateProofImage alt={company.image?.main?.alt || "Company Image"}
                src={company.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+ company.image?.main?.fileName : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
                className="img-thumbnail rounded-circle border"
                style={{ height: "200px", width: "200px", objectFit: "contain" }}
                />
                
            <MDBBtn color="light" size="sm" className="m-0" style={
            {
                top: "170px",
                position: "absolute",
                left: "60px",
                zIndex: "1"
            }
            }>{t("Upload Logo")}</MDBBtn>
               {/* <p>{t("Drag 'n' drop or click to select files")}</p> */}
            </div>
          </section>
        );
      }

    const [nameFieldLocked, setNameFieldLocked] = useState(true);

    // Editor
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editorModes, setEditorModes] = useState({showDraft: true,showHTML: false})
    const onEditorStateChange = (editorState) => {
     setEditorState(editorState);
      console.log("editorState",convertHTMlToRaw(editorState))
      let newDescription = [...company.description];
      newDescription[company.description.findIndex(description => description.language === "de")].text = convertHTMlToRaw(editorState);
      setCompany({...company, description: newDescription} )
      setChanges(diff(initialCompany, company));
    } 
    const ChangeHTMLDraft = () => {
        if (editorModes.showDraft && !editorModes.showHTML) {
            setEditorModes({showDraft: false,showHTML: true})
        } else if (!editorModes.showDraft && editorModes.showHTML) {
            setEditorModes({showDraft: true,showHTML: false})
        }
        }
    const ChangeDraftHTMLBtn = () => <div className="rdw-option-wrapper" onClick={ChangeHTMLDraft}><MDBIcon icon="code" /></div>

    // Effects

    

    // useEffect(() => {
    //     if (loading || !company) return
    //     else {
    //         convertHTMLtoDraftInit(company.description[0].text || "", ContentState, EditorState, setEditorState)
    //     }
    // }, [company])


   // Select Topics 
  const [topics, setTopics] = useState([]);
  const [topicGroups, setTopicGroups] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedTopicGroups, setSelectedTopicGroups] = useState([]);


  const [selectTopicsModal, setSelectTopicsModal] = useState(false);
  const toggleSelectTopicsModal = () => setSelectTopicsModal(!selectTopicsModal);

  const handleSetSelectedTopics = (topics) => {
    setSelectedTopics(topics);
    setSelectedTopicGroups([...new Set(topics?.map((topic) => topic.topicGroupTopicsId) || [])]);
    handleChange({
      target: {
        value: topics.map((topic) => topic.id),
        attributes: {
          datapath: {
            value: "topics"
          }
        },
      }
    })
  }

    // Topics data

    const getTopics = async () => {
        try {
          const topicGroupsWithTopics = await client.graphql({
            query: /* GraphQL */ `
            query ListTopicGroups(
              $filter: ModelTopicGroupFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                  id
                  color
                  sortKey
                  name {
                    language
                    text
                  }
                  topics {
                    items {
                      id
                      name {
                        language
                        text
                      }
                      topicGroupTopicsId
                    }
                  }
                }
                nextToken
                __typename
              }
            }
          `
            });
            setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items.sort((a, b) => a.sortKey - b.sortKey));
            const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
            setTopics(topics);
            

        } catch (error) {
            console.log("error on fetching topics", error);
        }
    }

    useEffect(() => {
        if (topics.length === 0) {
            getTopics();
        }
    }, [topics]);

    useEffect(() => {
      if(company && company.topics && topics.length > 0) {
        setSelectedTopicGroups([...new Set(company.topics.map((topicId) => topics.find(topic => topic.id === topicId).topicGroupTopicsId) || [])]);
      }
    }, [topics, company])
    

    const Topics = ({topics, topicGroups, selectedTopics}) => {
        // First sort the topics by topicGroup (new array with objects for eacht group, inside object the key: topics, inside the topics for this group), then return for each group a col, inside the col the title of the group, then the topics as chips
        let sortedTopics = [];
        selectedTopics.forEach((selectedTopic) => {
            const topicGroup = topicGroups.find((topicGroup) => topicGroup.id === selectedTopic.topicGroupTopicsId);
            const topicGroupIndex = sortedTopics.findIndex((sortedTopic) => sortedTopic.id === topicGroup?.id);
            if (topicGroupIndex === -1) {
                sortedTopics.push({...topicGroup, topics: [selectedTopic]});
            } else {
                sortedTopics[topicGroupIndex].topics.push(selectedTopic);
            }
        });
        //console.log("sortedTopics",sortedTopics);
        return (
            <MDBRow className="my-3 my-lg-4">
                {sortedTopics.map((topicGroup) => {
                    return (
                        <MDBCol size="12" xxl="6" key={topicGroup.id} className="mb-3 mb-lg-4 text-center">
                            <h6>{topicGroup.name?.find(n => n.language === i18n.language)?.text || topicGroup.name?.[0]?.text}</h6>
                            <MDBRow>
                                {topicGroup.topics.map((topic) => {
                                    return (
                                        <MDBCol size="12" key={topic.id} className="mx-auto" style={{width: "fit-content"}}>
                                            <MDBChip className="m-1 float-start" bgColor={topicGroup.color} textColor="white">{topic.name.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}</MDBChip>
                                        </MDBCol>
                                    )
                                })}
                            </MDBRow>
                        </MDBCol>
                    )
                })}
            </MDBRow>
        )
    }

    // User Image
    const [isUploadingImageStates, setIsUploadingImageStates] = useState({});
    const [uploadingImageErrors, setUploadingImageErrors] = useState({});
    
    const handleSetImage = (file, type) => {
      // upload file using mutation: uploadFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadFiles-${env}")
      if(file) {
        try {
            setIsUploadingImageStates(prevState => ({...prevState, [type]: true}));
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = error => {
          console.log('Error reader: ', error);
            setIsUploadingImageStates(prevState => ({...prevState, [type]: false}));
        }
        reader.onload = async () => {
          try {
            console.log(reader.result)
            const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
            const response = await client.graphql({
              query: /* GraphQL */ `
              mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
                uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
              }
            `,
              variables: {
                imageBuffer: base64FileBuffer,
                imageType: type === "image" ? "companyLogo" : "companyBanner",
              },
            });
            console.log("response", response);
            const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
            handleChange({
              target: {
                value: {
                  fileName,
                  alt: " Image of " + company.name,
                },
                attributes: {
                  datapath: {
                    value: type === "image" ? "image.main" : "banner",
                  }
                },
              },
            });
            } catch (error) {
              console.log("error", error);
                setUploadingImageErrors(prevState => ({...prevState, [type]: error.errors[0].message}));
                setIsUploadingImageStates(prevState => ({...prevState, [type]: false}));
            }
        }
        setIsUploadingImageStates(prevState => ({...prevState, [type]: false}));
        setUploadingImageErrors(prevState => ({...prevState, [type]: null}));
        } catch (error) {
          console.log("error", error);
            setUploadingImageErrors(prevState => ({...prevState, [type]: error.errors[0].message}));
            setIsUploadingImageStates(prevState => ({...prevState, [type]: false}));
        }
      }
    }

    useEffect(() => {
      if(toggleSaveChanges) {
        console.log("Saving changes to company profile")
        handleUpdateCompany();
      }
    }, [toggleSaveChanges])


    return (
<>
{
    typeof toggleSaveChanges !== "boolean" &&
    <MDBSticky
    offset={60}
    boundary
    tag='div'
    href=''

    >
    <MDBRow className="mb-3" >
        <MDBCol size="12" className="text-end">
        <div style={{backgroundColor: "white", width: "fit-content", float: "right"}}>
        <MDBBtn onClick={() => handleResetChanges()} color='light' rippleColor='dark' size="sm" >
        {t("Reset Changes")}
        </MDBBtn>
        <MDBBtn onClick={() => handleUpdateCompany()} color="success" outline>
        {t("Save")}
        </MDBBtn>
        </div>
       
        </MDBCol>
    </MDBRow>
</MDBSticky>
}


<MDBCard className="mb-4" style={

    {backgroundImage: `url(${company?.banner?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + company.banner.fileName : ""})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "300px"}

    }>
    <MDBCardBody>
        <div className="company_image_container">
        <StateProofImage alt={company.image?.main?.alt || "Company Image"}
            src={company.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+ company.image?.main?.fileName : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
            className="img-thumbnail rounded-circle border"
            style={{ height: "200px", width: "200px", objectFit: "contain" }}
        /> 
        <div
        className="bg-white button_company_image_upload"
        >
            <MDBBtn color="primary" onClick={() => document.getElementById("imageMain").click()}>{t("Upload Logo")}</MDBBtn>
            <MDBFile className="d-none" size='sm' id='imageMain' onChange={e => handleSetImage(e.target.files[0], "image")} />
            {
            isUploadingImageStates.image && <span><MDBSpinner role="status" /></span>
            }
            {
            uploadingImageErrors.image && <span className="mt-3 text-danger fw-bold">
            {uploadingImageErrors.image}
        </span>
        }   
        </div>
        </div>
        {/* <MDBBtn color="light" size="sm" className="m-0" style={
        {
            top: "170px",
            position: "absolute",
            left: "60px",
            zIndex: "1"
        }
        }>{t("Upload Logo")}</MDBBtn> */}
    <div className="bg-white position-absolute bottom-5 end-5">
    <MDBBtn color="primary" onClick={() => document.getElementById("imageBanner").click()}>{t("Upload Banner")}</MDBBtn>
    <MDBFile className="d-none" size='sm' id='imageBanner' onChange={e => handleSetImage(e.target.files[0], "banner")} />
            {
            isUploadingImageStates.banner && <span><MDBSpinner role="status" /></span>
            }
            {
            uploadingImageErrors.banner && <span className="mt-3 text-danger fw-bold">
            {uploadingImageErrors.banner}
            </span>
            }
    </div>
    </MDBCardBody>
</MDBCard>


<MDBCard className="mb-4">
    <MDBCardBody>
    <MDBCardTitle className="mb-4">{t("Informations")}</MDBCardTitle>

        <>
        <MDBRow>
        <MDBCol md="6">
                <h5>{t("Address")}</h5>
            <h6>{t("Name")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="12">
                        <MDBInput autoComplete="off" datapath="name" label={t("Name")} value={company.name || ""} onChange={handleChange} />
                    </MDBCol>
                   
                </MDBRow>    
                <h6>{t("Address")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="8">
                        <MDBInput autoComplete="off" datapath="contact.address.street" label={t("Street")} value={company.contact?.address?.street || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol size="4">
                        <MDBInput autoComplete="off" datapath="contact.address.streetNumber" label={t("Number")} value={company.contact?.address?.streetNumber || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.street2" label={t("Street Additonal")} value={company.contact?.address?.street2 || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.zip" label={t("Zip")} value={company.contact?.address?.zip || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.city" label={t("City")} value={company.contact?.address?.city || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.countryCode" label={t("Country")} value={company.contact?.address?.countryCode || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
                <h6>{t("Contact")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                        <PhoneInput
                            value={company.contact?.phone || ""}
                            placeholder={t("Phone")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.phone"}}, value: phone}})}
                            disabled={isUpdating}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                        <PhoneInput
                            value={company.contact?.mobile || ""}
                            placeholder={t("Mobile")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.mobile"}}, value: phone}})}
                            disabled={isUpdating}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4">
                        <PhoneInput
                            value={company.contact?.fax}
                            placeholder={t("Fax")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.fax"}}, value: phone}})}
                            disabled={isUpdating}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="6" className="mb-3 mb-md-0">
                        <MDBInput autoComplete="off" datapath="contact.email" label={t("Email")} value={company.contact?.email || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                    <MDBCol size="12" md="6">
                        <MDBInput autoComplete="off" datapath="website" label={t("Website")} value={company.website || ""} onChange={handleChange} disabled={isUpdating} />
                    </MDBCol>
                </MDBRow>
            </MDBCol>
            <hr className="w-100 d-md-none" />
            <MDBCol md="6" className="text-center">
                <MDBBtn onClick={() => toggleSelectTopicsModal()} color="primary" outline>
                    {t("Select topics")}
                </MDBBtn>
                {/* <h5>{t("Selected Topics")}</h5> */}
                
                <MDBRow className="w-100 mx-0">
                    <MDBCol>
                       <Topics
                            topics={topics}
                            topicGroups={topicGroups}
                            selectedTopics={selectedTopics}
                            

                            />
                    </MDBCol>
                </MDBRow>
            </MDBCol>

           
            
        </MDBRow>
    </>

    </MDBCardBody>
</MDBCard>

<MDBCard>
    <MDBCardBody>
    <MDBCardTitle className="mb-4">{t("Description")}</MDBCardTitle>
    {
      editorState &&
      <>
    <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName={editorModes.showDraft ? "md-textarea form-control" : "md-textarea form-control d-none"}
        toolbarCustomButtons={[<ChangeDraftHTMLBtn />]}
        onEditorStateChange={onEditorStateChange}
        />
        {/* <textarea
            onChange={e => {
              console.log("e")
              convertHTMLtoDraft(e, ContentState, EditorState, setEditorState)
            }}
            className={editorModes.showHTML ? "md-textarea form-control w-100" : "md-textarea form-control w-100 d-none"}
            value={editorState && convertHTMlToRaw(editorState)}
        /> */}
      </>
    }
   
    </MDBCardBody>
</MDBCard>
<MDBModal open={selectTopicsModal} onClose={() => setSelectTopicsModal(false)}>
    <MDBModalDialog size="xl">
        <MDBModalContent >
            <MDBModalHeader>
                <MDBModalTitle>Select Topics</MDBModalTitle>
                <MDBBtn className="btn-close" color="none" onClick={toggleSelectTopicsModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <SelectTopics
                  topics={topics}
                  topicGroups={topicGroups}
                  isActive={selectTopicsModal}
                  toggleActive={toggleSelectTopicsModal}
                  selectedTopics={selectedTopics}
                  selectedTopicGroups={selectedTopicGroups}
                  setSelectedTopics={handleSetSelectedTopics}
                  parentTopics={topics}
                  parentTopicGroups={topicGroups}
                  maxSelect={5}
                  />
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleSelectTopicsModal}>{t("Close")}</MDBBtn>
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
    )
}
