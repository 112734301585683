import React, { useState } from "react";
import { generateClient } from 'aws-amplify/api';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBInput, MDBRow, MDBTextArea, MDBSticky, MDBBtn, MDBFile, MDBSpinner, MDBValidationItem, MDBInputGroup, MDBCheckbox   } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from "react-router-dom";



import deepUpdateObject from "../../utils/deepUpdateObject";

const client = generateClient();
const StateProofImage = React.memo(function Image({ src, alt, className, style }) {
    return <img src={src} alt={alt} className={className} style={style} />;
});

export default function Admin_CreateUser({}) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
   
    
    const [loading, setLoading] = useState(false);

    
    // Changes and form handling
    const [user, setUser] = useState({});
    const [shouldSendInvitation, setShouldSendInvitation] = useState(false);

   
    const handleChange = (e, additionalInstructions) => {
    
    // Get values from input
    const datapath = e.target.attributes.datapath.value;
    let value;

    // Check if the input is a checkbox
    if (e.target.type === 'checkbox') {
        value = e.target.checked; // Use 'checked' for checkboxes
    } else {
        value = e.target.value; // Use 'value' for other input types
    }

    // Update current company object
        const newUser = deepUpdateObject({...user}, datapath, value, additionalInstructions);
        setUser(newUser);
        //  const newdiff = diff(newUser, initialUser)
        //  console.log("diff", newdiff)
         //setChanges(diff(initialUser, newUser));
        
    }


    // Create user
    const [isUpdating, setIsUpdating] = useState(false);
    const [errorUpdating, setErrorUpdating] = useState(false);
    const handleUpdateProfile = async () => {

        try {
            setIsUpdating(true);
            const userRes = await client.graphql({
                query: /* GraphQL */ `
                mutation CreateNewUser($user: String) {
                  createNewUser(user: $user)
                }
              `,
                variables: { user: JSON.stringify({...user, shouldSendInvitation}) }
            });
            console.log("user", userRes);
            setIsUpdating(false);
            const userId = userRes.data.createNewUser;
            console.log("userId", userId);
            setTimeout(() => {
                navigate(`/admin/user/${userId}`);
              }, 500);
        } catch (error) {
            console.warn("error updating user", error);
            setIsUpdating(false);
            setErrorUpdating(true);
            setTimeout(() => {
                setErrorUpdating(false);
            }, 10000);
        }
    }


    // User Image
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [uploadingImageError, setUploadingImageError] = useState(null);
    
    const handleSetImage = (file) => {
      // upload file using mutation: uploadFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadFiles-${env}")
      if(file) {
        try {
          setIsUploadingImage(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = error => {
          console.log('Error reader: ', error);
          setUploadingImageError(error);
        }
        reader.onload = async () => {
          try {
            console.log(reader.result)
            const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
            const response = await client.graphql({
              query: /* GraphQL */ `
              mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
                uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
              }
            `,
              variables: {
                imageBuffer: base64FileBuffer,
                imageType: "userProfileImage",
              },
            });
            console.log("response", response);
            const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
            handleChange({
              target: {
                value: {
                  fileName,
                  alt: " Image of " + user.firstName + " " + user.lastName,
                },
                attributes: {
                  datapath: {
                    value: "image"
                  }
                },
              },
            });
            } catch (error) {
              console.log("error", error);
              setIsUploadingImage(false);
              setUploadingImageError(error.errors[0].message);
            }
        }
        setIsUploadingImage(false);
        setUploadingImageError(null);
        } catch (error) {
          console.log("error", error);
          setIsUploadingImage(false);
          setUploadingImageError(error.errors[0].message);
        }
      }
    }



    

  return (
<>
{
  typeof toggleSaveChanges !== "boolean" &&
  <MDBSticky
    offset={60}
    boundary
    tag='div'
    href=''
    >
    <MDBRow className="mb-3">
        <MDBCol size="12" className="text-end">
        {/* <MDBBtn onClick={() => handleResetChanges()} color='light' rippleColor='dark' size="sm">
        {t("Reset Changes")}
        </MDBBtn> */}
        {
            isUpdating ?
            <MDBBtn color='light' rippleColor='dark' disabled>
            {t("Saving...")}
            </MDBBtn>
            :
            errorUpdating ?
            <MDBBtn color='danger' rippleColor='dark' disabled>
            {t("Error Saving")}
            </MDBBtn>
            :
            <MDBBtn onClick={() => handleUpdateProfile()} color="success" outline>
                {t("Create")}
            </MDBBtn>
        }
        </MDBCol>
    </MDBRow>
</MDBSticky>
}

<MDBCard className="m-1 m-lg-3 m-xl-5">
    <MDBCardBody>
    <MDBCardTitle className="mb-4">{t("Personal Profile")}</MDBCardTitle>
    {loading ?
        <div>Loading...</div>
        :
        <>
        <MDBRow>
            <MDBCol md="4" className="text-center">
            <div className="w-100 text-center mb-2 mb-lg-4">
            <StateProofImage
            src={user?.image?.fileName ? 
            "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" + user.image.fileName: "https://via.placeholder.com/400x400"}
            className="img-thumbnail rounded-circle border"
            style={{ height: "200px", objectFit: "cover" }}
            alt='...' />
            </div>
            <MDBBtn color="primary" size="sm" className="w-50 mb-2 mb-lg-4" onClick={() => document.getElementById("image").click()}>{t("Upload Image")}</MDBBtn>
            <MDBFile className="d-none" id='image' onChange={e => handleSetImage(e.target.files[0])} />
            {
            isUploadingImage.De && <span><MDBSpinner role="status" /></span>
            }
            {
            uploadingImageError && <span className="mt-3 text-danger fw-bold">
            {uploadingImageError}
            </span>
            }
            </MDBCol>
            <MDBCol md="8">
            <MDBRow className="mb-3">
                <MDBCol size="12" className="mb-2 mb-md-0">
                    <MDBInput autoComplete="off" datapath="email" label={t("Email")} value={user.email || ""} onChange={handleChange} />
                </MDBCol>
            </MDBRow>
            <h6>{t("Name")}</h6>
                <MDBRow className="mb-3">
                    <MDBValidationItem feedback='' className='col-6 col-md-3' invalid>
                        <MDBInput  autoComplete="off" datapath="salutation" label={t("Salutation")} value={user.salutation || ""} onChange={handleChange} />
                    </MDBValidationItem>
                    <MDBCol size="6" md="3">
                    <MDBInputGroup textAfter={<span style={user.title?.length === 12 ? {color: "red", fontWeight: "bold"} : {}}>{(user.title?.length || "0") + "/12"}</span>}>
                    <MDBInput maxLength={12} autoComplete="off" datapath="title" label={t("Person Title")} value={user.title || ""} onChange={handleChange} />
                    </MDBInputGroup>
                    </MDBCol>
                </MDBRow>    
                <MDBRow className="mb-3">
                    <MDBCol size="6" md="4">
                        <MDBInput autoComplete="off" datapath="firstName" label={t("First Name")} value={user.firstName || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="6" md="4">
                        <MDBInput autoComplete="off" datapath="middleName" label={t("Middle Name")} value={user.middleName || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="12" md="4" className="mt-3 mt-md-0">
                        <MDBInput autoComplete="off" datapath="lastName" label={t("Last name")} value={user.lastName || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <h6>{t("Address")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="8">
                        <MDBInput autoComplete="off" datapath="contact.address.street" label={t("Street")} value={user.contact?.address?.street || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="4">
                        <MDBInput autoComplete="off" datapath="contact.address.streetNumber" label={t("Number")} value={user.contact?.address?.streetNumber || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="6" className="mb-2 mb-md-0">
                        <MDBInput autoComplete="off" datapath="contact.address.street2" label={t("Street Additonal")} value={user.contact?.address?.street2 || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="12" md="6">
                        <MDBInput autoComplete="off" datapath="contact.address.company" label={t("Company")} value={user.contact?.address?.company || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.zip" label={t("Zip")} value={user.contact?.address?.zip || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.city" label={t("City")} value={user.contact?.address?.city || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.countryCode" label={t("Country")} value={user.contact?.address?.countryCode || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <h6>{t("Contact")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="4" className="mb-2 mb-md-0">
                        <PhoneInput
                            value={user.contact?.phone || ""}
                            placeholder={t("Phone")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.phone"}}, value: phone}})}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4" className="mb-2 mb-md-0">
                        <PhoneInput
                            value={user.contact?.mobile || ""}
                            placeholder={t("Mobile")}
                            preferredCountries={['de', 'at', 'ch', "gb", "us", "fr", "it", "es", "nl", "pl", "cz", "fi"]}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.mobile"}}, value: phone}})}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4">
                        <PhoneInput
                            value={user.contact?.fax}
                            placeholder={t("Fax")}
                            preferredCountries={['de', 'at', 'ch', "gb", "us", "fr", "it", "es", "nl", "pl", "cz", "fi"]}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.fax"}}, value: phone}})}
                        />
                    </MDBCol>
                </MDBRow>
                
            </MDBCol>
        </MDBRow>
        <h6>{t("About Me")}</h6>
        <MDBTextArea
            autoComplete="off"
            //datapath="aboutMe"
            //value={user.aboutMe?.find(t => t.language === i18n.language)?.text || user.aboutMe?.[0]?.text || ""}
            //onChange={handleChange}
            value={
                  user?.aboutMe?.length > 0
                    ? user.aboutMe.find((t) => t.language === "de")?.text
                    : ""
                }
                datapath={
                  "aboutMe[" +
                  (user?.aboutMe?.length > 0
                    ? user.aboutMe.findIndex(
                        (t) => t.language === "de"
                      ) === -1
                      ? user.aboutMe.length
                      : user.aboutMe.findIndex((t) => t.language === "de")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "de"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
            />

            <MDBCheckbox id="shouldSendInvitation" label={t("Send Invitation Email")} checked={shouldSendInvitation} onChange={() => setShouldSendInvitation(!shouldSendInvitation)} />
    </>
    }
    </MDBCardBody>
</MDBCard>

</>
   
  )
}
