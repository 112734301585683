import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateClient } from "aws-amplify/api";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTable, 
  MDBTableBody, MDBTableHead, MDBIcon, MDBCollapse, MDBInput, MDBInputGroup } from "mdb-react-ui-kit";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactCountryFlag from "react-country-flag";

const client = generateClient();

export default function EventCarpets() {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [allCarpets, setAllCarpets] = useState([]);
  const [showNewForm, setShowNewForm] = useState(false);
  
  const [newCarpet, setNewCarpet] = useState({
    name: [
      { language: 'de', text: '' },
      { language: 'en', text: '' }
    ],
    description: [
      { language: 'de', text: '' },
      { language: 'en', text: '' }
    ],
    color: '#000000',
    order: 0
  });

  const fetchAllCarpets = async () => {
    setIsLoading(true);
    try {
      const { data } = await client.graphql({
        query: /* GraphQL */ `
          query ListEventCarpets($filter: ModelEventCarpetFilterInput, $limit: Int) {
            listEventCarpets(filter: $filter, limit: $limit) {
              items {
                id
                name {
                  language
                  text
                }
                description {
                  language
                  text
                }
                color
                order
                image {
                  fileName
                  alt
                }
              }
            }
          }
        `,
        variables: { limit: 1000 }
      });

      const sortedCarpets = data.listEventCarpets.items.sort((a, b) => (a.order || 0) - (b.order || 0));
      setAllCarpets(sortedCarpets);
    } catch (error) {
      console.error("Error fetching carpets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllCarpets();
  }, []);

  const handleCreateCarpet = async () => {
    try {
      const { data } = await client.graphql({
        query: /* GraphQL */ `
          mutation CreateEventCarpet($input: CreateEventCarpetInput!) {
            createEventCarpet(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: {
            ...newCarpet,
            order: allCarpets.length
          }
        }
      });
      
      setNewCarpet({
        name: [
          { language: 'de', text: '' },
          { language: 'en', text: '' }
        ],
        description: [
          { language: 'de', text: '' },
          { language: 'en', text: '' }
        ],
        color: '#000000',
        order: 0
      });
      
      setShowNewForm(false);
      fetchAllCarpets();
    } catch (error) {
      console.error("Error creating carpet:", error);
    }
  };

  const handleDeleteCarpet = async (id) => {
    if (!window.confirm(t('Are you sure you want to delete this carpet?'))) {
      return;
    }
    
    try {
      await client.graphql({
        query: /* GraphQL */ `
          mutation DeleteEventCarpet($input: DeleteEventCarpetInput!) {
            deleteEventCarpet(input: $input) {
              id
            }
          }
        `,
        variables: {
          input: { id }
        }
      });
      setAllCarpets(allCarpets.filter(carpet => carpet.id !== id));
    } catch (error) {
      console.error("Error deleting carpet:", error);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(allCarpets);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAllCarpets(items);

    // Update order for affected items
    const startIndex = Math.min(result.source.index, result.destination.index);
    const endIndex = Math.max(result.source.index, result.destination.index);

    for (let i = startIndex; i <= endIndex; i++) {
      const carpet = items[i];
      try {
        await client.graphql({
          query: /* GraphQL */ `
            mutation UpdateEventCarpet($input: UpdateEventCarpetInput!) {
              updateEventCarpet(input: $input) {
                id
                order
              }
            }
          `,
          variables: {
            input: {
              id: carpet.id,
              order: i
            }
          }
        });
      } catch (error) {
        console.error(`Failed to update order for carpet ${carpet.id}:`, error);
      }
    }
  };

  return (
    <MDBRow>
      <MDBCol>
        <MDBCard>
          <MDBCardBody>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <MDBCardTitle className="mb-0">{t("Event Carpets")}</MDBCardTitle>
              <MDBBtn 
                onClick={() => setShowNewForm(!showNewForm)}
                className="mb-0"
              >
                <MDBIcon fas icon={showNewForm ? 'times' : 'plus'} className="me-2" />
                {showNewForm ? t("Cancel") : t("Add Carpet")}
              </MDBBtn>
            </div>

            <MDBCollapse open={showNewForm}>
              <MDBCard className="mb-4">
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol md="6">
                      <MDBInputGroup className="mb-3" textBefore={
                        <ReactCountryFlag countryCode="de" svg />
                      }>
                        <MDBInput
                          label={t("Name (German)")}
                          value={newCarpet.name.find(n => n.language === 'de')?.text || ''}
                          onChange={(e) => setNewCarpet({
                            ...newCarpet,
                            name: newCarpet.name.map(n => n.language === 'de' ? { ...n, text: e.target.value } : n)
                          })}
                        />
                      </MDBInputGroup>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInputGroup className="mb-3" textBefore={
                        <ReactCountryFlag countryCode="gb" svg />
                      }>
                        <MDBInput
                          label={t("Name (English)")}
                          value={newCarpet.name.find(n => n.language === 'en')?.text || ''}
                          onChange={(e) => setNewCarpet({
                            ...newCarpet,
                            name: newCarpet.name.map(n => n.language === 'en' ? { ...n, text: e.target.value } : n)
                          })}
                        />
                      </MDBInputGroup>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBInput
                        type="color"
                        label={t("Color")}
                        value={newCarpet.color}
                        onChange={(e) => setNewCarpet({
                          ...newCarpet,
                          color: e.target.value
                        })}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBBtn onClick={handleCreateCarpet} className="mt-3">
                    {t("Create Carpet")}
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCollapse>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="carpets">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <MDBTable align="middle">
                      <MDBTableHead>
                        <tr>
                          <th>{t("Name")}</th>
                          <th>{t("Color")}</th>
                          <th>{t("Actions")}</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {allCarpets.map((carpet, index) => (
                          <Draggable key={carpet.id} draggableId={carpet.id} index={index}>
                            {(provided) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <td {...provided.dragHandleProps}>
                                  <MDBIcon fas icon='arrows-alt-v' className='me-2' style={{cursor: 'grab'}} />
                                  {carpet.name.find(n => n.language === i18n.language)?.text || carpet.name[0]?.text}
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div 
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        backgroundColor: carpet.color,
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                        marginRight: '10px'
                                      }}
                                    />
                                    {carpet.color}
                                  </div>
                                </td>
                                <td>
                                  <MDBBtn color='danger' size='sm' onClick={() => handleDeleteCarpet(carpet.id)}>
                                    <MDBIcon fas icon='trash-alt' />
                                  </MDBBtn>
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                      </MDBTableBody>
                    </MDBTable>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}