import { useRef, useState, useEffect, useLayoutEffect } from "react";
import {
  MDBStepper,
  MDBStepperStep,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBCardTitle,
} from "mdb-react-ui-kit";
import AcceptConditions from "./step-1-accept-conditions/AcceptConditions";
import ChooseSpeaker from "./step-2-choose-speaker/ChooseSpeaker";
import DescribeLecture from "./step-3-describe-lecture/DescribeLecture";
import CheckLectureInformation from "./step-4-check-lecture-info/CheckLectureInformation";

import { useTranslation } from "react-i18next";

import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export default function RegistrationStepper({ handleChange, event, lecture, createLectureHandler, isSaving, setLectureData, isEdit, topics, topicGroups }) {
  const companyid = window.location.pathname.includes("mycompany") ? window.location.pathname.split("/")[2] : window.location.pathname.split("/")[3];
  const { t, i18n } = useTranslation();

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const handleNextClick = () => {
    nextRef.current.click();
  };

  const handlePrevClick = () => {
    prevRef.current.click();
  };

  const [activeStep, setActiveStep] = useState(1);

  // Scroll to active step on change
  useLayoutEffect(() => {
    const element = document.getElementById("stepper-step-" + activeStep);
    if(!element) return;
    var headerOffset = 120;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;
    setTimeout(() => {
      element.scrollIntoView({ behavior: "smooth" });
    }, 500);
    // window.scrollBy({
    //   top: offsetPosition,
    //   behavior: "smooth"
    // });
  }, [activeStep]);


  // Employees
  const [employees, setEmployees] = useState([]);
  // Get employees with company id
  const getEmployees = async () => {
    try {
        console.log("company", companyid)
        const employees = await client.graphql({
            variables: {
              filter: { companyEmployeesId: { eq: companyid } },
              limit: 5000
             },
           
            query: /* GraphQL */ `
            query ListEmployees(
              $filter: ModelEmployeeFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
                    nextToken
                    items {
                        id
                        user {
                                lastName
                                middleName
                                firstName
                                title
                                image {
                                    alt
                                    fileName
                                }
                        }
                        department {
                            items {
                                department {
                                    name {
                                        language
                                        text
                                    }
                                }
                            }
                        }
                        image {
                            alt
                            fileName
                          }
                      }
                }
            }
            `
        });
        //console.log("employees", employees)
        let employeesArray = employees.data.listEmployees.items;
        setEmployees(employeesArray);
        console.log("employees", employees.data.listEmployees.items)
    } catch (err) {
        console.log("error fetching employees", err);
    }
};

const initialLoadEmployees = async () => {
  await getEmployees();
};


  //Set eployees on first site render
  useEffect(() => {
    if (!employees || employees.length === 0) {
      console.log("initial load employees")
      initialLoadEmployees()
    } else {
      console.log("employees loaded", employees)
    } 
  }, []);



  return (
    lecture &&
    <MDBRow className="h-auto d-flex justify-content-center">
      <MDBCol size="12" xl="12" xxl="11">
        <MDBCard>
          <MDBCardBody className="px-1 px-md-5">
          <MDBCardTitle>{isEdit ? t("Edit Lecture") + ": " + (lecture.title?.find(t => t.language === i18n.language)?.text || lecture?.title?.[0]?.text) : t("Lecture Registration")}</MDBCardTitle>
          <div className='mb-3 d-none'>
          <MDBBtn ref={prevRef}>PREV</MDBBtn>
          <MDBBtn ref={nextRef}>NEXT</MDBBtn>
        </div>
            <MDBStepper defaultStep={isEdit ? 4 : 1} type="vertical" externalNext={nextRef} externalPrev={prevRef} onChange={stepId => setActiveStep(stepId)}>
              <MDBStepperStep headIcon={1} headText={t("Step 1: Accept conditions")} itemId={1} id="stepper-step-1">
                <AcceptConditions
                  handleChange={handleChange}
                  lecture={lecture}
                  handleNextClick={handleNextClick}
                  event={event}
                />
              </MDBStepperStep>
              <MDBStepperStep headIcon={2} headText={t("Step 2: Select Speakers")} itemId={2} id="stepper-step-2">
                <ChooseSpeaker
                  handleChange={handleChange}
                  lecture={lecture}
                  handleNextClick={handleNextClick}
                  reloadEmployees={getEmployees}
                  employees={employees}
                />
              </MDBStepperStep>
              <MDBStepperStep headIcon={3} headText={t("Step 3: Lecture Details")} itemId={3} id="stepper-step-3">
                <DescribeLecture
                  handleChange={handleChange}
                  lecture={lecture}
                  handleNextClick={handleNextClick}
                  activeStep={activeStep}
                  setLectureData={setLectureData}
                  companyId={companyid}
                  topics={topics}
                  topicGroups={topicGroups}
                  event={event}
                  
                />
              </MDBStepperStep>
              <MDBStepperStep headIcon={4} headText={t("Step 4: Confirm informations")} itemId={4} id="stepper-step-4">
                <CheckLectureInformation
                  handleChange={handleChange}
                  lecture={lecture}
                  handleNextClick={handleNextClick}
                  employees={employees}
                  createLectureHandler={createLectureHandler}
                  isSaving={isSaving}
                  topics={topics}
                  topicGroups={topicGroups}
                  backClick={handlePrevClick}
                />
              </MDBStepperStep>
            </MDBStepper>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}
