import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableHead, MDBTableBody, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter, MDBCard, MDBCardBody, MDBIcon, MDBCardTitle } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from "react-i18next";

const client = generateClient();

export default function AllNews() {
    const { t, i18n } = useTranslation();
    const [newsList, setNewsList] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const [filters, setFilters] = useState({ createdAtSort: "desc" });

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchAllNews = async (nextToken = null, allNews = []) => {
        try {
            const response = await client.graphql({
                query: /* GraphQL */ `
                    query ListCMSNews(
                        $filter: SearchableCMSNewsFilterInput
                        $limit: Int
                        $nextToken: String
                    ) {
                        searchCMSNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
                            items {
                                id
                                name {
                                language
                                text
                                }
                                status
                                employee {
                                    id
                                    image {
                                        fileName
                                        alt
                                    }
                                    user {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                                company {
                                    id
                                    name
                                    image {
                                        main {
                                            fileName
                                            alt
                                        }
                                    }
                                    website
                                }
                                companyNewsId
                                createdAt
                                updatedAt
                            }
                            nextToken
                        }
                    }
                `,
                variables: { 
                    nextToken: nextToken,
                    limit: 1000
                }
            });

            const newsData = response.data.searchCMSNews;
            allNews = allNews.concat(newsData.items);

            if (newsData.nextToken) {
                return fetchAllNews(newsData.nextToken, allNews);
            } else {
                
                return allNews.sort((a, b) => {
                    if (filters.createdAtSort === "asc") {
                        return new Date(a.createdAt) - new Date(b.createdAt);
                    } else {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    }
                }
                );
            }
        } catch (error) {
            console.error('Error fetching news:', error);
            return []; // Return an empty array or handle the error as needed
        }
    };

    const fetchNews = async () => {
        try {
            const allNews = await fetchAllNews();
            setNewsList(allNews);
        } catch (error) {
            console.error('Failed to fetch all news:', error);
        }
    };

  

    const deleteNews = async () => {
        try {
            await client.graphql({
                query: /* GraphQL */ `
                mutation DeleteNews($input: DeleteCMSNewsInput!) {
                  deleteCMSNews(input: $input) {
                    id
                  }
                }
              `,
                variables: { input: { id: selectedNews } }
            });
            setDeleteModal(false);
            setNewsList(newsList.filter(news => news.id !== selectedNews));
        } catch (error) {
            console.error('Error deleting news:', error);
        }
    };

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    return (
<> 
    <MDBCard>
    <MDBCardBody>
    <MDBCardTitle>{t("Company News")}</MDBCardTitle>
        
        
        <MDBTable>
            <MDBTableHead>
                <tr>
                <th style={{minWidth: "110px"}}>{t("Datum")} <MDBIcon fas icon="long-arrow-alt-up" size='sm' onClick={() => setFilters({...filters, createdAtSort: "desc"})} /><MDBIcon fas icon="long-arrow-alt-down" size='sm' onClick={() => setFilters({...filters, createdAtSort: "asc"})} /></th>
                <th>{t("Company")}</th>
                    <th>Name</th>
                    <th>{t("Employee")}</th>
                    <th>Status</th>
                    <th>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {newsList.map(news => (
                    <tr key={news.id}>
                        <td>
                        {new Date(news.createdAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}
                        {new Date(news.createdAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }) !== new Date(news.updatedAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' }) && <>
                            <br />
                        <span className="text-success" style={{fontSize: "smaller"}}>{new Date(news.updatedAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}</span>
                        </>}
                        </td>
                        <td><>
                            {news.company?.image?.main?.fileName ?
                                <div className='d-flex align-items-center'>
                                    {news.company.image?.main?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+news.company.image.main.fileName}
                                            className="rounded-circle border"
                                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                            alt={news.company.image.alt}
                                        />
                                    :
                                        <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                
                                    }
                                    <div className='ms-3'>
                                    <p className='fw-bold mb-1'>{news.company.name}</p>
                                    </div>
                                </div>
                                :
                                <MDBIcon
                                    className="rounded-circle border"
                                    style={{
                                        height: "50px",
                                        width: "50px",
                                        textAlign: "center",
                                        paddingTop: "12px",
                                        fontSize: "20px",
                                    }}
                                    fas
                                    icon="user-alt"
                                />
                            }
                        </>
                        </td>
                        <td>{news.name?.find(t => t.language === i18n.language)?.text}</td>
                        
                        <td>{news.employee && (
                        news.employee?.image?.fileName ?
                        <img
                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${news.employee.image.fileName}`}
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                objectFit: "cover",
                            }}
                            alt={news.employee?.image.alt}
                        />
                        :
                        <MDBIcon
                            className="rounded-circle border"
                            style={{
                                height: "50px",
                                width: "50px",
                                textAlign: "center",
                                paddingTop: "12px",
                                fontSize: "20px",
                            }}
                            fas
                            icon="user-alt"
                        />            
                        )}
                        </td>
                        <td>{news.status === "created" ? null : t(news.status === "checked" ? "Approved" : news.status)}</td>
                        <td>
                            <Link to={`/admin/news/${news.companyNewsId}/${news.id}`}>
                            <MDBBtn className='mx-2 my-0 py-0' color='link'>{t("Edit")}</MDBBtn>
                            </Link>
                            <MDBBtn className='mx-2 my-0 py-0' color='link' style={{color: "red"}} onClick={() => {
                                setSelectedNews(news.id);
                                toggleDeleteModal();
                            }}>{t("Delete")}</MDBBtn>
                        </td>
                    </tr>
                ))}
            </MDBTableBody>
        </MDBTable>
        
    </MDBCardBody>
</MDBCard>
<MDBModal open={deleteModal} onClose={() => toggleDeleteModal(false)} staticBackdrop>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>Delete News</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleDeleteModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                Are you sure you want to delete this news?
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color='secondary' onClick={toggleDeleteModal}>Cancel</MDBBtn>
                <MDBBtn color='danger' onClick={deleteNews}>Delete</MDBBtn>
            </MDBModalFooter>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
    );
}
