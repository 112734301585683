import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBCard, MDBCardBody, MDBIcon, MDBInput, MDBSelect } from "mdb-react-ui-kit";
import { isRouteErrorResponse } from "react-router-dom";

const client = generateClient();

export default function Admin_CreateEmployee({presetUserId, presetCompanyId, doneCallback}) {
    const { t, i18n } = useTranslation();

    const [userId, setUserId] = useState(presetUserId);
    const [companyId, setCompanyId] = useState(presetCompanyId);
    const [user, setUser] = useState({});
    const [company, setCompany] = useState({});
    const [role, setRole] = useState();

    const [searchedCompanies, setSearchedCompanies] = useState([]);
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [searchCompaniesInput, setSearchCompaniesInput] = useState("");
    const [searchUsersInput, setSearchUsersInput] = useState("");

    const getUser = async () => {
        try {
            const user = await client.graphql({
                variables: { id: userId },
                query: /* GraphQL */ `
                query GetUser($id: ID!) {
                  getUser(id: $id) {
                    id
                    email
                    firstName
                    middleName
                    title
                    lastName
                    salutation
                    image {
                      fileName
                      alt
                    }
                  }
                }
              `
            });
            return user.data.getUser;
        } catch (error) {
            console.warn("error fetching user", error);
        }
    }

    const getCompany = async () => {
        try {
            const company = await client.graphql({
                variables: { id: companyId },
                query: /* GraphQL */ `
                query GetCompany($id: ID!) {
                  getCompany(id: $id) {
                    id
                    name
                    image {
                        main {
                            fileName
                        }
                    }
                  }
                }
              `
            });
            return company.data.getCompany;
        } catch (error) {
            console.warn("error fetching company", error);
        }
    }

    const searchCompanies = async () => {
        try {
            const companies = await client.graphql({
                variables: { filter: { name: { regexp: `.*${searchCompaniesInput}.*` } } },
                query: /* GraphQL */ `
                query SearchCompanies(
                    $filter: SearchableCompanyFilterInput
                    $sort: [SearchableCompanySortInput]
                    $limit: Int
                    $nextToken: String
                    $from: Int
                    $aggregates: [SearchableCompanyAggregationInput]
                  ) {
                    searchCompanies(
                      filter: $filter
                      sort: $sort
                      limit: $limit
                      nextToken: $nextToken
                      from: $from
                      aggregates: $aggregates
                    ) {
                        items {
                            id
                            name
                            image {
                                main {
                                    fileName
                                }
                            }
                        }
                    }
                }
                `
            });
            setSearchedCompanies(companies.data.searchCompanies.items);
        } catch (error) {
            console.warn("error searching companies", error);
        }
    }

    const searchUsers = async () => {
        try {
            const users = await client.graphql({
                variables: { filter: { email: { regexp: `.*${searchUsersInput}.*`  } } },
                query: /* GraphQL */ `
                query SearchUsers(
                    $filter: SearchableUserFilterInput
                    $sort: [SearchableUserSortInput]
                    $limit: Int
                    $nextToken: String
                    $from: Int
                    $aggregates: [SearchableUserAggregationInput]
                    ) {
                    searchUsers(
                        filter: $filter
                        sort: $sort
                        limit: $limit
                        nextToken: $nextToken
                        from: $from
                        aggregates: $aggregates
                    ) {
                        items {
                            id
                            email
                            firstName
                            middleName
                            title
                            lastName
                            salutation
                            image {
                                fileName
                                alt
                            }
                        }
                    }
                }
                `
            });
            setSearchedUsers(users.data.searchUsers.items);
        } catch (error) {
            console.warn("error searching users", error);
        }
    }

    useEffect(() => {
        if(searchCompaniesInput) searchCompanies();
    }, [searchCompaniesInput]);

    useEffect(() => {
        if(searchUsersInput) searchUsers();
    }, [searchUsersInput]);


    const CreateEmployee = async () => {
        try {
            let input = {
                companyEmployeesId: companyId,
                userEmployeeProfilesId: userId,
                role: role,
                status: "active"
            }

            if(role === "admin") {input.companyAdminEmployeesId = companyId}
            if(user.image?.fileName) {input.image = {fileName: user.image.fileName}}
           
            if(await checkEmployeeAlreadyExists()) throw new Error("Employee already exists");
            const employee = await client.graphql({
                variables: { input },
                query: /* GraphQL */ `
                mutation CreateEmployee($input: CreateEmployeeInput!) {
                  createEmployee(input: $input) {
                    id
                  }
                }
              `
            });
            return employee.data.createEmployee;
        } catch (error) {
            console.warn("error creating employee", error);
        }
    }

    const checkEmployeeAlreadyExists = async () => {
        try {
            const employees = await client.graphql({
                variables: { filter: { companyEmployeesId: { eq: companyId }, userEmployeeProfilesId: { eq: userId } }, limit: 5000 },
                query: /* GraphQL */ `
                query ListEmployees(
                    $filter: ModelEmployeeFilterInput
                    $limit: Int
                    $nextToken: String
                    ) {
                    listEmployees(filter: $filter, limit: $limit, nextToken: $nextToken) {
                        items {
                            id
                        }
                    }
                }
                `
            });
            return employees.data.listEmployees.items.length > 0;
        } catch (error) {
            console.warn("error checking employee", error);
        }
    }

    const handleClickCreateEmployee = async () => {
        if(userId && companyId) {
            await CreateEmployee();
            doneCallback();
        }
    }

    useEffect(() => {
        if(companyId) {
            getCompany().then(data => setCompany(data));
        }
    }, [companyId]);
    useEffect(() => {
        if(userId) {
            getUser().then(data => setUser(data));
        }
    }, [userId]);

  return (
<div className="text-center">
<MDBCard>
    <MDBCardBody>
        {companyId ? 
            <div className='d-flex align-items-center'>
            {company?.image?.main?.fileName ?
                <img
                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+ company.image.main.fileName}
                    className=""
                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                    alt={company.name}
                />
            :
                <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                        
            }
            <div className='ms-3'>
                <p className='fw-bold mb-1'>{company.name}</p>
            </div>
        </div>
        :
        <>
        <MDBInput label={t("Search Company")} onChange={e => setSearchCompaniesInput(e.target.value)} />
        {searchedCompanies.map(company =>
            <div key={company.id} className='d-flex align-items-center' onClick={() => setCompanyId(company.id)}>
                {company.image?.main?.fileName ?
                    <img
                        src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+ company.image.main.fileName}
                        className=""
                        style={{ height: "50px", width: "50px", objectFit: "contain" }}
                        alt={company.name}
                    />
                :
                    <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                }
                <div className='ms-3'>
                    <p className='fw-bold mb-1'>{company.name}</p>
                </div>
            </div>
        )}
        </>
        }
    </MDBCardBody>
</MDBCard>
<MDBIcon fas size="2x" icon="link" className="my-2" />
<MDBCard>
    <MDBCardBody>
        {userId ?
            <div className='d-flex align-items-center'>
            {user.image?.fileName ?
                <img
                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+ user.image.fileName}
                    className="rounded-circle"
                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                    alt={user.firstName + " " + user.lastName}
                />
            :
                <MDBIcon className="rounded-circle" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
            }
            <div className='ms-3'>
                <p className='fw-bold mb-1'>{user.firstName} {user.lastName}</p>
            </div>
        </div>
        :
        <>
        <MDBInput label={t("Search User")} onChange={e => setSearchUsersInput(e.target.value)} />
        {searchedUsers.map(user =>
            <div key={user.id} className='d-flex align-items-center' onClick={() => setUserId(user.id)}>
                {user.image?.fileName ?
                    <img
                        src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+ user.image.fileName}
                        className="rounded-circle"
                        style={{ height: "50px", width: "50px", objectFit: "contain" }}
                        alt={user.firstName + " " + user.lastName}
                    />
                :
                    <MDBIcon className="rounded-circle" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                }
                <div className='ms-3'>
                    <p className='fw-bold mb-1'>{user.firstName} {user.lastName}</p>
                </div>
            </div>
        )}
        </>
        }
    </MDBCardBody>
</MDBCard>
<MDBSelect
        data={[
            { text: t("Select role"), value: null, defaultSelected: true },
            { text: 'Administrator', value: "admin" },
            { text: 'Mitarbeiter', value: "employee" }
        ]}
        onValueChange={e => setRole(e.value)}
        value={role}
    />
<MDBBtn color="primary" className="my-4" onClick={() => handleClickCreateEmployee()}>{t("Create Employee")}</MDBBtn>
</div>
  )
}
