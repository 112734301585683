import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MDBCard, MDBCardBody, MDBBtn, MDBInput, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';

const client = generateClient();

export default function EditIndustry() {
  const { industryId } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: [
      { language: 'DE', text: '' },
      { language: 'EN', text: '' }
    ],
    domain: '',
    handle: '',
    primaryColor: '#1f3241',
    secondaryColor: '#ed694b',
    status: 'ACTIVE',
    sortKey: 0
  });
  const [logoFile, setLogoFile] = useState(null);

  useEffect(() => {
    if (industryId) {
      fetchIndustry();
    }
  }, [industryId]);

  const fetchIndustry = async () => {
    try {
      const result = await client.graphql({
        query: `
          query GetIndustry($id: ID!) {
            getIndustry(id: $id) {
              id
              name {
                language
                text
              }
                handle
              domain
              primaryColor
              secondaryColor
              status
              sortKey
              logo {
                fileName
              }
            }
          }
        `,
        variables: { id: industryId }
      });
      setFormData(result.data.getIndustry);
    } catch (error) {
      console.error('Error fetching industry:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const input = { ...formData };
      if (logoFile) {
        // Handle logo upload to S3 here
        // You'll need to implement the file upload logic
        input.logo = { fileName: `${Date.now()}-${logoFile.name}` };
      }

      if (industryId) {
        input.id = industryId;
        await client.graphql({
          query: `
            mutation UpdateIndustry($input: UpdateIndustryInput!) {
              updateIndustry(input: $input) {
                id
              }
            }
          `,
          variables: { input }
        });
      } else {
        await client.graphql({
          query: `
            mutation CreateIndustry($input: CreateIndustryInput!) {
              createIndustry(input: $input) {
                id
              }
            }
          `,
          variables: { input }
        });
      }

      navigate('/admin/industries');
    } catch (error) {
      console.error('Error saving industry:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <h5 className="mb-4">
          {industryId ? t('Edit Industry') : t('Create Industry')}
        </h5>
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol md="6">
              <MDBInput
                label={t('German Name')}
                value={formData.name.find(n => n.language === 'DE')?.text || ''}
                onChange={(e) => setFormData({
                  ...formData,
                  name: formData.name.map(n => 
                    n.language === 'DE' ? { ...n, text: e.target.value } : n
                  )
                })}
                required
              />
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                label={t('English Name')}
                value={formData.name.find(n => n.language === 'EN')?.text || ''}
                onChange={(e) => setFormData({
                  ...formData,
                  name: formData.name.map(n => 
                    n.language === 'EN' ? { ...n, text: e.target.value } : n
                  )
                })}
                required
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol md="6">
                <MDBInput
                label={t('Handle (for theming)')}
                value={formData.handle}
                onChange={(e) => setFormData({ 
                    ...formData, 
                    handle: e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, '') 
                })}
                required
                hint="e.g., hd for Hygienic Design theme"
                />
            </MDBCol>
        </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol md="6">
              <MDBInput
                label={t('Domain')}
                value={formData.domain}
                onChange={(e) => setFormData({ ...formData, domain: e.target.value })}
                required
              />
            </MDBCol>
            <MDBCol md="3">
              <label className="form-label">{t('Primary Color')}</label>
              <input
                type="color"
                className="form-control form-control-color w-100"
                value={formData.primaryColor}
                onChange={(e) => setFormData({ ...formData, primaryColor: e.target.value })}
              />
            </MDBCol>
            <MDBCol md="3">
              <label className="form-label">{t('Secondary Color')}</label>
              <input
                type="color"
                className="form-control form-control-color w-100"
                value={formData.secondaryColor}
                onChange={(e) => setFormData({ ...formData, secondaryColor: e.target.value })}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-3">
            <MDBCol md="6">
              <select
                className="form-select"
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              >
                <option value="ACTIVE">{t('Active')}</option>
                <option value="INACTIVE">{t('Inactive')}</option>
              </select>
            </MDBCol>
            <MDBCol md="6">
              <MDBInput
                type="number"
                label={t('Sort Key')}
                value={formData.sortKey}
                onChange={(e) => setFormData({ ...formData, sortKey: parseInt(e.target.value) })}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="mt-3">
            <MDBCol>
              <label className="form-label">{t('Logo')}</label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) => setLogoFile(e.target.files[0])}
              />
            </MDBCol>
          </MDBRow>

          <div className="d-flex justify-content-end mt-4">
            <MDBBtn 
              color="secondary" 
              className="me-2"
              onClick={() => navigate('/admin/industries')}
            >
              {t('Cancel')}
            </MDBBtn>
            <MDBBtn 
              type="submit" 
              disabled={loading}
            >
              {loading && <MDBIcon icon="spinner" spin className="me-2" />}
              {t('Save')}
            </MDBBtn>
          </div>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
}