const deepUpdateObject = (object, path, value, additionalInstructions = {}) => {
    const pathParts = path.split('.').map(part => {
        if (part.includes('[')) {
            const [pathPart, arrayIndex] = part.split(/[\[\]]/).filter(Boolean);
            return [pathPart, arrayIndex];
        }
        return part;
    }).flat();

    let newObject = { ...object };

    pathParts.reduce((o, p, i) => {
        const isLastPart = i === pathParts.length - 1;
        const isArrayIndex = /^\d+$/.test(p);
        const nextPartIsArray = i < pathParts.length - 1 && /^\d+$/.test(pathParts[i + 1]);

        if (isLastPart) {
            if (isArrayIndex) {
                const index = parseInt(p, 10);
                if (!Array.isArray(o)) o = [];
                if (value === null) {
                    o.splice(index, 1); // Entfernt das Element bei Index
                } else {
                    o[index] = typeof value === 'object' ? { ...value } : value;
                    // Anwenden von sideload nur, wenn value ein Objekt ist
                    if (typeof value === 'object') {
                        o[index] = { ...o[index], ...additionalInstructions.sideload };
                    }
                }
            } else {
                if (value === null) {
                    delete o[p]; // Entfernt den Schlüssel p
                } else {
                    o[p] = typeof value === 'object' ? { ...o[p], ...value } : value;
                    // Anwenden von sideload nur, wenn value ein Objekt ist
                    if (typeof value === 'object') {
                        o[p] = { ...o[p], ...additionalInstructions.sideload };
                    }
                }
            }
        } else {
            if (isArrayIndex) {
                const index = parseInt(p, 10);
                if (!Array.isArray(o)) o = [];
                o[index] = o[index] || (nextPartIsArray ? [] : {});
            } else {
                o[p] = o[p] || (nextPartIsArray ? [] : {});
            }
        }
        return isArrayIndex ? o[parseInt(p, 10)] : o[p];
    }, newObject);

    return newObject;
};

export default deepUpdateObject;
