import { generateClient } from 'aws-amplify/api';
import { MDBBtn } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
const client = generateClient();


export default function DeleteDepartment({department, show, handleDeletedDepartment, toggle}) {
    const { t, i18n } = useTranslation();

    const deleteDepartment = async (id) => {
        try {
          const departments = await client.graphql({
            variables: { input: { id: id } },
            query: /* GraphQL */ `
            mutation DeleteDepartment(
              $input: DeleteDepartmentInput!
              $condition: ModelDepartmentConditionInput
            ) {
              deleteDepartment(input: $input, condition: $condition) {
                id
              }
            }
          `
            });
            console.log("deleted department", departments.data.deleteDepartment);
            handleDeletedDepartment(departments.data.deleteDepartment.id)
            return;

        } catch (error) {
            console.log("error on deleting department", error);
            return;
        }
    }
  return show &&
    <>
    <p>
        {t("Are you sure you want to delete this department?")}
    </p>
    <div className="text-center w-100">
    <h6>
        {department?.name.find(name => name.language === i18n.language)?.text || department?.name[0].text}
    </h6>
    <MDBBtn className="mt-2 mt-md-3" onClick={() => {deleteDepartment(department.id); toggle()}} color='danger'>{t("Delete")}</MDBBtn>
    </div>
    
    
</>
} 

