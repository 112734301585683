import {
  MDBBtn,
  MDBCard,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

export default function RegisterLectureLandingPage() {
  return (
    <div>
      <MDBContainer>
        <MDBRow className="bg-image">
          <img
            src="https://mdbootstrap.com/img/new/standard/nature/182.webp"
            className="img-fluid"
            alt="DummyPic"
          />
          <MDBCol size="12" id="hero--image">
            <div
              className="mask"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <h1 className="text-center text-white">
                  Fachwissen präsentieren
                </h1>
                <h1 className="text-center text-white">Netzwerk erweitern</h1>
                <h1 className="text-center text-white">
                  Sichtbarkeit steigern
                </h1>
                <MDBBtn id="hero--button" className="mt-5 btn-secondary">
                  Jetzt Vortrag eintragen
                </MDBBtn>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" id="instruction--section">
            <MDBTypography
              tag="h3"
              id="instruction--heading"
              className="text-center mt-4"
            >
              Jetzt Vortrag eintragen für Messe 2024!
            </MDBTypography>
            <MDBTypography
              id="instruction--sub-heading"
              className="text-center mt-3"
            >
              Werden Sie Teil unserer Vortragsreihe und gestalten Sie die
              Zukunft der Reinraum Branche.
            </MDBTypography>
          </MDBCol>
        </MDBRow>

        <section>
          <MDBRow className="d-flex justify-content-evenly my-5">
            <MDBCol
              size="2"
              id="instuction--step-one"
              className="shadow-3 rounded-3 p-2"
            >
              <div className="text-center my-3">
                <MDBIcon fas size="2x" icon="pen" />
              </div>

              <MDBTypography tag="h5" className="text-center">
                Schritt 1
              </MDBTypography>
              <MDBTypography className="text-center">
                Vortrag Thema Wählen und Vortrag Entwerfen
              </MDBTypography>
            </MDBCol>

            <MDBCol
              size="2"
              id="instuction--step-two"
              className="shadow-3 rounded-3 p-2"
            >
              <div className="text-center my-3">
                <MDBIcon fas size="2x" icon="sign-in-alt" />
              </div>
              <MDBTypography tag="h5" className="text-center">
                Schritt 2
              </MDBTypography>
              <MDBTypography className="text-center">
                Vortrag anmelden mit attraktiver Beschreibung
              </MDBTypography>
            </MDBCol>

            <MDBCol
              size="2"
              id="instuction--step-three"
              className="shadow-3 rounded-3 p-2"
            >
              <div className="text-center my-3">
                <MDBIcon fas size="2x" icon="chalkboard-teacher" />
              </div>
              <MDBTypography tag="h5" className="text-center">
                Schritt 3
              </MDBTypography>
              <MDBTypography className="text-center">
                Vortrag auf der Messe präsentieren
              </MDBTypography>
            </MDBCol>

            <MDBCol
              size="2"
              id="instuction--step-four"
              className="shadow-3 rounded-3 p-2"
            >
              <div className="text-center my-3">
                <MDBIcon fas size="2x" icon="comments" />
              </div>
              <MDBTypography tag="h5" className="text-center">
                Schritt 4
              </MDBTypography>
              <MDBTypography className="text-center">
                Nach dem Vortrag neue kontakte knüpfen
              </MDBTypography>
            </MDBCol>
          </MDBRow>
        </section>

        <section>
          <MDBRow className="mt-5">
            <MDBTypography
              tag="h3"
              id="notice--heading"
              className="text-center"
            >
              Wichtige Hinweise
            </MDBTypography>
          </MDBRow>

          <MDBRow className="mt-3">
            <MDBCol size="6">
              <div className="d-flex justify-content-center mb-3">
                <MDBIcon fas size="3x" icon="calendar-alt" />
              </div>
              <MDBListGroup>
                <MDBListGroupItem>
                  Vollständige Anmeldung des Vortages für CRP 2024 bis
                  spätestens xx.xx.xxxx
                </MDBListGroupItem>
                <MDBListGroupItem>
                  Der genaue Termin und Ort des Vortrags, wird nach der
                  Anmeldung von der Messe festgelegt und Sie werden darüber
                  informiert
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBCol>

            <MDBCol size="6">
              <div className="d-flex justify-content-center mb-3">
                <MDBIcon fas size="3x" icon="cloud-upload-alt" />
              </div>
              <MDBListGroup>
                <MDBListGroupItem>
                  Refferent:in muss auf dieser Plattform als Mitarbeiter:in des
                  Unternehmens eingetragen sein
                </MDBListGroupItem>
                <MDBListGroupItem>
                  Titel-Bild des Vortrags hochladen
                </MDBListGroupItem>
                <MDBListGroupItem>
                  Beschreibung des Vortrages in deutsch und englisch absenden
                </MDBListGroupItem>
                <MDBListGroupItem>
                  Vortrags Skript oder Presentation (pdf) hochladen{" "}
                </MDBListGroupItem>
              </MDBListGroup>
            </MDBCol>
          </MDBRow>
        </section>

        <section>
          <MDBCard className="mt-5 p-3 px-2 bg-primary">
            <MDBTypography tag="h3" className="text-secondary text-center">
              NEU!
            </MDBTypography>
            <MDBTypography tag="h5" className="text-center mb-3">
              Bekommen Sie wertvolle Insights und profitieren Sie nachhaltig an
              Ihrem Vortrag
            </MDBTypography>
            <div className="d-flex justify-content-center gap-4">
              <MDBIcon fas size="2x" icon="video" />
              <MDBIcon fas size="2x" icon="chart-line" />
              <MDBIcon fas size="2x" icon="chart-pie" />
            </div>
            <MDBTypography className="mt-3  text-center">
              Wir filmen Ihren Vortrag und stellen die Aufzeichnugn auf unserer
              Plattform der Branche zur Verfügung.
            </MDBTypography>
          </MDBCard>

          <div className="d-flex justify-content-center mt-5">
            <Link to="/mycompany/:companyid/register-lecture">
              <MDBBtn className="btn-secondary">Eintragung Starten</MDBBtn>
            </Link>
          </div>
        </section>
      </MDBContainer>
    </div>
  );
}
