import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
const client = generateClient();
export default function CancelInvitation(props) {
    const { t, i18n } = useTranslation();
    const { companyId, employee, isActive } = props;

    // const removeEmployee = async () => {
    //     try {
    //      const response = await client.graphql({
    //         variables: {input: {id: employee.id}},
    //         query: /* GraphQL */ `
    //         mutation DeleteEmployee(
    //           $input: DeleteEmployeeInput!
    //           $condition: ModelEmployeeConditionInput
    //         ) {
    //           deleteEmployee(input: $input, condition: $condition) {
    //             id
    //             user {
    //               id
    //               email
    //               firstName
    //               middleName
    //               lastName
    //               salutation
    //               title
    //               birthday
    //               personalIndustry
    //               personalDepartment
    //               x4Id
    //               pw_hash
    //               hasCompletedOnboarding
    //               createdAt
    //               updatedAt
    //               owner
    //               __typename
    //             }
    //             company {
    //               id
    //               name
    //               industry
    //               status
    //               website
    //               x4Id
    //               x4AdminId
    //               createdAt
    //               updatedAt
    //               companyAdminId
    //               __typename
    //             }
    //             department {
    //               nextToken
    //               __typename
    //             }
    //             contact {
    //               phone
    //               fax
    //               email
    //               __typename
    //             }
    //             role
    //             permissions
    //             status
    //             image {
    //               fileName
    //               alt
    //               x4UUID
    //               __typename
    //             }
    //             x4Id
    //             News {
    //               nextToken
    //               __typename
    //             }
    //             WPJetEngineId
    //             createdAt
    //             updatedAt
    //             companyEmployeesId
    //             userEmployeeProfilesId
    //             owner
    //             __typename
    //           }
    //         }
    //       `
    //         });
    //         console.log(response);
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // }


  return (
    <div className="text-center">
    <p>
        {t("Are you sure you want to cancel this invitation?")}
    </p>
    {employee && 
      <div className='d-flex align-items-center'>
    {employee?.image?.fileName ?
      <img
          src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+employee.image.fileName}
          className="rounded-circle border"
          style={{ height: "50px", width: "50px", objectFit: "contain" }}
          alt={employee.image.alt}
      />
      :
      <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
      
    }
      <div className='ms-3'>
      <p className='fw-bold mb-1'>{employee.user.firstName} {employee.user.middleName} {employee.user.lastName}</p>
      <p className='text-muted mb-0'>{employee.user.email}</p>
      </div>
    </div>
    }
     <MDBBtn color="danger" size="sm" className="mt-4">
        {t("Cancel Invitation")}
      </MDBBtn>
    </div>
  )
}
