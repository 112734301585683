import React from "react";
import { useCallback } from "react";
import { MDBIcon } from "mdb-react-ui-kit";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";
import { generateClient } from "aws-amplify/api";
const client = generateClient();

export default function UploadImage({type, setFileName}) {

  const [image, setImage] = React.useState(null);

  const uploadToS3 = async (fileBuffer) => {
    try {
      const res = await client.graphql({
        query: /* GraphQL */ `
        mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
          uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
        }
      `,
        variables: {
          imageBuffer: fileBuffer,
          imageType: type,
        },
      });
      console.log("res", res);
      const fileName = res.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
      setImage(fileName);
      setFileName(fileName);

    } catch (error) {
      console.log("error on uploading image", error);
    }
  };

  const uploadImage = async (file) => {
    try {
      console.log("file", file)
      if(!type) throw new Error("No type provided");
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const base64StringWithMime = reader.result;

        // Extrahieren Sie nur den Base64-String
        const base64String = base64StringWithMime.split(',')[1];
        uploadToS3(base64String);
      }
      reader.readAsDataURL(file)
      
    }
    catch (error) {
      console.log("error on uploading image", error);
    }

  };

  const StateProofImage = React.memo(function Image({
    src,
    alt,
    className,
    style,
  }) {
    return <img src={src} alt={alt} className={className} style={style} />;
  });

  const onDrop = useCallback((acceptedFiles) => {
    uploadImage(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="container d-flex justify-content-center">
      <div {...getRootProps({ className: "dropzone text-center" })}>
        <input {...getInputProps()} />
        <StateProofImage
          alt={"Company Image"}
          src={image ? "//iec-cp-public.s3.eu-central-1.amazonaws.com/"+type+"s/" + image : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
          className="img-thumbnail"
          style={{ height: "200px", width: "400px", objectFit: "contain" }}
        />
        <br></br>
        <MDBIcon icon="cloud-upload-alt" className="mt-3" />
        <p>Datei auswählen oder reinziehen</p>
      </div>
    </div>
  );
}
