import { fetchAuthSession  } from "aws-amplify/auth";
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBFile, MDBIcon, MDBInput, MDBRow, MDBSelect, MDBSpinner } from "mdb-react-ui-kit"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
const client = generateClient();
async function getIdToken() {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    console.log("idToken", idToken)
    return idToken;
}

export default function AddEmployees(props) {
    const { t, i18n } = useTranslation();
    const { companyId, isActive, reloadEmployees } = props;

    

    const [hasSearchedUser, setHasSearchedUser] = useState(false);
    const [searchedUser, setSearchedUser] = useState(null);



    // Search user by email
    const searchUser = async () => {
        try {
            const user = await client.graphql({
                variables: { filter: { email: { eq: userInput.email } }, limit: 1 },
                query: /* GraphQL */ `
                query SearchUsers(
                    $filter: SearchableUserFilterInput
                    $sort: [SearchableUserSortInput]
                    $limit: Int
                    $nextToken: String
                    $from: Int
                    $aggregates: [SearchableUserAggregationInput]
                  ) {
                    searchUsers(
                      filter: $filter
                      sort: $sort
                      limit: $limit
                      nextToken: $nextToken
                      from: $from
                      aggregates: $aggregates
                    ) {
                        nextToken
                        items {
                            email
                            lastName
                            middleName
                            image {
                            fileName
                            alt
                            }
                            id
                            firstName
                            title
                            personalDepartment
                            personalIndustry
                            employeeProfiles(filter: {companyEmployeesId: {eq: "${companyId}"}}) {
                                items {
                                  id
                                  company {
                                    name
                                    status
                                    id
                                  }
                                }
                              }
                        }
                    }
                }
                `
            });
            console.log("user", user)
            if (user.data.searchUsers.items.length > 0) {
                setSearchedUser(user.data.searchUsers.items[0]);
                setHasSearchedUser(true);
            } else {
                setSearchedUser(null);
                setHasSearchedUser(true);
            }

        } catch (error) {
            console.log("error", error)
        }
    }


    // Handle click on button
    const handleClick = e => {
        searchUser();
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchUser();
        }
      }


    useEffect(() => {
        if(!isActive) {
            setHasSearchedUser(false);
            setSearchedUser(null);
        }
    }
    , [isActive]);

    // Invite User
    const [isLoadingInviteExistingUser, setIsLoadingInviteExistingUser] = useState(false);
    const inviteUser = async () => {
        const idToken = await getIdToken();
        setIsLoadingInviteExistingUser(true);
        try {
            const response = await fetch('https://y3vayhw2i5bqrm6fpwqz7fzfwe.appsync-api.eu-central-1.amazonaws.com/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken
                },
                body: JSON.stringify({
                    query: /* GraphQL */ `
                    mutation InviteEmployee(
                      $userId: String
                      $companyId: String
                      $departmentId: String
                      $role: String
                      $existingUser: Boolean
                      $newUser: String
                      $newEmployee: String
                    ) {
                      inviteEmployee(
                        userId: $userId
                        companyId: $companyId
                        departmentId: $departmentId
                        role: $role
                        existingUser: $existingUser
                        newUser: $newUser
                        newEmployee: $newEmployee
                      )
                    }
                  `,
                    variables: {
                        userId: searchedUser.id,
                        companyId: companyId,
                        // departmentId: "XXX",
                        role: userInput.role,
                        existingUser: true
                    }
                })
            });
        
            const responseData = await response.json();
            console.log("responseData", responseData)
            // Update searched user to show that he is invited successfully
            setSearchedUser(old => ({ ...old, invited: true}));
            setTimeout(() => {
                reloadEmployees()
            }, 1500);

        } catch (error) {
            console.log("error", error)
        }
        setIsLoadingInviteExistingUser(false);
    }

    const [userInput, setUserInput] = useState({
        email: "",
        firstName: "",
        title: "",
        salutation: "",
        middleName: "",
        lastName: "",
        position: "",
        role: "",
        image: {
            fileName: "",
            alt: ""
        },
        invited: false
    });

    const [isLoadingInviteNewUser, setIsLoadingInviteNewUser] = useState(false);
    const inviteNewUser = async () => {
        const idToken = await getIdToken();
        setIsLoadingInviteNewUser(true)
        try {
            const response = await fetch('https://y3vayhw2i5bqrm6fpwqz7fzfwe.appsync-api.eu-central-1.amazonaws.com/graphql', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken
                },
                body: JSON.stringify({
                    query: /* GraphQL */ `
                    mutation InviteEmployee(
                      $userId: String
                      $companyId: String
                      $departmentId: String
                      $role: String
                      $existingUser: Boolean
                      $newUser: String
                      $newEmployee: String
                    ) {
                      inviteEmployee(
                        userId: $userId
                        companyId: $companyId
                        departmentId: $departmentId
                        role: $role
                        existingUser: $existingUser
                        newUser: $newUser
                        newEmployee: $newEmployee
                      )
                    }
                  `,
                    variables: {

                        companyId: companyId,
                        existingUser: false,
                        newUser: JSON.stringify({
                            email: userInput.email,
                            title: userInput.title,
                            salutation: userInput.salutation,
                            firstName: userInput.firstName,
                            middleName: userInput.middleName,
                            lastName: userInput.lastName,
                            image: userInput.image
                        }),
                        newEmployee: JSON.stringify({
                            role: userInput.role,
                            image: userInput.image
                        })
                    }
                })
            });
        
            const responseData = await response.json();
            console.log("responseData", responseData)
            // Update user to show that he is invited successfully
            setUserInput(old => ({ ...old, invited: true}));
            setTimeout(() => {
                reloadEmployees()
            }, 2000);
        } catch (error) {
            console.log("error", error)
        }
        setIsLoadingInviteNewUser(false);
}

 // User Image
 const [isUploadingImage, setIsUploadingImage] = useState(false);
 const [uploadingImageError, setUploadingImageError] = useState(null);
 
 const handleSetImage = (file) => {
   // upload file using mutation: uploadFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadFiles-${env}")
   if(file) {
     try {
       setIsUploadingImage(true);
     const reader = new FileReader();
     reader.readAsDataURL(file);
     reader.onerror = error => {
       console.log('Error reader: ', error);
       setUploadingImageError(error);
     }
     reader.onload = async () => {
       try {
         console.log(reader.result)
         const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
         const response = await client.graphql({
           query: /* GraphQL */ `
           mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
             uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
           }
         `,
           variables: {
             imageBuffer: base64FileBuffer,
             imageType: "userProfileImage",
           },
         });
         console.log("response", response);
         const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
         setUserInput(old => ({ ...old, image: { fileName, alt: userInput.firstName + " " + userInput.lastName } }));
         } catch (error) {
           console.log("error", error);
           setIsUploadingImage(false);
           setUploadingImageError(error.errors[0].message);
         }
     }
     setIsUploadingImage(false);
     setUploadingImageError(null);
     } catch (error) {
       console.log("error", error);
       setIsUploadingImage(false);
       setUploadingImageError(error.errors[0].message);
     }
   }
 }

return (
<>
<MDBCardText>
    {t("To invite a new employee, please search for the user by email address, then invite him to your company.")}
    </MDBCardText>
<MDBRow className="mb-3">
    <MDBCol size="12" className="text-center">
        <MDBInput type='email' autoComplete="off" datapath="email" label={t("E-mail address")} onChange={e => setUserInput({ ...userInput, email: e.target.value })} onKeyDown={handleKeyDown} />
        <MDBBtn className="mt-2" onClick={e => handleClick(e)}>{t("Search")}</MDBBtn>
    </MDBCol>
    
</MDBRow>
{hasSearchedUser && (searchedUser ?
<>
<div className="text-center">
<MDBCard className="text-center shadow-5-strong m-4">
    <MDBCardBody>
    
    <div className='d-flex align-items-center text-start'>
    {searchedUser?.image?.fileName ?
        <img
            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+searchedUser.image.fileName}
            className="rounded-circle border"
            style={{ height: "50px", width: "50px", objectFit: "contain" }}
            alt={searchedUser.image.alt}
        />
        :
        <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                
    }
    <div className='ms-3'>
    <p className='fw-bold mb-1'>{searchUser.title ? searchUser.title + " " : ""}{searchedUser.firstName} {searchedUser.middleName} {searchedUser.lastName}</p>
    <p className='text-muted mb-0'>{searchedUser.email}</p>
    </div>
    <div className='ms-auto'>
    {searchedUser.employeeProfiles.items.length > 0 ? 
        <MDBBtn disabled color="danger" className="mt-2">{t("Already in Company")}</MDBBtn> 
        : isLoadingInviteExistingUser ?
        <span><MDBSpinner role="status" /></span>
        :
        searchedUser.invited ? 
        <MDBBtn disabled color="success" className="mt-2">{t("Invited")}</MDBBtn>
        :
        <MDBBtn className="mt-2" onClick={() => inviteUser()}>{t("Invite")}</MDBBtn>
    }
    
    </div>
    </div>
    <MDBSelect
    className="mt-3"
    onChange={ input => input?.value && setUserInput(old => ({...old, role: input.value}))}
        data={[
            { text: t("Role"), value: "", disabled: true, defaultSelected: true},
            { text: 'Admin', value: "admin" },
            { text: 'Moderator', value: "moderator" },
            { text: 'Autor', value: "writer" },
            { text: 'Mitarbeiter', value: "employee" },
        ]}
    />
    </MDBCardBody>
</MDBCard>
</div>
</>
 : 
<>
<h6>{t("User was not found, please add information:")}</h6>
<div className="text-center">
<h6>{t("Bild")}</h6>
    <div className="w-100 text-center mb-2 mb-lg-4">
        <img
        src={userInput?.image?.fileName ? 
        "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" + userInput.image.fileName: "https://via.placeholder.com/400x400"}
        className="img-thumbnail rounded-circle border"
        style={{ height: "150px", objectFit: "cover" }}
        alt='...' />
    </div>
    <MDBBtn color="primary" size="sm" className="w-50 mb-2 mb-lg-4" onClick={() => document.getElementById("image").click()}>{t("Upload Image")}</MDBBtn>
    <MDBFile className="d-none" id='image' onChange={e => handleSetImage(e.target.files[0])} />
    {
    isUploadingImage.De && <span><MDBSpinner role="status" /></span>
    }
    {
    uploadingImageError && <span className="mt-3 text-danger fw-bold">
    {uploadingImageError}
    </span>
    }
    <h5>{t("Name")}</h5>
    <MDBRow className="mb-3">
        <MDBCol size="6" className="mb-2">
            <MDBInput autoComplete="off" datapath="salutation" label={t("Salutation")} onChange={e => setUserInput(old => ({...old, salutation: e.target.value}))} value={userInput.salutation} />
        </MDBCol>
        <MDBCol size="6" className="mb-2">
            <MDBInput autoComplete="off" datapath="title" label={t("Title")} onChange={e => setUserInput(old => ({...old, title: e.target.value}))} value={userInput.title} />
        </MDBCol>
        
        <MDBCol size="12" md="6" className="mb-2">
            <MDBInput autoComplete="off" datapath="firstName" label={t("First Name")} onChange={e => setUserInput(old => ({...old, firstName: e.target.value}))} value={userInput.firstName} />
        </MDBCol>
        <MDBCol size="12" md="6" className="mb-2">
            <MDBInput autoComplete="off" datapath="lastName" label={t("Last name")} onChange={e => setUserInput(old => ({...old, lastName: e.target.value}))} value={userInput.lastName} />
        </MDBCol>
    </MDBRow>
    <h5>{t("Position")}</h5>
    <MDBRow className="mb-3">
    {/* <MDBCol size="6">
    <MDBSelect
        data={[
            { text: 'Abteilung', value: "Abteilung", defaultSelected: true },
            { text: 'Support', value: "Support" },
            { text: 'Sonstiges', value: "Sonstiges" }
        ]}
    />
    
    </MDBCol> */}
    <MDBCol size="12">
   
    <MDBSelect
    onChange={ input => input?.value && setUserInput(old => ({...old, role: input.value}))}
    data={[
        { text: t("Role"), value: "", disabled: true, defaultSelected: true},
        { text: 'Admin', value: "admin" },
        { text: 'Moderator', value: "moderator" },
        { text: 'Autor', value: "writer" },
        { text: 'Mitarbeiter', value: "employee" },
    ]}
    />
    </MDBCol>
    </MDBRow>
    {
        isLoadingInviteNewUser ?
        <span><MDBSpinner role="status" /></span>
        :
        userInput.invited ?
        <MDBBtn disabled color="success" className="mt-2">{t("Invited")}</MDBBtn>
        :
        <MDBBtn className="mt-2" onClick={() => inviteNewUser()}>{t("Invite")}</MDBBtn>
    }
</div>
</>)}
    
</>
        
)
}
