import { useState, useEffect } from 'react';
import { signIn } from 'aws-amplify/auth';
import { MDBInput, MDBBtn, MDBRow, MDBCol, MDBCheckbox, MDBIcon, MDBCard, MDBCardBody, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane } from 'mdb-react-ui-kit';
import { useTranslation } from "react-i18next";
import { generateClient, put } from 'aws-amplify/api';
import { hotjar } from "react-hotjar";




const client = generateClient();

const ConfirmCustomSignUp = ({authRoute, setAuthRoute, isStepConfirm, setIsStepConfirm}) => {
  const { t, i18n } = useTranslation();
  const [ newUser, setNewUser ] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: ''
  });
  const [ emailStatus, setEmailStatus ] = useState(null);
  const [error, setError ] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    // URL parameters
    const [params, setParams] = useState();
    useEffect(() => {
        const url = new URL(window.location.href);

        // Extracting the specific query parameters
        setParams({
            verificationCode: url.searchParams.get("verificationCode"),
            email: url.searchParams.get("email"),
            //redirectURI: url.origin + url.pathname // This forms the base URL without query parameters
        });
        hotjar.identify(url.searchParams.get("email"), {verificationCode: url.searchParams.get("verificationCode")})
    
    }, []);

  const confirmSignUpHandler = async () => {
    if(!params?.password || params.password.length < 8) {
      setError(t("Password must be at least 8 characters long"));
      return;
    }
    // if(isLoading) return;
    setIsLoading(true);
    try {
      const restOperation = put({
      apiName: 'CPRESTAPI',
      path: '/user/confirmcustomsignup',
      options: {
       
        body: {
          email: params?.email,
          verificationCode: params?.verificationCode,
          newPassword: params?.password
        },
      }
    });
    const response = await restOperation.response;
    const data = await response.body.json();
    const statusCode = response.statusCode;
    console.log('response', response);
    
    if(statusCode === 200) {
      hotjar.event("Confirmed custom sign up");
      signIn({ username: params?.email, password: params?.password });
    } else if (statusCode === 205) {
      console.log('error', data);
      setError(data.message);
      hotjar.event("Error confirming custom sign up: " + data.message);
    }
      
    } catch (error) {
      console.error('error', error);

      setEmailStatus("error");
      hotjar.event("Error confirming custom sign up: " + error.message);
    }
    setIsLoading(false);
  };









  return (
   <MDBRow className="d-flex align-items-center m-0" style={{height: "100vh"}}>
    <MDBCol size="12" className="d-flex align-items-center justify-content-center" style={{height: "100%", backgroundColor: "#e6f4ff"}}>
        <MDBCard className="col-12 col-md-8 col-xl-6 col-xxl-4">
            <MDBCardBody>

            {/* <MDBTabs pills justify className='mb-3'>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signIn")}
                >
                    Login
                </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signUp")}
                    active
                >
                    Register
                </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs> */}

            <MDBTabsContent>          
                <MDBTabsPane open={true}>

               
                <h1>{t("Migrate Account")}</h1>
                <h4>{t("Complete your account by setting a password")}</h4>
                    <MDBInput disabled label={t("E-Mail")} id="email" data-hj-allow type="email" value={params?.email} />
                    <MDBInput className="mt-2" label={t("Password")} id="password" type="text" value={params?.password || ""} onChange={e => setParams(old => ({...old, password: e.target.value}))} />
                    <div className="text-center w-100 mt-3">
                    <MDBBtn color="primary" onClick={confirmSignUpHandler}>{t("Confirm")}</MDBBtn>
                    </div>
                    {error && <div className="alert alert-danger mt-2 text-center">{t(error)}</div>}
                </MDBTabsPane>
            </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    
   </MDBRow>
  );
};

export default ConfirmCustomSignUp;