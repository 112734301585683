import { useState, useEffect } from 'react';
import { signUp, confirmSignUp, signIn, resendSignUpCode  } from 'aws-amplify/auth';
import { MDBInput, MDBBtn, MDBRow, MDBCol, MDBCheckbox, MDBIcon, MDBCard, MDBCardBody, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBCardTitle, MDBFooter } from 'mdb-react-ui-kit';
import { useTranslation } from "react-i18next";
import { generateClient, put } from 'aws-amplify/api';
import useDebounce from '../../utils/debounce';
import PhoneInput from "react-phone-input-2";
import LangSelect from "../LangSelect";
import { hotjar } from "react-hotjar";
import { useIndustry } from "../../utils/industryContextHook";
import Footer from "./Footer";



const client = generateClient();

const SignUp = ({authRoute, setAuthRoute, isStepConfirm, setIsStepConfirm, email, password}) => {
  const { currentIndustry } = useIndustry();
  const { t, i18n } = useTranslation();
  const [ newUser, setNewUser ] = useState({
    email: email || '',
    password: password || '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    confirmationCode: ''
  });
  const [emailForConfirmation, setEmailForConfirmation] = useState(email || null);
  const [ emailAvailable, setEmailAvailable ] = useState(null);
  const [ emailAlreadyExists, setEmailAlreadyExists ] = useState(false);
  const [error, setError] = useState(null);
  const [resendInvitationStatus, setResendInvitationStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeInput = (e) => {
    setNewUser({
      ...newUser,
      [e.target.id]: e.target.value
    });
  };
  const [isEmailValid, setIsEmailValid] = useState(false);
  const debouncedEmail = useDebounce(newUser.email, 1000); // 1 Sekunde Verzögerung

  // E-Mail-Validierungsfunktion
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    // E-Mail validieren, wenn debouncedEmail sich ändert
    if (debouncedEmail.includes('@')) {
      setIsEmailValid(validateEmail(debouncedEmail));
    } else {
      setIsEmailValid(false);
      setEmailAvailable(false);
    }
  }, [debouncedEmail]);

  const checkEmailAvailable = async () => {
    try {
      const restOperation = put({
      apiName: 'CPRESTAPI',
      path: '/user/checkemailavailable',
      options: {
       
        body: {
          email: newUser.email
        },
      }
    });
    const responseCode = await (await restOperation.response).statusCode;
    if(responseCode === 200) {
      setEmailAvailable(true);
    } else if (responseCode === 204) {
      setEmailAvailable(false);
      setEmailAlreadyExists(true);
    }
      
    } catch (error) {
      console.error('error', error);
      setEmailAvailable(false);
    }
  };

  useEffect(() => {
    if (isEmailValid) {
      checkEmailAvailable();
    }
  }, [isEmailValid, debouncedEmail]);


  async function handleSignUp() {
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: newUser.email,
        password: newUser.password,
        options: {
          userAttributes: {
            email: newUser.email,
            phone_number: newUser.phone,
            given_name: newUser.firstName,
            family_name: newUser.lastName,
            middle_name: newUser.middleName
          },
          // optional
          autoSignIn: true // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        }
      });
  
      console.log(userId);
      hotjar.identify(userId, {email: newUser.email})
      hotjar.event("Signed up");
      console.log(isSignUpComplete);
      console.log(nextStep);
      if(!isSignUpComplete && nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        setIsStepConfirm(true);
        setEmailForConfirmation(newUser.email);
      }
    } catch (error) {
      console.log('error signing up:', error);
      hotjar.event("Error signing up");
      if(newUser.email) hotjar.identify(newUser.email);
      setError(error);
    }
  }

  async function handleSignUpConfirmation() {
    setIsLoading(true);
    try {
      const { isSignUpComplete, nextStep } = await confirmSignUp({
        username: newUser.email,
        confirmationCode: newUser.confirmationCode
      });
      await signIn({ username: newUser.email, password: password || newUser.password });
      hotjar.event("Confirmed sign up");
    } catch (error) {
      console.log('error confirming sign up', error);
      hotjar.event("Login failed")
      if(newUser.email) hotjar.identify(newUser.email);
      setError(error);
      if(error.message === "Incorrect username or password.") {
        setAuthRoute("signIn");
      }
    }
    setIsLoading(false);
  }

 


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await handleSignUp();
    } catch (error) {
      console.error(error);
    }
  };

  // Detect if user is in booking process
  const [bookingProcess, setBookingProcess] = useState(false);
  const [bookingProcessData, setBookingProcessData] = useState({});

  useEffect(() => {
    let params = new URL(window.location.href).searchParams;
    if(window.location.href.includes("checkout")) {
      setBookingProcess(true);
      setBookingProcessData({
          productName: params.get('productName'),
          
      });
  }
  if(window.location.href.includes("book-ticket")) {
    setBookingProcess(true);
    setBookingProcessData({
        productName: "Lounges 2024",
        productDescription: params.get('ticketType') === "default" ? "Visitor Ticket" : "Premium ticket",
    });
  }
}, []);


  return (
    <>

{bookingProcess &&
<div className="d-flex justify-content-center" >
    <MDBCard className="col-md-8 col-xl-6 col-xxl-4 mt-1 mt-xl-3" style={{position: "absolute"}}>
        <MDBCardBody className="py-1 text-center">
        <MDBCardTitle>{t("Your booking:")}</MDBCardTitle>
            <div className='d-flex align-items-center w-100'>
            <div className='d-flex align-items-center text-start mx-auto'>
            <MDBIcon style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="shopping-cart" />
            <div className='ms-3'>
            <p className='fw-bold mb-1'>{t(bookingProcessData.productName)}</p>
            <p className='text-muted mb-0'>{t(bookingProcessData.productDescription)}</p>
            </div>
            </div>
            </div>
            <div className="alert alert-info py-2 mx-auto mt-1" style={{width: "fit-content"}}>
                <h6 className="mb-0">{t("Please sign in to continue")}</h6>
            </div>
        </MDBCardBody>

    </MDBCard>
</div>
}
<MDBRow className="d-flex align-items-center m-0" style={{
    height: "100vh",
    backgroundImage: "url(https://iec-cp-public.s3.eu-central-1.amazonaws.com/2_2500x488px_lounges_ka_2024.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    }}>
    <MDBCol size="12" className="d-flex align-items-center justify-content-center" style={{height: "100%"}}>
        <MDBCard className="col-12 col-md-8 col-xl-6 col-xxl-4">
            <MDBCardBody>

            <MDBTabs pills justify className='mb-3'>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signIn")}
                >
                    Login
                </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signUp")}
                    active
                >
                    Register
                </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>

            <MDBTabsContent>          
                <MDBTabsPane open={true}>

               
                { !isStepConfirm ?
                <>
                <h1>{t("Register")}</h1>
                <form onSubmit={handleSubmit}>
                    <MDBInput className='mb-4' type='email' id='email' label='Email address' value={newUser.email} onChange={handleChangeInput} />
                    {emailAlreadyExists ?
                    <div className="alert alert-danger">
                      {t("Email already exists, please try to log in or use another email address.")}
                      <br></br>
                      <MDBBtn color="primary" onClick={() => setAuthRoute("signIn")}>{t("Login")}</MDBBtn>
                    </div>
                    : <>
                    <MDBRow>
                        <MDBCol size="6" md="4">
                        <MDBInput disabled={!emailAvailable} className='mb-4' type='text' id='firstName' label={t("First Name")} value={newUser.firstName} onChange={handleChangeInput} />
                        </MDBCol>
                        <MDBCol size="6" md="4">
                        <MDBInput disabled={!emailAvailable} className='mb-4' type='text' id='middleName' label={t("Middle Name")} value={newUser.middleName} onChange={handleChangeInput} />
                        </MDBCol>
                        <MDBCol size="6" md="4">
                        <MDBInput disabled={!emailAvailable} className='mb-4' type='text' id='lastName' label={t("Last Name")} value={newUser.lastName} onChange={handleChangeInput} />
                        </MDBCol>
                    </MDBRow>
                    <PhoneInput disabled={!emailAvailable} className="mb-4" country={'de'} enableSearch value={newUser.phone}  onChange={e => setNewUser(old => ({...old, phone: "+" + e}))} />

                    <MDBInput disabled={!emailAvailable} className='mb-4' type='password' id='password' label='Password' value={newUser.password} onChange={handleChangeInput} />
                    <MDBInput disabled={!emailAvailable} className='mb-4' type='password' id='passwordConfirm' label={t("Confirm Password")} value={newUser.passwordConfirm} onChange={handleChangeInput} />
                    </>
                  }
                    <MDBBtn type='submit' className='mb-4' block>{t("Register")} </MDBBtn>
                </form>
                {error && <div className="alert alert-danger">{t(error.message)}</div>}
                </>
                : <div>
                  <h2>{t("Registration successful")}</h2>
                  <h4>{t("Please check your email for a confirmation code.")}</h4>
                  <p>{t("If you haven't received an email, click here or contact our support.")} <span className="text-primary" style={{cursor: "pointer"}} onClick={() => resendSignUpCode({username: newUser.email || email})}>{t("Resend")}</span></p>

                  {!emailForConfirmation && <MDBInput className='mb-4' type='email' id='email' label='Email address' value={newUser.email} onChange={handleChangeInput} />}
                  <MDBInput className='mb-4' type='text' id='confirmationCode' label={t("Confirmation code")} value={newUser.confirmationCode} onChange={handleChangeInput} />
                 
                  {
                    isLoading ? <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                    </div> :
                    <MDBBtn onClick={handleSignUpConfirmation} className='mb-4' block>{t("Confirm")} </MDBBtn>
                  }

                  
                  {error && <div className="alert alert-danger text-center">{t(error.message)}</div>}
                  {resendInvitationStatus === "sent" && <div className="alert alert-success text-center">{t("Invitation email sent")}</div>}
                </div>
                }
                </MDBTabsPane>
            </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    
   </MDBRow>
   <LangSelect style={{bottom: "0px", right: "0px", position: "absolute"}} />
   <Footer currentIndustry={currentIndustry} />
   </>
  );
};

export default SignUp;