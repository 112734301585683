import { useState, useEffect, useContext } from "react"
  import { useTranslation } from "react-i18next"
  import { generateClient } from "aws-amplify/api"
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from "mdb-react-ui-kit"
import { Link } from "react-router-dom"
import { isoToGermanDate } from "../../utils/dateTools"

export default function AllEvents() {
    const { t, i18n } = useTranslation()
    const client = generateClient()

    // states
    const [isLoading, setIsLoading] = useState(true)
    const [events, setEvents] = useState([])

    // fetch all events
    const fetchEvents = async () => {
      try {
        const response = await client.graphql({
            query: /* GraphQL */ `
            query ListEvents(
              $filter: ModelEventFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                  id
                  name {
                    language
                    text
                  }

                  bookingNotice {
                    language
                    text
                  }
                  status
                  image {
                    fileName
                    alt
                  }
                  date
                  startDate
                  endDate
                  location {
                    name {
                        language
                        text
                    }
                 }
                  lectureRegistrationStartDate
                  lectureRegistrationEndDate
                  lecturesNotice {
                    language
                    text
                  }
                  availableLectureTypes
                  
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
            }
          `
            })
            // Set events sorted by startDate
        setEvents(response.data.listEvents.items.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)))
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    }

    useEffect(() => {
      fetchEvents()
    }, [])
  return (
<>
{/* <MDBRow>
    <MDBCol>
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>{t("All Products")}<Link to={`/admin/product/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create product")}</MDBBtn></Link></MDBCardTitle>
                <MDBTable align="middle">
                    <MDBTableHead>
                        <tr>
                            <th>{t("Image")}</th>
                            <th>{t("Name")}</th>
                            <th>{t("SKU")}</th>
                            <th>
                                {t("Stock")}
                                <br />
                                <span style={{fontSize: "11px"}}>{t("Total")} | {t("Ordered")} | {t("Available")}</span>
                                </th>
                            <th>{t("Actions")}</th>
                            
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {products?.map((product, index) => (
                            <tr key={index}>
                                <td>
                                <div className='d-flex align-items-center'>
                                    {product.image?.main?.fileName ?
                                        <img
                                            src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/"+product.image.main.fileName}
                                            className=""
                                            style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                            alt={product.image.alt}
                                        />
                                    :
                                        <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                
                                    }
                                </div>
                                </td>
                                <td>{product.name.find(n => n.language === i18n.language)?.text || product.name[0]?.text}</td>
                                <td>{product.sku}</td>
                                <td>
                                <span>{product.stock + product.orderLineItems?.items.reduce((acc, item) => acc + item.qty, 0)}</span> | <span>{product.orderLineItems?.items.reduce((acc, item) => acc + item.qty, 0)}</span> | <span className="fw-bold">{product.stock}</span> 
                                    <br />
                                   
                                    </td>
                                <td>
                                    <MDBBtnGroup>
                                        <MDBBtn color='info' size='sm' onClick={() => handleViewProduct(product.id)}><MDBIcon fas icon='eye' /></MDBBtn>
                                        <Link to={`/admin/product/${product.id}`}><MDBBtn color='info' size='sm' onClick={() => console.log("edit", product)}><MDBIcon fas icon='edit' /></MDBBtn></Link>
                                        <MDBBtn color='danger' size='sm' onClick={() => handleDeleteProduct(product.id)}><MDBIcon fas icon='trash-alt' /></MDBBtn>
                                    </MDBBtnGroup>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow> */}
<MDBRow>
    <MDBCol>
        <MDBCard>
            <MDBCardBody>
                <MDBCardTitle>{t("All Events")}<Link to={`/admin/event/create`}><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create event")}</MDBBtn></Link></MDBCardTitle>
                <MDBTable align="middle">
                    <MDBTableHead>
                        <tr>
                            <th>{t("Date")}</th>
                            <th>{t("Name")}</th>
                           
                            <th>{t("Location")}</th>
                            <th>{t("Actions")}</th>
                            
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {events?.map((event, index) => (
                            <tr key={index}>
                                <td>{event.startDate && isoToGermanDate(event.startDate)} - {event.endDate && isoToGermanDate(event.endDate)}</td>
                                <td>{event.name.find(n => n.language === i18n.language)?.text || event.name[0]?.text}</td>

                                
                                <td>{event.location?.name.find(n => n.language === i18n.language)?.text || event.location?.name[0]?.text}</td>
                                
                                <td>
                                    <MDBBtnGroup>
                                        <Link to={`/admin/event/${event.id}`}><MDBBtn color='primary' outline size='sm' onClick={() => console.log("edit", event)}><MDBIcon fas icon='edit' /></MDBBtn></Link>
                                    </MDBBtnGroup>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>
</>
  )
}
