import React, { useState, useEffect, useCallback } from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBTextArea, MDBBtn, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody   } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';

import deleteDepartment from "./DeleteDepartment";
import DeleteDepartment from "./DeleteDepartment";
import CreateDepartment from "./CreateDepartment";

const client = generateClient();
export default function Departments() {
    const { t, i18n } = useTranslation();
    const companyid = window.location.pathname.includes("mycompany") ? window.location.pathname.split("/")[2] : window.location.pathname.split("/")[3];

    const [departments, setDepartments] = useState([]);

    const getDepartments = async () => {
        try {
          const departments = await client.graphql({
            variables: { filter: { companyDepartmentsId: { eq: companyid } }, limit: 2000 },
            query: /* GraphQL */ `
            query ListDepartments(
              $filter: ModelDepartmentFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                  id
                  name {
                    language
                    text
                    }
                  status
                  companyDepartmentsId
                  employees {
                    items {
                      employee {
                        image {
                          fileName
                          alt
                        }
                        user {
                          lastName
                          middleName
                          firstName
                          title
                        }
                      }
                    }
                  }
                }
                nextToken
                __typename
              }
            }
          `
            });
            setDepartments(departments.data.listDepartments.items);
            console.log("departments", departments.data.listDepartments.items);

        } catch (error) {
            console.log("error on fetching departments", error);
            
        }
    }

    const addDepartment = async () => {
        try {
          const departments = await client.graphql({
            variables: { input: { name: { language: "en", text: "New Department" }, status: "active", companyDepartmentsId: companyid } },
            query: /* GraphQL */ `
            mutation CreateDepartment(
              $input: CreateDepartmentInput!
              $condition: ModelDepartmentConditionInput
            ) {
              createDepartment(input: $input, condition: $condition) {
                id
                  name {
                    language
                    text
                    }
                  status
                  companyDepartmentsId
                  employees {
                    items {
                      employee {
                        image {
                          fileName
                          alt
                        }
                        user {
                          lastName
                          middleName
                          firstName
                          title
                        }
                      }
                    }
                  }
              }
            }
          `
            });
            console.log("new department", departments.data.createDepartment);
            setDepartments([...departments, departments.data.createDepartment]);


        } catch (error) {
            console.log("error on creating department", error);
            
        }
    }

    // Delete department modal
    const [showDeleteDepartment, setShowDeleteDepartment] = useState(false);
    const [departmentToDelete, setDepartmentToDelete] = useState(null);

    const toggleDeleteDepartment = (id) => {
      console.log("langs:", i18n)
      setShowDeleteDepartment(!showDeleteDepartment);
      setDepartmentToDelete(departments?.find(department => department.id === id));
  }

  const handleDeletedDepartment = (id) => {
    console.log("deleted department", id);
    setDepartments(departments.filter(department => department.id !== id));
  }

    // Create department modal
    const [showCreateDepartment, setShowCreateDepartment] = useState(false);
    
    const toggleCreateDepartment = () => {
      setShowCreateDepartment(!showCreateDepartment);
    }

    const handleCreatedDepartment = (department) => {
      console.log("created department", department);
      setDepartments([...departments, department]);
    }


    useEffect(() => {
        getDepartments();
    }, []);

  return (
<>
<MDBCardTitle>{t("Departments")} <MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light' onClick={() => toggleCreateDepartment()}>{t("Add Department")}</MDBBtn></MDBCardTitle>
<MDBRow>
{departments && departments.map((department, index) => (
    <MDBCol md="4" key={index} className="mb-3">
        <MDBCard>
            <MDBCardBody><MDBBtn color='none' tag='a' onClick={() => toggleDeleteDepartment(department.id)} className="float-end text-danger"><MDBIcon icon="trash" /></MDBBtn>
            <MDBCardTitle>{department.name.find(name => name.language === i18n.language)?.text || department.name[0].text}</MDBCardTitle>
            
            {department.employees.items.map((employee) => {
                
                if(employee.employee?.image?.fileName) {
                    return <img
                        src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+employee.employee.image.fileName}
                        className="rounded-circle border"
                        style={{ height: "50px", width: "50px", objectFit: "contain" }}
                        alt={employee.employee.image.alt}
                    />
                } else {
                    return <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                }
       
                }
            )}
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
))}
</MDBRow>
  <MDBModal open={showDeleteDepartment} onClose={() => setShowDeleteDepartment(false)}>
    <MDBModalDialog centered size="sm">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Delete Department")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteDepartment}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <DeleteDepartment department={departmentToDelete} show={showDeleteDepartment} handleDeletedDepartment={handleDeletedDepartment} toggle={toggleDeleteDepartment} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
<MDBModal open={showCreateDepartment} onClose={() => setShowCreateDepartment(false)}  tabIndex='-1'>
    <MDBModalDialog centered size="md">
        <MDBModalContent>
        <MDBModalHeader>
            <MDBModalTitle>{t("Create Department")}</MDBModalTitle>
            <MDBBtn className='btn-close' color='none' onClick={toggleCreateDepartment}></MDBBtn>
        </MDBModalHeader>
        <MDBModalBody>
        <CreateDepartment show={showCreateDepartment} handleCreatedDepartment={handleCreatedDepartment} toggle={toggleCreateDepartment} />
        </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
  )
}
