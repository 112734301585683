import { useEffect } from 'react';

export const useThemeDomain = () => {
  useEffect(() => {
    const hostname = window.location.hostname.toLowerCase();
    
    // Remove any existing theme classes first
    document.body.classList.remove('hd-theme');
    
    // Function to change favicon
    const changeFavicon = (link) => {
      let favicon = document.querySelector('link[rel="icon"]');
      if (!favicon) {
        favicon = document.createElement('link');
        favicon.rel = 'icon';
        document.head.appendChild(favicon);
      }
      favicon.href = link;
    };

    // Check for specific domains and apply corresponding theme
    if (hostname.includes('hygienic-design.com')) {
      document.body.classList.add('hd-theme');
      document.title = 'Hygienic Design';
      changeFavicon('./favicon_512x512px_hd-150x150.png');
    }
    // if (hostname.includes('localhost')) {
    //     document.body.classList.add('dark');
    //     document.title = 'Localhost';
    //     changeFavicon('/path/to/localhost-favicon.ico');
    // }
  }, []);
};