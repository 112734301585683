import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export const getFullUser = async (userid) => {
    try {
        const user = await client.graphql({
            variables: { id: userid },
            query: /* GraphQL */ `
            query GetUser($id: ID!) {
              getUser(id: $id) {
                id
                hasCompletedOnboarding
                email
                firstName
                middleName
                lastName
                salutation
                
                aboutMe {
                  language
                  text
                }
                contact {
                  phone
                  fax
                  email
                  address {
                    street
                    streetNumber
                    zip
                    city
                    countryCode
                    company
                  }
                }
                image {
                  alt
                    fileName
                }
                personalIndustry
                personalDepartment
                employeeProfiles {
                    items {
                        id
                    company {
                        id
                        name
                        image {
                            main {
                            alt
                            fileName
                            }
                        }
                    }
                    department {
                        items {
                            department {
                                name {
                                    language
                                    text
                                }
                            }
                        }
                    }
                    role
                    permissions {
                      resource
                      actions
                    }
                    status
                    }
                }
                owner
                conditions {
                  id
                  accepted
                  acceptedAt
                }
                hasInsightAccess
              }
            }
          `
        });
        console.log("user",user);
        return user.data.getUser;
    } catch (error) {
        console.warn("error fetching user", error);
    }
  }