import React, { useState, useEffect, useContext } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBListGroup, MDBListGroupItem, MDBBtn, MDBBtnGroup, MDBIcon, MDBSelect, MDBPopover, MDBPopoverHeader, MDBPopoverBody } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { getLocalizedText } from "../../utils/localizedText";
import { useIndustry } from "../../utils/industryContextHook";
import { transformMoney } from "../../utils/money";
import { UserContext } from "../../App";

const client = generateClient();

const Shop = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { currentIndustry } = useIndustry();
  const [products, setProducts] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCustomerType, setSelectedCustomerType] = useState("consumer");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  // const categories = [
  //   { name: 'Messestände', customerTypes: ["business"], types: ['event_booth', 'event_booth_partner'] },
  //   { name: 'Veranstaltungen', customerTypes: ["consumer"], types: ['ticket'] },
  //   { name: 'Marketing', customerTypes: ["business"], types: ['marketing'] },
  //   { name: 'Academy', customerTypes: ["consumer"], types: ['digital_access'] },
  // ];

  const typeNames = {
    event_booth: 'Event booths',
    event_space: 'Event spaces',
    event_booth_partner: 'Event booths partner',
    event_equipment: 'Event equipment',
    marketing: 'Marketing',
    marketing_booth: 'Marketing Stand',
    digital_access: 'Digital Access',
    ticket: 'Tickets',
    discount: 'Discount',
  };

  const [events, setEvents] = useState([]);

  const fetchEvents = async (currentIndustry) => {
      try {
          const eventData = await client.graphql({
              query: /* GraphQL */ `
              query ListIndustryEvents(
                $filter: ModelIndustryEventsFilterInput
                $limit: Int
              ) {
                listIndustryEvents(filter: $filter, limit: $limit) {
                  items {
                  event {
                    id
                    name {
                      language
                      text
                    }
                    image {
                      fileName
                      alt
                    }
                    location {
                      address {
                          city
                      }
                    }
                    ticketProduct {
                      id
                      name {
                        language
                        text
                      }
                      price
                    }
                    status
                    type
                    date
                    startDate
                    endDate
                    boothBooking
                    enableTicketBooking
                    landingPageUrl
                    x4Id
                    createdAt
                    updatedAt
                    __typename
                  }
                  __typename
                }
              }
              }
            `,
              variables: { filter: { industryId: { eq: currentIndustry.id }} }
          });
          const eventList = eventData.data.listIndustryEvents.items.map(e => e.event).sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
          console.log('event list', eventList);
          setEvents(eventList);
          return eventList;
      } catch (error) {
          console.log('error on fetching events', error);
      }
  }

  const generateEventTicketProducts = (events) => {
    let products = [];
      const eventProducts = events.forEach(event => {
          if (event.enableTicketBooking) {
              products.push({
                id: event.id,
                name: event.ticketProduct.name,
                type: 'ticket',
                price: event.ticketProduct.price,
                visibleInShop: true,
                productCategoryProductsId: event.type === "seminar" ? "ed2a0743-c014-4222-8075-c5c4ebf4d8e4" : 'd9bef73c-3da7-4041-b031-c01b4792400d',
                image: {
                  main: event.image
                },
                event: event
            });
          }
          if(event.boothBooking) {
              products.push({
                id: event.id,
                name: event.name,
                type: 'event_booth',
                price: 0,
                visibleInShop: true,
                productCategoryProductsId: 'bc830bce-619f-4edb-814a-a7b6b0d57dee',
                image: {
                  main: event.image
                },
                event: event     
            });
          }
      });
      return products;
  }


  const getCategories = async () => {
    try {
      const categoryRes = await client.graphql({
        query: /* GraphQL */ `
        query ListProductCategorys(
          $filter: ModelProductCategoryFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listProductCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {

            items {
              id
              name {
                language
                text
              }
              customerTypes
              description {
                language
                text
              }
              sortKey
            }
            nextToken
          }
        }
      `,
      });
      const categories = categoryRes.data.listProductCategories.items;
      setCategories(categories);
    } catch (error) {
      console.warn("Error getting categories", error);
    }
  };

  const fetchProducts = async (nextToken = null, allProducts = [], currentIndustry) => {
    try {
      const productData = await client.graphql({
        query: `
          query ListProducts(
          $filter: ModelProductFilterInput
          $nextToken: String) {
            listProducts(filter: $filter, limit: 1000, nextToken: $nextToken) {
              items {
                id
                name {
                  text
                  language
                }
                type
                price
                visibleInShop
                productCategoryProductsId
                availableInIndustries {
                  items {
                    industryId
                  }
                }
                image {
                  main {
                    fileName
                    alt
                  }
                }
              }
              nextToken
            }
          }
        `,
        variables: { 
          filter: { visibleInShop: { eq: true } },
          nextToken }
      });

      const newProducts = productData.data.listProducts.items;
      let updatedProducts = [...allProducts, ...newProducts];

      if (productData.data.listProducts.nextToken) {
        // If there's a next token, fetch the next page
        return fetchProducts(productData.data.listProducts.nextToken, updatedProducts);
      } else {
        // No more pages, filter and set the products
        // const filteredProducts = updatedProducts.filter(product => 
        //   categories.some(category => category.types.includes(product.type))
        // );
        const events = await fetchEvents(currentIndustry);
        updatedProducts = [...updatedProducts.filter(product => product.availableInIndustries.items.map(i => i.industryId).includes(currentIndustry.id)), ...(await generateEventTicketProducts(events))];
        setProducts(updatedProducts);
        setFilteredProducts(updatedProducts);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };
  useEffect(() => {
    fetchProducts(null, [], currentIndustry);
    getCategories();

  }, [currentIndustry]);

  useEffect(() => {
    if (user?.employeeProfiles?.items?.length > 0) {
      setSelectedCompany(user.employeeProfiles.items[0].company);
    }
  }, [user]);


  const handleAddToCart = (product) => {
    // Implementieren Sie hier die Logik zum Hinzufügen zum Warenkorb
    console.log('Added to cart:', product);
  };

  const truncate = (str, max, len) => {
    return !str || !str.length ? "" :str.length > max ? str.substring(0, len) + "..." : str;
}

  return (

    <MDBRow>
        <MDBCol md="3">
          <h5>{t('Products for:')}</h5>
          <MDBBtnGroup className="mb-4 w-100">
            <MDBBtn
              color="secondary"
              outline={selectedCustomerType !== "consumer"}
              onClick={() => {setSelectedCustomerType("consumer"); setSelectedCategory(null)}}
              active={selectedCustomerType === "consumer"}
            >
              {t('Persons')}
            </MDBBtn>
            <MDBBtn
              color="secondary"
              outline={selectedCustomerType !== "business"}
              onClick={() => {setSelectedCustomerType("business"); setSelectedCategory(null)}}
              active={selectedCustomerType === "business"}
            >
              {t('Business')}
              <MDBPopover
              size='sm'
              color='none'
              placement='bottom'
              btnClassName='rounded p-0 m-0 border-0 float-end position-absolute end-0 me-3'
              btnChildren={selectedCompany && 
                <img
                src={selectedCompany.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + selectedCompany.image?.main?.fileName : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
                className="rounded-circle bg-white float-end"
                height="21"
                width="21"
                alt=""
              />
              } >
                <MDBPopoverBody className="p-0">
                  {user?.employeeProfiles?.items.map((profile, index) => (
                    <MDBBtn
                      key={index}
                      color="secondary"
                      outline={selectedCompany?.id !== profile.company.id}
                      onClick={() => setSelectedCompany(profile.company)}
                      active={selectedCompany?.id === profile.company.id}
                      className="w-100"
                    >
                      {profile.company.name}
                    </MDBBtn>
                  ))}
                </MDBPopoverBody>
              </MDBPopover>
             {/* {selectedCompany && 
                <img
                src={selectedCompany.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + selectedCompany.image?.main?.fileName : "//cdn.eu.com/connect/img/image-placeholder.jpg"}
                className="rounded-circle bg-white float-end"
                height="21"
                width="21"
                alt=""
              />
              }  */}
            </MDBBtn>
          </MDBBtnGroup>
          <MDBListGroup light small>
            <MDBListGroupItem 
              noBorders 
              action 
              className={(selectedCategory === null ? 'text-secondary ' : '') + "px-3"}
              onClick={() => setSelectedCategory(null)}
            >
              {t('All products')}
            </MDBListGroupItem>
            {categories.filter(category => category.customerTypes.includes(selectedCustomerType)).map((category, index) => (
              <MDBListGroupItem 
                key={index} 
                noBorders
                action 
                className={(selectedCategory === category.id ? 'text-secondary ' : '') + "px-3"}
                onClick={() => setSelectedCategory(category.id)}
              >
                {getLocalizedText(category.name)}
              </MDBListGroupItem>
            ))}
             <MDBListGroupItem 
              noBorders 
              action 
              className="px-3 mt-2"
            >
              <Link to="/bookings" className="text-dark">
              <MDBIcon icon="shopping-bag" /> {t('My Bookings')}
              </Link>
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBCol>
        <MDBCol md="9">
          <MDBRow>
          {products
          .filter(product => product.productCategoryProductsId === selectedCategory || selectedCategory === null)
          .filter(product => categories.find(category => category.id === product.productCategoryProductsId)?.customerTypes.includes(selectedCustomerType))
          .map((product) => (
            <MDBCol md="4" key={product.id}>
            <MDBCard key={product.id} className="mb-4">
            {product.image?.main?.fileName ? (
                      <MDBCardImage
                        src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/${product.type === "ticket" || product.type ===  "event_booth" ? "EventImages" : "ProductImages"}/${product.image.main.fileName}`}
                        alt={product.image.main.alt || product.name.find(n => n.language === 'de')?.text || product.name[0]?.text}
                        fluid
                        style={{ maxHeight: "250px", objectFit: "cover" }}
                      />
                    ) : (
                      <MDBCardImage
                        src="https://via.placeholder.com/150"
                        alt="Placeholder"
                        fluid
                        style={{ maxHeight: "150px", objectFit: "contain" }}
                      />
                    )}
              <MDBCardBody>
                    <MDBCardTitle>{(product.type ===  "event_booth" ? t("Booth") + " " : "") + getLocalizedText(product.name)}</MDBCardTitle>
                    <MDBCardText>
                      {product.description && getLocalizedText(truncate(product.description,100))}
                    </MDBCardText>
                    <MDBCardText>
                      {t(typeNames[product.type] || product.type)}
                    </MDBCardText>
                    <MDBRow>
                      {product.type ===  "event_booth" ? 
                      <MDBCol size="12">
                         <Link to={`/mycompany/${selectedCompany?.id}/select-booth/${product.id}`} className="btn btn-primary">
                        {t('Book now')}
                        </Link>
                      </MDBCol>
                      :
                      <>
                      <MDBCol size="6">
                        <h5 className="mb-3">{transformMoney(product.price)}</h5>
                      </MDBCol>
                      <MDBCol size="6" className="text-end">
                        {product.type === 'ticket' ? 
                          <Link to={`/book-ticket?eventId=${product.id}`} className="btn btn-primary">
                            {t('Book now')}
                          </Link>
                        : 
                        <Link to={`/checkout?productId=${product.id}`} className="btn btn-primary">
                        {t('Book now')}
                        </Link>
                        }
                      </MDBCol>
                      </>}
                    </MDBRow>
                    
                    
                    
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          ))}
          </MDBRow>
        </MDBCol>
      </MDBRow>
  );
};

export default Shop;