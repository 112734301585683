import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBInput, MDBIcon, MDBBtn, MDBChip, MDBValidation, MDBValidationItem} from "mdb-react-ui-kit"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-input-2"
import deepUpdateObject from "../../../../utils/deepUpdateObject"

export default function CompanyProfile({data, setData, handleUpdateOrder, dataSavedSuccess}) {
  const { t, i18n } = useTranslation()

  const [ company, setCompany ] = useState({})

  const handleChange = (e) => {
        //console.log("change",e.target.attributes.datapath.value, e.target.value)

        // Get values from input
        const datapath = e.target.attributes.datapath.value;
        const value = e.target.value;

        // Update current company object
        const newCompany = deepUpdateObject(company, datapath, value)
        setCompany(newCompany);
    }

  const handleSave = (e) => {
    e.preventDefault()
    console.log("Saving company", company)
    setData({...data, company})
    handleUpdateOrder()
  }
  




  return (
<>
<MDBCard className="mb-4">
    <MDBCardBody>
    <MDBCardTitle className="mb-4">{t("Company informations")}</MDBCardTitle>
    <form onSubmit={handleSave}>
        <MDBRow>
        <MDBCol className="mx-auto" size="12" xl="10">
            <h6>{t("Name")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="12">
                  <MDBInput required autoComplete="off" datapath="name" label={t("Name")} value={company.name || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>    
                <h6>{t("Address")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="8">
                        <MDBInput required autoComplete="off" datapath="contact.address.street" label={t("Street")} value={company.contact?.address?.street || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="4">
                        <MDBInput required autoComplete="off" datapath="contact.address.streetNumber" label={t("Number")} value={company.contact?.address?.streetNumber || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.street2" label={t("Street Additonal")} value={company.contact?.address?.street2 || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    
                    <MDBCol>
                        <MDBInput required autoComplete="off" datapath="contact.address.zip" label={t("Zip")} value={company.contact?.address?.zip || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput required autoComplete="off" datapath="contact.address.city" label={t("City")} value={company.contact?.address?.city || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput required autoComplete="off" datapath="contact.address.countryCode" label={t("Country")} value={company.contact?.address?.countryCode || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <h6>{t("Contact")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                        <PhoneInput
                            value={company.contact?.phone || ""}
                            placeholder={t("Phone")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.phone"}}, value: phone}})}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4" className="mb-3 mb-md-0">
                        <PhoneInput
                            value={company.contact?.mobile || ""}
                            placeholder={t("Mobile")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.mobile"}}, value: phone}})}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4">
                        <PhoneInput
                            value={company.contact?.fax}
                            placeholder={t("Fax")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.fax"}}, value: phone}})}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="6" className="mb-3 mb-md-0">
                        <MDBInput autoComplete="off" datapath="contact.email" label={t("Email")} value={company.contact?.email || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="12" md="6">
                        <MDBInput autoComplete="off" datapath="website" label={t("Website")} value={company.website || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBCol className="d-flex justify-content-center">
                <MDBBtn type="submit" color="primary">{t("Save")}</MDBBtn>
                {dataSavedSuccess && <p className="text-success ms-3">{t("Saved")}</p>}
            </MDBCol>
        </MDBRow>
        </form>
    </MDBCardBody>
</MDBCard>
</>
  )
}
