import React from 'react';
import { MDBRow, MDBCol, MDBTypography, MDBListGroup, MDBListGroupItem, MDBBtn } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import { useCarpets } from './hooks/useCarpets';

const CarpetTab = ({ eventId }) => {
  const { t, i18n } = useTranslation();
  const { 
    isLoading,
    eventCarpets,
    carpets,
    addCarpetToEvent,
    removeCarpetFromEvent
  } = useCarpets(eventId);

  const getLocalizedCarpetName = (carpet) => {
    return carpet.name.find(n => n.language === i18n.language)?.text || carpet.name[0].text;
  };

  const ColorSwatch = ({ color }) => (
    <div 
      style={{ 
        width: '20px', 
        height: '20px', 
        backgroundColor: color, 
        marginRight: '10px',
        border: '1px solid #000',
        display: 'inline-block',
        verticalAlign: 'middle'
      }} 
    />
  );

  const CarpetItem = ({ carpet, onAction, actionText, actionColor }) => (
    <MDBListGroupItem className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <ColorSwatch color={carpet.color} />
        <span>{getLocalizedCarpetName(carpet)}</span>
      </div>
      <MDBBtn 
        color={actionColor} 
        size="sm"
        onClick={onAction}
      >
        {actionText}
      </MDBBtn>
    </MDBListGroupItem>
  );

  return (
    <MDBRow className="mb-4">
      <MDBCol size="12" md="6">
        <MDBTypography tag="h5">{t("Available Carpets")}</MDBTypography>
        <MDBListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {carpets.map((carpet) => (
            <CarpetItem
              key={carpet.id}
              carpet={carpet}
              onAction={() => addCarpetToEvent(carpet.id)}
              actionText={t("Add")}
              actionColor="primary"
              disabled={eventCarpets.some(ec => ec.eventCarpet.id === carpet.id)}
            />
          ))}
        </MDBListGroup>
      </MDBCol>

      <MDBCol size="12" md="6">
        <MDBTypography tag="h5">{t("Selected Carpets for Event")}</MDBTypography>
        <MDBListGroup style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {eventCarpets.map((eventCarpet) => (
            <CarpetItem
              key={eventCarpet.id}
              carpet={eventCarpet.eventCarpet}
              onAction={() => removeCarpetFromEvent(eventCarpet.id)}
              actionText={t("Remove")}
              actionColor="danger"
            />
          ))}
        </MDBListGroup>
      </MDBCol>
    </MDBRow>
  );
};

export default CarpetTab;