import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";

export default function ChoosePromotionType({ lecture, handleChange, handleNextClick}) {
   const { t, i18n } = useTranslation();

   const handlePromotionTypeClick = (type) => {
    handleChange({target: {
        value: type,
        attributes: {
          datapath: {
            value: "type"
          }}
      }})
     }
  return (
<>
<MDBTypography tag="h3">{t("Select promotion type")}</MDBTypography>
<MDBRow className="my-2 my-xl-4 px-xl-5">
        <MDBCol>
            <MDBCard
            onClick={() => handlePromotionTypeClick("promotion_technical")}
            shadow='0'
            background={lecture.type === "promotion_technical" ? "success bg-opacity-10" : "white"}
            className={"h-100" + (lecture.type === "promotion_technical" ? " border-success" : "")}
            style={{border: "1px solid"}}
            >
                <MDBCardBody className='text-primary'>
                <MDBCardTitle>{t("Technical")}</MDBCardTitle>
                <MDBCardText>
                {t("Promotion related to a specialist topic or for training purposes (ad-free)")}
                </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
        <MDBCol>
        <MDBCard
            onClick={() => handlePromotionTypeClick("promotion_marketing")}
            shadow='0'
            background={lecture.type === "promotion_marketing" ? "success bg-opacity-10" : "white"}
            className={"h-100" + (lecture.type === "promotion_marketing" ? " border-success" : "")}
            style={{border: "1px solid"}}
            >
                <MDBCardBody className='text-primary'>
                <MDBCardTitle>{t("Marketing")}</MDBCardTitle>
                <MDBCardText>
                {t("Promotion to market products, services or the company")}
                </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    </MDBRow>
    <MDBBtn onClick={() => handleNextClick()} className="rounded-4 mb-2 mb-lg-4">
              {t("Continue")}
            </MDBBtn>
</>
    
  )
}
