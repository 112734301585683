import { MDBBtn, MDBFooter, MDBIcon } from 'mdb-react-ui-kit';

export default function Footer({currentIndustry}) {
  return (
    <MDBFooter className='text-center text-white' style={{backgroundColor: currentIndustry?.secondaryColor}}>
    <section>
    {currentIndustry?.socialLinks?.facebook &&
      <MDBBtn
        rippleColor="dark"
        color='link'
        floating
        size="lg"
        className='text-white m-1'
        href={currentIndustry.socialLinks.facebook}
        target="_blank"
        role='button'
      >
        <MDBIcon fab className='fab fa-facebook-f' />
      </MDBBtn>
    }

    
    {currentIndustry?.socialLinks?.instagram &&
      <MDBBtn
        rippleColor="dark"
        color='link'
        floating
        size="lg"
        className='text-white m-1'
        href={currentIndustry.socialLinks.instagram}
        target="_blank"
        role='button'
      >
        <MDBIcon fab className='fa-instagram' />
      </MDBBtn>
    }
    
    {currentIndustry?.socialLinks?.linkedin &&
      <MDBBtn
        rippleColor="dark"
        color='link'
        floating
        size="lg"
        className='text-white m-1'
        href={currentIndustry.socialLinks.linkedin}
        target="_blank"
        role='button'
      >
        <MDBIcon fab className='fa-linkedin' />
      </MDBBtn>
    }

    {currentIndustry?.socialLinks?.spotify &&
      <MDBBtn
        rippleColor="dark"
        color='link'
        floating
        size="lg"
        className='text-white m-1'
        href={currentIndustry.socialLinks.spotify}
        target="_blank"
        role='button'
      >
        <MDBIcon fab className='fa-spotify' />
      </MDBBtn>
    }

    {currentIndustry?.socialLinks?.youtube &&
      <MDBBtn
        rippleColor="dark"
        color='link'
        floating
        size="lg"
        className='text-white m-1'
        href={currentIndustry.socialLinks.youtube}
        target="_blank"
        role='button'
      >
        <MDBIcon size="lg" fab className='fa-youtube' />
      </MDBBtn>
    }
    </section>


  <div className='text-center text-white pb-3'>
    © {new Date().getFullYear()}{' '}
    <a className='text-white' href={currentIndustry?.domain}>
        {currentIndustry?.domain}
    </a>
  </div>
</MDBFooter>
  )
}
