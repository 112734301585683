import React from 'react';
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBInput,
  MDBInputGroup,
  MDBTextArea,
  MDBSwitch
} from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { getLocalizedText } from '../../../utils/localizedText';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function GeneralTab({ 
  seminarData, 
  setSeminarData, 
  editorState, 
  onEditorStateChange 
}) {
  const { t, i18n } = useTranslation();

  return (
    <div className="p-4">
      {/* Status */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <MDBTypography tag="h5" className="mb-3">{t("Status")}</MDBTypography>
          <MDBSwitch 
            checked={seminarData.status === 'PUBLISHED'}
            onChange={(e) => setSeminarData(prev => ({
              ...prev,
              status: e.target.checked ? 'PUBLISHED' : 'DRAFT'
            }))}
            label={t(seminarData.status || 'DRAFT')}
          />
        </MDBCol>
      </MDBRow>

      {/* Name */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <MDBTypography tag="h5" className="mb-3">{t("Name")}</MDBTypography>
          <MDBInputGroup className="mb-3" textAfter={<span style={seminarData.name?.find(n => n.language === "de")?.text?.length === 100 ? {color: "red"} : null}>{((seminarData.name?.find(n => n.language === "de")?.text || "").length)}/100</span>}>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{width: "20px", height: "20px"}} />
            </div>
            <MDBInput
              label={t("German Title")}
              value={getLocalizedText(seminarData.name?.filter(n => n.language === "de")) || ""}
              onChange={(e) => setSeminarData(prev => ({
                ...prev,
                name: [
                  { language: "de", text: e.target.value },
                  ...(prev.name?.filter(n => n.language !== "de") || [])
                ]
              }))}
              maxLength={100}
            />
          </MDBInputGroup>

          <MDBInputGroup textAfter={<span style={seminarData.name?.find(n => n.language === "en")?.text?.length === 100 ? {color: "red"} : null}>{((seminarData.name?.find(n => n.language === "en")?.text || "").length)}/100</span>}>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{width: "20px", height: "20px"}} />
            </div>
            <MDBInput
              label={t("English Title")}
              value={getLocalizedText(seminarData.name?.filter(n => n.language === "en")) || ""}
              onChange={(e) => setSeminarData(prev => ({
                ...prev,
                name: [
                  { language: "en", text: e.target.value },
                  ...(prev.name?.filter(n => n.language !== "en") || [])
                ]
              }))}
              maxLength={100}
            />
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>

      {/* Short Description */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <MDBTypography tag="h5" className="mb-3">{t("Short Description")}</MDBTypography>
          <MDBInputGroup className="mb-3" textAfter={<span style={seminarData.shortDescription?.find(d => d.language === "de")?.text?.length === 500 ? {color: "red"} : null}>{((seminarData.shortDescription?.find(d => d.language === "de")?.text || "").length)}/500</span>}>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="de" svg style={{width: "20px", height: "20px"}} />
            </div>
            <MDBTextArea
              label={t("German Description")}
              value={getLocalizedText(seminarData.shortDescription?.filter(d => d.language === "de")) || ""}
              onChange={(e) => setSeminarData(prev => ({
                ...prev,
                shortDescription: [
                  { language: "de", text: e.target.value },
                  ...(prev.shortDescription?.filter(d => d.language !== "de") || [])
                ]
              }))}
              maxLength={500}
              rows={3}
            />
          </MDBInputGroup>

          <MDBInputGroup textAfter={<span style={seminarData.shortDescription?.find(d => d.language === "en")?.text?.length === 500 ? {color: "red"} : null}>{((seminarData.shortDescription?.find(d => d.language === "en")?.text || "").length)}/500</span>}>
            <div className="input-group-text">
              <ReactCountryFlag countryCode="gb" svg style={{width: "20px", height: "20px"}} />
            </div>
            <MDBTextArea
              label={t("English Description")}
              value={getLocalizedText(seminarData.shortDescription?.filter(d => d.language === "en")) || ""}
              onChange={(e) => setSeminarData(prev => ({
                ...prev,
                shortDescription: [
                  { language: "en", text: e.target.value },
                  ...(prev.shortDescription?.filter(d => d.language !== "en") || [])
                ]
              }))}
              maxLength={500}
              rows={3}
            />
          </MDBInputGroup>
        </MDBCol>
      </MDBRow>

      {/* Full Description */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <MDBTypography tag="h5" className="mb-3">{t("Full Description")}</MDBTypography>
          <div className="mb-3">
            <div className="d-flex align-items-center mb-2">
              <ReactCountryFlag countryCode="de" svg style={{width: "20px", height: "20px"}} className="me-2" />
              <span>{t("German Description")}</span>
            </div>
            <Editor
              editorState={editorState.de}
              onEditorStateChange={(newState) => onEditorStateChange(newState, 'de')}
              editorClassName="form-control"
              toolbarClassName="mb-3"
              toolbar={{
                options: ['inline', 'blockType', 'list', 'link', 'history'],
                inline: { options: ['bold', 'italic', 'underline'] }
              }}
            />
          </div>

          <div>
            <div className="d-flex align-items-center mb-2">
              <ReactCountryFlag countryCode="gb" svg style={{width: "20px", height: "20px"}} className="me-2" />
              <span>{t("English Description")}</span>
            </div>
            <Editor
              editorState={editorState.en}
              onEditorStateChange={(newState) => onEditorStateChange(newState, 'en')}
              editorClassName="form-control"
              toolbarClassName="mb-3"
              toolbar={{
                options: ['inline', 'blockType', 'list', 'link', 'history'],
                inline: { options: ['bold', 'italic', 'underline'] }
              }}
            />
          </div>
        </MDBCol>
      </MDBRow>

      {/* Booking Period */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <MDBTypography tag="h5" className="mb-3">{t("Booking Period")}</MDBTypography>
          <MDBRow>
            <MDBCol size="12" md="6">
              <MDBInput
                type="date"
                label={t("Booking Start Date")}
                value={seminarData.bookingStartDate || ''}
                onChange={(e) => setSeminarData(prev => ({
                  ...prev,
                  bookingStartDate: e.target.value
                }))}
              />
            </MDBCol>
            <MDBCol size="12" md="6">
              <MDBInput
                type="date"
                label={t("Booking End Date")}
                value={seminarData.bookingEndDate || ''}
                onChange={(e) => setSeminarData(prev => ({
                  ...prev,
                  bookingEndDate: e.target.value
                }))}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>

      {/* Price */}
      <MDBRow>
        <MDBCol size="12">
          <MDBTypography tag="h5" className="mb-3">{t("Price")}</MDBTypography>
          <MDBInput
            type="number"
            label={t("Price in EUR")}
            value={seminarData.product?.price || ''}
            onChange={(e) => setSeminarData(prev => ({
              ...prev,
              product: {
                ...(prev.product || {}),
                price: parseFloat(e.target.value)
              }
            }))}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
}