import { MDBBtn, MDBCol, MDBInput, MDBRow, MDBSelect } from "mdb-react-ui-kit"
import { generateClient } from 'aws-amplify/api';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { useState, useEffect } from 'react';

import { useTranslation } from "react-i18next";
const client = generateClient();

const createCompany = async (company) => {
  try {
    const newCompany = await client.graphql({
      query: mutations.createCompany,
      variables: { input: company },
    });
    console.log(newCompany);
  } catch (error) {
    console.log(error);
  }
}


const getAvailableCountries = async () => {
  try {
    const countries = await client.graphql({
      query: queries.listCountries,
    });
    console.log(countries);
    return countries.data.listCountries.items;
  } catch (error) {
    console.warn("error fetching countries", error);
  }
}

export default function AddNewCompany() {
  const { t, i18n } = useTranslation();
  const {company, setCompany} = useState({
    name: '',
    address: {
      street: '',
      street2: '',
      zip: '',
      city: '',
      country: '',
    },
    contact: {
      phone: '',
      email: '',
    },
  });

  const [countries, setCountries] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await getAvailableCountries();
      setCountries(countries);
      setIsDataLoaded(true);
    }
    fetchCountries();
  }, [isDataLoaded]);




  return (
    <form>
        <MDBInput className="mb-4" label={t("Company Name")} />
<hr />
        <h5>{t("Address")}</h5>
        <MDBRow>
          <MDBCol size="8">
            <MDBInput className="mb-2 col col-6" label={t("Street")} />
          </MDBCol>
          <MDBCol size="4">
            <MDBInput className="mb-2 col col-6" label={t("House number")} />
          </MDBCol>
        </MDBRow>

        
        <MDBInput className="mb-2" label={t("Street addition (Optional)")} />

        <MDBRow>
          <MDBCol size="8">
          <MDBInput className="mb-2" label={t("Zip")} />
          </MDBCol>
          <MDBCol size="4">
          <MDBInput className="mb-2" label={t("City")} />
          </MDBCol>
        </MDBRow>
        <MDBSelect
          placeholder={t("Country")}
          data={isDataLoaded && countries?.length > 0 ? countries.map(country => ({text: country.name, value: country.handle})) : [{text: t("Loading Countries...")}]} />
<hr />
        <h5>{t("Contact")}</h5>
        <MDBInput className="mb-2" label={t("Phone Number")} />
        <MDBInput label={t("Email")} />
        <hr />
        <MDBRow>
          <MDBCol className="text-center">
            <MDBBtn color="primary" onClick={() => createCompany(company)}>
            {t("Add Company")}
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        
    </form>
   

  )
}
