import React, { useEffect, useState } from 'react';
import EmployeeSelector from "../../../components-user/news/EmployeeSelector";
import { 
  MDBInput, 
  MDBCheckbox, 
  MDBBtn, 
  MDBRow, 
  MDBCol, 
  MDBTypography, 
  MDBInputGroup, 
  MDBModal, 
  MDBModalDialog, 
  MDBModalContent, 
  MDBModalHeader, 
  MDBModalTitle, 
  MDBModalBody, 
  MDBModalFooter, 
  MDBCard, 
  MDBCardBody, 
  MDBCardTitle, 
  MDBFile, 
  MDBSpinner, 
  MDBIcon,
  MDBBadge,
  MDBSelect,
  MDBTextArea
} from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { convertHTMlToRaw } from "../../../utils/HTMLDraft";
import { generateClient } from 'aws-amplify/api';
import { useNavigate, useParams } from "react-router-dom";

const client = generateClient();

const AdminEditJobListing = () => {
    const navigate = useNavigate();
    const { jobId } = useParams();
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [jobData, setJobData] = useState({
        name: [],
        content: [],
        image: {},
        file: {},
        cMSJobListingEmployeeId: "",
        conditions: [],
        status: "DRAFT",
        approved: false,
        adminComment: ""
    });

    // Add admin-specific states
    const [showAdminControls, setShowAdminControls] = useState(true);
    const statusOptions = [
        { value: 'DRAFT', text: t('Draft') },
        { value: 'PENDING', text: t('Pending Review') },
        { value: 'PUBLISHED', text: t('Published') },
        { value: 'ARCHIVED', text: t('Archived') },
        { value: 'REJECTED', text: t('Rejected') }
    ];

    const getJobData = async () => {
        try {
            const response = await client.graphql({
                query: /* GraphQL */ `
                    query GetCMSJobListing($id: ID!) {
                        getCMSJobListing(id: $id) {
                            id
                            name {
                                language
                                text
                            }
                            content {
                                language
                                text
                            }
                            image {
                                fileName
                                alt
                            }
                            file {
                                url
                                s3Path
                                fileName
                                type
                                name
                            }
                            status
                            approved
                            adminComment
                            company {
                                id
                                name
                            }
                            employee {
                                id
                                user {
                                    firstName
                                    lastName
                                    email
                                }
                                image {
                                    fileName
                                }
                            }
                            conditions {
                                name
                                accepted
                            }
                            createdAt
                            updatedAt
                            cMSJobListingEmployeeId
                        }
                    }
                `,
                variables: { id: jobId }
            });
            
            return response.data.getCMSJobListing;
        } catch (error) {
            console.error("Error fetching job listing:", error);
        }
    };

    useEffect(() => {
        if (jobId) {
            loadJobData();
        }
    }, [jobId]);

    const loadJobData = async () => {
        setIsLoading(true);
        const data = await getJobData();
        setJobData(data);
        
        // Setup editor states
        if (data.content) {
            setEditorState({
                de: EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(data.content.find(c => c.language === "de")?.text || '')
                    )
                ),
                en: EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(data.content.find(c => c.language === "en")?.text || '')
                    )
                )
            });
        }
        setIsLoading(false);
    };

    const [editorState, setEditorState] = useState({
        de: EditorState.createEmpty(),
        en: EditorState.createEmpty()
    });

    const onEditorStateChange = (editorState, language) => {
        setEditorState(oldState => ({...oldState, [language]: editorState}));
        setJobData(oldData => ({
            ...oldData, 
            content: [
                ...oldData.content.filter(c => c.language !== language),
                {language, text: convertHTMlToRaw(editorState)}
            ]
        }));
    };

    const handleImageUpload = async (file) => {
        if (!file) return;
        setIsLoading(true);
        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
                const response = await client.graphql({
                    query: /* GraphQL */ `
                        mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
                            uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
                        }
                    `,
                    variables: {
                        imageBuffer: base64FileBuffer,
                        imageType: "jobImage",
                    },
                });
                const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
                setJobData(prev => ({...prev, image: {fileName, alt: "Job Image"}}));
                setIsLoading(false);
            };
        } catch (error) {
            console.error("Error uploading image:", error);
            setIsLoading(false);
        }
    };

    const saveJobListing = async () => {
        setIsLoading(true);
        try {
            const response = await client.graphql({
                query: /* GraphQL */ `
                    mutation UpdateCMSJobListing($input: UpdateCMSJobListingInput!) {
                        updateCMSJobListing(input: $input) {
                            id
                        }
                    }
                `,
                variables: {
                    input: {
                        id: jobId,
                        ...jobData
                    }
                }
            });

            navigate('/admin/jobs');
        } catch (error) {
            console.error("Error saving job listing:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <MDBCard>
            <MDBCardBody>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <MDBCardTitle>{t("Edit Job Listing")}</MDBCardTitle>
                    <div>
                        <MDBBtn 
                            color="secondary" 
                            className="me-2"
                            onClick={() => navigate('/admin/jobs')}
                        >
                            {t("Cancel")}
                        </MDBBtn>
                        <MDBBtn onClick={saveJobListing} disabled={isLoading}>
                            {isLoading ? (
                                <><MDBIcon fas icon="spinner" spin className="me-2" /> {t("Saving...")}</>
                            ) : (
                                t("Save")
                            )}
                        </MDBBtn>
                    </div>
                </div>

                {/* Admin Controls Section */}
                <MDBRow className="mb-4">
                    <MDBCol size="12">
                        <MDBCard background="light">
                            <MDBCardBody>
                                <h5>{t("Admin Controls")}</h5>
                                <div className="d-flex gap-4 align-items-center mb-3">
                                    <div>
                                        <label className="form-label">{t("Status")}</label>
                                        <select 
                                            className="form-select" 
                                            value={jobData.status}
                                            onChange={(e) => setJobData(prev => ({...prev, status: e.target.value}))}
                                        >
                                            {statusOptions.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.text}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="ms-4">
                                        <MDBCheckbox
                                            name="approved"
                                            id="approved"
                                            label={t("Approved")}
                                            checked={jobData.approved}
                                            onChange={(e) => setJobData(prev => ({...prev, approved: e.target.checked}))}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="form-label">{t("Admin Comment")}</label>
                                    <MDBTextArea
                                        rows={3}
                                        value={jobData.adminComment || ''}
                                        onChange={(e) => setJobData(prev => ({...prev, adminComment: e.target.value}))}
                                    />
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                {/* Company & Contact Info */}
                <MDBRow className="mb-4">
                    <MDBCol size="12">
                        <div className="d-flex align-items-center mb-3">
                            {jobData.company && (
                                <>
                                    <strong className="me-3">{t("Company")}:</strong>
                                    <span>{jobData.company.name}</span>
                                </>
                            )}
                        </div>
                        {jobData.employee && (
                            <div className="d-flex align-items-center">
                                <strong className="me-3">{t("Contact Person")}:</strong>
                                <div className="d-flex align-items-center">
                                    {jobData.employee.image?.fileName && (
                                        <img
                                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${jobData.employee.image.fileName}`}
                                            className="rounded-circle me-2"
                                            style={{ width: '30px', height: '30px', objectFit: 'cover' }}
                                            alt=""
                                        />
                                    )}
                                    <span>
                                        {jobData.employee.user.firstName} {jobData.employee.user.lastName} 
                                        ({jobData.employee.user.email})
                                    </span>
                                </div>
                            </div>
                        )}
                    </MDBCol>
                </MDBRow>

                {/* Rest of the form - reuse components from user edit page */}
                <MDBRow>
                    <MDBCol size="12">
                        <MDBTypography tag="h5">{t("Position Title")}</MDBTypography>
                        {['de', 'en'].map(lang => (
                            <MDBInputGroup key={lang} className="mb-3">
                                <div className="input-group-text">
                                    <ReactCountryFlag
                                        countryCode={lang === 'de' ? 'de' : 'gb'}
                                        svg
                                        style={{width: "20px", height: "20px"}}
                                    />
                                </div>
                                <MDBInput
                                    label={t(lang === 'de' ? "German Title" : "English Title")}
                                    value={jobData.name?.find(n => n.language === lang)?.text || ''}
                                    onChange={(e) => setJobData(prev => ({
                                        ...prev,
                                        name: [
                                            ...prev.name.filter(n => n.language !== lang),
                                            {language: lang, text: e.target.value}
                                        ]
                                    }))}
                                />
                            </MDBInputGroup>
                        ))}
                    </MDBCol>
                </MDBRow>

                {/* Description Editor */}
                <MDBRow className="mt-4">
                    <MDBCol size="12">
                        <MDBTypography tag="h5">{t("Job Description")}</MDBTypography>
                        {['de', 'en'].map(lang => (
                            <div key={lang} className="mb-4">
                                <ReactCountryFlag
                                    countryCode={lang === 'de' ? 'de' : 'gb'}
                                    svg
                                    style={{width: "20px", height: "20px"}}
                                    className="mb-2"
                                />
                                <Editor
                                    editorState={editorState[lang]}
                                    onEditorStateChange={(state) => onEditorStateChange(state, lang)}
                                    editorClassName="form-control"
                                    editorStyle={{minHeight: '200px'}}
                                />
                            </div>
                        ))}
                    </MDBCol>
                </MDBRow>

                {/* Image Upload */}
                <MDBRow className="mt-4">
                    <MDBCol size="12">
                        <MDBTypography tag="h5">{t("Job Image")}</MDBTypography>
                        {jobData.image?.fileName && (
                            <img
                                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/newsImages/${jobData.image.fileName}`}
                                className="img-thumbnail mb-3"
                                style={{ maxHeight: "200px" }}
                                alt={t("Job Image")}
                            />
                        )}
                        <MDBFile 
                            onChange={(e) => handleImageUpload(e.target.files[0])}
                            accept="image/*"
                        />
                    </MDBCol>
                </MDBRow>

            </MDBCardBody>
        </MDBCard>
    );
};

export default AdminEditJobListing;