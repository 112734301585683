import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBTypography,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import lectureType from "./lectureCardContents.json";

/* props.type: "technical" | "educational" | "marketing" */

export default function LectureTypeCard({ handleChange, type }) {
  /* let { name, icon, description, incentives } = lectureType.marketingLecture;*/
  let content;
  switch (type) {
    case "technical":
      content = lectureType.technicalLecture;
      break;
    case "educational":
      content = lectureType.educationalLecture;
      break;
    case "marketing":
      content = lectureType.marketingLecture;
      break;
    case "technical_wien":
      content = lectureType.technical_wien;
      break;
    case "educational_wien":
      content = lectureType.educational_wien;
      break;
    case "marketing_wien":
      content = lectureType.marketing_wien;
      break;
    default:
      content = {};
  }
  return (
    <MDBCard alignment="center" className="px-1 px-lg-2 shadow-5 h-100">
      <MDBCardHeader className="py-4 px-1 px-xl-3">
        <MDBTypography tag="h2">{content.name}</MDBTypography>
      </MDBCardHeader>
      <MDBCardBody className="px-1 px-lg-3 px-xl-4">
        <MDBIcon fas icon={content.icon} size="4x"></MDBIcon>
        <MDBCardText className="mt-4">{content.description}</MDBCardText>
        <MDBRow className="mt-5">
          <MDBCol size="3" className="text-end">
            <MDBIcon
              fas
              icon={content.incentives.room.icon}
              size="2x"
              className="me-5"
            ></MDBIcon>
          </MDBCol>
          <MDBCol className="text-start">{content.incentives.room.text}</MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
          <MDBCol size="3" className="text-end">
            <MDBIcon
              fas
              icon={content.incentives.recording.icon}
              size="2x"
              className="me-5"
            ></MDBIcon>
          </MDBCol>
          <MDBCol className="text-start">
            {content.incentives.recording.text}
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-4">
          <MDBCol size="3" className="text-end">
            <MDBIcon
              fas
              icon={content.incentives.networking.icon}
              size="2x"
              className="me-5"
            ></MDBIcon>
          </MDBCol>
          <MDBCol className="text-start">
            {content.incentives.networking.text}
          </MDBCol>
        </MDBRow>
      </MDBCardBody>
      <MDBCardFooter className="mt-2 py-4">
        
          <MDBBtn datapath="type" onClick={handleChange} value={type}>{content.name} einreichen</MDBBtn>
        
      </MDBCardFooter>
    </MDBCard>
  );
}
