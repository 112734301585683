import { useState, useEffect } from 'react';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Handler setzen, der den Wert nach der angegebenen Verzögerung aktualisiert
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup-Funktion, die den Timeout löscht, wenn der Wert oder die Verzögerung sich ändert
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;