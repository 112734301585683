import { MDBTable,  MDBModal, MDBModalTitle, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody, MDBBtn, MDBIcon, MDBCardTitle, MDBRow, MDBCol, MDBInput, MDBCard, MDBCardBody } from "mdb-react-ui-kit"
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
const client = generateClient();
export default function Requests() {
    const { t, i18n } = useTranslation();

    // Requet Affiliation Modal
    const [showAffiliationModal, setShowAffiliationModal] = useState(false);
    const toggleAffiliationModal = () => setShowAffiliationModal(!showAffiliationModal);

  return (
<>
<MDBCard>
    <MDBCardBody>
      <MDBCardTitle className="mb-4">{t("Requests")}</MDBCardTitle>
      <MDBTable>
        <thead>
            <tr>
                <th>{t("User")}</th>
                <th>{t("Request Type")}</th>
                <th>{t("Status")}</th>
                <th>{t("Actions")}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>John Doe</td>
                <td>{t("Affiliation")}</td>
                <td>{t("Pending")}</td>
                <td>
                    <button className="btn btn-primary" onClick={toggleAffiliationModal}>{t("Show")}</button>
                </td>
            </tr>
        </tbody>
      </MDBTable>
    </MDBCardBody>
</MDBCard>

<MDBModal open={showAffiliationModal} onClose={() => setShowAffiliationModal(false)}>
    <MDBModalDialog centered size="lg">
        <MDBModalContent>
            <MDBModalHeader>
                <MDBModalTitle>{t("Affiliation Request")}</MDBModalTitle>
                <MDBBtn className='btn-close' color='none' onClick={toggleAffiliationModal}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
            <MDBCardTitle>
                    </MDBCardTitle>
                      <img
                      src='https://med.gov.bz/wp-content/uploads/2020/08/dummy-profile-pic.jpg'
                      className='rounded-circle shadow-1-strong mx-auto d-block my-4'
                      alt=''
                      width="150" height="150"
                      />
                          {/* <MDBFileUpload acceptedExtensions='image/*' defaultMessage="" /> */}

                  <h5>{t("Position")}</h5>
                  <MDBRow className="mb-3">
                    <MDBCol size="6">
                      <MDBInput className="mb-2 col col-6" label={t("Department")} />
                    </MDBCol>
                    <MDBCol size="6">
                      <MDBInput className="mb-2 col col-6" label={t("Role")} />
                    </MDBCol>
                  </MDBRow>
                
            </MDBModalBody>
        </MDBModalContent>
    </MDBModalDialog>
</MDBModal>
</>
    
  )
}
