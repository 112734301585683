import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBIcon } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const InsightUserOverview = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const client = generateClient();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const userData = await client.graphql({
        query: `
          query SearchUsers($filter: SearchableUserFilterInput) {
            searchUsers(filter: $filter) {
              items {
              id
                videoProgress {
                  items {
                    lecture {
                      title {
                        language
                        text
                      }
                    }
                    totalWatchedInSeconds
                  }
                }
                image {
                  fileName
                }
                lastName
                firstName
                contact {
                  address {
                    company
                  }
                }
              }
            }
          }
        `,
        variables: {
          filter: {
            hasInsightAccess: { eq: true }
          }
        }
      });
      
      const sortedUsers = userData.data.searchUsers.items
        .map(user => ({
          ...user,
          stats: calculateUserStats(user)
        }))
        .sort((a, b) => b.stats.totalWatchTimeSeconds - a.stats.totalWatchTimeSeconds);

      setUsers(sortedUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  const calculateUserStats = (user) => {
    const watchedVideos = new Set();
    let totalWatchTimeSeconds = 0;

    user.videoProgress.items.forEach(progress => {
      const title = progress.lecture.title.find(t => t.language === i18n.language) || progress.lecture.title[0];
      watchedVideos.add(title.text);
      totalWatchTimeSeconds += progress.totalWatchedInSeconds;
    });

    return {
      uniqueVideosWatched: watchedVideos.size,
      totalWatchTimeSeconds: totalWatchTimeSeconds,
      totalWatchTimeMinutes: Math.round(totalWatchTimeSeconds / 60)
    };
  };

  if (loading) {
    return <div>{t('Loading')}...</div>;
  }

  return (
    <MDBContainer className="my-5">
      <h2 className="text-center mb-5">{t('Insight User Overview')}</h2>
      <MDBRow className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
        {users.map((user, index) => (
          <MDBCol key={index}>
            <MDBCard className="h-100">
            <Link className="text-body" to={`/admin/insight/user-course-progress/${user.id}`}>
              <MDBCardBody className="d-flex flex-column align-items-center">
                <div className="text-center mb-3">
                  {user.image?.fileName ?
                    <img
                      src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${user.image.fileName}`}
                      className="rounded-circle border"
                      style={{
                        height: "100px",
                        width: "100px",
                        objectFit: "cover"
                      }}
                      alt={`${user.firstName} ${user.lastName}`}
                    />
                    :
                    <MDBIcon className="rounded-circle border mx-auto" 
                      style={{ 
                        height: "100px", 
                        width: "100px", 
                        fontSize: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f8f9fa"
                      }} 
                      fas 
                      icon="user-alt" 
                    />
                  }
                  <MDBCardTitle className="mt-3">{user.firstName} {user.lastName}</MDBCardTitle>
                  {user.contact?.address?.company && (
                    <MDBCardText>
                      {user.contact.address.company}
                    </MDBCardText>
                  )}
                </div>
                <hr className="w-100" />
                <MDBCardText>
                  <MDBIcon fas icon="video" className="me-2" />
                  {t('Videos watched')}: {user.stats.uniqueVideosWatched}
                </MDBCardText>
                <MDBCardText>
                  <MDBIcon fas icon="clock" className="me-2" />
                  {t('Total watch time')}: {user.stats.totalWatchTimeMinutes} {t('minutes')}
                </MDBCardText>
              </MDBCardBody>
            </Link>
            </MDBCard>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
};

export default InsightUserOverview;