import React, { useState, useEffect } from 'react';
import { resetPassword, confirmResetPassword, signIn } from 'aws-amplify/auth';
import { MDBInput, MDBBtn, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit';
import useDebounce from '../../utils/debounce';
import { useTranslation } from "react-i18next";
import { put } from "aws-amplify/api";
import LangSelect from "../LangSelect";
import { hotjar } from "react-hotjar";

const ResetPassword = ({authRoute, setAuthRoute, isStepConfirmResetPassword, setIsStepConfirmResetPassword}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState(null);



  const debouncedEmail = useDebounce(email, 1000);

  // E-Mail Validierungsfunktion (optional hinzugefügt für Konsistenz)
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());

  // Überprüft, ob die E-Mail-Adresse gültig ist (optional)
  useEffect(() => {
    validateEmail(debouncedEmail);
  }, [debouncedEmail]);

  const handleResetPasswordInitiation = async () => {
    try {
      const output = await resetPassword({ username: email });
      console.log(output); // Optional: Loggen der Ausgabe für Debugging-Zwecke
      setIsStepConfirmResetPassword(true);
    } catch (error) {
      console.error(error);
      setError(error.message || 'An error occurred.');
      if(error.message === "User password cannot be reset in the current state.") {
        resendInvitationEmail();
      }
    }
  };

  const handleResetPasswordConfirmation = async () => {
    hotjar.identify(email, {email: email})
    hotjar.event("Password reset confirmation started");
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode,
        newPassword,
      });
      signIn({ username: email, password: newPassword });
      setAuthRoute("signIn");
      hotjar.event("Password reset successful");
    } catch (error) {
      console.error(error);
      setError(error.message || 'An error occurred.');
      hotjar.event("Password reset failed: " + error.message);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [resendInvitationStatus, setResendInvitationStatus] = useState(null);

  const resendInvitationEmail = async () => {
    if(isLoading) return;
    setIsLoading(true);
    hotjar.identify(email, {email: email})
    try {
      const restOperation = put({
      apiName: 'CPRESTAPI',
      path: '/user/resendinvitation',
      options: {
       
        body: {
          email
        },
      }
    });
    const responseCode = (await restOperation.response).statusCode;
    if(responseCode === 200) {
      setResendInvitationStatus("sent");
      hotjar.event("Resend invitation email successful");
    } else if (responseCode === 400) {
      setResendInvitationStatus("email-required");
      hotjar.event("Resend invitation email failed: email required");
    } 
    } catch (error) {
      console.error('error', error);
      setResendInvitationStatus("error");
      hotjar.event("Resend invitation email failed: " + error.message);
    }
    setIsLoading(false);
  };

  return (
    <>
    <MDBRow className="d-flex align-items-center m-0" style={{ height: "100vh" }}>
      <MDBCol size="12" className="d-flex align-items-center justify-content-center" style={{ height: "100%", backgroundColor: "#eeeeee" }}>
        <MDBCard className="col-12 col-md-8 col-lg-6 col-xl-6 col-xxl-4">
          <MDBCardBody>
          <MDBTabs pills justify className='mb-3'>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signIn")}

                >
                    Login
                </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                <MDBTabsLink
                    onClick={() => setAuthRoute("signUp")}

                >
                    Register
                </MDBTabsLink>
                </MDBTabsItem>
            </MDBTabs>
            {!isStepConfirmResetPassword ? (
              <>
                <h1>{t("Reset Password")}</h1>
                <MDBInput className='mb-4' type='email' data-hj-allow id='email' label={t('Email address')} value={email} onChange={(e) => setEmail(e.target.value)} />
                <MDBBtn onClick={handleResetPasswordInitiation} className='mb-4' block>{t("Send Code")}</MDBBtn>
              </>
            ) : (
              <>
                  <h4>{t("Please check your email for a confirmation code.")}</h4>
                  <p>{t("If you haven't received an email, contact our support")}</p>
                <MDBInput className='mb-4' type='text' data-hj-allow id='confirmationCode' label={t('Confirmation code')} value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} />
                <MDBInput className='mb-4' type='text' id='newPassword' label={t('New Password')} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                <MDBBtn onClick={handleResetPasswordConfirmation} className='mb-4' block>{t("Reset Password")}</MDBBtn>
              </>
            )}
          
            {error && (error === "User password cannot be reset in the current state." ? (
              <div className="alert alert-warning">
                {t("Your account was created but never activated.")}
                <br />
                <strong>{t("Please check your email for a new invitation email.")}</strong>
                <br />
                {t("If you didn't receive an email, contact our support.")}
                
              </div>
            ) : error === "Username/client id combination not found." ? (
              <div className="alert alert-danger text-center">
                {t("No account found with this email address.")}
                <br></br>
                {t("Please register a new account or migrate your account from our old x4Com platform.")}
                <MDBRow className="mt-2">
                  <MDBCol size="6">
                    <MDBBtn onClick={() => setAuthRoute("signUp")} block>{t("Register")}</MDBBtn>
                  </MDBCol>
                  <MDBCol size="6">
                    <MDBBtn onClick={() => setAuthRoute("migrate")} block>{t("Migrate Account")}</MDBBtn>
                  </MDBCol>
                </MDBRow>
              </div>
            )
            
            : <div className="alert alert-danger">{error}</div>)}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    <LangSelect style={{bottom: "0px", right: "0px", position: "absolute"}} />
  </>
  );
};

export default ResetPassword;
