/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const uploadCompanyImage = /* GraphQL */ `
  mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
    uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
  }
`;
export const inviteEmployee = /* GraphQL */ `
  mutation InviteEmployee(
    $userId: String
    $companyId: String
    $departmentId: String
    $role: String
    $existingUser: Boolean
    $newUser: String
    $newEmployee: String
  ) {
    inviteEmployee(
      userId: $userId
      companyId: $companyId
      departmentId: $departmentId
      role: $role
      existingUser: $existingUser
      newUser: $newUser
      newEmployee: $newEmployee
    )
  }
`;
export const requestEmployment = /* GraphQL */ `
  mutation RequestEmployment(
    $companyId: String
    $departmentId: String
    $role: String
  ) {
    requestEmployment(
      companyId: $companyId
      departmentId: $departmentId
      role: $role
    )
  }
`;
export const uploadLectureFile = /* GraphQL */ `
  mutation UploadLectureFile(
    $originalFileName: String
    $base64FileBuffer: String
    $companyId: String
  ) {
    uploadLectureFile(
      originalFileName: $originalFileName
      base64FileBuffer: $base64FileBuffer
      companyId: $companyId
    ) {
      message
      fileName
      __typename
    }
  }
`;
export const createCourse = /* GraphQL */ `
  mutation CreateCourse(
    $input: CreateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    createCourse(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      sortKey
      lectures {
        items {
          id
          courseId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videos
      shortDescription {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      type
      duration
      price
      topics {
        language
        text
        __typename
      }
      speakersDescription {
        language
        text
        __typename
      }
      CTADescription {
        language
        text
        __typename
      }
      CTABulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      WPJetEngineId
      academyTopics {
        items {
          id
          courseId
          academyTopicId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCourse = /* GraphQL */ `
  mutation UpdateCourse(
    $input: UpdateCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    updateCourse(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      sortKey
      lectures {
        items {
          id
          courseId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videos
      shortDescription {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      type
      duration
      price
      topics {
        language
        text
        __typename
      }
      speakersDescription {
        language
        text
        __typename
      }
      CTADescription {
        language
        text
        __typename
      }
      CTABulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      WPJetEngineId
      academyTopics {
        items {
          id
          courseId
          academyTopicId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCourse = /* GraphQL */ `
  mutation DeleteCourse(
    $input: DeleteCourseInput!
    $condition: ModelCourseConditionInput
  ) {
    deleteCourse(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      sortKey
      lectures {
        items {
          id
          courseId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      videos
      shortDescription {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      type
      duration
      price
      topics {
        language
        text
        __typename
      }
      speakersDescription {
        language
        text
        __typename
      }
      CTADescription {
        language
        text
        __typename
      }
      CTABulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      WPJetEngineId
      academyTopics {
        items {
          id
          courseId
          academyTopicId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAcademyTopic = /* GraphQL */ `
  mutation CreateAcademyTopic(
    $input: CreateAcademyTopicInput!
    $condition: ModelAcademyTopicConditionInput
  ) {
    createAcademyTopic(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      sortKey
      academyTopicGroup {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      courses {
        items {
          id
          courseId
          academyTopicId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      seminars {
        items {
          id
          academyTopicId
          seminarId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      academyTopicGroupAcademyTopicsId
      __typename
    }
  }
`;
export const updateAcademyTopic = /* GraphQL */ `
  mutation UpdateAcademyTopic(
    $input: UpdateAcademyTopicInput!
    $condition: ModelAcademyTopicConditionInput
  ) {
    updateAcademyTopic(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      sortKey
      academyTopicGroup {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      courses {
        items {
          id
          courseId
          academyTopicId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      seminars {
        items {
          id
          academyTopicId
          seminarId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      academyTopicGroupAcademyTopicsId
      __typename
    }
  }
`;
export const deleteAcademyTopic = /* GraphQL */ `
  mutation DeleteAcademyTopic(
    $input: DeleteAcademyTopicInput!
    $condition: ModelAcademyTopicConditionInput
  ) {
    deleteAcademyTopic(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      sortKey
      academyTopicGroup {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      courses {
        items {
          id
          courseId
          academyTopicId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      seminars {
        items {
          id
          academyTopicId
          seminarId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      academyTopicGroupAcademyTopicsId
      __typename
    }
  }
`;
export const createAcademyTopicGroup = /* GraphQL */ `
  mutation CreateAcademyTopicGroup(
    $input: CreateAcademyTopicGroupInput!
    $condition: ModelAcademyTopicGroupConditionInput
  ) {
    createAcademyTopicGroup(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      sortKey
      academyTopics {
        items {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAcademyTopicGroup = /* GraphQL */ `
  mutation UpdateAcademyTopicGroup(
    $input: UpdateAcademyTopicGroupInput!
    $condition: ModelAcademyTopicGroupConditionInput
  ) {
    updateAcademyTopicGroup(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      sortKey
      academyTopics {
        items {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAcademyTopicGroup = /* GraphQL */ `
  mutation DeleteAcademyTopicGroup(
    $input: DeleteAcademyTopicGroupInput!
    $condition: ModelAcademyTopicGroupConditionInput
  ) {
    deleteAcademyTopicGroup(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      sortKey
      academyTopics {
        items {
          id
          color
          sortKey
          createdAt
          updatedAt
          academyTopicGroupAcademyTopicsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSeminar = /* GraphQL */ `
  mutation CreateSeminar(
    $input: CreateSeminarInput!
    $condition: ModelSeminarConditionInput
  ) {
    createSeminar(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      cover {
        fileName
        alt
        x4UUID
        __typename
      }
      seminarDays {
        date
        startTime
        endTime
        notice {
          language
          text
          __typename
        }
        __typename
      }
      location {
        name {
          language
          text
          __typename
        }
        travelAndParkingNotice {
          language
          text
          __typename
        }
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      speakers {
        items {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        nextToken
        __typename
      }
      CTABulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      bookingStartDate
      bookingEndDate
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      academyTopics {
        items {
          id
          academyTopicId
          seminarId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      sortKey
      WPJetEngineId
      createdAt
      updatedAt
      seminarProductId
      __typename
    }
  }
`;
export const updateSeminar = /* GraphQL */ `
  mutation UpdateSeminar(
    $input: UpdateSeminarInput!
    $condition: ModelSeminarConditionInput
  ) {
    updateSeminar(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      cover {
        fileName
        alt
        x4UUID
        __typename
      }
      seminarDays {
        date
        startTime
        endTime
        notice {
          language
          text
          __typename
        }
        __typename
      }
      location {
        name {
          language
          text
          __typename
        }
        travelAndParkingNotice {
          language
          text
          __typename
        }
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      speakers {
        items {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        nextToken
        __typename
      }
      CTABulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      bookingStartDate
      bookingEndDate
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      academyTopics {
        items {
          id
          academyTopicId
          seminarId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      sortKey
      WPJetEngineId
      createdAt
      updatedAt
      seminarProductId
      __typename
    }
  }
`;
export const deleteSeminar = /* GraphQL */ `
  mutation DeleteSeminar(
    $input: DeleteSeminarInput!
    $condition: ModelSeminarConditionInput
  ) {
    deleteSeminar(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      cover {
        fileName
        alt
        x4UUID
        __typename
      }
      seminarDays {
        date
        startTime
        endTime
        notice {
          language
          text
          __typename
        }
        __typename
      }
      location {
        name {
          language
          text
          __typename
        }
        travelAndParkingNotice {
          language
          text
          __typename
        }
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      speakers {
        items {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        nextToken
        __typename
      }
      CTABulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      bookingStartDate
      bookingEndDate
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      academyTopics {
        items {
          id
          academyTopicId
          seminarId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      status
      sortKey
      WPJetEngineId
      createdAt
      updatedAt
      seminarProductId
      __typename
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      owner
      id
      industryId
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdByUserId
      userTicketsId
      eventDays
      status
      paymentStatus
      type
      visitorType
      addons
      payment {
        id
        orderFinancial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        status
        isPayed
        amount
        currency
        paymentProvider
        transactionId
        paymentDate
        paymentAmount
        paymentCurrency
        createdAt
        updatedAt
        orderFinancialPaymentsId
        __typename
      }
      ticketFile {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      notes
      personalAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingEmail
      invitationCode
      orderNumber
      firstName
      lastName
      companyName
      scans {
        items {
          id
          scannedAt
          scannedLocation
          scannedDevice
          createdAt
          updatedAt
          ticketScansId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      ticketEventId
      ticketCompanyId
      ticketPaymentId
      __typename
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      owner
      id
      industryId
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdByUserId
      userTicketsId
      eventDays
      status
      paymentStatus
      type
      visitorType
      addons
      payment {
        id
        orderFinancial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        status
        isPayed
        amount
        currency
        paymentProvider
        transactionId
        paymentDate
        paymentAmount
        paymentCurrency
        createdAt
        updatedAt
        orderFinancialPaymentsId
        __typename
      }
      ticketFile {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      notes
      personalAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingEmail
      invitationCode
      orderNumber
      firstName
      lastName
      companyName
      scans {
        items {
          id
          scannedAt
          scannedLocation
          scannedDevice
          createdAt
          updatedAt
          ticketScansId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      ticketEventId
      ticketCompanyId
      ticketPaymentId
      __typename
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      owner
      id
      industryId
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdByUserId
      userTicketsId
      eventDays
      status
      paymentStatus
      type
      visitorType
      addons
      payment {
        id
        orderFinancial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        status
        isPayed
        amount
        currency
        paymentProvider
        transactionId
        paymentDate
        paymentAmount
        paymentCurrency
        createdAt
        updatedAt
        orderFinancialPaymentsId
        __typename
      }
      ticketFile {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      notes
      personalAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingEmail
      invitationCode
      orderNumber
      firstName
      lastName
      companyName
      scans {
        items {
          id
          scannedAt
          scannedLocation
          scannedDevice
          createdAt
          updatedAt
          ticketScansId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      ticketEventId
      ticketCompanyId
      ticketPaymentId
      __typename
    }
  }
`;
export const createTicketScan = /* GraphQL */ `
  mutation CreateTicketScan(
    $input: CreateTicketScanInput!
    $condition: ModelTicketScanConditionInput
  ) {
    createTicketScan(input: $input, condition: $condition) {
      id
      ticket {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      scannedAt
      scannedLocation
      scannedDevice
      createdAt
      updatedAt
      ticketScansId
      owner
      __typename
    }
  }
`;
export const updateTicketScan = /* GraphQL */ `
  mutation UpdateTicketScan(
    $input: UpdateTicketScanInput!
    $condition: ModelTicketScanConditionInput
  ) {
    updateTicketScan(input: $input, condition: $condition) {
      id
      ticket {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      scannedAt
      scannedLocation
      scannedDevice
      createdAt
      updatedAt
      ticketScansId
      owner
      __typename
    }
  }
`;
export const deleteTicketScan = /* GraphQL */ `
  mutation DeleteTicketScan(
    $input: DeleteTicketScanInput!
    $condition: ModelTicketScanConditionInput
  ) {
    deleteTicketScan(input: $input, condition: $condition) {
      id
      ticket {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      scannedAt
      scannedLocation
      scannedDevice
      createdAt
      updatedAt
      ticketScansId
      owner
      __typename
    }
  }
`;
export const createInvitationCode = /* GraphQL */ `
  mutation CreateInvitationCode(
    $input: CreateInvitationCodeInput!
    $condition: ModelInvitationCodeConditionInput
  ) {
    createInvitationCode(input: $input, condition: $condition) {
      id
      code
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdAt
      updatedAt
      companyInvitationCodesId
      eventInvitationCodesId
      owner
      __typename
    }
  }
`;
export const updateInvitationCode = /* GraphQL */ `
  mutation UpdateInvitationCode(
    $input: UpdateInvitationCodeInput!
    $condition: ModelInvitationCodeConditionInput
  ) {
    updateInvitationCode(input: $input, condition: $condition) {
      id
      code
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdAt
      updatedAt
      companyInvitationCodesId
      eventInvitationCodesId
      owner
      __typename
    }
  }
`;
export const deleteInvitationCode = /* GraphQL */ `
  mutation DeleteInvitationCode(
    $input: DeleteInvitationCodeInput!
    $condition: ModelInvitationCodeConditionInput
  ) {
    deleteInvitationCode(input: $input, condition: $condition) {
      id
      code
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdAt
      updatedAt
      companyInvitationCodesId
      eventInvitationCodesId
      owner
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      visibleInShop
      name {
        language
        text
        __typename
      }
      category {
        id
        name {
          language
          text
          __typename
        }
        customerTypes
        description {
          language
          text
          __typename
        }
        sortKey
        image {
          __typename
        }
        products {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      invoiceName {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      includes {
        language
        text
        __typename
      }
      sku
      ean
      type
      subType
      price
      downPaymentPrice
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      orders {
        items {
          id
          productId
          orderId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      orderLineItems {
        items {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        nextToken
        __typename
      }
      allowedBuyerGroups
      requiredShipping
      icon
      stock
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      availableInIndustries {
        items {
          id
          productId
          industryId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableInEvents {
        items {
          id
          productId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      eventProducts {
        items {
          id
          price
          stock
          isAvailable
          createdAt
          updatedAt
          productEventProductsId
          eventEventProductsId
          __typename
        }
        nextToken
        __typename
      }
      components {
        items {
          id
          parentProductID
          componentProductID
          qty
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      productCategoryProductsId
      bundleBoothAvaiableAccessoriesId
      bundleBoothIncludedAccessoriesId
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      visibleInShop
      name {
        language
        text
        __typename
      }
      category {
        id
        name {
          language
          text
          __typename
        }
        customerTypes
        description {
          language
          text
          __typename
        }
        sortKey
        image {
          __typename
        }
        products {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      invoiceName {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      includes {
        language
        text
        __typename
      }
      sku
      ean
      type
      subType
      price
      downPaymentPrice
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      orders {
        items {
          id
          productId
          orderId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      orderLineItems {
        items {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        nextToken
        __typename
      }
      allowedBuyerGroups
      requiredShipping
      icon
      stock
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      availableInIndustries {
        items {
          id
          productId
          industryId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableInEvents {
        items {
          id
          productId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      eventProducts {
        items {
          id
          price
          stock
          isAvailable
          createdAt
          updatedAt
          productEventProductsId
          eventEventProductsId
          __typename
        }
        nextToken
        __typename
      }
      components {
        items {
          id
          parentProductID
          componentProductID
          qty
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      productCategoryProductsId
      bundleBoothAvaiableAccessoriesId
      bundleBoothIncludedAccessoriesId
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      visibleInShop
      name {
        language
        text
        __typename
      }
      category {
        id
        name {
          language
          text
          __typename
        }
        customerTypes
        description {
          language
          text
          __typename
        }
        sortKey
        image {
          __typename
        }
        products {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      invoiceName {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      includes {
        language
        text
        __typename
      }
      sku
      ean
      type
      subType
      price
      downPaymentPrice
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      orders {
        items {
          id
          productId
          orderId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      orderLineItems {
        items {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        nextToken
        __typename
      }
      allowedBuyerGroups
      requiredShipping
      icon
      stock
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      availableInIndustries {
        items {
          id
          productId
          industryId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableInEvents {
        items {
          id
          productId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      eventProducts {
        items {
          id
          price
          stock
          isAvailable
          createdAt
          updatedAt
          productEventProductsId
          eventEventProductsId
          __typename
        }
        nextToken
        __typename
      }
      components {
        items {
          id
          parentProductID
          componentProductID
          qty
          order
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      productCategoryProductsId
      bundleBoothAvaiableAccessoriesId
      bundleBoothIncludedAccessoriesId
      __typename
    }
  }
`;
export const createProductCategory = /* GraphQL */ `
  mutation CreateProductCategory(
    $input: CreateProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    createProductCategory(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      customerTypes
      description {
        language
        text
        __typename
      }
      sortKey
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      products {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductCategory = /* GraphQL */ `
  mutation UpdateProductCategory(
    $input: UpdateProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    updateProductCategory(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      customerTypes
      description {
        language
        text
        __typename
      }
      sortKey
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      products {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductCategory = /* GraphQL */ `
  mutation DeleteProductCategory(
    $input: DeleteProductCategoryInput!
    $condition: ModelProductCategoryConditionInput
  ) {
    deleteProductCategory(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      customerTypes
      description {
        language
        text
        __typename
      }
      sortKey
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      products {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProductComponent = /* GraphQL */ `
  mutation CreateProductComponent(
    $input: CreateProductComponentInput!
    $condition: ModelProductComponentConditionInput
  ) {
    createProductComponent(input: $input, condition: $condition) {
      id
      parentProductID
      parentProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      componentProductID
      componentProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      qty
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProductComponent = /* GraphQL */ `
  mutation UpdateProductComponent(
    $input: UpdateProductComponentInput!
    $condition: ModelProductComponentConditionInput
  ) {
    updateProductComponent(input: $input, condition: $condition) {
      id
      parentProductID
      parentProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      componentProductID
      componentProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      qty
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProductComponent = /* GraphQL */ `
  mutation DeleteProductComponent(
    $input: DeleteProductComponentInput!
    $condition: ModelProductComponentConditionInput
  ) {
    deleteProductComponent(input: $input, condition: $condition) {
      id
      parentProductID
      parentProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      componentProductID
      componentProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      qty
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventProduct = /* GraphQL */ `
  mutation CreateEventProduct(
    $input: CreateEventProductInput!
    $condition: ModelEventProductConditionInput
  ) {
    createEventProduct(input: $input, condition: $condition) {
      id
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      price
      stock
      isAvailable
      createdAt
      updatedAt
      productEventProductsId
      eventEventProductsId
      __typename
    }
  }
`;
export const updateEventProduct = /* GraphQL */ `
  mutation UpdateEventProduct(
    $input: UpdateEventProductInput!
    $condition: ModelEventProductConditionInput
  ) {
    updateEventProduct(input: $input, condition: $condition) {
      id
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      price
      stock
      isAvailable
      createdAt
      updatedAt
      productEventProductsId
      eventEventProductsId
      __typename
    }
  }
`;
export const deleteEventProduct = /* GraphQL */ `
  mutation DeleteEventProduct(
    $input: DeleteEventProductInput!
    $condition: ModelEventProductConditionInput
  ) {
    deleteEventProduct(input: $input, condition: $condition) {
      id
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      price
      stock
      isAvailable
      createdAt
      updatedAt
      productEventProductsId
      eventEventProductsId
      __typename
    }
  }
`;
export const createStockReservation = /* GraphQL */ `
  mutation CreateStockReservation(
    $input: CreateStockReservationInput!
    $condition: ModelStockReservationConditionInput
  ) {
    createStockReservation(input: $input, condition: $condition) {
      id
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      qty
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      status
      reservationDate
      reservationEndDate
      createdAt
      updatedAt
      productStockReservationsId
      orderStockReservationsId
      companyStockReservationsId
      userStockReservationsId
      __typename
    }
  }
`;
export const updateStockReservation = /* GraphQL */ `
  mutation UpdateStockReservation(
    $input: UpdateStockReservationInput!
    $condition: ModelStockReservationConditionInput
  ) {
    updateStockReservation(input: $input, condition: $condition) {
      id
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      qty
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      status
      reservationDate
      reservationEndDate
      createdAt
      updatedAt
      productStockReservationsId
      orderStockReservationsId
      companyStockReservationsId
      userStockReservationsId
      __typename
    }
  }
`;
export const deleteStockReservation = /* GraphQL */ `
  mutation DeleteStockReservation(
    $input: DeleteStockReservationInput!
    $condition: ModelStockReservationConditionInput
  ) {
    deleteStockReservation(input: $input, condition: $condition) {
      id
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      qty
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      status
      reservationDate
      reservationEndDate
      createdAt
      updatedAt
      productStockReservationsId
      orderStockReservationsId
      companyStockReservationsId
      userStockReservationsId
      __typename
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      industryId
      type
      orderNumber
      buyerOrderNumber
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      status
      paymentStatus
      deliveryStatus
      products {
        items {
          id
          productId
          orderId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lineItems {
        items {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        nextToken
        __typename
      }
      notes
      deliveryAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingEmail
      financial {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      data
      totalAmount
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyOrdersId
      userOrdersId
      orderFinancialId
      owner
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      industryId
      type
      orderNumber
      buyerOrderNumber
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      status
      paymentStatus
      deliveryStatus
      products {
        items {
          id
          productId
          orderId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lineItems {
        items {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        nextToken
        __typename
      }
      notes
      deliveryAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingEmail
      financial {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      data
      totalAmount
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyOrdersId
      userOrdersId
      orderFinancialId
      owner
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      industryId
      type
      orderNumber
      buyerOrderNumber
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      status
      paymentStatus
      deliveryStatus
      products {
        items {
          id
          productId
          orderId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      lineItems {
        items {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        nextToken
        __typename
      }
      notes
      deliveryAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      billingEmail
      financial {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      data
      totalAmount
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyOrdersId
      userOrdersId
      orderFinancialId
      owner
      __typename
    }
  }
`;
export const createOrderFinancial = /* GraphQL */ `
  mutation CreateOrderFinancial(
    $input: CreateOrderFinancialInput!
    $condition: ModelOrderFinancialConditionInput
  ) {
    createOrderFinancial(input: $input, condition: $condition) {
      id
      isBusiness
      isInterEUDelivery
      vatInfo {
        vatNumber
        country
        __typename
      }
      currency
      documents {
        documentType
        s3Path
        documentNumber
        referingDocumentNumber
        documentName
        status
        date
        amount
        amountNet
        sentToCustomerTime
        sentToCustomerAddresses
        __typename
      }
      invoiceAmount
      invoiceAmountNet
      invoiceNumber
      invoiceDate
      invoiceFile {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      invoiceLastSent
      invoiceReceivers
      servicePeriodStart
      servicePeriodEnd
      taxRate
      shippingCost
      shippingCostNet
      paymentCost
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      payments {
        items {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        nextToken
        __typename
      }
      discountCode
      discountAmount
      discountType
      paymentProvider
      totalAmount
      createdAt
      updatedAt
      orderFinancialOrderId
      __typename
    }
  }
`;
export const updateOrderFinancial = /* GraphQL */ `
  mutation UpdateOrderFinancial(
    $input: UpdateOrderFinancialInput!
    $condition: ModelOrderFinancialConditionInput
  ) {
    updateOrderFinancial(input: $input, condition: $condition) {
      id
      isBusiness
      isInterEUDelivery
      vatInfo {
        vatNumber
        country
        __typename
      }
      currency
      documents {
        documentType
        s3Path
        documentNumber
        referingDocumentNumber
        documentName
        status
        date
        amount
        amountNet
        sentToCustomerTime
        sentToCustomerAddresses
        __typename
      }
      invoiceAmount
      invoiceAmountNet
      invoiceNumber
      invoiceDate
      invoiceFile {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      invoiceLastSent
      invoiceReceivers
      servicePeriodStart
      servicePeriodEnd
      taxRate
      shippingCost
      shippingCostNet
      paymentCost
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      payments {
        items {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        nextToken
        __typename
      }
      discountCode
      discountAmount
      discountType
      paymentProvider
      totalAmount
      createdAt
      updatedAt
      orderFinancialOrderId
      __typename
    }
  }
`;
export const deleteOrderFinancial = /* GraphQL */ `
  mutation DeleteOrderFinancial(
    $input: DeleteOrderFinancialInput!
    $condition: ModelOrderFinancialConditionInput
  ) {
    deleteOrderFinancial(input: $input, condition: $condition) {
      id
      isBusiness
      isInterEUDelivery
      vatInfo {
        vatNumber
        country
        __typename
      }
      currency
      documents {
        documentType
        s3Path
        documentNumber
        referingDocumentNumber
        documentName
        status
        date
        amount
        amountNet
        sentToCustomerTime
        sentToCustomerAddresses
        __typename
      }
      invoiceAmount
      invoiceAmountNet
      invoiceNumber
      invoiceDate
      invoiceFile {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      invoiceLastSent
      invoiceReceivers
      servicePeriodStart
      servicePeriodEnd
      taxRate
      shippingCost
      shippingCostNet
      paymentCost
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      payments {
        items {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        nextToken
        __typename
      }
      discountCode
      discountAmount
      discountType
      paymentProvider
      totalAmount
      createdAt
      updatedAt
      orderFinancialOrderId
      __typename
    }
  }
`;
export const createOrderLineItem = /* GraphQL */ `
  mutation CreateOrderLineItem(
    $input: CreateOrderLineItemInput!
    $condition: ModelOrderLineItemConditionInput
  ) {
    createOrderLineItem(input: $input, condition: $condition) {
      id
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      parentLineItemId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      productID
      qty
      price
      downPaymentPrice
      discount
      discountType
      taxRate
      taxAmount
      bookedBooth {
        id
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      bookedBoothEquipment {
        id
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        qty
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        status
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        data
        createdAt
        updatedAt
        bookedBoothEquipmentId
        boothEquipmentOrderLineItemId
        __typename
      }
      bookedTicket {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      createdAt
      updatedAt
      productOrderLineItemsId
      orderLineItemsId
      orderLineItemProductId
      orderLineItemBookedBoothId
      orderLineItemBookedBoothEquipmentId
      orderLineItemBookedTicketId
      __typename
    }
  }
`;
export const updateOrderLineItem = /* GraphQL */ `
  mutation UpdateOrderLineItem(
    $input: UpdateOrderLineItemInput!
    $condition: ModelOrderLineItemConditionInput
  ) {
    updateOrderLineItem(input: $input, condition: $condition) {
      id
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      parentLineItemId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      productID
      qty
      price
      downPaymentPrice
      discount
      discountType
      taxRate
      taxAmount
      bookedBooth {
        id
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      bookedBoothEquipment {
        id
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        qty
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        status
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        data
        createdAt
        updatedAt
        bookedBoothEquipmentId
        boothEquipmentOrderLineItemId
        __typename
      }
      bookedTicket {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      createdAt
      updatedAt
      productOrderLineItemsId
      orderLineItemsId
      orderLineItemProductId
      orderLineItemBookedBoothId
      orderLineItemBookedBoothEquipmentId
      orderLineItemBookedTicketId
      __typename
    }
  }
`;
export const deleteOrderLineItem = /* GraphQL */ `
  mutation DeleteOrderLineItem(
    $input: DeleteOrderLineItemInput!
    $condition: ModelOrderLineItemConditionInput
  ) {
    deleteOrderLineItem(input: $input, condition: $condition) {
      id
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      parentLineItemId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      productID
      qty
      price
      downPaymentPrice
      discount
      discountType
      taxRate
      taxAmount
      bookedBooth {
        id
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      bookedBoothEquipment {
        id
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        qty
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        status
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        data
        createdAt
        updatedAt
        bookedBoothEquipmentId
        boothEquipmentOrderLineItemId
        __typename
      }
      bookedTicket {
        owner
        id
        industryId
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        createdByUserId
        userTicketsId
        eventDays
        status
        paymentStatus
        type
        visitorType
        addons
        payment {
          id
          status
          isPayed
          amount
          currency
          paymentProvider
          transactionId
          paymentDate
          paymentAmount
          paymentCurrency
          createdAt
          updatedAt
          orderFinancialPaymentsId
          __typename
        }
        ticketFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        notes
        personalAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        invitationCode
        orderNumber
        firstName
        lastName
        companyName
        scans {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        ticketEventId
        ticketCompanyId
        ticketPaymentId
        __typename
      }
      createdAt
      updatedAt
      productOrderLineItemsId
      orderLineItemsId
      orderLineItemProductId
      orderLineItemBookedBoothId
      orderLineItemBookedBoothEquipmentId
      orderLineItemBookedTicketId
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      orderFinancial {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      status
      isPayed
      amount
      currency
      paymentProvider
      transactionId
      paymentDate
      paymentAmount
      paymentCurrency
      createdAt
      updatedAt
      orderFinancialPaymentsId
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      orderFinancial {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      status
      isPayed
      amount
      currency
      paymentProvider
      transactionId
      paymentDate
      paymentAmount
      paymentCurrency
      createdAt
      updatedAt
      orderFinancialPaymentsId
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      orderFinancial {
        id
        isBusiness
        isInterEUDelivery
        vatInfo {
          vatNumber
          country
          __typename
        }
        currency
        documents {
          documentType
          s3Path
          documentNumber
          referingDocumentNumber
          documentName
          status
          date
          amount
          amountNet
          sentToCustomerTime
          sentToCustomerAddresses
          __typename
        }
        invoiceAmount
        invoiceAmountNet
        invoiceNumber
        invoiceDate
        invoiceFile {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        invoiceLastSent
        invoiceReceivers
        servicePeriodStart
        servicePeriodEnd
        taxRate
        shippingCost
        shippingCostNet
        paymentCost
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        payments {
          nextToken
          __typename
        }
        discountCode
        discountAmount
        discountType
        paymentProvider
        totalAmount
        createdAt
        updatedAt
        orderFinancialOrderId
        __typename
      }
      status
      isPayed
      amount
      currency
      paymentProvider
      transactionId
      paymentDate
      paymentAmount
      paymentCurrency
      createdAt
      updatedAt
      orderFinancialPaymentsId
      __typename
    }
  }
`;
export const createBundleBooth = /* GraphQL */ `
  mutation CreateBundleBooth(
    $input: CreateBundleBoothInput!
    $condition: ModelBundleBoothConditionInput
  ) {
    createBundleBooth(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      sku
      boothNumber
      availableInEvents {
        items {
          id
          bundleBoothId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      price
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      floorPlan {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      status
      availablePrints {
        items {
          id
          dimensions
          type
          createdAt
          updatedAt
          bundleBoothAvailablePrintsId
          __typename
        }
        nextToken
        __typename
      }
      avaiableFloors {
        items {
          id
          color
          createdAt
          updatedAt
          bundleBoothAvaiableFloorsId
          __typename
        }
        nextToken
        __typename
      }
      avaiableAccessories {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      includedMarketingPackage {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      includedAccessories {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBundleBooth = /* GraphQL */ `
  mutation UpdateBundleBooth(
    $input: UpdateBundleBoothInput!
    $condition: ModelBundleBoothConditionInput
  ) {
    updateBundleBooth(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      sku
      boothNumber
      availableInEvents {
        items {
          id
          bundleBoothId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      price
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      floorPlan {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      status
      availablePrints {
        items {
          id
          dimensions
          type
          createdAt
          updatedAt
          bundleBoothAvailablePrintsId
          __typename
        }
        nextToken
        __typename
      }
      avaiableFloors {
        items {
          id
          color
          createdAt
          updatedAt
          bundleBoothAvaiableFloorsId
          __typename
        }
        nextToken
        __typename
      }
      avaiableAccessories {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      includedMarketingPackage {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      includedAccessories {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBundleBooth = /* GraphQL */ `
  mutation DeleteBundleBooth(
    $input: DeleteBundleBoothInput!
    $condition: ModelBundleBoothConditionInput
  ) {
    deleteBundleBooth(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      sku
      boothNumber
      availableInEvents {
        items {
          id
          bundleBoothId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      price
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      floorPlan {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      status
      availablePrints {
        items {
          id
          dimensions
          type
          createdAt
          updatedAt
          bundleBoothAvailablePrintsId
          __typename
        }
        nextToken
        __typename
      }
      avaiableFloors {
        items {
          id
          color
          createdAt
          updatedAt
          bundleBoothAvaiableFloorsId
          __typename
        }
        nextToken
        __typename
      }
      avaiableAccessories {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      includedMarketingPackage {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      includedAccessories {
        items {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBookedBooth = /* GraphQL */ `
  mutation CreateBookedBooth(
    $input: CreateBookedBoothInput!
    $condition: ModelBookedBoothConditionInput
  ) {
    createBookedBooth(input: $input, condition: $condition) {
      id
      orderLineItem {
        id
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      boothNumber
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      status
      equipment {
        items {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyBookedBoothsId
      eventBookedBoothsId
      bookedBoothOrderLineItemId
      __typename
    }
  }
`;
export const updateBookedBooth = /* GraphQL */ `
  mutation UpdateBookedBooth(
    $input: UpdateBookedBoothInput!
    $condition: ModelBookedBoothConditionInput
  ) {
    updateBookedBooth(input: $input, condition: $condition) {
      id
      orderLineItem {
        id
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      boothNumber
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      status
      equipment {
        items {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyBookedBoothsId
      eventBookedBoothsId
      bookedBoothOrderLineItemId
      __typename
    }
  }
`;
export const deleteBookedBooth = /* GraphQL */ `
  mutation DeleteBookedBooth(
    $input: DeleteBookedBoothInput!
    $condition: ModelBookedBoothConditionInput
  ) {
    deleteBookedBooth(input: $input, condition: $condition) {
      id
      orderLineItem {
        id
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      boothNumber
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      status
      equipment {
        items {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyBookedBoothsId
      eventBookedBoothsId
      bookedBoothOrderLineItemId
      __typename
    }
  }
`;
export const createBoothEquipment = /* GraphQL */ `
  mutation CreateBoothEquipment(
    $input: CreateBoothEquipmentInput!
    $condition: ModelBoothEquipmentConditionInput
  ) {
    createBoothEquipment(input: $input, condition: $condition) {
      id
      bookedBooth {
        id
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      qty
      orderLineItem {
        id
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      status
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      data
      createdAt
      updatedAt
      bookedBoothEquipmentId
      boothEquipmentOrderLineItemId
      __typename
    }
  }
`;
export const updateBoothEquipment = /* GraphQL */ `
  mutation UpdateBoothEquipment(
    $input: UpdateBoothEquipmentInput!
    $condition: ModelBoothEquipmentConditionInput
  ) {
    updateBoothEquipment(input: $input, condition: $condition) {
      id
      bookedBooth {
        id
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      qty
      orderLineItem {
        id
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      status
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      data
      createdAt
      updatedAt
      bookedBoothEquipmentId
      boothEquipmentOrderLineItemId
      __typename
    }
  }
`;
export const deleteBoothEquipment = /* GraphQL */ `
  mutation DeleteBoothEquipment(
    $input: DeleteBoothEquipmentInput!
    $condition: ModelBoothEquipmentConditionInput
  ) {
    deleteBoothEquipment(input: $input, condition: $condition) {
      id
      bookedBooth {
        id
        orderLineItem {
          id
          parentLineItemId
          productID
          qty
          price
          downPaymentPrice
          discount
          discountType
          taxRate
          taxAmount
          createdAt
          updatedAt
          productOrderLineItemsId
          orderLineItemsId
          orderLineItemProductId
          orderLineItemBookedBoothId
          orderLineItemBookedBoothEquipmentId
          orderLineItemBookedTicketId
          __typename
        }
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        boothNumber
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        equipment {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyBookedBoothsId
        eventBookedBoothsId
        bookedBoothOrderLineItemId
        __typename
      }
      qty
      orderLineItem {
        id
        order {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        parentLineItemId
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        productID
        qty
        price
        downPaymentPrice
        discount
        discountType
        taxRate
        taxAmount
        bookedBooth {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        bookedBoothEquipment {
          id
          qty
          status
          data
          createdAt
          updatedAt
          bookedBoothEquipmentId
          boothEquipmentOrderLineItemId
          __typename
        }
        bookedTicket {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        createdAt
        updatedAt
        productOrderLineItemsId
        orderLineItemsId
        orderLineItemProductId
        orderLineItemBookedBoothId
        orderLineItemBookedBoothEquipmentId
        orderLineItemBookedTicketId
        __typename
      }
      status
      files {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      data
      createdAt
      updatedAt
      bookedBoothEquipmentId
      boothEquipmentOrderLineItemId
      __typename
    }
  }
`;
export const createDiscountCode = /* GraphQL */ `
  mutation CreateDiscountCode(
    $input: CreateDiscountCodeInput!
    $condition: ModelDiscountCodeConditionInput
  ) {
    createDiscountCode(input: $input, condition: $condition) {
      id
      code
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      discountType
      discountAmount
      discountPercentage
      maxUsage
      usageCount
      startDate
      endDate
      status
      restrictedProductIds
      canBeCombined
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDiscountCode = /* GraphQL */ `
  mutation UpdateDiscountCode(
    $input: UpdateDiscountCodeInput!
    $condition: ModelDiscountCodeConditionInput
  ) {
    updateDiscountCode(input: $input, condition: $condition) {
      id
      code
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      discountType
      discountAmount
      discountPercentage
      maxUsage
      usageCount
      startDate
      endDate
      status
      restrictedProductIds
      canBeCombined
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDiscountCode = /* GraphQL */ `
  mutation DeleteDiscountCode(
    $input: DeleteDiscountCodeInput!
    $condition: ModelDiscountCodeConditionInput
  ) {
    deleteDiscountCode(input: $input, condition: $condition) {
      id
      code
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      discountType
      discountAmount
      discountPercentage
      maxUsage
      usageCount
      startDate
      endDate
      status
      restrictedProductIds
      canBeCombined
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTopic = /* GraphQL */ `
  mutation CreateTopic(
    $input: CreateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    createTopic(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      topicGroup {
        id
        name {
          language
          text
          __typename
        }
        color
        topics {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        __typename
      }
      companies {
        items {
          id
          topicId
          companyId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          topicId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sortKey
      createdAt
      updatedAt
      topicGroupTopicsId
      __typename
    }
  }
`;
export const updateTopic = /* GraphQL */ `
  mutation UpdateTopic(
    $input: UpdateTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    updateTopic(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      topicGroup {
        id
        name {
          language
          text
          __typename
        }
        color
        topics {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        __typename
      }
      companies {
        items {
          id
          topicId
          companyId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          topicId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sortKey
      createdAt
      updatedAt
      topicGroupTopicsId
      __typename
    }
  }
`;
export const deleteTopic = /* GraphQL */ `
  mutation DeleteTopic(
    $input: DeleteTopicInput!
    $condition: ModelTopicConditionInput
  ) {
    deleteTopic(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      topicGroup {
        id
        name {
          language
          text
          __typename
        }
        color
        topics {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        __typename
      }
      companies {
        items {
          id
          topicId
          companyId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          topicId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      sortKey
      createdAt
      updatedAt
      topicGroupTopicsId
      __typename
    }
  }
`;
export const createTopicGroup = /* GraphQL */ `
  mutation CreateTopicGroup(
    $input: CreateTopicGroupInput!
    $condition: ModelTopicGroupConditionInput
  ) {
    createTopicGroup(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      topics {
        items {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        nextToken
        __typename
      }
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTopicGroup = /* GraphQL */ `
  mutation UpdateTopicGroup(
    $input: UpdateTopicGroupInput!
    $condition: ModelTopicGroupConditionInput
  ) {
    updateTopicGroup(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      topics {
        items {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        nextToken
        __typename
      }
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTopicGroup = /* GraphQL */ `
  mutation DeleteTopicGroup(
    $input: DeleteTopicGroupInput!
    $condition: ModelTopicGroupConditionInput
  ) {
    deleteTopicGroup(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      color
      topics {
        items {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        nextToken
        __typename
      }
      sortKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCMSInterests = /* GraphQL */ `
  mutation CreateCMSInterests(
    $input: CreateCMSInterestsInput!
    $condition: ModelCMSInterestsConditionInput
  ) {
    createCMSInterests(input: $input, condition: $condition) {
      name {
        language
        text
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCMSInterests = /* GraphQL */ `
  mutation UpdateCMSInterests(
    $input: UpdateCMSInterestsInput!
    $condition: ModelCMSInterestsConditionInput
  ) {
    updateCMSInterests(input: $input, condition: $condition) {
      name {
        language
        text
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCMSInterests = /* GraphQL */ `
  mutation DeleteCMSInterests(
    $input: DeleteCMSInterestsInput!
    $condition: ModelCMSInterestsConditionInput
  ) {
    deleteCMSInterests(input: $input, condition: $condition) {
      name {
        language
        text
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCMSNews = /* GraphQL */ `
  mutation CreateCMSNews(
    $input: CreateCMSNewsInput!
    $condition: ModelCMSNewsConditionInput
  ) {
    createCMSNews(input: $input, condition: $condition) {
      id
      type
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      name {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      excerpt {
        language
        text
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      content {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      isHero
      createdAt
      updatedAt
      companyNewsId
      employeeNewsId
      cMSNewsEmployeeId
      cMSNewsTopicId
      owner
      __typename
    }
  }
`;
export const updateCMSNews = /* GraphQL */ `
  mutation UpdateCMSNews(
    $input: UpdateCMSNewsInput!
    $condition: ModelCMSNewsConditionInput
  ) {
    updateCMSNews(input: $input, condition: $condition) {
      id
      type
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      name {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      excerpt {
        language
        text
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      content {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      isHero
      createdAt
      updatedAt
      companyNewsId
      employeeNewsId
      cMSNewsEmployeeId
      cMSNewsTopicId
      owner
      __typename
    }
  }
`;
export const deleteCMSNews = /* GraphQL */ `
  mutation DeleteCMSNews(
    $input: DeleteCMSNewsInput!
    $condition: ModelCMSNewsConditionInput
  ) {
    deleteCMSNews(input: $input, condition: $condition) {
      id
      type
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      name {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      excerpt {
        language
        text
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      content {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      isHero
      createdAt
      updatedAt
      companyNewsId
      employeeNewsId
      cMSNewsEmployeeId
      cMSNewsTopicId
      owner
      __typename
    }
  }
`;
export const createNewsTicketNews = /* GraphQL */ `
  mutation CreateNewsTicketNews(
    $input: CreateNewsTicketNewsInput!
    $condition: ModelNewsTicketNewsConditionInput
  ) {
    createNewsTicketNews(input: $input, condition: $condition) {
      id
      title {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      link
      excerpt {
        language
        text
        __typename
      }
      content {
        language
        text
        __typename
      }
      externalNewsId
      WPJetEngineId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNewsTicketNews = /* GraphQL */ `
  mutation UpdateNewsTicketNews(
    $input: UpdateNewsTicketNewsInput!
    $condition: ModelNewsTicketNewsConditionInput
  ) {
    updateNewsTicketNews(input: $input, condition: $condition) {
      id
      title {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      link
      excerpt {
        language
        text
        __typename
      }
      content {
        language
        text
        __typename
      }
      externalNewsId
      WPJetEngineId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNewsTicketNews = /* GraphQL */ `
  mutation DeleteNewsTicketNews(
    $input: DeleteNewsTicketNewsInput!
    $condition: ModelNewsTicketNewsConditionInput
  ) {
    deleteNewsTicketNews(input: $input, condition: $condition) {
      id
      title {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      link
      excerpt {
        language
        text
        __typename
      }
      content {
        language
        text
        __typename
      }
      externalNewsId
      WPJetEngineId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createLecture = /* GraphQL */ `
  mutation CreateLecture(
    $input: CreateLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    createLecture(input: $input, condition: $condition) {
      id
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      startTime
      endTime
      dateTimes
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      seminar {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      companyBooth
      speakers {
        items {
          id
          lectureId
          employeeId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      session
      mainSpeaker {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      room
      title {
        language
        text
        __typename
      }
      subjects {
        id
        name {
          language
          text
          __typename
        }
        __typename
      }
      topics {
        items {
          id
          topicId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content {
        language
        text
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      presentationLanguage
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      pdfDe {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      pdfEn {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      videoS3Key {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      adminComment
      adminTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      approved
      approvedInsight
      lengthInSeconds
      status
      videoProgress {
        items {
          id
          userId
          videoId
          progressInSeconds
          totalWatchedInSeconds
          createdAt
          updatedAt
          lectureVideoProgressId
          userVideoProgressId
          __typename
        }
        nextToken
        __typename
      }
      courses {
        items {
          id
          courseId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      seminarLecturesId
      industryLecturesId
      companyLecturesId
      eventLecturesId
      lectureMainSpeakerId
      lectureAdminTopicId
      __typename
    }
  }
`;
export const updateLecture = /* GraphQL */ `
  mutation UpdateLecture(
    $input: UpdateLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    updateLecture(input: $input, condition: $condition) {
      id
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      startTime
      endTime
      dateTimes
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      seminar {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      companyBooth
      speakers {
        items {
          id
          lectureId
          employeeId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      session
      mainSpeaker {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      room
      title {
        language
        text
        __typename
      }
      subjects {
        id
        name {
          language
          text
          __typename
        }
        __typename
      }
      topics {
        items {
          id
          topicId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content {
        language
        text
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      presentationLanguage
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      pdfDe {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      pdfEn {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      videoS3Key {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      adminComment
      adminTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      approved
      approvedInsight
      lengthInSeconds
      status
      videoProgress {
        items {
          id
          userId
          videoId
          progressInSeconds
          totalWatchedInSeconds
          createdAt
          updatedAt
          lectureVideoProgressId
          userVideoProgressId
          __typename
        }
        nextToken
        __typename
      }
      courses {
        items {
          id
          courseId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      seminarLecturesId
      industryLecturesId
      companyLecturesId
      eventLecturesId
      lectureMainSpeakerId
      lectureAdminTopicId
      __typename
    }
  }
`;
export const deleteLecture = /* GraphQL */ `
  mutation DeleteLecture(
    $input: DeleteLectureInput!
    $condition: ModelLectureConditionInput
  ) {
    deleteLecture(input: $input, condition: $condition) {
      id
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      type
      startTime
      endTime
      dateTimes
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      seminar {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      companyBooth
      speakers {
        items {
          id
          lectureId
          employeeId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      session
      mainSpeaker {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      room
      title {
        language
        text
        __typename
      }
      subjects {
        id
        name {
          language
          text
          __typename
        }
        __typename
      }
      topics {
        items {
          id
          topicId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      content {
        language
        text
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      presentationLanguage
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      pdfDe {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      pdfEn {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      videoS3Key {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      adminComment
      adminTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      approved
      approvedInsight
      lengthInSeconds
      status
      videoProgress {
        items {
          id
          userId
          videoId
          progressInSeconds
          totalWatchedInSeconds
          createdAt
          updatedAt
          lectureVideoProgressId
          userVideoProgressId
          __typename
        }
        nextToken
        __typename
      }
      courses {
        items {
          id
          courseId
          lectureId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      seminarLecturesId
      industryLecturesId
      companyLecturesId
      eventLecturesId
      lectureMainSpeakerId
      lectureAdminTopicId
      __typename
    }
  }
`;
export const createCMSProduct = /* GraphQL */ `
  mutation CreateCMSProduct(
    $input: CreateCMSProductInput!
    $condition: ModelCMSProductConditionInput
  ) {
    createCMSProduct(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      content {
        language
        text
        __typename
      }
      excerpt {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      type
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      createdAt
      updatedAt
      companyProductsId
      cMSProductEmployeeId
      cMSProductTopicId
      owner
      __typename
    }
  }
`;
export const updateCMSProduct = /* GraphQL */ `
  mutation UpdateCMSProduct(
    $input: UpdateCMSProductInput!
    $condition: ModelCMSProductConditionInput
  ) {
    updateCMSProduct(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      content {
        language
        text
        __typename
      }
      excerpt {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      type
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      createdAt
      updatedAt
      companyProductsId
      cMSProductEmployeeId
      cMSProductTopicId
      owner
      __typename
    }
  }
`;
export const deleteCMSProduct = /* GraphQL */ `
  mutation DeleteCMSProduct(
    $input: DeleteCMSProductInput!
    $condition: ModelCMSProductConditionInput
  ) {
    deleteCMSProduct(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      content {
        language
        text
        __typename
      }
      excerpt {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      type
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      createdAt
      updatedAt
      companyProductsId
      cMSProductEmployeeId
      cMSProductTopicId
      owner
      __typename
    }
  }
`;
export const createCMSJobListing = /* GraphQL */ `
  mutation CreateCMSJobListing(
    $input: CreateCMSJobListingInput!
    $condition: ModelCMSJobListingConditionInput
  ) {
    createCMSJobListing(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      name {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      content {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      createdAt
      updatedAt
      companyJobsId
      cMSJobListingEmployeeId
      owner
      __typename
    }
  }
`;
export const updateCMSJobListing = /* GraphQL */ `
  mutation UpdateCMSJobListing(
    $input: UpdateCMSJobListingInput!
    $condition: ModelCMSJobListingConditionInput
  ) {
    updateCMSJobListing(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      name {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      content {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      createdAt
      updatedAt
      companyJobsId
      cMSJobListingEmployeeId
      owner
      __typename
    }
  }
`;
export const deleteCMSJobListing = /* GraphQL */ `
  mutation DeleteCMSJobListing(
    $input: DeleteCMSJobListingInput!
    $condition: ModelCMSJobListingConditionInput
  ) {
    deleteCMSJobListing(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      name {
        language
        text
        __typename
      }
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      previewImage {
        fileName
        alt
        x4UUID
        __typename
      }
      interests {
        name {
          language
          text
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      content {
        language
        text
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      conditions {
        name
        accepted
        __typename
      }
      adminComment
      approved
      status
      createdAt
      updatedAt
      companyJobsId
      cMSJobListingEmployeeId
      owner
      __typename
    }
  }
`;
export const createCMSTour = /* GraphQL */ `
  mutation CreateCMSTour(
    $input: CreateCMSTourInput!
    $condition: ModelCMSTourConditionInput
  ) {
    createCMSTour(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      fromDate
      toDate
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      tourCompanyRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourVisitorRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventToursId
      owner
      __typename
    }
  }
`;
export const updateCMSTour = /* GraphQL */ `
  mutation UpdateCMSTour(
    $input: UpdateCMSTourInput!
    $condition: ModelCMSTourConditionInput
  ) {
    updateCMSTour(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      fromDate
      toDate
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      tourCompanyRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourVisitorRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventToursId
      owner
      __typename
    }
  }
`;
export const deleteCMSTour = /* GraphQL */ `
  mutation DeleteCMSTour(
    $input: DeleteCMSTourInput!
    $condition: ModelCMSTourConditionInput
  ) {
    deleteCMSTour(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      fromDate
      toDate
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      tourCompanyRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourVisitorRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      eventToursId
      owner
      __typename
    }
  }
`;
export const createCMSTourCompanyRegistration = /* GraphQL */ `
  mutation CreateCMSTourCompanyRegistration(
    $input: CreateCMSTourCompanyRegistrationInput!
    $condition: ModelCMSTourCompanyRegistrationConditionInput
  ) {
    createCMSTourCompanyRegistration(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      date
      tour {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      cMSTourTourCompanyRegistrationsId
      companyTourRegistrationsId
      employeeTourRegistrationsId
      cMSTourCompanyRegistrationCompanyId
      cMSTourCompanyRegistrationEmployeeId
      owner
      __typename
    }
  }
`;
export const updateCMSTourCompanyRegistration = /* GraphQL */ `
  mutation UpdateCMSTourCompanyRegistration(
    $input: UpdateCMSTourCompanyRegistrationInput!
    $condition: ModelCMSTourCompanyRegistrationConditionInput
  ) {
    updateCMSTourCompanyRegistration(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      date
      tour {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      cMSTourTourCompanyRegistrationsId
      companyTourRegistrationsId
      employeeTourRegistrationsId
      cMSTourCompanyRegistrationCompanyId
      cMSTourCompanyRegistrationEmployeeId
      owner
      __typename
    }
  }
`;
export const deleteCMSTourCompanyRegistration = /* GraphQL */ `
  mutation DeleteCMSTourCompanyRegistration(
    $input: DeleteCMSTourCompanyRegistrationInput!
    $condition: ModelCMSTourCompanyRegistrationConditionInput
  ) {
    deleteCMSTourCompanyRegistration(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      date
      tour {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      cMSTourTourCompanyRegistrationsId
      companyTourRegistrationsId
      employeeTourRegistrationsId
      cMSTourCompanyRegistrationCompanyId
      cMSTourCompanyRegistrationEmployeeId
      owner
      __typename
    }
  }
`;
export const createCMSTourVisitorRegistration = /* GraphQL */ `
  mutation CreateCMSTourVisitorRegistration(
    $input: CreateCMSTourVisitorRegistrationInput!
    $condition: ModelCMSTourVisitorRegistrationConditionInput
  ) {
    createCMSTourVisitorRegistration(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      date
      tour {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      cMSTourTourVisitorRegistrationsId
      companyTourVisitorRegistrationsId
      userTourRegistrationsId
      owner
      __typename
    }
  }
`;
export const updateCMSTourVisitorRegistration = /* GraphQL */ `
  mutation UpdateCMSTourVisitorRegistration(
    $input: UpdateCMSTourVisitorRegistrationInput!
    $condition: ModelCMSTourVisitorRegistrationConditionInput
  ) {
    updateCMSTourVisitorRegistration(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      date
      tour {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      cMSTourTourVisitorRegistrationsId
      companyTourVisitorRegistrationsId
      userTourRegistrationsId
      owner
      __typename
    }
  }
`;
export const deleteCMSTourVisitorRegistration = /* GraphQL */ `
  mutation DeleteCMSTourVisitorRegistration(
    $input: DeleteCMSTourVisitorRegistrationInput!
    $condition: ModelCMSTourVisitorRegistrationConditionInput
  ) {
    deleteCMSTourVisitorRegistration(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      date
      tour {
        id
        name {
          language
          text
          __typename
        }
        fromDate
        toDate
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        tourCompanyRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        eventToursId
        owner
        __typename
      }
      status
      createdAt
      updatedAt
      cMSTourTourVisitorRegistrationsId
      companyTourVisitorRegistrationsId
      userTourRegistrationsId
      owner
      __typename
    }
  }
`;
export const createVideoProgress = /* GraphQL */ `
  mutation CreateVideoProgress(
    $input: CreateVideoProgressInput!
    $condition: ModelVideoProgressConditionInput
  ) {
    createVideoProgress(input: $input, condition: $condition) {
      id
      userId
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      videoId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      progressInSeconds
      totalWatchedInSeconds
      createdAt
      updatedAt
      lectureVideoProgressId
      userVideoProgressId
      __typename
    }
  }
`;
export const updateVideoProgress = /* GraphQL */ `
  mutation UpdateVideoProgress(
    $input: UpdateVideoProgressInput!
    $condition: ModelVideoProgressConditionInput
  ) {
    updateVideoProgress(input: $input, condition: $condition) {
      id
      userId
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      videoId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      progressInSeconds
      totalWatchedInSeconds
      createdAt
      updatedAt
      lectureVideoProgressId
      userVideoProgressId
      __typename
    }
  }
`;
export const deleteVideoProgress = /* GraphQL */ `
  mutation DeleteVideoProgress(
    $input: DeleteVideoProgressInput!
    $condition: ModelVideoProgressConditionInput
  ) {
    deleteVideoProgress(input: $input, condition: $condition) {
      id
      userId
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      videoId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      progressInSeconds
      totalWatchedInSeconds
      createdAt
      updatedAt
      lectureVideoProgressId
      userVideoProgressId
      __typename
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      participantsUsers {
        items {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        nextToken
        __typename
      }
      participantsUserCogIds
      messages {
        items {
          id
          chatId
          participantsUserCogIds
          message
          createdAt
          updatedAt
          chatMessagesId
          userMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userChatsId
      __typename
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      participantsUsers {
        items {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        nextToken
        __typename
      }
      participantsUserCogIds
      messages {
        items {
          id
          chatId
          participantsUserCogIds
          message
          createdAt
          updatedAt
          chatMessagesId
          userMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userChatsId
      __typename
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      participantsUsers {
        items {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        nextToken
        __typename
      }
      participantsUserCogIds
      messages {
        items {
          id
          chatId
          participantsUserCogIds
          message
          createdAt
          updatedAt
          chatMessagesId
          userMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userChatsId
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      chatId
      chat {
        id
        participantsUsers {
          nextToken
          __typename
        }
        participantsUserCogIds
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChatsId
        __typename
      }
      participantsUserCogIds
      sender {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      message
      createdAt
      updatedAt
      chatMessagesId
      userMessagesId
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      chatId
      chat {
        id
        participantsUsers {
          nextToken
          __typename
        }
        participantsUserCogIds
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChatsId
        __typename
      }
      participantsUserCogIds
      sender {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      message
      createdAt
      updatedAt
      chatMessagesId
      userMessagesId
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      chatId
      chat {
        id
        participantsUsers {
          nextToken
          __typename
        }
        participantsUserCogIds
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        userChatsId
        __typename
      }
      participantsUserCogIds
      sender {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      message
      createdAt
      updatedAt
      chatMessagesId
      userMessagesId
      __typename
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      name {
        language
        text
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      name {
        language
        text
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      name {
        language
        text
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIndustry = /* GraphQL */ `
  mutation CreateIndustry(
    $input: CreateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    createIndustry(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      subTitle {
        language
        text
        __typename
      }
      handle
      domain
      notificationEmailAddress
      primaryColor
      secondaryColor
      logo {
        fileName
        alt
        x4UUID
        __typename
      }
      favicon {
        fileName
        alt
        x4UUID
        __typename
      }
      sortKey
      status
      socialLinks {
        facebook
        x
        instagram
        spotify
        linkedin
        xing
        youtube
        pinterest
        snapchat
        tiktok
        __typename
      }
      events {
        items {
          id
          industryId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableProducts {
        items {
          id
          productId
          industryId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIndustry = /* GraphQL */ `
  mutation UpdateIndustry(
    $input: UpdateIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    updateIndustry(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      subTitle {
        language
        text
        __typename
      }
      handle
      domain
      notificationEmailAddress
      primaryColor
      secondaryColor
      logo {
        fileName
        alt
        x4UUID
        __typename
      }
      favicon {
        fileName
        alt
        x4UUID
        __typename
      }
      sortKey
      status
      socialLinks {
        facebook
        x
        instagram
        spotify
        linkedin
        xing
        youtube
        pinterest
        snapchat
        tiktok
        __typename
      }
      events {
        items {
          id
          industryId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableProducts {
        items {
          id
          productId
          industryId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIndustry = /* GraphQL */ `
  mutation DeleteIndustry(
    $input: DeleteIndustryInput!
    $condition: ModelIndustryConditionInput
  ) {
    deleteIndustry(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      subTitle {
        language
        text
        __typename
      }
      handle
      domain
      notificationEmailAddress
      primaryColor
      secondaryColor
      logo {
        fileName
        alt
        x4UUID
        __typename
      }
      favicon {
        fileName
        alt
        x4UUID
        __typename
      }
      sortKey
      status
      socialLinks {
        facebook
        x
        instagram
        spotify
        linkedin
        xing
        youtube
        pinterest
        snapchat
        tiktok
        __typename
      }
      events {
        items {
          id
          industryId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableProducts {
        items {
          id
          productId
          industryId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      description {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      industry
      status
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      website
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      banner {
        fileName
        alt
        x4UUID
        __typename
      }
      departments {
        items {
          id
          status
          x4Name
          createdAt
          updatedAt
          companyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      attendingEvents {
        items {
          id
          companyId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      employmentRequests {
        items {
          id
          initiatior
          role
          permissions
          status
          temporaryPassword
          newCognitoUserCreated
          createdAt
          updatedAt
          companyEmploymentRequestsId
          userEmploymentRequestsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      admin {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      adminEmployees {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      admins
      x4Id
      x4AdminId
      todos {
        items {
          id
          title
          description
          status
          dueDate
          startDate
          priority
          createdAt
          updatedAt
          companyTodosId
          userTodosId
          owner
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          id
          type
          adminComment
          approved
          status
          isHero
          createdAt
          updatedAt
          companyNewsId
          employeeNewsId
          cMSNewsEmployeeId
          cMSNewsTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          type
          adminComment
          approved
          status
          createdAt
          updatedAt
          companyProductsId
          cMSProductEmployeeId
          cMSProductTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          adminComment
          approved
          status
          createdAt
          updatedAt
          companyJobsId
          cMSJobListingEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      topics {
        items {
          id
          topicId
          companyId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      billingContact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      billingSettings {
        orderNumberRequired
        __typename
      }
      orders {
        items {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourVisitorRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      invitationCodes {
        items {
          id
          code
          createdAt
          updatedAt
          companyInvitationCodesId
          eventInvitationCodesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      bookedBooths {
        items {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyAdminId
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      description {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      industry
      status
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      website
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      banner {
        fileName
        alt
        x4UUID
        __typename
      }
      departments {
        items {
          id
          status
          x4Name
          createdAt
          updatedAt
          companyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      attendingEvents {
        items {
          id
          companyId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      employmentRequests {
        items {
          id
          initiatior
          role
          permissions
          status
          temporaryPassword
          newCognitoUserCreated
          createdAt
          updatedAt
          companyEmploymentRequestsId
          userEmploymentRequestsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      admin {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      adminEmployees {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      admins
      x4Id
      x4AdminId
      todos {
        items {
          id
          title
          description
          status
          dueDate
          startDate
          priority
          createdAt
          updatedAt
          companyTodosId
          userTodosId
          owner
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          id
          type
          adminComment
          approved
          status
          isHero
          createdAt
          updatedAt
          companyNewsId
          employeeNewsId
          cMSNewsEmployeeId
          cMSNewsTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          type
          adminComment
          approved
          status
          createdAt
          updatedAt
          companyProductsId
          cMSProductEmployeeId
          cMSProductTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          adminComment
          approved
          status
          createdAt
          updatedAt
          companyJobsId
          cMSJobListingEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      topics {
        items {
          id
          topicId
          companyId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      billingContact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      billingSettings {
        orderNumberRequired
        __typename
      }
      orders {
        items {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourVisitorRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      invitationCodes {
        items {
          id
          code
          createdAt
          updatedAt
          companyInvitationCodesId
          eventInvitationCodesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      bookedBooths {
        items {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyAdminId
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      description {
        language
        text
        __typename
      }
      shortDescription {
        language
        text
        __typename
      }
      industry
      status
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      website
      image {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      banner {
        fileName
        alt
        x4UUID
        __typename
      }
      departments {
        items {
          id
          status
          x4Name
          createdAt
          updatedAt
          companyDepartmentsId
          __typename
        }
        nextToken
        __typename
      }
      attendingEvents {
        items {
          id
          companyId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      employmentRequests {
        items {
          id
          initiatior
          role
          permissions
          status
          temporaryPassword
          newCognitoUserCreated
          createdAt
          updatedAt
          companyEmploymentRequestsId
          userEmploymentRequestsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      admin {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      adminEmployees {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      admins
      x4Id
      x4AdminId
      todos {
        items {
          id
          title
          description
          status
          dueDate
          startDate
          priority
          createdAt
          updatedAt
          companyTodosId
          userTodosId
          owner
          __typename
        }
        nextToken
        __typename
      }
      news {
        items {
          id
          type
          adminComment
          approved
          status
          isHero
          createdAt
          updatedAt
          companyNewsId
          employeeNewsId
          cMSNewsEmployeeId
          cMSNewsTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      products {
        items {
          id
          type
          adminComment
          approved
          status
          createdAt
          updatedAt
          companyProductsId
          cMSProductEmployeeId
          cMSProductTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          adminComment
          approved
          status
          createdAt
          updatedAt
          companyJobsId
          cMSJobListingEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      topics {
        items {
          id
          topicId
          companyId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      billingContact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      billingSettings {
        orderNumberRequired
        __typename
      }
      orders {
        items {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourVisitorRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      invitationCodes {
        items {
          id
          code
          createdAt
          updatedAt
          companyInvitationCodesId
          eventInvitationCodesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      bookedBooths {
        items {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyAdminId
      __typename
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee(
    $input: CreateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    createEmployee(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      department {
        items {
          id
          employeeId
          departmentId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      role
      permissions {
        resource
        actions
        __typename
      }
      status
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      x4Id
      News {
        items {
          id
          type
          adminComment
          approved
          status
          isHero
          createdAt
          updatedAt
          companyNewsId
          employeeNewsId
          cMSNewsEmployeeId
          cMSNewsTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      WPJetEngineId
      lectures {
        items {
          id
          lectureId
          employeeId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      userCognitoId
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyEmployeesId
      companyAdminEmployeesId
      userEmployeeProfilesId
      __typename
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee(
    $input: UpdateEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    updateEmployee(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      department {
        items {
          id
          employeeId
          departmentId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      role
      permissions {
        resource
        actions
        __typename
      }
      status
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      x4Id
      News {
        items {
          id
          type
          adminComment
          approved
          status
          isHero
          createdAt
          updatedAt
          companyNewsId
          employeeNewsId
          cMSNewsEmployeeId
          cMSNewsTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      WPJetEngineId
      lectures {
        items {
          id
          lectureId
          employeeId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      userCognitoId
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyEmployeesId
      companyAdminEmployeesId
      userEmployeeProfilesId
      __typename
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee(
    $input: DeleteEmployeeInput!
    $condition: ModelEmployeeConditionInput
  ) {
    deleteEmployee(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      department {
        items {
          id
          employeeId
          departmentId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      role
      permissions {
        resource
        actions
        __typename
      }
      status
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      x4Id
      News {
        items {
          id
          type
          adminComment
          approved
          status
          isHero
          createdAt
          updatedAt
          companyNewsId
          employeeNewsId
          cMSNewsEmployeeId
          cMSNewsTopicId
          owner
          __typename
        }
        nextToken
        __typename
      }
      WPJetEngineId
      lectures {
        items {
          id
          lectureId
          employeeId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      userCognitoId
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourCompanyRegistrationsId
          companyTourRegistrationsId
          employeeTourRegistrationsId
          cMSTourCompanyRegistrationCompanyId
          cMSTourCompanyRegistrationEmployeeId
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyEmployeesId
      companyAdminEmployeesId
      userEmployeeProfilesId
      __typename
    }
  }
`;
export const createEmploymentRequest = /* GraphQL */ `
  mutation CreateEmploymentRequest(
    $input: CreateEmploymentRequestInput!
    $condition: ModelEmploymentRequestConditionInput
  ) {
    createEmploymentRequest(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      requestingUser {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      initiatior
      department {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      role
      permissions
      status
      temporaryPassword
      newCognitoUserCreated
      createdAt
      updatedAt
      companyEmploymentRequestsId
      userEmploymentRequestsId
      owner
      __typename
    }
  }
`;
export const updateEmploymentRequest = /* GraphQL */ `
  mutation UpdateEmploymentRequest(
    $input: UpdateEmploymentRequestInput!
    $condition: ModelEmploymentRequestConditionInput
  ) {
    updateEmploymentRequest(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      requestingUser {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      initiatior
      department {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      role
      permissions
      status
      temporaryPassword
      newCognitoUserCreated
      createdAt
      updatedAt
      companyEmploymentRequestsId
      userEmploymentRequestsId
      owner
      __typename
    }
  }
`;
export const deleteEmploymentRequest = /* GraphQL */ `
  mutation DeleteEmploymentRequest(
    $input: DeleteEmploymentRequestInput!
    $condition: ModelEmploymentRequestConditionInput
  ) {
    deleteEmploymentRequest(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      requestingUser {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      initiatior
      department {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      role
      permissions
      status
      temporaryPassword
      newCognitoUserCreated
      createdAt
      updatedAt
      companyEmploymentRequestsId
      userEmploymentRequestsId
      owner
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      owner
      id
      email
      firstName
      middleName
      lastName
      salutation
      title
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      birthday
      aboutMe {
        language
        text
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      personalIndustry
      personalDepartment
      employeeProfiles {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      x4Id
      pw_hash
      hasCompletedOnboarding
      conditions {
        id
        accepted
        acceptedAt
        __typename
      }
      employmentRequests {
        items {
          id
          initiatior
          role
          permissions
          status
          temporaryPassword
          newCognitoUserCreated
          createdAt
          updatedAt
          companyEmploymentRequestsId
          userEmploymentRequestsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      todos {
        items {
          id
          title
          description
          status
          dueDate
          startDate
          priority
          createdAt
          updatedAt
          companyTodosId
          userTodosId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tickets {
        items {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        nextToken
        __typename
      }
      status
      orders {
        items {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      hasInsightAccess
      videoProgress {
        items {
          id
          userId
          videoId
          progressInSeconds
          totalWatchedInSeconds
          createdAt
          updatedAt
          lectureVideoProgressId
          userVideoProgressId
          __typename
        }
        nextToken
        __typename
      }
      chats {
        items {
          id
          participantsUserCogIds
          createdAt
          updatedAt
          userChatsId
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          chatId
          participantsUserCogIds
          message
          createdAt
          updatedAt
          chatMessagesId
          userMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      seminarSpeakersId
      chatParticipantsUsersId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      owner
      id
      email
      firstName
      middleName
      lastName
      salutation
      title
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      birthday
      aboutMe {
        language
        text
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      personalIndustry
      personalDepartment
      employeeProfiles {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      x4Id
      pw_hash
      hasCompletedOnboarding
      conditions {
        id
        accepted
        acceptedAt
        __typename
      }
      employmentRequests {
        items {
          id
          initiatior
          role
          permissions
          status
          temporaryPassword
          newCognitoUserCreated
          createdAt
          updatedAt
          companyEmploymentRequestsId
          userEmploymentRequestsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      todos {
        items {
          id
          title
          description
          status
          dueDate
          startDate
          priority
          createdAt
          updatedAt
          companyTodosId
          userTodosId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tickets {
        items {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        nextToken
        __typename
      }
      status
      orders {
        items {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      hasInsightAccess
      videoProgress {
        items {
          id
          userId
          videoId
          progressInSeconds
          totalWatchedInSeconds
          createdAt
          updatedAt
          lectureVideoProgressId
          userVideoProgressId
          __typename
        }
        nextToken
        __typename
      }
      chats {
        items {
          id
          participantsUserCogIds
          createdAt
          updatedAt
          userChatsId
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          chatId
          participantsUserCogIds
          message
          createdAt
          updatedAt
          chatMessagesId
          userMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      seminarSpeakersId
      chatParticipantsUsersId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      owner
      id
      email
      firstName
      middleName
      lastName
      salutation
      title
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      birthday
      aboutMe {
        language
        text
        __typename
      }
      contact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      personalIndustry
      personalDepartment
      employeeProfiles {
        items {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        nextToken
        __typename
      }
      x4Id
      pw_hash
      hasCompletedOnboarding
      conditions {
        id
        accepted
        acceptedAt
        __typename
      }
      employmentRequests {
        items {
          id
          initiatior
          role
          permissions
          status
          temporaryPassword
          newCognitoUserCreated
          createdAt
          updatedAt
          companyEmploymentRequestsId
          userEmploymentRequestsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      todos {
        items {
          id
          title
          description
          status
          dueDate
          startDate
          priority
          createdAt
          updatedAt
          companyTodosId
          userTodosId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tickets {
        items {
          owner
          id
          industryId
          createdByUserId
          userTicketsId
          eventDays
          status
          paymentStatus
          type
          visitorType
          addons
          notes
          billingEmail
          invitationCode
          orderNumber
          firstName
          lastName
          companyName
          createdAt
          updatedAt
          ticketEventId
          ticketCompanyId
          ticketPaymentId
          __typename
        }
        nextToken
        __typename
      }
      status
      orders {
        items {
          id
          industryId
          type
          orderNumber
          buyerOrderNumber
          status
          paymentStatus
          deliveryStatus
          notes
          billingEmail
          data
          totalAmount
          createdAt
          updatedAt
          companyOrdersId
          userOrdersId
          orderFinancialId
          owner
          __typename
        }
        nextToken
        __typename
      }
      tourRegistrations {
        items {
          id
          date
          status
          createdAt
          updatedAt
          cMSTourTourVisitorRegistrationsId
          companyTourVisitorRegistrationsId
          userTourRegistrationsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      stockReservations {
        items {
          id
          qty
          status
          reservationDate
          reservationEndDate
          createdAt
          updatedAt
          productStockReservationsId
          orderStockReservationsId
          companyStockReservationsId
          userStockReservationsId
          __typename
        }
        nextToken
        __typename
      }
      hasInsightAccess
      videoProgress {
        items {
          id
          userId
          videoId
          progressInSeconds
          totalWatchedInSeconds
          createdAt
          updatedAt
          lectureVideoProgressId
          userVideoProgressId
          __typename
        }
        nextToken
        __typename
      }
      chats {
        items {
          id
          participantsUserCogIds
          createdAt
          updatedAt
          userChatsId
          __typename
        }
        nextToken
        __typename
      }
      messages {
        items {
          id
          chatId
          participantsUserCogIds
          message
          createdAt
          updatedAt
          chatMessagesId
          userMessagesId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      seminarSpeakersId
      chatParticipantsUsersId
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      type
      boothBooking
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      status
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      date
      startDate
      endDate
      startTime
      endTime
      location {
        name {
          language
          text
          __typename
        }
        travelAndParkingNotice {
          language
          text
          __typename
        }
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      lectureRooms
      lectureRegistrationStartDate
      lectureRegistrationEndDate
      lectureDocumentsEndDate
      lectureRecordingCorrectionEndDate
      deadlineBoothEquipmentPrint
      startBoothEquipmentBooking
      deadlineBoothEquipmentBooking
      availableLectureTypes
      floorPlan {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      landingPageUrl
      attendees {
        items {
          id
          companyId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableBundleBooths {
        items {
          id
          bundleBoothId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableProducts {
        items {
          id
          productId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ticketProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      eventProducts {
        items {
          id
          price
          stock
          isAvailable
          createdAt
          updatedAt
          productEventProductsId
          eventEventProductsId
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      tours {
        items {
          id
          fromDate
          toDate
          createdAt
          updatedAt
          eventToursId
          owner
          __typename
        }
        nextToken
        __typename
      }
      invitationCodes {
        items {
          id
          code
          createdAt
          updatedAt
          companyInvitationCodesId
          eventInvitationCodesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      bookedBooths {
        items {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      availableCarpets {
        items {
          id
          eventId
          eventCarpetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      highlightedPersons {
        userid
        text {
          language
          text
          __typename
        }
        __typename
      }
      industries {
        items {
          id
          industryId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      x4Id
      WPJetEngineId
      bookingTicketBulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      hasInvitationCodes
      badgePreview {
        fileName
        alt
        x4UUID
        __typename
      }
      bookingSelectDays
      ticketTAC {
        language
        text
        __typename
      }
      ticketPrivacyPolicy {
        language
        text
        __typename
      }
      boothTAC {
        language
        text
        __typename
      }
      boothPrivacyPolicy {
        language
        text
        __typename
      }
      enableTicketBooking
      ticketNotice {
        language
        text
        __typename
      }
      ticketOrganizerLine {
        language
        text
        __typename
      }
      bookingNotice {
        language
        text
        __typename
      }
      exhibitorLectures
      lecturesNotice {
        language
        text
        __typename
      }
      createdAt
      updatedAt
      eventTicketProductId
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      type
      boothBooking
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      status
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      date
      startDate
      endDate
      startTime
      endTime
      location {
        name {
          language
          text
          __typename
        }
        travelAndParkingNotice {
          language
          text
          __typename
        }
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      lectureRooms
      lectureRegistrationStartDate
      lectureRegistrationEndDate
      lectureDocumentsEndDate
      lectureRecordingCorrectionEndDate
      deadlineBoothEquipmentPrint
      startBoothEquipmentBooking
      deadlineBoothEquipmentBooking
      availableLectureTypes
      floorPlan {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      landingPageUrl
      attendees {
        items {
          id
          companyId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableBundleBooths {
        items {
          id
          bundleBoothId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableProducts {
        items {
          id
          productId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ticketProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      eventProducts {
        items {
          id
          price
          stock
          isAvailable
          createdAt
          updatedAt
          productEventProductsId
          eventEventProductsId
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      tours {
        items {
          id
          fromDate
          toDate
          createdAt
          updatedAt
          eventToursId
          owner
          __typename
        }
        nextToken
        __typename
      }
      invitationCodes {
        items {
          id
          code
          createdAt
          updatedAt
          companyInvitationCodesId
          eventInvitationCodesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      bookedBooths {
        items {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      availableCarpets {
        items {
          id
          eventId
          eventCarpetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      highlightedPersons {
        userid
        text {
          language
          text
          __typename
        }
        __typename
      }
      industries {
        items {
          id
          industryId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      x4Id
      WPJetEngineId
      bookingTicketBulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      hasInvitationCodes
      badgePreview {
        fileName
        alt
        x4UUID
        __typename
      }
      bookingSelectDays
      ticketTAC {
        language
        text
        __typename
      }
      ticketPrivacyPolicy {
        language
        text
        __typename
      }
      boothTAC {
        language
        text
        __typename
      }
      boothPrivacyPolicy {
        language
        text
        __typename
      }
      enableTicketBooking
      ticketNotice {
        language
        text
        __typename
      }
      ticketOrganizerLine {
        language
        text
        __typename
      }
      bookingNotice {
        language
        text
        __typename
      }
      exhibitorLectures
      lecturesNotice {
        language
        text
        __typename
      }
      createdAt
      updatedAt
      eventTicketProductId
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      type
      boothBooking
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      status
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      date
      startDate
      endDate
      startTime
      endTime
      location {
        name {
          language
          text
          __typename
        }
        travelAndParkingNotice {
          language
          text
          __typename
        }
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        coordinates {
          lat
          lng
          __typename
        }
        __typename
      }
      lectureRooms
      lectureRegistrationStartDate
      lectureRegistrationEndDate
      lectureDocumentsEndDate
      lectureRecordingCorrectionEndDate
      deadlineBoothEquipmentPrint
      startBoothEquipmentBooking
      deadlineBoothEquipmentBooking
      availableLectureTypes
      floorPlan {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      landingPageUrl
      attendees {
        items {
          id
          companyId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableBundleBooths {
        items {
          id
          bundleBoothId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      availableProducts {
        items {
          id
          productId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ticketProduct {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      eventProducts {
        items {
          id
          price
          stock
          isAvailable
          createdAt
          updatedAt
          productEventProductsId
          eventEventProductsId
          __typename
        }
        nextToken
        __typename
      }
      lectures {
        items {
          id
          type
          startTime
          endTime
          dateTimes
          companyBooth
          session
          room
          presentationLanguage
          adminComment
          approved
          approvedInsight
          lengthInSeconds
          status
          createdAt
          updatedAt
          seminarLecturesId
          industryLecturesId
          companyLecturesId
          eventLecturesId
          lectureMainSpeakerId
          lectureAdminTopicId
          __typename
        }
        nextToken
        __typename
      }
      tours {
        items {
          id
          fromDate
          toDate
          createdAt
          updatedAt
          eventToursId
          owner
          __typename
        }
        nextToken
        __typename
      }
      invitationCodes {
        items {
          id
          code
          createdAt
          updatedAt
          companyInvitationCodesId
          eventInvitationCodesId
          owner
          __typename
        }
        nextToken
        __typename
      }
      bookedBooths {
        items {
          id
          boothNumber
          status
          createdAt
          updatedAt
          companyBookedBoothsId
          eventBookedBoothsId
          bookedBoothOrderLineItemId
          __typename
        }
        nextToken
        __typename
      }
      availableCarpets {
        items {
          id
          eventId
          eventCarpetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      highlightedPersons {
        userid
        text {
          language
          text
          __typename
        }
        __typename
      }
      industries {
        items {
          id
          industryId
          eventId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      x4Id
      WPJetEngineId
      bookingTicketBulletPoints {
        text {
          language
          text
          __typename
        }
        __typename
      }
      hasInvitationCodes
      badgePreview {
        fileName
        alt
        x4UUID
        __typename
      }
      bookingSelectDays
      ticketTAC {
        language
        text
        __typename
      }
      ticketPrivacyPolicy {
        language
        text
        __typename
      }
      boothTAC {
        language
        text
        __typename
      }
      boothPrivacyPolicy {
        language
        text
        __typename
      }
      enableTicketBooking
      ticketNotice {
        language
        text
        __typename
      }
      ticketOrganizerLine {
        language
        text
        __typename
      }
      bookingNotice {
        language
        text
        __typename
      }
      exhibitorLectures
      lecturesNotice {
        language
        text
        __typename
      }
      createdAt
      updatedAt
      eventTicketProductId
      __typename
    }
  }
`;
export const createEventCarpet = /* GraphQL */ `
  mutation CreateEventCarpet(
    $input: CreateEventCarpetInput!
    $condition: ModelEventCarpetConditionInput
  ) {
    createEventCarpet(input: $input, condition: $condition) {
      id
      order
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      color
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      availableInEvents {
        items {
          id
          eventId
          eventCarpetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventCarpet = /* GraphQL */ `
  mutation UpdateEventCarpet(
    $input: UpdateEventCarpetInput!
    $condition: ModelEventCarpetConditionInput
  ) {
    updateEventCarpet(input: $input, condition: $condition) {
      id
      order
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      color
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      availableInEvents {
        items {
          id
          eventId
          eventCarpetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventCarpet = /* GraphQL */ `
  mutation DeleteEventCarpet(
    $input: DeleteEventCarpetInput!
    $condition: ModelEventCarpetConditionInput
  ) {
    deleteEventCarpet(input: $input, condition: $condition) {
      id
      order
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      color
      image {
        fileName
        alt
        x4UUID
        __typename
      }
      availableInEvents {
        items {
          id
          eventId
          eventCarpetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment(
    $input: CreateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    createDepartment(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      status
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employees {
        items {
          id
          employeeId
          departmentId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      x4Name
      createdAt
      updatedAt
      companyDepartmentsId
      __typename
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment(
    $input: UpdateDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    updateDepartment(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      status
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employees {
        items {
          id
          employeeId
          departmentId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      x4Name
      createdAt
      updatedAt
      companyDepartmentsId
      __typename
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment(
    $input: DeleteDepartmentInput!
    $condition: ModelDepartmentConditionInput
  ) {
    deleteDepartment(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      status
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      employees {
        items {
          id
          employeeId
          departmentId
          createdAt
          updatedAt
          userCognitoId
          __typename
        }
        nextToken
        __typename
      }
      x4Name
      createdAt
      updatedAt
      companyDepartmentsId
      __typename
    }
  }
`;
export const createPrint = /* GraphQL */ `
  mutation CreatePrint(
    $input: CreatePrintInput!
    $condition: ModelPrintConditionInput
  ) {
    createPrint(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      dimensions
      type
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      createdAt
      updatedAt
      bundleBoothAvailablePrintsId
      __typename
    }
  }
`;
export const updatePrint = /* GraphQL */ `
  mutation UpdatePrint(
    $input: UpdatePrintInput!
    $condition: ModelPrintConditionInput
  ) {
    updatePrint(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      dimensions
      type
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      createdAt
      updatedAt
      bundleBoothAvailablePrintsId
      __typename
    }
  }
`;
export const deletePrint = /* GraphQL */ `
  mutation DeletePrint(
    $input: DeletePrintInput!
    $condition: ModelPrintConditionInput
  ) {
    deletePrint(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      dimensions
      type
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      file {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      createdAt
      updatedAt
      bundleBoothAvailablePrintsId
      __typename
    }
  }
`;
export const createFloor = /* GraphQL */ `
  mutation CreateFloor(
    $input: CreateFloorInput!
    $condition: ModelFloorConditionInput
  ) {
    createFloor(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      color
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      bundleBoothAvaiableFloorsId
      __typename
    }
  }
`;
export const updateFloor = /* GraphQL */ `
  mutation UpdateFloor(
    $input: UpdateFloorInput!
    $condition: ModelFloorConditionInput
  ) {
    updateFloor(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      color
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      bundleBoothAvaiableFloorsId
      __typename
    }
  }
`;
export const deleteFloor = /* GraphQL */ `
  mutation DeleteFloor(
    $input: DeleteFloorInput!
    $condition: ModelFloorConditionInput
  ) {
    deleteFloor(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      color
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      bundleBoothAvaiableFloorsId
      __typename
    }
  }
`;
export const createMarketingPackage = /* GraphQL */ `
  mutation CreateMarketingPackage(
    $input: CreateMarketingPackageInput!
    $condition: ModelMarketingPackageConditionInput
  ) {
    createMarketingPackage(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMarketingPackage = /* GraphQL */ `
  mutation UpdateMarketingPackage(
    $input: UpdateMarketingPackageInput!
    $condition: ModelMarketingPackageConditionInput
  ) {
    updateMarketingPackage(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMarketingPackage = /* GraphQL */ `
  mutation DeleteMarketingPackage(
    $input: DeleteMarketingPackageInput!
    $condition: ModelMarketingPackageConditionInput
  ) {
    deleteMarketingPackage(input: $input, condition: $condition) {
      id
      name {
        language
        text
        __typename
      }
      description {
        language
        text
        __typename
      }
      images {
        main {
          fileName
          alt
          x4UUID
          __typename
        }
        additional {
          fileName
          alt
          x4UUID
          __typename
        }
        __typename
      }
      features {
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBookingBundleBooth = /* GraphQL */ `
  mutation CreateBookingBundleBooth(
    $input: CreateBookingBundleBoothInput!
    $condition: ModelBookingBundleBoothConditionInput
  ) {
    createBookingBundleBooth(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdBy {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      booth {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      totalAmount
      printFiles {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      floor {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        images {
          __typename
        }
        createdAt
        updatedAt
        bundleBoothAvaiableFloorsId
        __typename
      }
      accessories {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      marketingPackage {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBookingBundleBooth = /* GraphQL */ `
  mutation UpdateBookingBundleBooth(
    $input: UpdateBookingBundleBoothInput!
    $condition: ModelBookingBundleBoothConditionInput
  ) {
    updateBookingBundleBooth(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdBy {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      booth {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      totalAmount
      printFiles {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      floor {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        images {
          __typename
        }
        createdAt
        updatedAt
        bundleBoothAvaiableFloorsId
        __typename
      }
      accessories {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      marketingPackage {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBookingBundleBooth = /* GraphQL */ `
  mutation DeleteBookingBundleBooth(
    $input: DeleteBookingBundleBoothInput!
    $condition: ModelBookingBundleBoothConditionInput
  ) {
    deleteBookingBundleBooth(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdBy {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      booth {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      status
      totalAmount
      printFiles {
        url
        s3Path
        fileName
        type
        name
        __typename
      }
      floor {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        images {
          __typename
        }
        createdAt
        updatedAt
        bundleBoothAvaiableFloorsId
        __typename
      }
      accessories {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      marketingPackage {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        images {
          __typename
        }
        features {
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      notes
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createToDo = /* GraphQL */ `
  mutation CreateToDo(
    $input: CreateToDoInput!
    $condition: ModelToDoConditionInput
  ) {
    createToDo(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      title
      description
      status
      dueDate
      startDate
      priority
      createdBy {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      createdAt
      updatedAt
      companyTodosId
      userTodosId
      owner
      __typename
    }
  }
`;
export const updateToDo = /* GraphQL */ `
  mutation UpdateToDo(
    $input: UpdateToDoInput!
    $condition: ModelToDoConditionInput
  ) {
    updateToDo(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      title
      description
      status
      dueDate
      startDate
      priority
      createdBy {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      createdAt
      updatedAt
      companyTodosId
      userTodosId
      owner
      __typename
    }
  }
`;
export const deleteToDo = /* GraphQL */ `
  mutation DeleteToDo(
    $input: DeleteToDoInput!
    $condition: ModelToDoConditionInput
  ) {
    deleteToDo(input: $input, condition: $condition) {
      id
      user {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      title
      description
      status
      dueDate
      startDate
      priority
      createdBy {
        owner
        id
        email
        firstName
        middleName
        lastName
        salutation
        title
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        birthday
        aboutMe {
          language
          text
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        personalIndustry
        personalDepartment
        employeeProfiles {
          nextToken
          __typename
        }
        x4Id
        pw_hash
        hasCompletedOnboarding
        conditions {
          id
          accepted
          acceptedAt
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        todos {
          nextToken
          __typename
        }
        tickets {
          nextToken
          __typename
        }
        status
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        hasInsightAccess
        videoProgress {
          nextToken
          __typename
        }
        chats {
          nextToken
          __typename
        }
        messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarSpeakersId
        chatParticipantsUsersId
        __typename
      }
      createdAt
      updatedAt
      companyTodosId
      userTodosId
      owner
      __typename
    }
  }
`;
export const createVerificationCode = /* GraphQL */ `
  mutation CreateVerificationCode(
    $input: CreateVerificationCodeInput!
    $condition: ModelVerificationCodeConditionInput
  ) {
    createVerificationCode(input: $input, condition: $condition) {
      id
      code
      userId
      type
      status
      expirationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateVerificationCode = /* GraphQL */ `
  mutation UpdateVerificationCode(
    $input: UpdateVerificationCodeInput!
    $condition: ModelVerificationCodeConditionInput
  ) {
    updateVerificationCode(input: $input, condition: $condition) {
      id
      code
      userId
      type
      status
      expirationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteVerificationCode = /* GraphQL */ `
  mutation DeleteVerificationCode(
    $input: DeleteVerificationCodeInput!
    $condition: ModelVerificationCodeConditionInput
  ) {
    deleteVerificationCode(input: $input, condition: $condition) {
      id
      code
      userId
      type
      status
      expirationDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSystemDocumentSettings = /* GraphQL */ `
  mutation CreateSystemDocumentSettings(
    $input: CreateSystemDocumentSettingsInput!
    $condition: ModelSystemDocumentSettingsConditionInput
  ) {
    createSystemDocumentSettings(input: $input, condition: $condition) {
      documentType
      name
      logo {
        fileName
        alt
        x4UUID
        __typename
      }
      companyName
      companyAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      companyContact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      website
      bank {
        accountHolder
        iban
        bic
        bankName
        __typename
      }
      taxid
      legalNotice {
        short
        html
        __typename
      }
      dueDays
      prefix
      digits
      lastNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSystemDocumentSettings = /* GraphQL */ `
  mutation UpdateSystemDocumentSettings(
    $input: UpdateSystemDocumentSettingsInput!
    $condition: ModelSystemDocumentSettingsConditionInput
  ) {
    updateSystemDocumentSettings(input: $input, condition: $condition) {
      documentType
      name
      logo {
        fileName
        alt
        x4UUID
        __typename
      }
      companyName
      companyAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      companyContact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      website
      bank {
        accountHolder
        iban
        bic
        bankName
        __typename
      }
      taxid
      legalNotice {
        short
        html
        __typename
      }
      dueDays
      prefix
      digits
      lastNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSystemDocumentSettings = /* GraphQL */ `
  mutation DeleteSystemDocumentSettings(
    $input: DeleteSystemDocumentSettingsInput!
    $condition: ModelSystemDocumentSettingsConditionInput
  ) {
    deleteSystemDocumentSettings(input: $input, condition: $condition) {
      documentType
      name
      logo {
        fileName
        alt
        x4UUID
        __typename
      }
      companyName
      companyAddress {
        firstName
        lastName
        title
        salutation
        street
        street2
        streetNumber
        company
        contactPerson
        city
        zip
        countryCode
        __typename
      }
      companyContact {
        phone
        mobile
        fax
        email
        address {
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        __typename
      }
      website
      bank {
        accountHolder
        iban
        bic
        bankName
        __typename
      }
      taxid
      legalNotice {
        short
        html
        __typename
      }
      dueDays
      prefix
      digits
      lastNumber
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLecturesInCourses = /* GraphQL */ `
  mutation CreateLecturesInCourses(
    $input: CreateLecturesInCoursesInput!
    $condition: ModelLecturesInCoursesConditionInput
  ) {
    createLecturesInCourses(input: $input, condition: $condition) {
      id
      courseId
      lectureId
      course {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLecturesInCourses = /* GraphQL */ `
  mutation UpdateLecturesInCourses(
    $input: UpdateLecturesInCoursesInput!
    $condition: ModelLecturesInCoursesConditionInput
  ) {
    updateLecturesInCourses(input: $input, condition: $condition) {
      id
      courseId
      lectureId
      course {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLecturesInCourses = /* GraphQL */ `
  mutation DeleteLecturesInCourses(
    $input: DeleteLecturesInCoursesInput!
    $condition: ModelLecturesInCoursesConditionInput
  ) {
    deleteLecturesInCourses(input: $input, condition: $condition) {
      id
      courseId
      lectureId
      course {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoursesAcademyTopics = /* GraphQL */ `
  mutation CreateCoursesAcademyTopics(
    $input: CreateCoursesAcademyTopicsInput!
    $condition: ModelCoursesAcademyTopicsConditionInput
  ) {
    createCoursesAcademyTopics(input: $input, condition: $condition) {
      id
      courseId
      academyTopicId
      course {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      academyTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoursesAcademyTopics = /* GraphQL */ `
  mutation UpdateCoursesAcademyTopics(
    $input: UpdateCoursesAcademyTopicsInput!
    $condition: ModelCoursesAcademyTopicsConditionInput
  ) {
    updateCoursesAcademyTopics(input: $input, condition: $condition) {
      id
      courseId
      academyTopicId
      course {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      academyTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoursesAcademyTopics = /* GraphQL */ `
  mutation DeleteCoursesAcademyTopics(
    $input: DeleteCoursesAcademyTopicsInput!
    $condition: ModelCoursesAcademyTopicsConditionInput
  ) {
    deleteCoursesAcademyTopics(input: $input, condition: $condition) {
      id
      courseId
      academyTopicId
      course {
        id
        name {
          language
          text
          __typename
        }
        sortKey
        lectures {
          nextToken
          __typename
        }
        videos
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        type
        duration
        price
        topics {
          language
          text
          __typename
        }
        speakersDescription {
          language
          text
          __typename
        }
        CTADescription {
          language
          text
          __typename
        }
        CTABulletPoints {
          __typename
        }
        WPJetEngineId
        academyTopics {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      academyTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSeminarAcademyTopics = /* GraphQL */ `
  mutation CreateSeminarAcademyTopics(
    $input: CreateSeminarAcademyTopicsInput!
    $condition: ModelSeminarAcademyTopicsConditionInput
  ) {
    createSeminarAcademyTopics(input: $input, condition: $condition) {
      id
      academyTopicId
      seminarId
      academyTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      seminar {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSeminarAcademyTopics = /* GraphQL */ `
  mutation UpdateSeminarAcademyTopics(
    $input: UpdateSeminarAcademyTopicsInput!
    $condition: ModelSeminarAcademyTopicsConditionInput
  ) {
    updateSeminarAcademyTopics(input: $input, condition: $condition) {
      id
      academyTopicId
      seminarId
      academyTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      seminar {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSeminarAcademyTopics = /* GraphQL */ `
  mutation DeleteSeminarAcademyTopics(
    $input: DeleteSeminarAcademyTopicsInput!
    $condition: ModelSeminarAcademyTopicsConditionInput
  ) {
    deleteSeminarAcademyTopics(input: $input, condition: $condition) {
      id
      academyTopicId
      seminarId
      academyTopic {
        id
        name {
          language
          text
          __typename
        }
        color
        sortKey
        academyTopicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        courses {
          nextToken
          __typename
        }
        seminars {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        academyTopicGroupAcademyTopicsId
        __typename
      }
      seminar {
        id
        name {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        cover {
          fileName
          alt
          x4UUID
          __typename
        }
        seminarDays {
          date
          startTime
          endTime
          __typename
        }
        location {
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        speakers {
          nextToken
          __typename
        }
        CTABulletPoints {
          __typename
        }
        bookingStartDate
        bookingEndDate
        product {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        academyTopics {
          nextToken
          __typename
        }
        status
        sortKey
        WPJetEngineId
        createdAt
        updatedAt
        seminarProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrderProducts = /* GraphQL */ `
  mutation CreateOrderProducts(
    $input: CreateOrderProductsInput!
    $condition: ModelOrderProductsConditionInput
  ) {
    createOrderProducts(input: $input, condition: $condition) {
      id
      productId
      orderId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateOrderProducts = /* GraphQL */ `
  mutation UpdateOrderProducts(
    $input: UpdateOrderProductsInput!
    $condition: ModelOrderProductsConditionInput
  ) {
    updateOrderProducts(input: $input, condition: $condition) {
      id
      productId
      orderId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteOrderProducts = /* GraphQL */ `
  mutation DeleteOrderProducts(
    $input: DeleteOrderProductsInput!
    $condition: ModelOrderProductsConditionInput
  ) {
    deleteOrderProducts(input: $input, condition: $condition) {
      id
      productId
      orderId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      order {
        id
        industryId
        type
        orderNumber
        buyerOrderNumber
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        status
        paymentStatus
        deliveryStatus
        products {
          nextToken
          __typename
        }
        lineItems {
          nextToken
          __typename
        }
        notes
        deliveryAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingAddress {
          firstName
          lastName
          title
          salutation
          street
          street2
          streetNumber
          company
          contactPerson
          city
          zip
          countryCode
          __typename
        }
        billingEmail
        financial {
          id
          isBusiness
          isInterEUDelivery
          currency
          invoiceAmount
          invoiceAmountNet
          invoiceNumber
          invoiceDate
          invoiceLastSent
          invoiceReceivers
          servicePeriodStart
          servicePeriodEnd
          taxRate
          shippingCost
          shippingCostNet
          paymentCost
          discountCode
          discountAmount
          discountType
          paymentProvider
          totalAmount
          createdAt
          updatedAt
          orderFinancialOrderId
          __typename
        }
        data
        totalAmount
        stockReservations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyOrdersId
        userOrdersId
        orderFinancialId
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAvailableProductsInIndustries = /* GraphQL */ `
  mutation CreateAvailableProductsInIndustries(
    $input: CreateAvailableProductsInIndustriesInput!
    $condition: ModelAvailableProductsInIndustriesConditionInput
  ) {
    createAvailableProductsInIndustries(input: $input, condition: $condition) {
      id
      productId
      industryId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAvailableProductsInIndustries = /* GraphQL */ `
  mutation UpdateAvailableProductsInIndustries(
    $input: UpdateAvailableProductsInIndustriesInput!
    $condition: ModelAvailableProductsInIndustriesConditionInput
  ) {
    updateAvailableProductsInIndustries(input: $input, condition: $condition) {
      id
      productId
      industryId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAvailableProductsInIndustries = /* GraphQL */ `
  mutation DeleteAvailableProductsInIndustries(
    $input: DeleteAvailableProductsInIndustriesInput!
    $condition: ModelAvailableProductsInIndustriesConditionInput
  ) {
    deleteAvailableProductsInIndustries(input: $input, condition: $condition) {
      id
      productId
      industryId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAvailableProductsInEvents = /* GraphQL */ `
  mutation CreateAvailableProductsInEvents(
    $input: CreateAvailableProductsInEventsInput!
    $condition: ModelAvailableProductsInEventsConditionInput
  ) {
    createAvailableProductsInEvents(input: $input, condition: $condition) {
      id
      productId
      eventId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAvailableProductsInEvents = /* GraphQL */ `
  mutation UpdateAvailableProductsInEvents(
    $input: UpdateAvailableProductsInEventsInput!
    $condition: ModelAvailableProductsInEventsConditionInput
  ) {
    updateAvailableProductsInEvents(input: $input, condition: $condition) {
      id
      productId
      eventId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAvailableProductsInEvents = /* GraphQL */ `
  mutation DeleteAvailableProductsInEvents(
    $input: DeleteAvailableProductsInEventsInput!
    $condition: ModelAvailableProductsInEventsConditionInput
  ) {
    deleteAvailableProductsInEvents(input: $input, condition: $condition) {
      id
      productId
      eventId
      product {
        id
        visibleInShop
        name {
          language
          text
          __typename
        }
        category {
          id
          customerTypes
          sortKey
          createdAt
          updatedAt
          __typename
        }
        invoiceName {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        includes {
          language
          text
          __typename
        }
        sku
        ean
        type
        subType
        price
        downPaymentPrice
        image {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        orders {
          nextToken
          __typename
        }
        orderLineItems {
          nextToken
          __typename
        }
        allowedBuyerGroups
        requiredShipping
        icon
        stock
        stockReservations {
          nextToken
          __typename
        }
        availableInIndustries {
          nextToken
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        components {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        productCategoryProductsId
        bundleBoothAvaiableAccessoriesId
        bundleBoothIncludedAccessoriesId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAvailableBundleBoothsInEvents = /* GraphQL */ `
  mutation CreateAvailableBundleBoothsInEvents(
    $input: CreateAvailableBundleBoothsInEventsInput!
    $condition: ModelAvailableBundleBoothsInEventsConditionInput
  ) {
    createAvailableBundleBoothsInEvents(input: $input, condition: $condition) {
      id
      bundleBoothId
      eventId
      bundleBooth {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAvailableBundleBoothsInEvents = /* GraphQL */ `
  mutation UpdateAvailableBundleBoothsInEvents(
    $input: UpdateAvailableBundleBoothsInEventsInput!
    $condition: ModelAvailableBundleBoothsInEventsConditionInput
  ) {
    updateAvailableBundleBoothsInEvents(input: $input, condition: $condition) {
      id
      bundleBoothId
      eventId
      bundleBooth {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAvailableBundleBoothsInEvents = /* GraphQL */ `
  mutation DeleteAvailableBundleBoothsInEvents(
    $input: DeleteAvailableBundleBoothsInEventsInput!
    $condition: ModelAvailableBundleBoothsInEventsConditionInput
  ) {
    deleteAvailableBundleBoothsInEvents(input: $input, condition: $condition) {
      id
      bundleBoothId
      eventId
      bundleBooth {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        sku
        boothNumber
        availableInEvents {
          nextToken
          __typename
        }
        price
        images {
          __typename
        }
        features {
          __typename
        }
        files {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        status
        availablePrints {
          nextToken
          __typename
        }
        avaiableFloors {
          nextToken
          __typename
        }
        avaiableAccessories {
          nextToken
          __typename
        }
        includedMarketingPackage {
          id
          createdAt
          updatedAt
          __typename
        }
        includedAccessories {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompaniesTopics = /* GraphQL */ `
  mutation CreateCompaniesTopics(
    $input: CreateCompaniesTopicsInput!
    $condition: ModelCompaniesTopicsConditionInput
  ) {
    createCompaniesTopics(input: $input, condition: $condition) {
      id
      topicId
      companyId
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompaniesTopics = /* GraphQL */ `
  mutation UpdateCompaniesTopics(
    $input: UpdateCompaniesTopicsInput!
    $condition: ModelCompaniesTopicsConditionInput
  ) {
    updateCompaniesTopics(input: $input, condition: $condition) {
      id
      topicId
      companyId
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompaniesTopics = /* GraphQL */ `
  mutation DeleteCompaniesTopics(
    $input: DeleteCompaniesTopicsInput!
    $condition: ModelCompaniesTopicsConditionInput
  ) {
    deleteCompaniesTopics(input: $input, condition: $condition) {
      id
      topicId
      companyId
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLecturesTopics = /* GraphQL */ `
  mutation CreateLecturesTopics(
    $input: CreateLecturesTopicsInput!
    $condition: ModelLecturesTopicsConditionInput
  ) {
    createLecturesTopics(input: $input, condition: $condition) {
      id
      topicId
      lectureId
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLecturesTopics = /* GraphQL */ `
  mutation UpdateLecturesTopics(
    $input: UpdateLecturesTopicsInput!
    $condition: ModelLecturesTopicsConditionInput
  ) {
    updateLecturesTopics(input: $input, condition: $condition) {
      id
      topicId
      lectureId
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLecturesTopics = /* GraphQL */ `
  mutation DeleteLecturesTopics(
    $input: DeleteLecturesTopicsInput!
    $condition: ModelLecturesTopicsConditionInput
  ) {
    deleteLecturesTopics(input: $input, condition: $condition) {
      id
      topicId
      lectureId
      topic {
        id
        name {
          language
          text
          __typename
        }
        color
        topicGroup {
          id
          color
          sortKey
          createdAt
          updatedAt
          __typename
        }
        companies {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        sortKey
        createdAt
        updatedAt
        topicGroupTopicsId
        __typename
      }
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLecturesSpeakers = /* GraphQL */ `
  mutation CreateLecturesSpeakers(
    $input: CreateLecturesSpeakersInput!
    $condition: ModelLecturesSpeakersConditionInput
  ) {
    createLecturesSpeakers(input: $input, condition: $condition) {
      id
      lectureId
      employeeId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      createdAt
      updatedAt
      userCognitoId
      __typename
    }
  }
`;
export const updateLecturesSpeakers = /* GraphQL */ `
  mutation UpdateLecturesSpeakers(
    $input: UpdateLecturesSpeakersInput!
    $condition: ModelLecturesSpeakersConditionInput
  ) {
    updateLecturesSpeakers(input: $input, condition: $condition) {
      id
      lectureId
      employeeId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      createdAt
      updatedAt
      userCognitoId
      __typename
    }
  }
`;
export const deleteLecturesSpeakers = /* GraphQL */ `
  mutation DeleteLecturesSpeakers(
    $input: DeleteLecturesSpeakersInput!
    $condition: ModelLecturesSpeakersConditionInput
  ) {
    deleteLecturesSpeakers(input: $input, condition: $condition) {
      id
      lectureId
      employeeId
      lecture {
        id
        industry {
          id
          handle
          domain
          notificationEmailAddress
          primaryColor
          secondaryColor
          sortKey
          status
          createdAt
          updatedAt
          __typename
        }
        type
        startTime
        endTime
        dateTimes
        event {
          id
          type
          boothBooking
          status
          date
          startDate
          endDate
          startTime
          endTime
          lectureRooms
          lectureRegistrationStartDate
          lectureRegistrationEndDate
          lectureDocumentsEndDate
          lectureRecordingCorrectionEndDate
          deadlineBoothEquipmentPrint
          startBoothEquipmentBooking
          deadlineBoothEquipmentBooking
          availableLectureTypes
          landingPageUrl
          x4Id
          WPJetEngineId
          hasInvitationCodes
          bookingSelectDays
          enableTicketBooking
          exhibitorLectures
          createdAt
          updatedAt
          eventTicketProductId
          __typename
        }
        seminar {
          id
          bookingStartDate
          bookingEndDate
          status
          sortKey
          WPJetEngineId
          createdAt
          updatedAt
          seminarProductId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        companyBooth
        speakers {
          nextToken
          __typename
        }
        session
        mainSpeaker {
          id
          role
          status
          x4Id
          WPJetEngineId
          userCognitoId
          createdAt
          updatedAt
          companyEmployeesId
          companyAdminEmployeesId
          userEmployeeProfilesId
          __typename
        }
        room
        title {
          language
          text
          __typename
        }
        subjects {
          id
          __typename
        }
        topics {
          nextToken
          __typename
        }
        content {
          language
          text
          __typename
        }
        conditions {
          name
          accepted
          __typename
        }
        presentationLanguage
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        pdfDe {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        pdfEn {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        videoS3Key {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        adminComment
        adminTopic {
          id
          color
          sortKey
          createdAt
          updatedAt
          topicGroupTopicsId
          __typename
        }
        approved
        approvedInsight
        lengthInSeconds
        status
        videoProgress {
          nextToken
          __typename
        }
        courses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        seminarLecturesId
        industryLecturesId
        companyLecturesId
        eventLecturesId
        lectureMainSpeakerId
        lectureAdminTopicId
        __typename
      }
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      createdAt
      updatedAt
      userCognitoId
      __typename
    }
  }
`;
export const createIndustryEvents = /* GraphQL */ `
  mutation CreateIndustryEvents(
    $input: CreateIndustryEventsInput!
    $condition: ModelIndustryEventsConditionInput
  ) {
    createIndustryEvents(input: $input, condition: $condition) {
      id
      industryId
      eventId
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIndustryEvents = /* GraphQL */ `
  mutation UpdateIndustryEvents(
    $input: UpdateIndustryEventsInput!
    $condition: ModelIndustryEventsConditionInput
  ) {
    updateIndustryEvents(input: $input, condition: $condition) {
      id
      industryId
      eventId
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIndustryEvents = /* GraphQL */ `
  mutation DeleteIndustryEvents(
    $input: DeleteIndustryEventsInput!
    $condition: ModelIndustryEventsConditionInput
  ) {
    deleteIndustryEvents(input: $input, condition: $condition) {
      id
      industryId
      eventId
      industry {
        id
        name {
          language
          text
          __typename
        }
        subTitle {
          language
          text
          __typename
        }
        handle
        domain
        notificationEmailAddress
        primaryColor
        secondaryColor
        logo {
          fileName
          alt
          x4UUID
          __typename
        }
        favicon {
          fileName
          alt
          x4UUID
          __typename
        }
        sortKey
        status
        socialLinks {
          facebook
          x
          instagram
          spotify
          linkedin
          xing
          youtube
          pinterest
          snapchat
          tiktok
          __typename
        }
        events {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompaniesAttendingEvents = /* GraphQL */ `
  mutation CreateCompaniesAttendingEvents(
    $input: CreateCompaniesAttendingEventsInput!
    $condition: ModelCompaniesAttendingEventsConditionInput
  ) {
    createCompaniesAttendingEvents(input: $input, condition: $condition) {
      id
      companyId
      eventId
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompaniesAttendingEvents = /* GraphQL */ `
  mutation UpdateCompaniesAttendingEvents(
    $input: UpdateCompaniesAttendingEventsInput!
    $condition: ModelCompaniesAttendingEventsConditionInput
  ) {
    updateCompaniesAttendingEvents(input: $input, condition: $condition) {
      id
      companyId
      eventId
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompaniesAttendingEvents = /* GraphQL */ `
  mutation DeleteCompaniesAttendingEvents(
    $input: DeleteCompaniesAttendingEventsInput!
    $condition: ModelCompaniesAttendingEventsConditionInput
  ) {
    deleteCompaniesAttendingEvents(input: $input, condition: $condition) {
      id
      companyId
      eventId
      company {
        id
        name
        description {
          language
          text
          __typename
        }
        shortDescription {
          language
          text
          __typename
        }
        industry
        status
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        website
        image {
          __typename
        }
        banner {
          fileName
          alt
          x4UUID
          __typename
        }
        departments {
          nextToken
          __typename
        }
        attendingEvents {
          nextToken
          __typename
        }
        employees {
          nextToken
          __typename
        }
        employmentRequests {
          nextToken
          __typename
        }
        admin {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        adminEmployees {
          nextToken
          __typename
        }
        admins
        x4Id
        x4AdminId
        todos {
          nextToken
          __typename
        }
        news {
          nextToken
          __typename
        }
        products {
          nextToken
          __typename
        }
        jobs {
          nextToken
          __typename
        }
        topics {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        billingContact {
          phone
          mobile
          fax
          email
          __typename
        }
        billingSettings {
          orderNumberRequired
          __typename
        }
        orders {
          nextToken
          __typename
        }
        tourRegistrations {
          nextToken
          __typename
        }
        tourVisitorRegistrations {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        stockReservations {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyAdminId
        __typename
      }
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeDepartments = /* GraphQL */ `
  mutation CreateEmployeeDepartments(
    $input: CreateEmployeeDepartmentsInput!
    $condition: ModelEmployeeDepartmentsConditionInput
  ) {
    createEmployeeDepartments(input: $input, condition: $condition) {
      id
      employeeId
      departmentId
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      department {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      createdAt
      updatedAt
      userCognitoId
      __typename
    }
  }
`;
export const updateEmployeeDepartments = /* GraphQL */ `
  mutation UpdateEmployeeDepartments(
    $input: UpdateEmployeeDepartmentsInput!
    $condition: ModelEmployeeDepartmentsConditionInput
  ) {
    updateEmployeeDepartments(input: $input, condition: $condition) {
      id
      employeeId
      departmentId
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      department {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      createdAt
      updatedAt
      userCognitoId
      __typename
    }
  }
`;
export const deleteEmployeeDepartments = /* GraphQL */ `
  mutation DeleteEmployeeDepartments(
    $input: DeleteEmployeeDepartmentsInput!
    $condition: ModelEmployeeDepartmentsConditionInput
  ) {
    deleteEmployeeDepartments(input: $input, condition: $condition) {
      id
      employeeId
      departmentId
      employee {
        id
        user {
          owner
          id
          email
          firstName
          middleName
          lastName
          salutation
          title
          birthday
          personalIndustry
          personalDepartment
          x4Id
          pw_hash
          hasCompletedOnboarding
          status
          hasInsightAccess
          createdAt
          updatedAt
          seminarSpeakersId
          chatParticipantsUsersId
          __typename
        }
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        department {
          nextToken
          __typename
        }
        contact {
          phone
          mobile
          fax
          email
          __typename
        }
        role
        permissions {
          resource
          actions
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        x4Id
        News {
          nextToken
          __typename
        }
        WPJetEngineId
        lectures {
          nextToken
          __typename
        }
        userCognitoId
        tourRegistrations {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        companyEmployeesId
        companyAdminEmployeesId
        userEmployeeProfilesId
        __typename
      }
      department {
        id
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        company {
          id
          name
          industry
          status
          website
          admins
          x4Id
          x4AdminId
          createdAt
          updatedAt
          companyAdminId
          __typename
        }
        employees {
          nextToken
          __typename
        }
        x4Name
        createdAt
        updatedAt
        companyDepartmentsId
        __typename
      }
      createdAt
      updatedAt
      userCognitoId
      __typename
    }
  }
`;
export const createAvailableCarpetsInEvents = /* GraphQL */ `
  mutation CreateAvailableCarpetsInEvents(
    $input: CreateAvailableCarpetsInEventsInput!
    $condition: ModelAvailableCarpetsInEventsConditionInput
  ) {
    createAvailableCarpetsInEvents(input: $input, condition: $condition) {
      id
      eventId
      eventCarpetId
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      eventCarpet {
        id
        order
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAvailableCarpetsInEvents = /* GraphQL */ `
  mutation UpdateAvailableCarpetsInEvents(
    $input: UpdateAvailableCarpetsInEventsInput!
    $condition: ModelAvailableCarpetsInEventsConditionInput
  ) {
    updateAvailableCarpetsInEvents(input: $input, condition: $condition) {
      id
      eventId
      eventCarpetId
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      eventCarpet {
        id
        order
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAvailableCarpetsInEvents = /* GraphQL */ `
  mutation DeleteAvailableCarpetsInEvents(
    $input: DeleteAvailableCarpetsInEventsInput!
    $condition: ModelAvailableCarpetsInEventsConditionInput
  ) {
    deleteAvailableCarpetsInEvents(input: $input, condition: $condition) {
      id
      eventId
      eventCarpetId
      event {
        id
        type
        boothBooking
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        status
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        date
        startDate
        endDate
        startTime
        endTime
        location {
          __typename
        }
        lectureRooms
        lectureRegistrationStartDate
        lectureRegistrationEndDate
        lectureDocumentsEndDate
        lectureRecordingCorrectionEndDate
        deadlineBoothEquipmentPrint
        startBoothEquipmentBooking
        deadlineBoothEquipmentBooking
        availableLectureTypes
        floorPlan {
          url
          s3Path
          fileName
          type
          name
          __typename
        }
        landingPageUrl
        attendees {
          nextToken
          __typename
        }
        availableBundleBooths {
          nextToken
          __typename
        }
        availableProducts {
          nextToken
          __typename
        }
        ticketProduct {
          id
          visibleInShop
          sku
          ean
          type
          subType
          price
          downPaymentPrice
          allowedBuyerGroups
          requiredShipping
          icon
          stock
          createdAt
          updatedAt
          productCategoryProductsId
          bundleBoothAvaiableAccessoriesId
          bundleBoothIncludedAccessoriesId
          __typename
        }
        eventProducts {
          nextToken
          __typename
        }
        lectures {
          nextToken
          __typename
        }
        tours {
          nextToken
          __typename
        }
        invitationCodes {
          nextToken
          __typename
        }
        bookedBooths {
          nextToken
          __typename
        }
        availableCarpets {
          nextToken
          __typename
        }
        highlightedPersons {
          userid
          __typename
        }
        industries {
          nextToken
          __typename
        }
        x4Id
        WPJetEngineId
        bookingTicketBulletPoints {
          __typename
        }
        hasInvitationCodes
        badgePreview {
          fileName
          alt
          x4UUID
          __typename
        }
        bookingSelectDays
        ticketTAC {
          language
          text
          __typename
        }
        ticketPrivacyPolicy {
          language
          text
          __typename
        }
        boothTAC {
          language
          text
          __typename
        }
        boothPrivacyPolicy {
          language
          text
          __typename
        }
        enableTicketBooking
        ticketNotice {
          language
          text
          __typename
        }
        ticketOrganizerLine {
          language
          text
          __typename
        }
        bookingNotice {
          language
          text
          __typename
        }
        exhibitorLectures
        lecturesNotice {
          language
          text
          __typename
        }
        createdAt
        updatedAt
        eventTicketProductId
        __typename
      }
      eventCarpet {
        id
        order
        name {
          language
          text
          __typename
        }
        description {
          language
          text
          __typename
        }
        color
        image {
          fileName
          alt
          x4UUID
          __typename
        }
        availableInEvents {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNewUser = /* GraphQL */ `
  mutation CreateNewUser($user: String) {
    createNewUser(user: $user)
  }
`;
export const placeOrder = /* GraphQL */ `
  mutation PlaceOrder($order: String) {
    placeOrder(order: $order)
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $documentType: String
    $orderIds: String
    $split: Boolean
    $returnBase64: Boolean
    $referingDocumentNumber: String
    $dueDate: AWSDate
  ) {
    createDocument(
      documentType: $documentType
      orderIds: $orderIds
      split: $split
      returnBase64: $returnBase64
      referingDocumentNumber: $referingDocumentNumber
      dueDate: $dueDate
    ) {
      succeded
      url
      base64
      invoiceNumber
      documentType
      s3Path
      documentNumber
      referingDocumentNumber
      documentName
      status
      date
      amount
      amountNet
      sentToCustomerTime
      __typename
    }
  }
`;
export const sendDocumentEmail = /* GraphQL */ `
  mutation SendDocumentEmail(
    $orderId: String
    $orderFinancialId: String
    $documentNumber: String
  ) {
    sendDocumentEmail(
      orderId: $orderId
      orderFinancialId: $orderFinancialId
      documentNumber: $documentNumber
    ) {
      sentToEmails
      __typename
    }
  }
`;
export const getNextSystemNumber = /* GraphQL */ `
  mutation GetNextSystemNumber($documentType: String) {
    getNextSystemNumber(documentType: $documentType) {
      nextDocumentId
      __typename
    }
  }
`;
