import { useEffect, useRef, useState } from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBIcon, MDBTypography } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { isoToGermanDate } from "../../../../utils/dateTools";
import QRCode from 'qrcode.react';
import shortUUID from 'short-uuid';

const client = generateClient();

export default function Success({ ticketId }) {
  const { t, i18n } = useTranslation();
  const [ticket, setTicket] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const qrRef = useRef(null);

  // Function to fetch ticket data
  const fetchTicket = async () => {
    try {
      const ticketData = await client.graphql({
        query: /* GraphQL */ `
          query GetTicket($id: ID!) {
            getTicket(id: $id) {
              id
              type
              eventDays
              createdAt
              ticketFile {
                s3Path
              }
              personalAddress {
                firstName
                lastName
                company
              }
              event {
                id
                name {
                  language
                  text
                }
                type
                startDate
                endDate
              }
            }
          }
        `,
        variables: { id: ticketId }
      });

      setTicket(ticketData.data.getTicket);
      return ticketData.data.getTicket;
    } catch (error) {
      console.error('Error fetching ticket:', error);
      return null;
    }
  };

  // Effect to handle ticket monitoring
  useEffect(() => {
    if (!ticketId) return;

    const checkTicketStatus = async () => {
      const ticketData = await fetchTicket();
      
      if (ticketData?.ticketFile?.s3Path) {
        setIsLoading(false);
      } else {
        // If ticket file is not yet available, check again after 5 seconds
        setTimeout(checkTicketStatus, 5000);
      }
    };

    checkTicketStatus();
  }, [ticketId]);

  // Function to download QR code
  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector('canvas');
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = `QRCode Ticket Lounges 2024 - ${ticket.personalAddress.firstName} ${ticket.personalAddress.lastName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (!ticketId || !ticket) {
    return <div className="text-center mt-4">{t("No ticket information available")}</div>;
  }

  return (
    <div className="container mt-4">
      <MDBTypography tag="h1" className="text-center text-success mb-4">
        <MDBIcon fas icon="check-circle" className="me-2" />
        {t("Booking Successful!")}
      </MDBTypography>

      <MDBCard className="mx-auto" style={{ maxWidth: '800px' }}>
        <MDBCardBody>
          <MDBCardTitle>{t("Your Ticket Details")}</MDBCardTitle>
          
          <div className="mb-4">
            <h5 className="text-primary">{t("Event")}</h5>
            <p>{ticket.event.name.find(n => n.language === i18n.language)?.text || ticket.event?.name[0].text}</p>
            
            <h5 className="text-primary mt-3">{t("Selected Days")}</h5>
            <p>{ticket.eventDays.map(day => isoToGermanDate(day)).join(", ")}</p>

            <h5 className="text-primary mt-3">{t("Booking Date")}</h5>
            <p>{isoToGermanDate(ticket.createdAt)}</p>
          </div>

          <div className="mb-4">
            <h5 className="text-primary">{t("Download Ticket")}</h5>
            {isLoading ? (
              <div className="text-center py-3">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">{t("Loading")}</span>
                </div>
                <p className="mt-2">{t("Generating your ticket")}</p>
              </div>
            ) : (
              <MDBBtn
                href={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/${ticket.ticketFile.s3Path}`}
                target="_blank"
                color="primary"
                className="me-2"
              >
                <MDBIcon fas icon="download" className="me-2" />
                {t("Download PDF Ticket")}
              </MDBBtn>
            )}
          </div>

          <div className="mb-4">
            <h5 className="text-primary">{t("QR Code")}</h5>
            <div className="text-center" ref={qrRef}>
              <QRCode
                value={shortUUID().fromUUID(ticketId)}
                size={128}
                level={"H"}
                includeMargin={true}
              />
              <div className="mt-2">
                <MDBBtn color="secondary" size="sm" onClick={downloadQRCode}>
                  <MDBIcon fas icon="qrcode" className="me-2" />
                  {t("Download QR Code")}
                </MDBBtn>
              </div>
              <MDBCardText className="mt-2 text-muted">
                {t("This QR code will be used at the event to identify your booking. You can use it instead of your ticket.")}
              </MDBCardText>
            </div>
          </div>

          {ticket.type !== "default" && (
            <div className="alert alert-info">
              <MDBIcon fas icon="info-circle" className="me-2" />
              {t("You will receive the invoice by email.")}
            </div>
          )}
        </MDBCardBody>
      </MDBCard>
    </div>
  );
}