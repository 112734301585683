import { useState, useEffect, useContext } from "react"
import { MDBAlert, MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCheckbox, MDBChip, MDBCol, MDBIcon, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBRadio, MDBRow, MDBSelect, MDBStepper, MDBStepperStep, MDBTypography, MDBValidationItem } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { generateClient } from 'aws-amplify/api';
import countryList from 'react-select-country-list'


export default function Address({
    user, params, orderData, setOrderData, setParams, useProfileAddressForBilling, setUseProfileAddressForBilling,
    billingAddress, setBillingAddress,personalAddress, setPersonalAddress, handleClickPrev, handleClickNext,
    validations, showValidations, customerType, setCustomerType, products}) {
    const client = generateClient();
    const { t, i18n } = useTranslation()

    const [profiles, setProfiles] = useState([]);

    const [companyData, setCompanyData] = useState({});

    const loadCompanyData = async (companyId) => {
        try {
            const company = await client.graphql({
                variables: { id: companyId },
                query: /* GraphQL */ `
                query GetCompany($id: ID!) {
                  getCompany(id: $id) {
                        id
                        name
                        billingContact {
                            email
                            address {
                                street
                                streetNumber
                                zip
                                city
                                countryCode
                            }
                        }
                    }
                }
                `
            });
            setCompanyData(company.data.getCompany);
            setBillingAddress({
                company: company.data.getCompany.name,
                ...company.data.getCompany.billingContact?.address
            });
            setOrderData(old => ({...old, billingEmail: company.data.getCompany.billingContact?.email || ""}));
        } catch (error) {
            console.log("error on fetching company data", error);
        }
    }

    useEffect(() => {
        if(params.companyId) {
            loadCompanyData(params.companyId);
            setUseProfileAddressForBilling(false)
            setCustomerType("business");
        }
    }, [params.companyId]);


    const updatePersonalAddress = (event) => {
        console.log(event);
        const path = event.target.attributes.datapath.value;
        const value = event.target.value;
        setPersonalAddress({...personalAddress, [path]: value});
    }

    const updateBillingAddress = (event) => {
        const path = event.target.attributes.datapath.value;
        const value = event.target.value;
        setBillingAddress({...billingAddress, [path]: value});
    }

    // If booked by user group admin, get the company for the booking admins so that admin can book for the selected admin user of the company
    const [companyAdmins, setCompanyAdmins] = useState([]);
    const getCompanyAdmins = async (companyId) => {
        try {
            const admins = await client.graphql({
                variables: { id: companyId },
                query: /* GraphQL */ `
                query GetCompany($id: ID!) {
                  getCompany(id: $id) {
                    adminEmployees {
                        items {
                            user {
                                salutation
                              firstName
                              lastName
                              image {
                                fileName
                              }
                              id
                              contact {
                                address {
                                  city
                                  company
                                  countryCode
                                  street
                                  streetNumber
                                  zip
                                }
                              }
                            }
                            image {
                              fileName
                            }
                          }
                    }
                    }
                }
                `
            });
            setCompanyAdmins(admins.data.getCompany.adminEmployees.items);
        } catch (error) {
            console.log("error on fetching company admins", error);
        }
    }

    useEffect(() => {
        if(user["cognito:groups"]?.includes("Admin") && params.companyId) {
            getCompanyAdmins(params.companyId);
        }
    }, [params.companyId]);

    const handleSelectAdmin = (adminUserId) => {
        const admin = companyAdmins.find(admin => admin.user.id === adminUserId);
        setParams(old => ({...old, adminId: adminUserId}));
        setPersonalAddress({
            ...personalAddress,
            salutation: admin?.user.salutation,
            firstName: admin?.user.firstName,
            lastName: admin?.user.lastName,
            ...admin?.user.contact?.address
        });
    }




const handleClickButton = () => { 
  handleClickNext();
    // if(validations?.personalAddress?.length > 0) {
    //     setShowValidations(old => ({...old, personalAddress: true}));
    // } else {
    //     setShowValidations(old => ({...old, personalAddress: false}));
        
    // }
    
    // if (!useProfileAddressForBilling && validations?.billingAddress?.length > 0) {
    //     setShowValidations(old => ({...old, billingAddress: true}));
    // } else {
    //     setShowValidations(old => ({...old, billingAddress: false}));
    // }

    // if(validations?.personalAddress?.length === 0 && (useProfileAddressForBilling || (!useProfileAddressForBilling && validations?.billingAddress?.length === 0))) {
    //     handleClickNext();
    // }

}

  return (
<>
 {/* Address data from user profile */}
 
<MDBCard className="mt-2 mt-lg-4 col-lg-10 col-xxl-8 mx-auto border-none shadow-none text-center">
    <MDBCardBody>
    {
        
    }
    {companyData?.name ?
    <div className="alert alert-info mt-2 mt-lg-4">
        {t("You are currently booking for")} <strong>{companyData.name}</strong>
    </div>
    :
    products.some(product => product?.allowedBuyerGroups &&  product.allowedBuyerGroups.length === 1 && product.allowedBuyerGroups?.includes("business")) ?
        <div className="alert alert-info mt-2 mt-lg-4">
            <strong>{t("Only for business customers")}</strong><br /> {t("At least one of your products can only be purchased by corporate customers")}
        </div>
        : <>
        <h4>{t("Book as")}</h4>
        <MDBBtnGroup className="mb-3">
        <MDBRadio btn defaultChecked={customerType === "private"} btnColor='light' id='btn-radio' name='customerType' wrapperTag='span' label={t("Private individual")} onChange={() => setCustomerType("private")} />
        <MDBRadio btn defaultChecked={customerType === "business"} btnColor='light' id='btn-radio2' name='customerType' wrapperTag='span' label={t("Company")} onChange={() => setCustomerType("business")} />
        </MDBBtnGroup>
        </>
    }

    {
        user["cognito:groups"]?.includes("Admin") && params.companyId &&
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBSelect search data={[{text: t("Select"), value: "", disabled: true, defaultSelected:true}, ...companyAdmins.map(admin => ({text: admin.user.firstName + " " + admin.user.lastName, value: admin.user.id}))]} value={params.adminId || ""} size="sm" required autoComplete="off" datapath="adminId" label={t("Select admin")} onChange={e => handleSelectAdmin(e?.value)} />
                
            </MDBCol>
        </MDBRow>
    }
    
    <h4>{t("Your information")}</h4>
        <MDBRow className="mb-2">
            <MDBCol size="12">
                <MDBInput size="sm" autoComplete="off" datapath="company" label={t("Company")} value={personalAddress.company || ""} onChange={updatePersonalAddress} />
            </MDBCol>
         </MDBRow>
         <MDBRow className="mb-2">
            <MDBCol size="6" md="6">
                <MDBInput size="sm" autoComplete="off" datapath="salutation" label={t("Salutation")} value={personalAddress?.salutation || ""} onChange={updatePersonalAddress} />
            </MDBCol>
            <MDBCol size="6" md="6">
                <MDBInput size="sm" autoComplete="off" datapath="title" label={t("Title")} value={personalAddress?.title || ""} onChange={updatePersonalAddress} />
            </MDBCol>
         </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="6" md="6">
            <MDBValidationItem invalid feedback={t("Invalid")}>
              <MDBInput size="sm" required autoComplete="off" datapath="firstName" label={t("First Name")} value={personalAddress?.firstName || ""} onChange={updatePersonalAddress} />
            </MDBValidationItem>
               
            </MDBCol>
            
            <MDBCol size="12" md="6" className="mt-3 mt-md-0">
            <MDBValidationItem invalid feedback={t("Invalid")}>
                <MDBInput size="sm" required autoComplete="off" datapath="lastName" label={t("Last name")} value={personalAddress?.lastName || ""} onChange={updatePersonalAddress} />
            </MDBValidationItem>
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="8">
            <MDBValidationItem invalid feedback={t("Invalid")}>
                <MDBInput size="sm" required autoComplete="off" datapath="street" label={t("Street")} value={personalAddress?.street || ""} onChange={updatePersonalAddress} />
            </MDBValidationItem>
            </MDBCol>
            <MDBCol size="4">
            <MDBValidationItem invalid feedback={t("Invalid")}>
                <MDBInput size="sm" required autoComplete="off" datapath="streetNumber" label={t("Number")} value={personalAddress?.streetNumber || ""} onChange={updatePersonalAddress} />
            </MDBValidationItem>
            </MDBCol>
        </MDBRow>
        
        <MDBRow className="mb-2">
            
            <MDBCol>
            <MDBValidationItem invalid feedback={t("Invalid")}>
                <MDBInput size="sm" required autoComplete="off" datapath="zip" label={t("Zip")} value={personalAddress?.zip || ""} onChange={updatePersonalAddress} />
            </MDBValidationItem>
            </MDBCol>
            <MDBCol>
            <MDBValidationItem invalid feedback={t("Invalid")}>
                <MDBInput size="sm" required autoComplete="off" datapath="city" label={t("City")} value={personalAddress?.city || ""} onChange={updatePersonalAddress} />
            </MDBValidationItem>
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>
            
            <MDBValidationItem invalid feedback={t("Invalid")}>
            <MDBSelect
            search
            data={[
                {text: t("Select"), value: "", disabled: true, defaultSelected:true},
                ...countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
                const order = ['DE', 'AT', 'CH'];
                    const indexA = order.indexOf(a.value);
                const indexB = order.indexOf(b.value);
                if (indexA > -1 && indexB > -1) {
                    return indexA - indexB;
                } else if (indexA > -1) {
                    return -1;
                } else if (indexB > -1) {
                    return 1;
                }
                return a.value.localeCompare(b.value);
                }) || [{}] || [{}]
            ]}
            value={personalAddress?.countryCode || ""}
            size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")} onChange={e => updatePersonalAddress({
                target: {
                    attributes: {
                        datapath: {
                            value: "countryCode"
                        }
                    },
                    value: e?.value
                }
            })} />
                <MDBInput className="d-none" labelClass="d-none" size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")} value={personalAddress?.countryCode || ""} />
            </MDBValidationItem>
            </MDBCol>
        </MDBRow>
        <h6>{t("Tax")}:</h6>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBInput required={customerType === "business" && personalAddress.countryCode !== "DE"} size="sm" autoComplete="off" datapath="taxId" label={t("Vat Id")} value={orderData?.vatInfo?.vatNumber || ""} onChange={(e) => setOrderData(old => ({...old, vatInfo: {vatNumber: e.target.value}}))} />
            </MDBCol>
        </MDBRow>
        <h6>{t("Billing data")}:</h6>
        <MDBRow className="mb-2">
            <MDBCol>
                <MDBRow>
                    <MDBCol size="8">
                    <MDBInput required={customerType === "business"} disabled={orderData?.orderNumber === "not required"} size="sm" autoComplete="off" datapath="buyerOrderNumber" label={t("Order number")} value={orderData?.buyerOrderNumber && orderData?.buyerOrderNumber !== "not required" ? orderData?.buyerOrderNumber : ""} onChange={(e) => setOrderData(old => ({...old, buyerOrderNumber: e.target.value}))} />
                    </MDBCol>
                    <MDBCol size="4">
                    <MDBCheckbox label={t("Not required")} checked={orderData?.orderNumber === "not required"} onChange={(e) => setOrderData(old => ({...old, orderNumber: e.target.checked ? "not required" : ""}))} />
                    </MDBCol>
                </MDBRow>
                
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>
            {customerType === "business" ?
            <>
            <p>{t("Invoice will be sent to")} {user.email}</p>
            <MDBRow>
            <MDBCol size="8">
            <MDBInput required={customerType === "business"} disabled={orderData?.billingEmail === "not required"} size="sm" autoComplete="off" datapath="billingEmail" label={t("Additional Billing email address")} value={orderData?.billingEmail && orderData?.billingEmail !== "not required" ? orderData?.billingEmail : ""} onChange={(e) => setOrderData(old => ({...old, billingEmail: e.target.value}))} />
            </MDBCol>
            <MDBCol size="4">
            <MDBCheckbox label={t("Not required")} checked={orderData?.billingEmail === "not required"} onChange={(e) => setOrderData(old => ({...old, billingEmail: e.target.checked ? "not required" : ""}))} />
            </MDBCol>
            </MDBRow>
            </>
            
            :
            <MDBInput size="sm" autoComplete="off" datapath="billingEmail" label={t("Billing email address")} value={orderData?.billingEmail || ""} onChange={(e) => setOrderData(old => ({...old, billingEmail: e.target.value}))} />
            }
               
                
            </MDBCol>
        </MDBRow>
    </MDBCardBody>
</MDBCard>
{
!companyData?.name &&
    <div className="mt-2 mt-lg-4 text-start mx-auto" style={{width: "fit-content"}}>
        <MDBCheckbox label={t("Use the same address for billing")} checked={useProfileAddressForBilling} onChange={e => {
            setUseProfileAddressForBilling(e.target.checked);
            // Set personal country code to billing country code
            if(!e.target.checked) {
                setBillingAddress(old => ({...old, countryCode: personalAddress.countryCode}));
            }
        }} />
    </div>
}



{ !useProfileAddressForBilling && 
<MDBCard className="mt-2 mt-lg-4 col-lg-10 col-xl-7 mx-auto">
    <MDBCardBody>
        <h6>{t("Billing Address")}</h6>
         <MDBRow className="mb-2">
            <MDBCol size="12">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("company") ? "is-invalid" : ""} autoComplete="off" datapath="company" label={t("Company")} value={billingAddress?.company || ""} onChange={updateBillingAddress} />
            </MDBCol>
         </MDBRow>
        <MDBRow className="mb-2">
        
            <MDBCol size="6" md="6">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("firstName") ? "is-invalid" : ""} autoComplete="off" datapath="firstName" label={t("First Name")} value={billingAddress?.firstName || ""} onChange={updateBillingAddress} />
            </MDBCol>
            
            <MDBCol size="12" md="6" className="mt-3 mt-md-0">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("lastName") ? "is-invalid" : ""} autoComplete="off" datapath="lastName" label={t("Last name")} value={billingAddress?.lastName || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol size="8">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("street") ? "is-invalid" : ""} autoComplete="off" datapath="street" label={t("Street")} value={billingAddress?.street || ""} onChange={updateBillingAddress} />
            </MDBCol>
            <MDBCol size="4">
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("streetNumber") ? "is-invalid" : ""} autoComplete="off" datapath="streetNumber" label={t("Number")} value={billingAddress?.streetNumber || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        
        <MDBRow className="mb-2">
            
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("zip") ? "is-invalid" : ""} autoComplete="off" datapath="zip" label={t("Zip")} value={billingAddress?.zip || ""} onChange={updateBillingAddress} />
            </MDBCol>
            <MDBCol>
                <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("city") ? "is-invalid" : ""} autoComplete="off" datapath="city" label={t("City")} value={billingAddress?.city || ""} onChange={updateBillingAddress} />
            </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
            <MDBCol>
            <MDBSelect 
            search
            data={[
                ...(countryList().getData().map(c => ({text: t(c.label), value: c.value})).sort((a, b) => {
                const order = ['DE', 'AT', 'CH'];
                    const indexA = order.indexOf(a.value);
                const indexB = order.indexOf(b.value);
                if (indexA > -1 && indexB > -1) {
                    return indexA - indexB;
                } else if (indexA > -1) {
                    return -1;
                } else if (indexB > -1) {
                    return 1;
                }
                return a.value.localeCompare(b.value);
                }) || [{}] || [{}]),
                {text: t("Select"), value: "", disabled: true, defaultSelected:true},
            ]}

            value={billingAddress?.countryCode || ""}
            size="sm" required autoComplete="off" datapath="countryCode" label={t("Country")} onChange={e => updateBillingAddress({
                target: {
                    attributes: {
                        datapath: {
                            value: "countryCode"
                        }
                    },
                    value: e?.value
                }
            })} />
                {/* <MDBInput size="sm" className={showValidations?.billingAddress && validations?.billingAddress?.includes("countryCode") ? "is-invalid" : ""} autoComplete="off" datapath="countryCode" label={t("Country")} value={billingAddress?.countryCode || ""} onChange={updateBillingAddress} /> */}
            </MDBCol>
        </MDBRow>
       
    </MDBCardBody>

</MDBCard> }

<div className="text-center mt-4">
    <MDBBtn color="primary" outline className="me-3" onClick={handleClickPrev}>{t("Back")}</MDBBtn>
    <MDBBtn color="primary" onClick={handleClickButton}>{t("Next")}</MDBBtn>
</div>
</>
  )
}
