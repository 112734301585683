import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody, MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBBadge } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';
import { getLocalizedText } from '../../utils/localizedText';

const client = generateClient();

export default function AdminIndustries() {
  const [industries, setIndustries] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchIndustries();
  }, []);

  const fetchIndustries = async () => {
    try {
      const result = await client.graphql({
        query: `
          query ListIndustries {
            listIndustries {
              items {
                id
                name {
                  language
                  text
                }
                domain
                primaryColor
                secondaryColor
                status
                sortKey
                logo {
                  fileName
                }
              }
            }
          }
        `
      });
      setIndustries(result.data.listIndustries.items);
    } catch (error) {
      console.error('Error fetching industries:', error);
    }
  };

  const deleteIndustry = async (id) => {
    if (window.confirm(t('Are you sure you want to delete this industry?'))) {
      try {
        await client.graphql({
          query: `
            mutation DeleteIndustry($input: DeleteIndustryInput!) {
              deleteIndustry(input: $input) {
                id
              }
            }
          `,
          variables: { input: { id } }
        });
        fetchIndustries();
      } catch (error) {
        console.error('Error deleting industry:', error);
      }
    }
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">{t('Industries')}</h5>
          <Link to="/admin/industry/create">
            <MDBBtn color="primary">
              <MDBIcon icon="plus" className="me-2" /> {t('Add Industry')}
            </MDBBtn>
          </Link>
        </div>

        <MDBTable hover>
          <MDBTableHead>
            <tr>
              <th>{t('Name')}</th>
              <th>{t('Domain')}</th>
              <th>{t('Colors')}</th>
              <th>{t('Status')}</th>
              <th>{t('Actions')}</th>
            </tr>
          </MDBTableHead>
          <MDBTableBody>
            {industries.map((industry) => (
              <tr key={industry.id}>
                <td>
                  {industry.logo && (
                    <img 
                      src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/industryLogos/${industry.logo.fileName}`}
                      alt="Logo"
                      height="30"
                      className="me-2"
                    />
                  )}
                  {getLocalizedText(industry.name)}
                </td>
                <td>{industry.domain}</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div 
                      className="rounded me-2" 
                      style={{
                        backgroundColor: industry.primaryColor,
                        width: '20px',
                        height: '20px'
                      }}
                    />
                    <div 
                      className="rounded" 
                      style={{
                        backgroundColor: industry.secondaryColor,
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  </div>
                </td>
                <td>
                  <MDBBadge color={industry.status === 'ACTIVE' ? 'success' : 'warning'}>
                    {industry.status}
                  </MDBBadge>
                </td>
                <td>
                  <Link to={`/admin/industry/${industry.id}`}>
                    <MDBBtn color="link" size="sm">
                      <MDBIcon icon="edit" />
                    </MDBBtn>
                  </Link>
                  <MDBBtn 
                    color="link" 
                    size="sm" 
                    onClick={() => deleteIndustry(industry.id)}
                  >
                    <MDBIcon icon="trash" className="text-danger" />
                  </MDBBtn>
                </td>
              </tr>
            ))}
          </MDBTableBody>
        </MDBTable>
      </MDBCardBody>
    </MDBCard>
  );
}