
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { useState } from "react";
import { useEffect } from "react";
import { MDBChip, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane, MDBAlert } from "mdb-react-ui-kit";

const client = generateClient();

export default function SelectTopics({isActive, toggleActive, setSelectedTopics, selectedTopics, selectedTopicGroups, parentTopics, parentTopicGroups, maxSelect}) {
    const { t, i18n } = useTranslation();

    const [topics, setTopics] = useState([]);
    const [topicGroups, setTopicGroups] = useState([]);


    const getTopics = async () => {
        try {
          const topicGroupsWithTopics = await client.graphql({
            query: /* GraphQL */ `
            query ListTopicGroups(
              $filter: ModelTopicGroupFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listTopicGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                  id
                  color
                  sortKey
                  name {
                    language
                    text
                  }
                  topics {
                    items {
                      id
                      name {
                        language
                        text
                      }
                      topicGroupTopicsId
                    }
                  }
                }
                nextToken
                __typename
              }
            }
          `
            });
            console.log("topicGroupsWithTopics", topicGroupsWithTopics);
            // Set Topics groups, sort by field sortKey
            setTopicGroups(topicGroupsWithTopics.data.listTopicGroups.items.sort((a, b) => a.sortKey - b.sortKey));
            const topics = topicGroupsWithTopics.data.listTopicGroups.items.map((topicGroup) => topicGroup.topics.items).flat();
            setTopics(topics);

        } catch (error) {
            console.log("error on fetching topics", error);
        }
    }

    useEffect(() => {
        if (!isActive) return;
        if (topicGroups.length === 0) {
          if (parentTopics.length > 0) {
            setTopics(parentTopics);
            setTopicGroups(parentTopicGroups);
          } else {
            getTopics();
          }
        }
    }, [isActive]);

const [basicActive, setBasicActive] = useState(0);

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  // Handle the click on the chip, check maxSelect and if there are already [maxSelect] topics selected, set an error message that will be displayed in the parent component
  const [showAlert, setShowAlert] = useState(false);

  const handleChipClick = (topic) => {
    if (selectedTopics.find(t => t === topic.id)) {
      console.log("remove topic", topic);
      console.log("new selected topics", selectedTopics.filter(t => t !== topic.id));
      setSelectedTopics(selectedTopics.filter(t => t !== topic.id));
    } else {
      if (selectedTopics.length < maxSelect) {
        setSelectedTopics([...selectedTopics, topic.id]);
      } else {
        setShowAlert(true);
      }
    }
  }

  


  return (
<>
    {/* Use MDBTabs and MDBChip to display all topics ordered by their group. if you click on a chip, it should be added to the selectedTopics array. if you click on it again, it should be removed from the array. this should be visible in the chip (e.g. a different color).  */}
    {
        isActive && (<>
            <MDBTabs fill>
                {
                    topicGroups.sort((a, b) => a.sortKey - b.sortKey).map((topicGroup, index) => {
                        return (
                            <MDBTabsItem className="topicsTab">
                                <MDBTabsLink color={selectedTopicGroups?.includes(topicGroup.id) ? "success" : ""} onClick={() => handleBasicClick(index)} active={basicActive === index} className="px-0 bg-opacity-10">
                                    {topicGroup.name.find((name) => name.language === i18n.language)?.text || topicGroup.name[0]?.text}
                                </MDBTabsLink>
                            </MDBTabsItem>
                        )
                    })
                }
            </MDBTabs>

            <MDBTabsContent className="my-2 my-lg-5">
                {
                    topicGroups.map((topicGroup, index) => {
                        return (
                            <MDBTabsPane open={basicActive === index} className="px-lg-5">
                                {
                                    topicGroup.topics.items.map((topic) => {
                                        return (
                                            <MDBChip
                                                className="m-2 float-start"
                                                close
                                                style={{ backgroundColor: selectedTopics.find(t => t === topic.id) ? "#3dd378" : "#ececec", color: selectedTopics.find(t => t === topic.id) ? "white" : "black"}}
                                                onClick={() => handleChipClick(topic)}
                                            >
                                                {topic.name.find((name) => name.language === i18n.language)?.text || topic.name[0]?.text}
                                            </MDBChip>
                                        )
                                    })
                                }
                            </MDBTabsPane>
                        )
                    })
                }
            </MDBTabsContent>
        </>)

    }
    <MDBAlert
        color='danger'
        autohide
        position='bottom-right'
        delay={2000}
        appendToBody
        open={showAlert}
        onClose={() => setShowAlert(false)}
      >
        {t("You can only select up to") + " " + maxSelect + " " + t("topics")}
      </MDBAlert>
</>
  )
}
