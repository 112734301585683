import React, { useState, useEffect, useContext } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon, MDBSpinner, MDBCardImage, MDBModal, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalTitle, MDBModalBody } from 'mdb-react-ui-kit';
import { generateClient } from 'aws-amplify/api';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";

const client = generateClient();

const AllBasicCourses = () => {
  
  const [courses, setCourses] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user, addUserCondition } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const coursesData = await client.graphql({
        query: /* GraphQL */ `
          query ListCourses($filter: ModelCourseFilterInput, $limit: Int, $nextToken: String) {
            listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                name {
                  language
                  text
                }
                topics {
                    language
                    text
                }
                shortDescription {
                  language
                  text
                }
                type
                duration
                sortKey
              }
            }
          }
        `
      });
      setCourses(coursesData.data.listCourses.items.sort((a, b) => (a.sortKey || 0) - (b.sortKey || 0)));
      setAllTopics([...new Set(coursesData.data.listCourses.items.map(course => course.topics.find(topic => topic.language === i18n.language)?.text))]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching courses:", error);
      setIsLoading(false);
    }
  };

  const getLocalizedText = (textArray, field) => {
    const localizedText = textArray?.find(item => item.language === i18n.language);
    return localizedText ? localizedText.text : textArray?.[0]?.[field] || '';
  };

  // Modal buy access
  const [buyModal, setBuyModal] = useState(false);

  useEffect(() => {
      if(user.hasInsightAccess) {
        setTimeout(() => {
          setBuyModal(false);
      }, 500);
      } else {
          setTimeout(() => {
              setBuyModal(true);
          }, 500);
      }
          
  }, [user]);

  const handleTopicClick = (topic) => {
    setSelectedTopic(selectedTopic === topic ? null : topic);
  };

  const filteredCourses = selectedTopic
    ? courses.filter(course => course.topics.some(t => t.text === selectedTopic)).sort((a, b) => (a.sortKey || 0) - (b.sortKey || 0))
    : courses;

  return (
    <>
      <MDBContainer className="my-5">
        <h1 className="text-center mb-4">
          <span className="text-danger">ACADEMY</span> <span className="text-dark">Basics</span>
        </h1>
        
        <div className="text-center mb-4">
          <img src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/academy/Format-Icons/button_basics_cp+(1).png" alt="Academy Basics" height="70px" />
          <img src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/academy/Format-Icons/button_live_seminare_cp+(1).png" alt="Academy Basics" className="ms-2" height="50px" />
          <img src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/academy/Format-Icons/button_reports_cp.png" alt="Academy Basics" className="ms-2" height="50px" />
          <img src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/academy/Format-Icons/button_e_learning_cp (1).png" alt="Academy Basics" className="ms-2" height="50px" />
          <img src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/academy/Format-Icons/button_deep_dive_cp.png" alt="Academy Basics" className="ms-2" height="50px" />
          <img src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/academy/Format-Icons/button_masterclass_cp.png" alt="Academy Basics" className="ms-2" height="50px" />
        </div>

        {isLoading ? (
          <div className="text-center">
            <MDBSpinner role="status">
              <span className="visually-hidden">{t("Loading")}</span>
            </MDBSpinner>
          </div>
        ) : (
          <>
            <div className="d-flex flex-wrap justify-content-center mb-4">
              {allTopics.map((topic, index) => (
                <React.Fragment key={index}>
                  <span 
                    className={`mx-2 my-1 cursor-pointer ${selectedTopic === topic ? 'fw-bold' : ''}`}
                    onClick={() => handleTopicClick(topic)}
                    style={{ cursor: 'pointer' }}
                  >
                    {topic}
                  </span>
                  {index < allTopics.length - 1 && <span className="mx-2 my-1">•</span>}
                </React.Fragment>
              ))}
            </div>
            <MDBRow className="row-cols-1 row-cols-md-4 g-4">
              {filteredCourses.map((course) => (
                <MDBCol key={course.id}>
                  <Link to={`/academy/basics/course/${course.id}`}>
                    <MDBCard className="h-100 rounded-8">
                      <MDBCardText tag="h6" className="px-3 pt-3 pb-2">{getLocalizedText(course.topics, 'text')}</MDBCardText>
                      <MDBCardTitle tag="h4" className="text-black px-3" style={{fontWeight: "600"}}>{getLocalizedText(course.name, 'text')}</MDBCardTitle>
                      <MDBCardImage src="https://iec-cp-public.s3.eu-central-1.amazonaws.com/academy/Format-Cover/Cropped/CoverBasicsCropped2x.jpg" alt="Course" className="mt-auto rounded-8 img-fluid" />
                    </MDBCard>
                  </Link>
                </MDBCol>
              ))}
            </MDBRow>
          </>
        )}
      </MDBContainer>
      <MDBModal open={buyModal} onClose={() => setBuyModal(false)} nonInvasive={true}>
        <MDBModalDialog centered size="lg">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{t("No access to the lecture videos")}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <div className="text-center">
                <h5>{t("You do not have access to the ACADEMY Basics videos")}</h5>
                <p>{t("To gain access, you can purchase an ACADEMY Basics access")}</p>
                <p>{t("You can view the courses, but you do not have access to the videos.")}</p>
                <MDBBtn color="success" onClick={() => navigate("/bookings")}>{t("Purchase access")}</MDBBtn>
                <MDBBtn className="ms-2" color='primary' rippleColor='light' onClick={() => setBuyModal(false)}>{t("Discover courses")}</MDBBtn>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default AllBasicCourses;