import React from 'react';
import { MDBRow, MDBCol, MDBInput, MDBInputGroup, MDBTextArea } from 'mdb-react-ui-kit';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag";

const LocationTab = ({ event, onInputChange }) => {
  const { t } = useTranslation();

  const handleLocationChange = (field, value, language = null) => {
    const updatedLocation = { ...event.location };
    
    if (language) {
      // Handle localized fields (name, travelAndParkingNotice)
      const fieldArray = updatedLocation[field] || [];
      const existingIndex = fieldArray.findIndex(item => item.language === language);
      
      if (existingIndex !== -1) {
        fieldArray[existingIndex] = { ...fieldArray[existingIndex], text: value };
      } else {
        fieldArray.push({ language, text: value });
      }
      updatedLocation[field] = fieldArray;
    } else if (field.includes('.')) {
      // Handle nested fields (address.street, coordinates.lat, etc.)
      const [parent, child] = field.split('.');
      updatedLocation[parent] = {
        ...updatedLocation[parent],
        [child]: value
      };
    } else {
      // Handle direct fields
      updatedLocation[field] = value;
    }

    onInputChange('location', updatedLocation);
  };

  return (
    <MDBRow>
      <MDBCol md="6">
        {/* Location Name */}
        <h5>{t("Location Name")}</h5>
        <MDBInputGroup className="mb-3">
          <div className="input-group-text">
            <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
          </div>
          <MDBInput
            label={t("Name (German)")}
            value={event?.location?.name?.find(n => n.language === 'de')?.text || ''}
            onChange={(e) => handleLocationChange('name', e.target.value, 'de')}
          />
        </MDBInputGroup>
        <MDBInputGroup className="mb-4">
          <div className="input-group-text">
            <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
          </div>
          <MDBInput
            label={t("Name (English)")}
            value={event?.location?.name?.find(n => n.language === 'en')?.text || ''}
            onChange={(e) => handleLocationChange('name', e.target.value, 'en')}
          />
        </MDBInputGroup>

        {/* Travel & Parking Notice */}
        <h5>{t("Travel & Parking Notice")}</h5>
        <MDBInputGroup className="mb-3">
          <div className="input-group-text">
            <ReactCountryFlag countryCode="de" svg style={{ width: "20px", height: "20px" }} />
          </div>
          <MDBTextArea
            label={t("Notice (German)")}
            value={event?.location?.travelAndParkingNotice?.find(n => n.language === 'de')?.text || ''}
            onChange={(e) => handleLocationChange('travelAndParkingNotice', e.target.value, 'de')}
          />
        </MDBInputGroup>
        <MDBInputGroup className="mb-4">
          <div className="input-group-text">
            <ReactCountryFlag countryCode="gb" svg style={{ width: "20px", height: "20px" }} />
          </div>
          <MDBTextArea
            label={t("Notice (English)")}
            value={event?.location?.travelAndParkingNotice?.find(n => n.language === 'en')?.text || ''}
            onChange={(e) => handleLocationChange('travelAndParkingNotice', e.target.value, 'en')}
          />
        </MDBInputGroup>
      </MDBCol>

      <MDBCol md="6">
        {/* Address */}
        <h5>{t("Address")}</h5>
        <MDBRow>
          <MDBCol md="8">
            <MDBInput
              className="mb-3"
              label={t("Street")}
              value={event?.location?.address?.street || ''}
              onChange={(e) => handleLocationChange('address.street', e.target.value)}
            />
          </MDBCol>
          <MDBCol md="4">
            <MDBInput
              className="mb-3"
              label={t("Street Number")}
              value={event?.location?.address?.streetNumber || ''}
              onChange={(e) => handleLocationChange('address.streetNumber', e.target.value)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <MDBInput
              className="mb-3"
              label={t("ZIP")}
              value={event?.location?.address?.zip || ''}
              onChange={(e) => handleLocationChange('address.zip', e.target.value)}
            />
          </MDBCol>
          <MDBCol md="6">
            <MDBInput
              className="mb-3"
              label={t("City")}
              value={event?.location?.address?.city || ''}
              onChange={(e) => handleLocationChange('address.city', e.target.value)}
            />
          </MDBCol>
        </MDBRow>
        <MDBInput
          className="mb-4"
          label={t("Country Code")}
          value={event?.location?.address?.countryCode || ''}
          onChange={(e) => handleLocationChange('address.countryCode', e.target.value)}
        />

        {/* Coordinates */}
        <h5>{t("Coordinates")}</h5>
        <MDBInput
          className="mb-3"
          type="number"
          step="any"
          label={t("Latitude")}
          value={event?.location?.coordinates?.lat || ''}
          onChange={(e) => handleLocationChange('coordinates.lat', parseFloat(e.target.value))}
        />
        <MDBInput
          className="mb-3"
          type="number"
          step="any"
          label={t("Longitude")}
          value={event?.location?.coordinates?.lng || ''}
          onChange={(e) => handleLocationChange('coordinates.lng', parseFloat(e.target.value))}
        />
      </MDBCol>
    </MDBRow>
  );
};

export default LocationTab;