import { useEffect, useState } from 'react'
import { MDBCheckbox, MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBBtn, MDBIcon,
    MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
    MDBCollapse, MDBInput, MDBChip, MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBCardImage, MDBCardText, MDBModal, MDBModalTitle, MDBModalDialog, MDBModalContent, MDBModalHeader, MDBModalBody 
 } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
const client = generateClient();
export default function RequestAffiliation() {
    const { t, i18n } = useTranslation();

    // Get company id from URL which is in format /companies/:id
    const companyId = window.location.pathname.split("/")[2];

    const [company, setCompany] = useState({});

    useEffect(() => {
        const getCompany = async () => {
            try {
                const company = await client.graphql({
                    query: /* GraphQL */ `
                    query GetCompany($id: ID!) {
                        getCompany(id: $id) {
                            id
                            name
                            status
                            image {
                                main {
                                alt
                                fileName
                                }
                                
                            }
                        }
                    }
                    `,
                    variables: { id: companyId },
                });
                setCompany(company.data.getCompany);
            } catch (error) {
                console.log(error);
            }
        };
        getCompany();
    }, []);
  return (
    <MDBCard>
        <MDBCardBody>
            <MDBCardTitle>{t("Request Affiliation")}</MDBCardTitle>
            <MDBCardText>
                {t("Submit a request to be listed as an employee of the company. The company's manager will process your request as quickly as possible. You will then receive a notification from us.")}
            </MDBCardText>
            <MDBCard className="mx-auto" style={{maxWidth: "800px"}}>
                <MDBCardBody>
                    <MDBCardTitle>
                    {company.name}
                    <img
                    src={company.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + company.image?.main?.fileName : "https://iec-cp-public.s3.eu-central-1.amazonaws.com/defaultImages/horizontal.jpg"}
                    alt={company.image?.alt}
                    className="float-end"
                    style={{ height: "2rem" }}
                     />
                    </MDBCardTitle>
                      <img
                      src='https://med.gov.bz/wp-content/uploads/2020/08/dummy-profile-pic.jpg'
                      className='rounded-circle shadow-1-strong mx-auto d-block my-4'
                      alt=''
                      width="150" height="150"
                      />
                          {/* <MDBFileUpload acceptedExtensions='image/*' defaultMessage="" /> */}

                  <h5>{t("Position")}</h5>
                  <MDBRow className="mb-3">
                    <MDBCol size="6">
                      <MDBInput className="mb-2 col col-6" label={t("Department")} />
                    </MDBCol>
                    <MDBCol size="6">
                      <MDBInput className="mb-2 col col-6" label={t("Role")} />
                    </MDBCol>
                  </MDBRow>

                </MDBCardBody>
            </MDBCard>
            <MDBBtn className="float-end" color="primary">{t("Request Affiliation")}</MDBBtn>
        </MDBCardBody>
    </MDBCard>
  )
}
