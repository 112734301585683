import React, { useState, useEffect, useCallback } from "react";
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBTextArea, MDBChipsInput  } from "mdb-react-ui-kit";
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
const client = generateClient();
export default function Images() {
    const { t, i18n } = useTranslation();
  return (
<>
<MDBCardTitle>{t("Images")}</MDBCardTitle>
<MDBRow>
  <MDBCol md="3">
      <MDBCard>
          <MDBCardBody>
          
          <img
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"}
                className="border"
                style={{ width: "100%", objectFit: "contain" }}
            />
            <h5 className="mt-2">Beispielbild</h5>
            <p>Bildbeschreibung</p>
            <MDBChipsInput
      label="Tags"
      editable
      initialValues={[
        { tag: 'reinraum' },
        { tag: 'wasser' }
      ]}
    />
          </MDBCardBody>
      </MDBCard>
  </MDBCol>
  <MDBCol md="3">
      <MDBCard>
          <MDBCardBody>
          
          <img
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"}
                className="border"
                style={{ width: "100%", objectFit: "contain" }}
            />
            <h5 className="mt-2">Beispielbild</h5>
            <p>Bildbeschreibung</p>
            <MDBChipsInput
      label="Tags"
      editable
      initialValues={[
        { tag: 'reinraum' },
        { tag: 'wasser' }
      ]}
    />
          </MDBCardBody>
      </MDBCard>
  </MDBCol>
  <MDBCol md="3">
      <MDBCard>
          <MDBCardBody>
          
          <img
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"}
                className="border"
                style={{ width: "100%", objectFit: "contain" }}
            />
            <h5 className="mt-2">Beispielbild</h5>
            <p>Bildbeschreibung</p>
            <MDBChipsInput
      label="Tags"
      editable
      initialValues={[
        { tag: 'reinraum' },
        { tag: 'wasser' }
      ]}
    />
          </MDBCardBody>
      </MDBCard>
  </MDBCol>
  <MDBCol md="3">
      <MDBCard>
          <MDBCardBody>
          
          <img
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"}
                className="border"
                style={{ width: "100%", objectFit: "contain" }}
            />
            <h5 className="mt-2">Beispielbild</h5>
            <p>Bildbeschreibung</p>
            <MDBChipsInput
      label="Tags"
      editable
      initialValues={[
        { tag: 'reinraum' },
        { tag: 'wasser' }
      ]}
    />
          </MDBCardBody>
      </MDBCard>
  </MDBCol>
  <MDBCol md="3">
      <MDBCard>
          <MDBCardBody>
          
          <img
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"}
                className="border"
                style={{ width: "100%", objectFit: "contain" }}
            />
            <h5 className="mt-2">Beispielbild</h5>
            <p>Bildbeschreibung</p>
            <MDBChipsInput
      label="Tags"
      editable
      initialValues={[
        { tag: 'reinraum' },
        { tag: 'wasser' }
      ]}
    />
          </MDBCardBody>
      </MDBCard>
  </MDBCol>
  <MDBCol md="3">
      <MDBCard>
          <MDBCardBody>
          
          <img
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"}
                className="border"
                style={{ width: "100%", objectFit: "contain" }}
            />
            <h5 className="mt-2">Beispielbild</h5>
            <p>Bildbeschreibung</p>
            <MDBChipsInput
      label="Tags"
      editable
      initialValues={[
        { tag: 'reinraum' },
        { tag: 'wasser' }
      ]}
    />
          </MDBCardBody>
      </MDBCard>
  </MDBCol>
  <MDBCol md="3">
      <MDBCard>
          <MDBCardBody>
          
          <img
                src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png"}
                className="border"
                style={{ width: "100%", objectFit: "contain" }}
            />
            <h5 className="mt-2">Beispielbild</h5>
            <p>Bildbeschreibung</p>
            <MDBChipsInput
      label="Tags"
      editable
      initialValues={[
        { tag: 'reinraum' },
        { tag: 'wasser' }
      ]}
    />
          </MDBCardBody>
      </MDBCard>
  </MDBCol>
  
</MDBRow>
  
</>
  )
}
