import Schema from 'validate'

const contentLength = str => str.length >= 430;
const isTrue = boolean => boolean === true;

// type FullAddress {
//     firstName: String
//     lastName: String
//     title: String
//     salutation: String
//     street: String
//     street2: String
//     streetNumber: String
//     company: String
//     contactPerson: String
//     city: String
//     zip: String
//     countryCode: String
// }


const fullAddressSchema = new Schema({
    firstName: {
        required: true,
        type: String
    },
    lastName: {
        required: true,
        type: String
    },
    title: {
        type: String
    },
    salutation: {
        type: String
    },
    street: {
        required: true,
        type: String
    },
    street2: {
        type: String
    },
    streetNumber: {
        // required: true,
        type: String
    },
    company: {
        type: String
    },
    contactPerson: {
        type: String
    },
    city: {
        required: true,
        type: String
    },
    zip: {
        required: true,
        type: String
    },
    countryCode: {
        required: true,
        type: String
    }
})

const billingAddressSchema = new Schema({
    firstName: {
        type: String
    },
    lastName: {
        type: String
    },
    title: {
        type: String
    },
    salutation: {
        type: String
    },
    street: {
        required: true,
        type: String
    },
    street2: {
        type: String
    },
    streetNumber: {
        // required: true,
        type: String,
        message: "Billing address street number"
    },
    company: {
        type: String
    },
    contactPerson: {
        type: String
    },
    city: {
        required: true,
        type: String
    },
    zip: {
        required: true,
        type: String
    },
    countryCode: {
        required: true,
        type: String
    }
})

const visitSchema = new Schema({
    invitationCode: {
        type: String,
    },
    selectedDays: {
        type: Array,
        required: true,
        length: { min: 1 }
    }
})

const visitAdmin = new Schema({
    selectedDays: {
        type: Array,
        required: true,
        length: { min: 1 }
    }
})

export {
    fullAddressSchema,
    billingAddressSchema,
    visitSchema,
    visitAdmin
}