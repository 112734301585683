import { useEffect, useState } from 'react'
import { MDBCheckbox, MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBBtn, MDBIcon,
    MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
    MDBCollapse, MDBInput, MDBChip, MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, MDBCardImage 
 } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { Link } from "react-router-dom";
const client = generateClient();
export default function Companies() {
    const { t, i18n } = useTranslation();

    const [companys, setCompanys] = useState([]);

    // Table Settings
    const [selectedElements, setSelectedElements] = useState([]);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState({ field: "name", order: "asc" });
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    const getCompanys = async (filter) => {
        try {
            const variables = { 
                limit: rowsPerPage,
                nextToken: null,
                // Sort by x4Id
                //sort: {field: "id", direction: "asc"}

            };
            const companys = await client.graphql({
                query: /* GraphQL */ `
                query SearchCompanies(
                  $filter: SearchableCompanyFilterInput
                  $sort: [SearchableCompanySortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableCompanyAggregationInput]
                ) {
                  searchCompanies(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                      id
                      name
                      status
                      website
                      contact {
                        address {
                            city
                            countryCode
                        }
                      }
                      image {
                        main {
                        alt
                        fileName
                        }
                    }
                      x4Id
                      x4AdminId
                      createdAt
                      updatedAt
                      companyAdminId
                      __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `,
            variables: variables
            });
            console.log("company",companys);
            return {
                items: companys.data.searchCompanies.items,
                nextToken: companys.data.searchCompanies.nextToken,
                total: companys.data.searchCompanies.total
            };
        } catch (error) {
            console.warn("error fetching companys", error);
        }
    }

    useEffect(() => {
        const fetchCompanys = async () => {
            const companysFromAPI = await getCompanys(filter);
            setCompanys(companysFromAPI.items);
            setTotalPages(Math.ceil(companysFromAPI.total / rowsPerPage));
        }
        fetchCompanys();
    }, [rowsPerPage, filter]);

    const truncate = (str, max, len) => {
        return str.length > max ? str.substring(0, len) + "..." : str;
    }

  return (
    <MDBCard>
        <MDBCardBody>
            <MDBCardTitle>{t("Companys")}</MDBCardTitle>
            {companys && companys.length > 0 && 
            <MDBRow>
                {
                    companys.map((company, index) => 
                        <MDBCol size="6" md="4" xl="3" key={index} className="mb-3">
                        <Link to={"/company/" + company.id}>
                            <MDBCard>
                            <MDBCardImage

                            src={company.image?.main?.fileName ? "https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/" + company.image?.main?.fileName : "https://iec-cp-public.s3.eu-central-1.amazonaws.com/defaultImages/horizontal.jpg"}
                            alt={company.image?.main?.alt}
                            style={{height: "185px", objectFit: "contain"}}
                            position='top' />
                                <MDBCardBody>
                                    <MDBCardTitle>{truncate(company.name, 25, 25)}</MDBCardTitle>
                                    <p className="text-muted">{company.contact?.address?.city}, {company.contact?.address?.countryCode}</p>
                                </MDBCardBody>
                            </MDBCard>
                        </Link>
                        </MDBCol>
                    )
                }
            </MDBRow>
            }
        </MDBCardBody>
    </MDBCard>
  )
}
