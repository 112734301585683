import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBInput, MDBSpinner, MDBIcon, MDBRow, MDBCol } from "mdb-react-ui-kit";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useState } from 'react';

const client = generateClient();

export default function CreateDepartment({ show, handleCreatedDepartment, toggle }) {
    const { t } = useTranslation();
    const [nameEn, setNameEn] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [nameDe, setNameDe] = useState('');
    const [descriptionDe, setDescriptionDe] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const createDepartment = async () => {
        setIsLoading(true);
        try {
            const department = await client.graphql({
                variables: { 
                    input: { 
                        name: [
                            { language: 'en', text: nameEn },
                            { language: 'de', text: nameDe }
                        ],
                        description: [
                            { language: 'en', text: descriptionEn },
                            { language: 'de', text: descriptionDe }
                        ]
                    } 
                },
                query: /* GraphQL */ `
                mutation CreateDepartment(
                  $input: CreateDepartmentInput!
                  $condition: ModelDepartmentConditionInput
                ) {
                  createDepartment(input: $input, condition: $condition) {
                        id
                        name {
                            language
                            text
                            }
                        status
                        companyDepartmentsId
                        employees {
                            items {
                            employee {
                                image {
                                fileName
                                alt
                                }
                                user {
                                lastName
                                middleName
                                firstName
                                title
                                }
                            }
                            }
                        }
                    }
                }
                `
            });
            console.log("created department", department.data.createDepartment);
            handleCreatedDepartment(department.data.createDepartment);
            setIsCompleted(true);
            setTimeout(() => {
                toggle();
                setIsLoading(false);
                setIsCompleted(false);
            }, 500); // Close modal after 2 seconds
            return;
        } catch (error) {
            console.log("error on creating department", error);
            setIsLoading(false);
            return;
        }
    }

    return show && (
        <>
            <MDBRow className="mb-2 mb-md-3">
                <MDBCol md="6">
                    <div className="d-flex align-items-center gap-2">
                        <ReactCountryFlag countryCode="DE" svg style={{ width: "20px", height: "20px" }} />
                        <MDBInput 
                            label={t("Department Name")}
                            type="text" 
                            value={nameDe} 
                            onChange={(e) => setNameDe(e.target.value)} 
                        />
                    </div>
                </MDBCol>
                <MDBCol md="6">
                    <div className="d-flex align-items-center gap-2">
                        <ReactCountryFlag countryCode="GB" svg style={{ width: "20px", height: "20px" }} />
                        <MDBInput 
                            label={t("Department Name")}
                            type="text" 
                            value={nameEn} 
                            onChange={(e) => setNameEn(e.target.value)} 
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            <MDBRow >
                <MDBCol md="6">
                    <div className="d-flex align-items-center gap-2">
                        <ReactCountryFlag countryCode="DE" svg style={{ width: "20px", height: "20px" }} />
                        <MDBInput 
                            label={t("Department Description")}
                            type="text" 
                            value={descriptionDe} 
                            onChange={(e) => setDescriptionDe(e.target.value)} 
                        />
                    </div>
                </MDBCol>
                <MDBCol md="6">
                    <div className="d-flex align-items-center gap-2">
                        <ReactCountryFlag countryCode="GB" svg style={{ width: "20px", height: "20px" }} />
                        <MDBInput 
                            label={t("Department Description")}
                            type="text" 
                            value={descriptionEn} 
                            onChange={(e) => setDescriptionEn(e.target.value)} 
                        />
                    </div>
                </MDBCol>
            </MDBRow>
            <div className="text-center w-100">
                <MDBBtn 
                    className="mt-2 mt-md-3" 
                    onClick={createDepartment} 
                    color='success'
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <MDBSpinner small />
                    ) : isCompleted ? (
                        <MDBIcon icon='check-circle' /> // MDB Check Circle Icon
                    ) : (
                        t("Create Department")
                    )}
                </MDBBtn>
            </div>
        </>
    );
}
