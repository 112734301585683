import React, { useState, useEffect, useCallback, useContext } from "react";
import { generateClient } from 'aws-amplify/api';
import { diff } from 'deep-object-diff';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBIcon, MDBInput, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBChip, MDBTextArea, MDBSticky, MDBBtn, MDBFile, MDBSpinner, MDBValidation, MDBValidationItem, MDBInputGroup   } from "mdb-react-ui-kit";
import { updateUser } from "../graphql/mutations";
import { useTranslation } from "react-i18next";
import adjustChanges from "../utils/adjustDiffChanges";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { MDBWysiwyg } from 'mdb-react-wysiwyg';
import { MDBFileUpload } from 'mdb-react-file-upload';

import {useDropzone} from 'react-dropzone';
import {v4 as uuid} from "uuid";

import deepUpdateObject from "../utils/deepUpdateObject";

import { UserContext } from "../App";
const client = generateClient();
const StateProofImage = React.memo(function Image({ src, alt, className, style }) {
    return <img src={src} alt={alt} className={className} style={style} />;
});

export default function EditProfile({presetUserId, toggleSaveChanges, handleChangeComplete, adminOptions}) {
    const { t, i18n } = useTranslation();
    // Get userid from url /users/:userid
    let userid;
    const { user: currentUserContext} = useContext(UserContext);
    if(window.location.pathname.split("/")[2] === "user") {
        userid = window.location.pathname.split("/")[3];
    } else if(window.location.pathname.split("/")[1] === "myprofile") {
        userid = currentUserContext.id;
    } else if (presetUserId) {
        userid = presetUserId;
    } else {
      userid = currentUserContext.id;
        console.log("no userid")
    }

    // Userdata
    const [initialUser, setInitialUser] = useState({});
    
    const [loading, setLoading] = useState(true);

    // Get user data
    const getUser = async () => {
        try {
            const user = await client.graphql({
                variables: { id: userid },
                query: /* GraphQL */ `
                query GetUser($id: ID!) {
                  getUser(id: $id) {
                    id
                    email
                    firstName
                    middleName
                    title
                    lastName
                    salutation
                    image {
                      fileName
                      alt
                    }
                    birthday
                    aboutMe {
                      language
                      text
                    }
                    contact {
                      phone
                      fax
                      email
                      mobile
                      address {
                        street
                        street2
                        streetNumber
                        company
                        contactPerson
                        city
                        zip
                        countryCode
                      }
                    }
                    personalIndustry
                    personalDepartment
                  }
                }
              `
            });
            console.log("user",user);
            return user.data.getUser;
        } catch (error) {
            console.warn("error fetching user", error);
        }
    }

    
    // Changes and form handling
    const [user, setUser] = useState({});

   
    const handleChange = (e, additionalInstructions) => {
        console.log("change", e.target.attributes.datapath.value, e.target.value);
    console.log("additionalInstructions", additionalInstructions);
    
    // Get values from input
    const datapath = e.target.attributes.datapath.value;
    let value;

    // Check if the input is a checkbox
    if (e.target.type === 'checkbox') {
        value = e.target.checked; // Use 'checked' for checkboxes
    } else {
        value = e.target.value; // Use 'value' for other input types
    }

    // Update current company object
        const newUser = deepUpdateObject({...user}, datapath, value, additionalInstructions);
        setUser(newUser);
        //  const newdiff = diff(newUser, initialUser)
        //  console.log("diff", newdiff)
         //setChanges(diff(initialUser, newUser));
        
    }


    // Load user data
    const loadUser = async () => {
        const userData = await getUser();
        const initialUserData = {...userData};
        setInitialUser(initialUserData);
        setUser({...userData});
        setLoading(false);
    }

    useEffect(() => {
        console.log(userid)
        if(!userid) {
            console.log("no userid")
            return;
        };
        setLoading(true);
        loadUser();
    }, [userid]);

    const extractUpdatedTopLevelObjects = (company, changes) => {
        const updatedObjects = {};
      
        Object.keys(changes).forEach(topLevelKey => {
          if (company.hasOwnProperty(topLevelKey)) {
            updatedObjects[topLevelKey] = company[topLevelKey];
          }
        });
      
        return updatedObjects;
      };

    // Update user
    const [isUpdating, setIsUpdating] = useState(false);
    const [errorUpdating, setErrorUpdating] = useState(false);
    const handleUpdateProfile = async () => {

        try {
            setIsUpdating(true);
            const userForUpdate = {...user};
            if(!adminOptions) delete userForUpdate.email;
            const userRes = await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateUser(
                  $input: UpdateUserInput!
                  $condition: ModelUserConditionInput
                ) {
                  updateUser(input: $input, condition: $condition) {
                    id
                    email
                    firstName
                    middleName
                    title
                    lastName
                    salutation
                    image {
                      fileName
                      alt
                    }
                    birthday
                    aboutMe {
                      language
                      text
                    }
                    contact {
                      phone
                      fax
                      email
                      mobile
                      address {
                        street
                        street2
                        streetNumber
                        company
                        contactPerson
                        city
                        zip
                        countryCode
                      }
                    }
                    personalIndustry
                    personalDepartment
                  }
                }
              `,
                variables: { input: userForUpdate }
            });
            console.log("user", userRes);
            //setUser(userRes.data.updateUser);
            setIsUpdating(false);
            if(toggleSaveChanges) {
              handleChangeComplete("personalProfile");
            }
        } catch (error) {
            console.warn("error updating user", error);
            setIsUpdating(false);
            setErrorUpdating(true);
            setTimeout(() => {
                setErrorUpdating(false);
            }, 10000);
        }
    }

    // Reset changes
    const handleResetChanges = () => {
        // setUser(initialUser);
       // setChanges({});
    }




    // User Image
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [uploadingImageError, setUploadingImageError] = useState(null);
    
    const handleSetImage = (file) => {
      // upload file using mutation: uploadFile(base64FileBuffer: String, companyId: String): LambdaS3Response @function(name: "uploadFiles-${env}")
      if(file) {
        try {
          setIsUploadingImage(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = error => {
          console.log('Error reader: ', error);
          setUploadingImageError(error);
        }
        reader.onload = async () => {
          try {
            //console.log(reader.result)
            const base64FileBuffer = reader.result.replace(/^data:.+;base64,/, '');
            const response = await client.graphql({
              query: /* GraphQL */ `
              mutation UploadCompanyImage($imageBuffer: String, $imageType: String) {
                uploadCompanyImage(imageBuffer: $imageBuffer, imageType: $imageType)
              }
            `,
              variables: {
                imageBuffer: base64FileBuffer,
                imageType: "userProfileImage",
              },
            });
            console.log("response", response);
            const fileName = response.data.uploadCompanyImage.replace(/{|}|fileName=/g, '');
            handleChange({
              target: {
                value: {
                  fileName,
                  alt: " Image of " + user.firstName + " " + user.lastName,
                },
                attributes: {
                  datapath: {
                    value: "image"
                  }
                },
              },
            });
            } catch (error) {
              console.log("error", error);
              setIsUploadingImage(false);
              setUploadingImageError(error.errors[0].message);
            }
        }
        setIsUploadingImage(false);
        setUploadingImageError(null);
        } catch (error) {
          console.log("error", error);
          setIsUploadingImage(false);
          setUploadingImageError(error.errors[0].message);
        }
      }
    }



    useEffect(() => {
      if(toggleSaveChanges) {
        console.log("Saving changes to personal profile")
        handleUpdateProfile();
      }
    }, [toggleSaveChanges])
    

  return (
<>
{
  typeof toggleSaveChanges !== "boolean" &&
  <MDBSticky
    offset={60}
    boundary
    tag='div'
    href=''
    >
    <MDBRow className="mb-3">
        <MDBCol size="12" className="text-end">
        {/* <MDBBtn onClick={() => handleResetChanges()} color='light' rippleColor='dark' size="sm">
        {t("Reset Changes")}
        </MDBBtn> */}
        {
            isUpdating ?
            <MDBBtn color='light' rippleColor='dark' disabled>
            {t("Saving...")}
            </MDBBtn>
            :
            errorUpdating ?
            <MDBBtn color='danger' rippleColor='dark' disabled>
            {t("Error Saving")}
            </MDBBtn>
            :
            <MDBBtn onClick={() => handleUpdateProfile()} color="success" outline>
                {t("Save")}
            </MDBBtn>
        }
        </MDBCol>
    </MDBRow>
</MDBSticky>
}

<MDBCard className="m-1 m-lg-3 m-xl-5">
    <MDBCardBody>
    <MDBCardTitle className="mb-4">{t("Personal Profile")}</MDBCardTitle>
    {loading ?
        <div>Loading...</div>
        :
        <>
        <MDBRow>
            <MDBCol md="4" className="text-center">
            <div className="w-100 text-center mb-2 mb-lg-4">
            <img
            src={user?.image?.fileName ? 
            "https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/" + user.image.fileName: "https://via.placeholder.com/400x400"}
            className="img-thumbnail rounded-circle border"
            style={{ height: "200px", width: "200px", objectFit: "cover" }}
            alt='...' />
            </div>
            <MDBBtn color="primary" size="sm" className="w-50 mb-2 mb-lg-4" onClick={() => document.getElementById("image").click()}>{t("Upload Image")}</MDBBtn>
            <MDBFile className="d-none" id='image' onChange={e => handleSetImage(e.target.files[0])} />
            {
            isUploadingImage.De && <span><MDBSpinner role="status" /></span>
            }
            {
            uploadingImageError && <span className="mt-3 text-danger fw-bold">
            {uploadingImageError}
            </span>
            }
            </MDBCol>
            <MDBCol md="8">
            <h6>{t("Name")}</h6>
                <MDBRow className="mb-3">
                    <MDBValidationItem feedback='' className='col-6 col-md-3' invalid>
                        <MDBInput  autoComplete="off" datapath="salutation" label={t("Salutation")} value={user.salutation || ""} onChange={handleChange} />
                    </MDBValidationItem>
                    <MDBCol size="6" md="3">
                    <MDBInputGroup textAfter={<span style={user.title?.length === 12 ? {color: "red", fontWeight: "bold"} : {}}>{(user.title?.length || "0") + "/12"}</span>}>
                    <MDBInput maxLength={12} autoComplete="off" datapath="title" label={t("Person Title")} value={user.title || ""} onChange={handleChange} />
                    </MDBInputGroup>
                    </MDBCol>
                </MDBRow>    
                <MDBRow className="mb-3">
                    <MDBCol size="6" md="4">
                        <MDBInput autoComplete="off" datapath="firstName" label={t("First Name")} value={user.firstName || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="6" md="4">
                        <MDBInput autoComplete="off" datapath="middleName" label={t("Middle Name")} value={user.middleName || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="12" md="4" className="mt-3 mt-md-0">
                        <MDBInput autoComplete="off" datapath="lastName" label={t("Last name")} value={user.lastName || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <h6>{t("Address")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="8">
                        <MDBInput autoComplete="off" datapath="contact.address.street" label={t("Street")} value={user.contact?.address?.street || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="4">
                        <MDBInput autoComplete="off" datapath="contact.address.streetNumber" label={t("Number")} value={user.contact?.address?.streetNumber || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="6" className="mb-2 mb-md-0">
                        <MDBInput autoComplete="off" datapath="contact.address.street2" label={t("Street Additonal")} value={user.contact?.address?.street2 || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol size="12" md="6">
                        <MDBInput autoComplete="off" datapath="contact.address.company" label={t("Company")} value={user.contact?.address?.company || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.zip" label={t("Zip")} value={user.contact?.address?.zip || ""} onChange={handleChange} />
                    </MDBCol>
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.city" label={t("City")} value={user.contact?.address?.city || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol>
                        <MDBInput autoComplete="off" datapath="contact.address.countryCode" label={t("Country")} value={user.contact?.address?.countryCode || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
                <h6>{t("Contact")}</h6>
                <MDBRow className="mb-3">
                    <MDBCol size="12" md="4" className="mb-2 mb-md-0">
                        <PhoneInput
                            value={user.contact?.phone || ""}
                            placeholder={t("Phone")}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.phone"}}, value: phone}})}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4" className="mb-2 mb-md-0">
                        <PhoneInput
                            value={user.contact?.mobile || ""}
                            placeholder={t("Mobile")}
                            preferredCountries={['de', 'at', 'ch', "gb", "us", "fr", "it", "es", "nl", "pl", "cz", "fi"]}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.mobile"}}, value: phone}})}
                        />
                    </MDBCol>
                    <MDBCol size="12" md="4">
                        <PhoneInput
                            value={user.contact?.fax}
                            placeholder={t("Fax")}
                            preferredCountries={['de', 'at', 'ch', "gb", "us", "fr", "it", "es", "nl", "pl", "cz", "fi"]}
                            onChange={phone => handleChange({target: {attributes: {datapath: {value: "contact.fax"}}, value: phone}})}
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                    <MDBCol size="12" className="mb-2 mb-md-0">
                        <MDBInput autoComplete="off" datapath="email" label={t("Email")} value={user.email || ""} onChange={handleChange} />
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
        <h6>{t("About Me")}</h6>
        <MDBTextArea
            autoComplete="off"
            //datapath="aboutMe"
            //value={user.aboutMe?.find(t => t.language === i18n.language)?.text || user.aboutMe?.[0]?.text || ""}
            //onChange={handleChange}
            value={
                  user?.aboutMe?.length > 0
                    ? user.aboutMe.find((t) => t.language === "de")?.text
                    : ""
                }
                datapath={
                  "aboutMe[" +
                  (user?.aboutMe?.length > 0
                    ? user.aboutMe.findIndex(
                        (t) => t.language === "de"
                      ) === -1
                      ? user.aboutMe.length
                      : user.aboutMe.findIndex((t) => t.language === "de")
                    : "0") +
                  "]"
                }
                onChange={e => handleChange({...e, target: {...e.target,value: {text: e.target.value, language: "de"},attributes: {...e.target.attributes,datapath: e.target.attributes.datapath}}})}
            />

            
    </>
    }
    </MDBCardBody>
</MDBCard>
{/* <MDBCard>
    <MDBCardBody>
    <MDBCardTitle>{t("Company Profiles")}</MDBCardTitle>
        <MDBTable responsive>
            <MDBTableHead>
                <tr>
                    <th>{t("Company")}</th>
                    <th>{t("Image")}</th>
                    <th>{t("Position")}</th>
                    <th>{t("Department")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody>
                {user.employeeProfiles?.items?.map((profile, index) => (
                    <tr key={index}>
                        <td>
                        {profile.company.image?.main?.fileName ?
                            <img
                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+ profile.company.image?.main?.fileName}
                                className="rounded-circle border"
                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                alt={profile.company.name}
                            />
                            :
                            <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                                
                        }
                       
                        </td>
                        <td>
                        {profile.image?.fileName ?
                            <img
                                src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+ profile.image.fileName}
                                className="rounded-circle border"
                                style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                alt={profile.image.alt}
                            />
                            :
                            <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                                
                        }
                        </td>
                        <td>{profile.position}</td>
                        <td>{profile.department?.items?.map(department => department?.department?.name && department.department.name.find(text => text.language === i18n.language).text)}</td>
                        <td>{profile.status === "active" ? <MDBChip className="bg-success text-white bg-opacity-75" style={{maxWidth: "fit-content"}}>{t("Active")}</MDBChip> : profile.status === "deactivated" ? <MDBChip>{t("Deactivated")}</MDBChip> : profile.status}</td>
                        <td>Delete, Reasign to other user</td>
                    </tr>
                ))}

            </MDBTableBody>
            
        </MDBTable>
    </MDBCardBody>
</MDBCard> */}
</>
   
  )
}
