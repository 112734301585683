import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import { isoToGermanDate } from '../../../../utils/dateTools'
import { useTranslation } from 'react-i18next'
import { transformMoney } from "../../../../utils/money"

export default function EventAndTicket({event, params, setParams, handleClickPrev, handleClickNext}) {
    const { t, i18n } = useTranslation()

    const [isValid, setIsValid] = useState(true);

    const checkValidity = () => {
        if (params.ticketType) {
            setIsValid(true);
            return true;
        } else {
            setIsValid(false);
            return false;
        }
    }

    useEffect(() => {
        if(params.ticketType) checkValidity();
    }, [params.ticketType]);

    const handleClickButton = () => { 
        if (checkValidity()) handleClickNext();
    }



  return (
<>
<MDBTypography tag='h2' className="mb-lg-4">{t("Event")}</MDBTypography>
{event && 
<>
    <MDBCard className="mb-2 col-lg-4 mx-auto">
    {event.image?.fileName &&
    <MDBCardImage src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/EventImages/" + event.image.fileName} position='top' alt='...' />}
    <MDBCardBody>
        <MDBCardTitle>{event.name.find(t => t.language === i18n.language)?.text || event.name[0].text}</MDBCardTitle>
        <MDBCardText>
        {isoToGermanDate(event.startDate)} - {isoToGermanDate(event.endDate)}<br></br>
        {event.location?.address?.city}</MDBCardText>
    </MDBCardBody>
</MDBCard>

<MDBRow className="my-2 my-xl-4 px-xl-5" style={!isValid ? {color: "red"} : {}}>
<MDBTypography tag='h2' className="my-lg-4">{t("Ticket")}</MDBTypography>
<MDBTypography tag='h4' className="mb-lg-3">{t("Select your ticket type")}</MDBTypography>
    <MDBCol>
    <MDBCard
        onClick={e => setParams(old => ({...old, ticketType: "default"}))}
        shadow='0'
        background={params.ticketType === "default" ? "success bg-opacity-10" : "white"}
        className={"h-100" + (params.ticketType === "default" ? " border-success" : "")}
        style={{border: "1px solid", cursor: "pointer"}}
        >
            <MDBCardBody className='text-primary text-start'>
            <MDBCardTitle>{t("Visitor-Ticket")}
            {/* <span className="float-end">0€* <span className="text-danger fw-light text-decoration-line-through">100€</span></span> */}
            <span className="float-end">{transformMoney(event.ticketProduct?.price)}</span>
            </MDBCardTitle>
            <MDBCardText className="text-start">
            <ul>
            {event.bookingTicketBulletPoints?.map((bullet, index) => <li key={index}>{bullet.text.find(t => t.language === i18n.language)?.text || bullet.text[0].text}</li>)}
            </ul>
            {/* <span className="float-end mt-1">
            <small>*{t("Price for bookings and payment until 15.10.2024.")}</small>
            </span> */}
            </MDBCardText>
            <div className="text-center" style={{ bottom: "0.5rem", position: "absolute", width: "-webkit-fill-available"}}>
            <strong>{t("Select")}</strong>
            </div>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
    {/* <MDBCol>
    <MDBCard
        onClick={e => setParams(old => ({...old, ticketType: "premium"}))}
        shadow='0'
        background={params.ticketType === "premium" ? "success bg-opacity-10" : "white"}
        className={"h-100" + (params.ticketType === "premium" ? " border-success" : "")}
        style={{border: "1px solid", cursor: "pointer"}}
        >
            <MDBCardBody className='text-primary text-start'>
            <MDBCardTitle>{t("Premium-Ticket")}<span className="float-end">500€* <span className="text-danger fw-light text-decoration-line-through">1000€</span></span></MDBCardTitle>
            <MDBCardText className="text-start">
            <ul>
            <li>{t("Access to the event on the selected days")}</li>
            <li>{t("Over 200 technical, educational and marketing lectures from more than 200 exhibitors")}</li>
            <li>{t("Free food and drinks and participation in 2 special themed evenings")}</li>
            <li><strong>{t("Access to the recordings of all technical and educational lectures")}</strong></li>
            </ul>
            <span className="float-end mt-1">
            <small>*{t("Price for bookings and payment until 15.04.2024 and visitors that attend the event.")}</small>
            </span>
            </MDBCardText>
            <div className="text-center" style={{ bottom: "0.5rem", position: "absolute", width: "-webkit-fill-available"}}>
            <strong>{t("Select")}</strong>
            </div>
            </MDBCardBody>
        </MDBCard>
    </MDBCol> */}

    <div className="text-center mt-4">
    <MDBBtn color="primary" onClick={handleClickButton}>{t("Next")}</MDBBtn>
    </div>
</MDBRow>
</>
}
</>
  )
}
