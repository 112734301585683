import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBTypography, MDBIcon, MDBBtn, MDBInput } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next"
import { transformMoney } from "../../../utils/money"

export default function SideCart({params, products = [], customerType, hideDescription = false, hideImage = false, taxRate, showDiscountCode,  discountCode, setDiscountCode, applyDiscountCode, removeDiscountCode, appliedDiscounts, discountErrorMessages}) {
  const { t, i18n } = useTranslation()
  return (
<>
<MDBCard>
  <MDBCardBody>
    <MDBCardTitle>{t("Cart")}</MDBCardTitle>
    <MDBRow className="mt-3">
      {products.length > 0 && products.map((product, index) => (
        <MDBCol key={index} md='12' className='mb-4'>
          <MDBRow>
            <MDBCol md='2'>
              {product?.image?.main?.url || product?.image?.main?.fileName ? 
              <MDBCol md='12'><img src={product?.image?.main?.url || "https://iec-cp-public.s3.eu-central-1.amazonaws.com/ProductImages/" + product?.image?.main.fileName} alt={product?.name.find(n => n.language === i18n.language)?.text || product?.name[0]?.text} className='img-fluid' /></MDBCol>
              : <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon={product.icon} />}
            </MDBCol>
            <MDBCol md='6'>
              <MDBTypography tag='h6' className="fw-bold">
                {product.qty && product.qty > 1 && <span className="me-2">{product.qty}x</span>}
                {product?.name.find(n => n.language === i18n.language)?.text || product?.name[0]?.text}
                </MDBTypography>
              {!hideDescription && <MDBTypography tag='p'>{product?.shortDescription?.find(n => n.language === i18n.language)?.text || product?.shortDescription?.[0]?.text}</MDBTypography>}
            </MDBCol>
            <MDBCol md='4'>
              <MDBTypography tag='h6' className='text-end'>
                {transformMoney(product.qty && product.qty > 1 ? product?.price * product.qty : product?.price)}
                {/* {customerType === "private" ? 
                transformMoney(product.qty && product.qty > 1 ? (product?.price * (1 + taxRate / 100)) * product.qty : product?.price * (1 + taxRate / 100))
                :
                transformMoney(product.qty && product.qty > 1 ? product?.price * product.qty : product?.price)
                
                } */}
                </MDBTypography>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      ))}
      {appliedDiscounts?.length > 0 && appliedDiscounts.map((discount, index) => (
        <MDBCol key={index} md='12' className='mb-4'>
          <MDBRow>
            <MDBCol md='2'>
              <MDBIcon className="" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="tag" />
            </MDBCol>
            <MDBCol md='6'>
              <MDBTypography tag='h6' className="fw-bold">
                {discount?.name.find(n => n.language === i18n.language)?.text || discount?.name[0]?.text}
                <MDBIcon fas icon="times" className="ms-2 text-danger" onClick={() => removeDiscountCode(discount.id)} />
                </MDBTypography>
                
             
            </MDBCol>
            <MDBCol md='4'>
              <MDBTypography tag='h6' className='text-end'>
                -{transformMoney(discount?.amount)}
                </MDBTypography>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      ))}
    </MDBRow>
    {/* Discount Code */}
    {showDiscountCode && <>
    <MDBRow>
      <MDBCol md='12'>
        <MDBTypography tag='h6'>{t("Discount code")}</MDBTypography>
      </MDBCol>
      <MDBCol md='8'>
        <MDBInput type="text" value={discountCode} onChange={(e) => setDiscountCode(e.target.value)} />
      </MDBCol>
      <MDBCol md='4'>
        <MDBBtn outline onClick={applyDiscountCode}>{t("Apply")}</MDBBtn>
      </MDBCol>
    </MDBRow>
    {discountErrorMessages?.length > 0 && <MDBRow>
      <MDBCol md='12'>
        <MDBTypography tag='p' className="text-danger">{discountErrorMessages.join(", ")}</MDBTypography>
      </MDBCol>
    </MDBRow>}
    </>}
    <hr />
    <MDBRow>
      <MDBCol md='6'>
        <MDBTypography tag='h6'>{t("Total net")}</MDBTypography>
      </MDBCol>
      <MDBCol md='6'>
        <MDBTypography tag='h6' className='text-end'>{
        transformMoney((products.reduce((acc, product) => acc + (product.qty && product.qty > 1 ? product?.price * product.qty : product?.price) , 0))
        - (appliedDiscounts?.length > 0 ? appliedDiscounts.reduce((acc, discount) => acc + discount.amount, 0) : 0))
        }</MDBTypography>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md='6'>
        <MDBTypography tag='h6'>{t("VAT")}</MDBTypography>
      </MDBCol>
      <MDBCol md='6'>
        <MDBTypography tag='h6' className='text-end'>{
          transformMoney(
        (products.reduce((acc, product) => acc + (product.qty && product.qty > 1 ? product?.price * product.qty : product?.price) , 0) * taxRate / 100)
        - (appliedDiscounts?.length > 0 ? appliedDiscounts.reduce((acc, discount) => acc + discount.amount * taxRate / 100, 0) : 0)
          )
        }</MDBTypography>
      </MDBCol>
    </MDBRow>
    <hr></hr>
    <MDBRow>
      <MDBCol md='6'>
        <MDBTypography tag='h6'>{t("Total incl. vat")}</MDBTypography>
      </MDBCol>
      <MDBCol md='6'>
        <MDBTypography tag='h6' className='text-end fw-bold'>{
          transformMoney(
        (products.reduce((acc, product) => acc + (product.qty && product.qty > 1 ? product?.price * product.qty : product?.price) , 0) * (1 + taxRate / 100))
          - (appliedDiscounts?.length > 0 ? appliedDiscounts.reduce((acc, discount) => acc + discount.amount * (1 + taxRate / 100), 0) : 0)
      )
      }</MDBTypography>
      </MDBCol>
    </MDBRow>
  </MDBCardBody>
</MDBCard>

</>

  )
}
