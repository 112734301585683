import { Message } from "@aws-amplify/ui-react"
import { Avatar, ChatContainer, Conversation, ConversationHeader, ConversationList, EllipsisButton, MainContainer, MessageInput, MessageList, MessageSeparator, Search, Sidebar, TypingIndicator, VideoCallButton, VoiceCallButton } from "@chatscope/chat-ui-kit-react"
import "@chatscope/chat-ui-kit-styles/themes/default/main.scss";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../App";



export default function Chat() {
    const testChat = [
        {
            id: 1,
            participantsUsers: [
                {
                    id: "c5caf7a3-f5bd-40a0-b6de-1c8118c1c4e6",
                    firstName: "John",
                    lastName: "Doe",
                    image: {
                        fileName: "john-doe.jpg",
                    }
                },
                {
                    id: 2,
                    firstName: "Jane",
                    lastName: "Doe",
                    image: {
                        fileName: "jane-doe.jpg",
                    }
                }
            ],
            messages: [
                {
                    id: 1,
                    sender: {
                        id: 1,
                        firstName: "John",
                        lastName: "Doe",
                        image: {
                            fileName: "john-doe.jpg",
                        }
                    },
                    message: "Hello, how are you?",
                    createdAt: "2021-01-01T12:00:00Z"
                },
                {
                    id: 2,
                    sender: {
                        id: 2,
                        firstName: "Jane",
                        lastName: "Doe",
                        image: {
                            fileName: "jane-doe.jpg",
                        }
                    },
                    message: "I'm fine, thank you!",
                    createdAt: "2021-01-01T12:01:00Z"
                },
                {
                    id: 3,
                    sender: {
                        id: 1,
                        firstName: "John",
                        lastName: "Doe",
                        image: {
                            fileName: "john-doe.jpg",
                        }
                    },
                    message: "That's great!",
                    createdAt: "2021-01-01T12:02:00Z"
                }
            ]
        }
    ]


    const { t, i18n } = useTranslation();
    const { user } = useContext(UserContext);
    const [chats, setChats] = useState(testChat);
  return (
<>
<MainContainer
  responsive
  style={{
    height: '600px'
  }}
>
  <Sidebar
    position="left"
  >
    <Search placeholder={t("Search") + "..."} />
    <ConversationList>
        {chats.map(chat => (
            <Conversation

                info={chat.messages[chat.messages.length - 1].message}
                lastSenderName={chat.messages[chat.messages.length - 1].sender.firstName}
                name={chat.participantsUsers.filter(chatUser => user.sub !== chatUser.id)[0].firstName}
            >
                <Avatar
                    name={chat.participantsUsers.filter(chatUser => user.sub !== chatUser.id)[0].firstName}
                    src={chat.participantsUsers.filter(chatUser => user.sub !== chatUser.id)[0].image.fileName}
                    status="available"
                />
                
            </Conversation>
        ))}
      <Conversation
        info="Yes i can do it for you"
        lastSenderName="Lilly"
        name="Lilly"
      >
        <Avatar
          name="Lilly"
          src="https://chatscope.io/storybook/react/assets/lilly-aj6lnGPk.svg"
          status="available"
        />
      </Conversation>
      <Conversation
        info="Yes i can do it for you"
        lastSenderName="Joe"
        name="Joe"
      >
        <Avatar
          name="Joe"
          src="https://chatscope.io/storybook/react/assets/joe-v8Vy3KOS.svg"
          status="dnd"
        />
      </Conversation>
      <Conversation
        info="Yes i can do it for you"
        lastSenderName="Emily"
        name="Emily"
        unreadCnt={3}
      >
        <Avatar
          name="Emily"
          src="https://chatscope.io/storybook/react/assets/emily-xzL8sDL2.svg"
          status="available"
        />
      </Conversation>
      <Conversation
        info="Yes i can do it for you"
        lastSenderName="Kai"
        name="Kai"
        unreadDot
      >
        <Avatar
          name="Kai"
          src="https://chatscope.io/storybook/react/assets/kai-5wHRJGb2.svg"
          status="unavailable"
        />
      </Conversation>
      <Conversation
        info="Yes i can do it for you"
        lastSenderName="Akane"
        name="Akane"
      >
        <Avatar
          name="Akane"
          src="https://chatscope.io/storybook/react/assets/akane-MXhWvx63.svg"
          status="eager"
        />
      </Conversation>
      <Conversation
        info="Yes i can do it for you"
        lastSenderName="Eliot"
        name="Eliot"
      >
        <Avatar
          name="Eliot"
          src="https://chatscope.io/storybook/react/assets/eliot-JNkqSAth.svg"
          status="away"
        />
      </Conversation>
      <Conversation
        info="Yes i can do it for you"
        lastSenderName="Zoe"
        name="Zoe"
      >
        <Avatar
          name="Zoe"
          src="https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg"
          status="dnd"
        />
      </Conversation>
      <Conversation
        info="Yes i can do it for you"
        lastSenderName="Patrik"
        name="Patrik"
      >
        <Avatar
          name="Patrik"
          src="https://chatscope.io/storybook/react/assets/patrik-yC7svbAR.svg"
          status="invisible"
        />
      </Conversation>
    </ConversationList>
  </Sidebar>
  <ChatContainer>
    <ConversationHeader>
      <ConversationHeader.Back />
      <Avatar
        name="Zoe"
        src="https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg"
      />
      <ConversationHeader.Content
        info="Active 10 mins ago"
        userName="Zoe"
      />
      <ConversationHeader.Actions>
        <VoiceCallButton />
        <VideoCallButton />
        <EllipsisButton orientation="vertical" />
      </ConversationHeader.Actions>
    </ConversationHeader>
    <MessageList typingIndicator={<TypingIndicator content="Zoe is typing" />}>
      <MessageSeparator content="Saturday, 30 November 2019" />
      <Message
        model={{
          direction: 'incoming',
          message: 'Hello my friend',
          position: 'single',
          sender: 'Zoe',
          sentTime: '15 mins ago'
        }}
      >
        <Avatar
          name="Zoe"
          src="https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg"
        />
      </Message>
      <Message
        avatarSpacer
        model={{
          direction: 'outgoing',
          message: 'Hello my friend',
          position: 'single',
          sender: 'Patrik',
          sentTime: '15 mins ago'
        }}
      />
      <Message
        avatarSpacer
        model={{
          direction: 'incoming',
          message: 'Hello my friend',
          position: 'first',
          sender: 'Zoe',
          sentTime: '15 mins ago'
        }}
      />
      <Message
        avatarSpacer
        model={{
          direction: 'incoming',
          message: 'Hello my friend',
          position: 'normal',
          sender: 'Zoe',
          sentTime: '15 mins ago'
        }}
      />
      <Message
        avatarSpacer
        model={{
          direction: 'incoming',
          message: 'Hello my friend',
          position: 'normal',
          sender: 'Zoe',
          sentTime: '15 mins ago'
        }}
      />
      <Message
        model={{
          direction: 'incoming',
          message: 'Hello my friend',
          position: 'last',
          sender: 'Zoe',
          sentTime: '15 mins ago'
        }}
      >
        <Avatar
          name="Zoe"
          src="https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg"
        />
      </Message>
      <Message
        model={{
          direction: 'outgoing',
          message: 'Hello my friend',
          position: 'first',
          sender: 'Patrik',
          sentTime: '15 mins ago'
        }}
       />
      <Message
        model={{
          direction: 'outgoing',
          message: 'Hello my friend',
          position: 'normal',
          sender: 'Patrik',
          sentTime: '15 mins ago'
        }}
       />
      <Message
        model={{
          direction: 'outgoing',
          message: 'Hello my friend',
          position: 'normal',
          sender: 'Patrik',
          sentTime: '15 mins ago'
        }}
       />
      <Message
        model={{
          direction: 'outgoing',
          message: 'Hello my friend',
          position: 'last',
          sender: 'Patrik',
          sentTime: '15 mins ago'
        }}
       />
      <Message
        avatarSpacer
        model={{
          direction: 'incoming',
          message: 'Hello my friend',
          position: 'first',
          sender: 'Zoe',
          sentTime: '15 mins ago'
        }}
      />
      <Message
        model={{
          direction: 'incoming',
          message: 'Hello my friend',
          position: 'last',
          sender: 'Zoe',
          sentTime: '15 mins ago'
        }}
      >
        <Avatar
          name="Zoe"
          src="https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg"
        />
      </Message>
    </MessageList>
    <MessageInput placeholder="Type message here" />
  </ChatContainer>
</MainContainer>
</>
  )
}
