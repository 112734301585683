import { useEffect, useState } from 'react'
import { MDBCheckbox, MDBTable, MDBTableBody, MDBTableHead, MDBContainer, MDBBtn, MDBIcon,
    MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
    MDBCollapse, MDBInput, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow, MDBSelect 
 } from "mdb-react-ui-kit"
import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { Link } from "react-router-dom";
// import * as mutations from '../../../graphql/mutations';
//import { listUsers } from '../../../graphql/queries';
const client = generateClient();

export default function UserList() {
    const { t, i18n } = useTranslation();

    const [users, setUsers] = useState([]);

    // Table Settings
    const [selectedElements, setSelectedElements] = useState([]);
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState({ field: "createdAt", direction: "desc" });
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    const getUsers = async (filter) => {
        try {
            const variables = { 
                limit: rowsPerPage,
                nextToken: null,
                from: (page - 1) * rowsPerPage, 
                filter,
                sort
            };
            const users = await client.graphql({
                query: /* GraphQL */ `
                query SearchUsers(
                $filter: SearchableUserFilterInput
                $sort: [SearchableUserSortInput]
                $limit: Int
                $nextToken: String
                $from: Int
                $aggregates: [SearchableUserAggregationInput]
                ) {
                searchUsers(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                ) {
                    items {
                        createdAt
                        email
                        lastName
                        middleName
                        image {
                            fileName
                            alt
                        }
                        id
                        firstName
                        title
                        employeeProfiles(limit: 10) {
                        items {
                            company {
                            name
                            image {
                                main {
                                alt
                                fileName
                                }
                            }
                            }
                        }
                        }
                        employmentRequests(limit: 10) {
                            items {
                                company {
                                name
                                image {
                                    main {
                                    alt
                                    fileName
                                    }
                                }
                                }
                            }
                            }
                        personalDepartment
                        personalIndustry
                    }
                    nextToken
                    total
                    aggregateItems {
                    name
                    result {
                        ... on SearchableAggregateScalarResult {
                        value
                        }
                        ... on SearchableAggregateBucketResult {
                        buckets {
                            key
                            doc_count
                        }
                        }
                    }
                    }
                }
                }
            `,
            variables: variables
            });
            console.log("user",users);
            return {
                items: users.data.searchUsers.items,
                nextToken: users.data.searchUsers.nextToken,
                total: users.data.searchUsers.total
            };
        } catch (error) {
            console.warn("error fetching users", error);
        }
    }

    const buildTable = (users) => {
        return users.map((user) => {
            return (
                <tr key={user.id}>
                    <td><MDBCheckbox /></td>
                    <td>
                    <div className='d-flex align-items-center'>
                    {user?.image?.fileName ?
                                <img
                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+user.image.fileName}
                                    className="rounded-circle border"
                                    style={{    height: "50px",
                                                width: "50px",
                                                objectFit: "cover" }}
                                    alt={user.image.alt}
                                />
                                :
                                <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
                                
                    }
                    <div className='ms-3'>
                      <p className='fw-bold mb-1'>{user.title ? user.title + " " : ""}{user.firstName} {user.middleName} {user.lastName}</p>
                      <p className='text-muted mb-0'>{user.email}</p>
                    </div>
                    </div>
                    </td>
                    <td>
                    {new Date(user.createdAt).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                    </td>
                    <td>{user.employeeProfiles.items.map((item) => {
                        return (
                            <div key={item.company.name}>
                                {item.company.image?.main &&
                                <img
                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+item.company.image.main.fileName}
                                    className="rounded-circle border"
                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                    alt={item.company.image.main.alt}
                                />
                                }
                                {/* <span>
                                    <strong>{item.company.name}</strong>
                                </span> */}
                        
                            </div>
                        )
                    })}
                    {user.employmentRequests.items.map((item) => {
                        return (
                            <div key={item.company.name}>
                                {item.company.image &&
                                <img
                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+item.company.image.main.fileName}
                                    className="rounded-circle border"
                                    style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                    alt={item.company.image.main.alt}
                                />
                                }
                                {/* <span>
                                    <strong>{item.company.name}</strong>
                                </span> */}
                        
                            </div>
                        )
                    })}
                    </td>
                    
                    <td>
                    <Link to={"/admin/user/" + user.id}><MDBBtn size="sm" className="me-2"><MDBIcon fas icon="eye" /></MDBBtn></Link>
                    </td>
                </tr>
            )
        })
    }

    useEffect(() => {
        const fetchUsers = async () => {
            const usersFromAPI = await getUsers(filter);
            setUsers(usersFromAPI.items);
            setTotalPages(Math.ceil(usersFromAPI.total / rowsPerPage));
        }
        fetchUsers();
    }, [rowsPerPage, filter, page, sort]);

    useEffect(() => {
        if(search.length > 0) {
            setFilter({ ...filter, 
            or: [
                {firstName: { regexp: `.*${search.toLowerCase()}.*` }},
                {lastName: { regexp: `.*${search.toLowerCase()}.*` }},
                {email: { regexp: `.*${search}.*` }},
                {email: { eq: search }}
            ]
            });
        } else {
            setFilter({});
        }
    }, [search])

    // Table headers search collapse status

    const [tableCollapse, setTableCollapse] = useState({});

    const toggleCollapse = (field) => () => {
        setTableCollapse({ ...tableCollapse, [field]: !tableCollapse[field] });
    }




    return (
        <>
<MDBCard>
    <MDBCardBody>
        <MDBCardTitle>{t("Users")}<Link to="/admin/create-user"><MDBBtn className='mx-2 my-0 py-0' color='tertiary' rippleColor='light'>{t("Create new")}</MDBBtn></Link></MDBCardTitle>
        {/* Filters */}

        <MDBRow>
            <MDBCol size="12">
            <MDBInput type="text" onChange={e => setSearch(e.target.value)} label={t("Search")} />
            </MDBCol>
        </MDBRow>
        
        
       
        <MDBTable>
            <MDBTableHead>
                <tr>
                    <th style={{verticalAlign: "top"}}><MDBCheckbox /></th>
                    <th style={{verticalAlign: "top"}}>{t("Person")}</th>
                    <th style={{verticalAlign: "top"}}>{t("Created Date")}</th>
                    <th style={{verticalAlign: "top"}}>
                        <span className="float-start">{t("Employee profiles")}</span>
                        
                        <span className="float-end"><MDBIcon fas icon="plus" onClick={toggleCollapse("companyAccounts")} /></span>
                        <br></br>
                        <MDBCollapse open={tableCollapse.companyAccounts}>
                        <MDBInput label='Search' id='form1' type='text' />
                        </MDBCollapse>
                    </th>
                    
                    <th style={{verticalAlign: "top"}}>{t("Actions")}</th>
                </tr>
            </MDBTableHead>
            <MDBTableBody style={{ verticalAlign: 'middle' }}>
            {users.length > 0 ? buildTable(users) : <tr><td colSpan="4">{t("Loading")}</td></tr> }
            
            </MDBTableBody>

        </MDBTable>
        {users.length > 0 && 
        <>
        <nav aria-label='Page navigation' className="d-flex">
                <MDBPagination className='mx-auto' style={{width: "fit-content"}}>
                    <MDBPaginationItem>
                    {page !== 1 && <MDBPaginationLink  className="text-dark" onClick={() => setPage(page - 1)}>{t("Previous")}</MDBPaginationLink>}
                    </MDBPaginationItem>{
                        totalPages > 0 && Array.from(Array(totalPages).keys()).slice(0,5).map((pageIndex) => {
                            return (
                                <MDBPaginationItem key={pageIndex} active={pageIndex + 1 === page}>
                                    <MDBPaginationLink href='#' className="text-dark" onClick={() => setPage(pageIndex+1)}>{pageIndex + 1}</MDBPaginationLink>
                                </MDBPaginationItem>
                            )
                        })
                    }
                    {
                        totalPages > 5 &&
                        <MDBDropdown dropup>
                            <MDBDropdownToggle caret color="white" className="text-dark">
                                {t("More")}
                            </MDBDropdownToggle>
                            <MDBDropdownMenu basic>
                                {
                                    Array.from(Array(totalPages).keys()).slice(5).map((pageIndex) => {
                                        return (
                                            <MDBDropdownItem link key={pageIndex} active={pageIndex + 1 === page} className="text-dark"  onClick={() => setPage(pageIndex+1)}>
                                                {pageIndex + 1}
                                            </MDBDropdownItem>
                                        )
                                    })
                                }
                            </MDBDropdownMenu>
                        </MDBDropdown>
                    }



                    <MDBPaginationItem>
                        {page !== totalPages && <MDBPaginationLink className="text-dark" onClick={() => setPage(page + 1)}>{t("Next")}</MDBPaginationLink>}
                    </MDBPaginationItem>
                </MDBPagination>
                <MDBDropdown className="float-end">
                <MDBDropdownToggle caret color="white" className="text-dark">
                    {rowsPerPage}
                </MDBDropdownToggle>
                <MDBDropdownMenu basic>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(10)} className="text-dark">
                        10
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(20)} className="text-dark">
                        20
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(50)} className="text-dark">
                        50
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(100)} className="text-dark">
                        100
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(200)} className="text-dark">
                        200
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => setRowsPerPage(500)} className="text-dark">
                        500
                    </MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
            </nav>
            </>
        }
    </MDBCardBody>
</MDBCard>
         </>
    )
}
