import { Authenticator } from '@aws-amplify/ui-react';
import App from './App';

const AuthLayer = () => (
    <Authenticator.Provider>
        <App />
    </Authenticator.Provider>
);

export default AuthLayer;
