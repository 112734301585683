export function isoToGermanDate(isoString) {
    // Parse the ISO string into a Date object
    const date = new Date(isoString);

    // Format the date as DD.MM.YYYY
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}