import { useEffect, useState, useCallback } from 'react';
import { MDBInput, MDBBtn, MDBRow, MDBCol, MDBTypography, MDBInputGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBTextArea, MDBSwitch } from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, convertFromHTML } from 'draft-js';
import { convertHTMlToRaw } from "../../utils/HTMLDraft";
import { generateClient } from 'aws-amplify/api';
import { useNavigate, useParams } from "react-router-dom";
import GeneralTab from "./seminar/TabGeneral";
import DatesTab from "./seminar/DatesTab";
import SpeakersTab from "./seminar/TabSpeakers";

const client = generateClient();

const EditSeminar = () => {
    const navigate = useNavigate();
    const { seminarId } = useParams();
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSpeakers, setSelectedSpeakers] = useState([]);
    const [selectedTopics, setSelectedTopics] = useState([]);
    const [activeTab, setActiveTab] = useState("general");

    const [seminarData, setSeminarData] = useState({
        name: [],
        shortDescription: [],
        description: [],
        cover: null,
        seminarDays: [],
        location: null,
        speakers: [],
        CTABulletPoints: [],
        academyTopics: [],
        bookingStartDate: null,
        bookingEndDate: null,
        status: 'DRAFT'
    });

    const [editorState, setEditorState] = useState({
        de: EditorState.createEmpty(),
        en: EditorState.createEmpty(),
    });

    const onEditorStateChange = (editorState, language) => {
        setEditorState(prev => ({
          ...prev,
          [language]: editorState
        }));
        
        setSeminarData(prev => ({
          ...prev,
          description: [
            ...prev.description?.filter(d => d.language !== language) || [],
            {
              language,
              text: convertHTMlToRaw(editorState)
            }
          ]
        }));
      };

    // Lade existierendes Seminar
    const getSeminarData = async () => {
        try {
            const { data } = await client.graphql({
                query: /* GraphQL */ `
                query GetSeminar($id: ID!) {
                    getSeminar(id: $id) {
                        id
                        name {
                            language
                            text
                        }
                        shortDescription {
                            language
                            text
                        }
                        description {
                            language
                            text
                        }
                        cover {
                            fileName
                            alt
                        }
                        seminarDays {
                            date
                            startTime
                            endTime
                            notice {
                                language
                                text
                            }
                        }
                        location {
                            name {
                                language
                                text
                            }
                            address {
                                street
                                streetNumber
                                zip
                                city
                                countryCode
                            }
                            coordinates {
                                lat
                                lng
                            }
                        }
                        speakers {
                            items {
                                user {
                                    id
                                    firstName
                                    lastName
                                    image {
                                        fileName
                                    }
                                }
                            }
                        }
                        CTABulletPoints {
                            text {
                                language
                                text
                            }
                        }
                        academyTopics {
                            items {
                                topic {
                                    id
                                    name {
                                        language
                                        text
                                    }
                                }
                            }
                        }
                        product {
                            id
                            price
                        }
                        bookingStartDate
                        bookingEndDate
                        status
                    }
                }
                `,
                variables: { id: seminarId }
            });

            setSeminarData(data.getSeminar);
            
            // Setze Editor States
            if (data.getSeminar.description) {
                setEditorState({
                    de: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(data.getSeminar.description.find(d => d.language === 'de')?.text || '')
                        )
                    ),
                    en: EditorState.createWithContent(
                        ContentState.createFromBlockArray(
                            convertFromHTML(data.getSeminar.description.find(d => d.language === 'en')?.text || '')
                        )
                    )
                });
            }

            setSelectedSpeakers(data.getSeminar.speakers.items.map(item => item.user));
            setSelectedTopics(data.getSeminar.academyTopics.items.map(item => item.topic));

        } catch (error) {
            console.error("Error fetching seminar:", error);
        }
    };

    useEffect(() => {
        if (seminarId) {
            getSeminarData();
        }
    }, [seminarId]);

    // Erstelle/Update Product basierend auf Seminar Daten
    const handleProductData = async (seminarData, existingProductId = null) => {
        const datesString = seminarData.seminarDays
            ?.map(day => `${day.date}${day.startTime ? ` ${day.startTime}` : ''}`)
            .join(', ');

        const productData = {
            name: seminarData.name,
            shortDescription: seminarData.shortDescription,
            description: seminarData.description,
            type: 'seminar',
            sku: existingProductId ? undefined : `SEM-${Date.now()}`,
            invoiceName: seminarData.name.map(n => ({
                language: n.language,
                text: `${n.text} - ${datesString}`
            }))
        };

        if (existingProductId) {
            await client.graphql({
                query: /* GraphQL */ `
                mutation UpdateProduct($input: UpdateProductInput!) {
                    updateProduct(input: $input) {
                        id
                    }
                }
                `,
                variables: {
                    input: {
                        id: existingProductId,
                        ...productData
                    }
                }
            });
            return existingProductId;
        } else {
            const { data } = await client.graphql({
                query: /* GraphQL */ `
                mutation CreateProduct($input: CreateProductInput!) {
                    createProduct(input: $input) {
                        id
                    }
                }
                `,
                variables: {
                    input: productData
                }
            });
            return data.createProduct.id;
        }
    };

    // Hauptspeicherfunktion
    const saveSeminar = async () => {
        try {
            setIsLoading(true);
            let productId;

            // Handle Produkt
            if (seminarId && seminarData.product?.id) {
                productId = await handleProductData(seminarData, seminarData.product.id);
            } else {
                productId = await handleProductData(seminarData);
            }

            // Seminar Mutation
            const mutation = seminarId ? /* GraphQL */ `
                mutation UpdateSeminar($input: UpdateSeminarInput!) {
                    updateSeminar(input: $input) {
                        id
                    }
                }
            ` : /* GraphQL */ `
                mutation CreateSeminar($input: CreateSeminarInput!) {
                    createSeminar(input: $input) {
                        id
                    }
                }
            `;

            const seminarInput = {
                ...seminarData,
                productId,
                ...(seminarId && { id: seminarId })
            };

            const { data } = await client.graphql({
                query: mutation,
                variables: {
                    input: seminarInput
                }
            });

            navigate('/admin/seminars');
        } catch (error) {
            console.error("Error saving seminar:", error);
            setIsLoading(false);
        }
    };

    const handleTabChange = (value) => {
        if (value !== activeTab) {
            setActiveTab(value);
        }
    };

    return (
        <MDBRow>
            <MDBCol size="12">
                <MDBCard>
                    <MDBCardBody>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <MDBCardTitle>
                                {seminarId ? t("Edit Seminar") : t("Create Seminar")}
                            </MDBCardTitle>
                            <div>
                                <MDBBtn 
                                    color="secondary" 
                                    className="me-2"
                                    onClick={() => navigate('/admin/seminars')}
                                >
                                    {t("Cancel")}
                                </MDBBtn>
                                <MDBBtn 
                                    onClick={saveSeminar}
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <><MDBIcon fas icon="spinner" spin className="me-2" /> {t("Saving...")}</>
                                    ) : (
                                        <>{t("Save")}</>
                                    )}
                                </MDBBtn>
                            </div>
                        </div>

                        <MDBTabs className='mb-3'>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleTabChange('general')} active={activeTab === 'general'}>
                                    <MDBIcon fas icon="info-circle" className="me-2" /> {t("General")}
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleTabChange('dates')} active={activeTab === 'dates'}>
                                    <MDBIcon fas icon="calendar-alt" className="me-2" /> {t("Dates & Location")}
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleTabChange('speakers')} active={activeTab === 'speakers'}>
                                    <MDBIcon fas icon="users" className="me-2" /> {t("Speakers")}
                                </MDBTabsLink>
                            </MDBTabsItem>
                            <MDBTabsItem>
                                <MDBTabsLink onClick={() => handleTabChange('program')} active={activeTab === 'program'}>
                                    <MDBIcon fas icon="file-alt" className="me-2" /> {t("Content")}
                                </MDBTabsLink>
                            </MDBTabsItem>
                        </MDBTabs>
                        <MDBTabsContent>
                            <MDBTabsPane open={activeTab === 'general'}>
                               
                            <GeneralTab 
                                seminarData={seminarData}
                                setSeminarData={setSeminarData}
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                            />
                            </MDBTabsPane>
                            
                            <MDBTabsPane open={activeTab === 'dates'}>
                            <DatesTab 
                                seminarData={seminarData}
                                setSeminarData={setSeminarData}
                            />
                            </MDBTabsPane>
                            <MDBTabsPane open={activeTab === 'speakers'}>
                            <SpeakersTab 
                                seminarData={seminarData}
                                setSeminarData={setSeminarData}
                            />
                            </MDBTabsPane>
                            <MDBTabsPane open={activeTab === 'program'}>
                                {/* Content Tab Inhalt folgt */}
                            </MDBTabsPane>
                        </MDBTabsContent>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};

export default EditSeminar;