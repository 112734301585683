import { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';

export const useIndustries = (eventId, initialSelectedIds = []) => {
  const client = generateClient();
  const [industries, setIndustries] = useState([]);
  const [selectedIndustryIds, setSelectedIndustryIds] = useState(initialSelectedIds);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch all industries on mount
  useEffect(() => {
    fetchIndustries();
  }, []); // Empty dependency array as this should only run once

  // Update selected industries when initialSelectedIds changes
  useEffect(() => {
    // Only update if the arrays are actually different
    if (JSON.stringify(initialSelectedIds) !== JSON.stringify(selectedIndustryIds)) {
      setSelectedIndustryIds(initialSelectedIds);
    }
  }, [JSON.stringify(initialSelectedIds)]); // Stringify for proper comparison

  const fetchIndustries = async () => {
    try {
      const response = await client.graphql({
        query: LIST_INDUSTRIES_QUERY
      });
      setIndustries(response.data.listIndustries.items);
    } catch (error) {
      console.error('Error fetching industries:', error);
    }
  };

  const updateEventIndustries = async (eventId) => {
    if (!eventId) return;
    
    try {
      setIsLoading(true);
      const existingIndustries = await client.graphql({
        query: LIST_INDUSTRY_EVENTS_QUERY,
        variables: {
          filter: { eventId: { eq: eventId } }
        }
      });

      const existingIndustryIds = existingIndustries.data.listIndustryEvents.items.map(item => item.industryId);

      // Determine which industries to add and remove
      const industriesToAdd = selectedIndustryIds.filter(id => !existingIndustryIds.includes(id));
      const industriesToRemove = existingIndustries.data.listIndustryEvents.items
        .filter(item => !selectedIndustryIds.includes(item.industryId));

      // Remove old connections
      await Promise.all(industriesToRemove.map(async item => {
        await client.graphql({
          query: DELETE_INDUSTRY_EVENTS_MUTATION,
          variables: {
            input: { id: item.id }
          }
        });
      }));

      // Add new connections
      await Promise.all(industriesToAdd.map(async industryId => {
        await client.graphql({
          query: CREATE_INDUSTRY_EVENTS_MUTATION,
          variables: {
            input: {
              eventId: eventId,
              industryId: industryId
            }
          }
        });
      }));

    } catch (error) {
      console.error('Error updating event industries:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    industries,
    selectedIndustryIds,
    setSelectedIndustryIds,
    isLoading,
    updateEventIndustries
  };
};

const LIST_INDUSTRIES_QUERY = `
  query ListIndustries {
    listIndustries {
      items {
        id
        name {
          language
          text
        }
        handle
      }
    }
  }
`;

const LIST_INDUSTRY_EVENTS_QUERY = `
  query ListIndustryEvents($filter: ModelIndustryEventsFilterInput) {
    listIndustryEvents(filter: $filter) {
      items {
        id
        industryId
      }
    }
  }
`;

const CREATE_INDUSTRY_EVENTS_MUTATION = `
  mutation CreateIndustryEvents($input: CreateIndustryEventsInput!) {
    createIndustryEvents(input: $input) {
      id
    }
  }
`;

const DELETE_INDUSTRY_EVENTS_MUTATION = `
  mutation DeleteIndustryEvents($input: DeleteIndustryEventsInput!) {
    deleteIndustryEvents(input: $input) {
      id
    }
  }
`;