import { useState, useEffect } from 'react';
import { generateClient } from 'aws-amplify/api';

export const useCarpets = (eventId) => {
  const client = generateClient();
  const [isLoading, setIsLoading] = useState(false);
  const [eventCarpets, setEventCarpets] = useState([]);
  const [carpets, setCarpets] = useState([]);

  useEffect(() => {
    if (eventId) {
      getEventCarpets();
      getCarpets();
    }
  }, [eventId]);

  const getEventCarpets = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: LIST_EVENT_CARPETS_QUERY,
        variables: {
          filter: {
            eventId: {
              eq: eventId,
            },
          },
        },
      });
      setEventCarpets(response.data.listAvailableCarpetsInEvents.items);
    } catch (error) {
      console.error('Error fetching event carpets:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCarpets = async () => {
    setIsLoading(true);
    try {
      const response = await client.graphql({
        query: LIST_CARPETS_QUERY
      });
      setCarpets(response.data.listEventCarpets.items);
    } catch (error) {
      console.error('Error fetching carpets:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addCarpetToEvent = async (carpetId) => {
    try {
      const response = await client.graphql({
        query: CREATE_CARPET_MUTATION,
        variables: {
          input: {
            eventId,
            eventCarpetId: carpetId,
          },
        },
      });
      setEventCarpets(old => [...old, response.data.createAvailableCarpetsInEvents]);
    } catch (error) {
      console.error('Error adding carpet to event:', error);
    }
  };

  const removeCarpetFromEvent = async (availableCarpetId) => {
    try {
      await client.graphql({
        query: DELETE_CARPET_MUTATION,
        variables: {
          input: {
            id: availableCarpetId,
          },
        },
      });
      setEventCarpets(old => old.filter(carpet => carpet.id !== availableCarpetId));
    } catch (error) {
      console.error('Error removing carpet from event:', error);
    }
  };

  return {
    isLoading,
    eventCarpets,
    carpets,
    addCarpetToEvent,
    removeCarpetFromEvent
  };
};

// GraphQL Queries and Mutations
const LIST_EVENT_CARPETS_QUERY = /* GraphQL */ `
  query ListAvailableCarpetsInEvents(
    $filter: ModelAvailableCarpetsInEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailableCarpetsInEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        eventCarpetId
        event {
          id
        }
        eventCarpet {
          id
          order
          name {
            language
            text
          }
          description {
            language
            text
          }
          color
          image {
            fileName
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const LIST_CARPETS_QUERY = /* GraphQL */ `
  query ListEventCarpets(
    $filter: ModelEventCarpetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventCarpets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        order
        name {
          language
          text
        }
        description {
          language
          text
        }
        color
        image {
          fileName
        }
      }
      nextToken
      __typename
    }
  }
`;

const CREATE_CARPET_MUTATION = /* GraphQL */ `
  mutation CreateAvailableCarpetsInEvents(
    $input: CreateAvailableCarpetsInEventsInput!
  ) {
    createAvailableCarpetsInEvents(input: $input) {
      id
      eventId
      eventCarpetId
      event {
        id
      }
      eventCarpet {
        id
        order
        name {
          language
          text
        }
        description {
          language
          text
        }
        color
        image {
          fileName
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

const DELETE_CARPET_MUTATION = /* GraphQL */ `
  mutation DeleteAvailableCarpetsInEvents(
    $input: DeleteAvailableCarpetsInEventsInput!
  ) {
    deleteAvailableCarpetsInEvents(input: $input) {
      id
    }
  }
`;