import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { generateClient } from "aws-amplify/api";

// Custom Product Forms
import CompanyProfile from './after-purchase-forms/CompanyProfile';

export default function ThankYou() {
    const client = generateClient();
    const { t, i18n } = useTranslation();
    const { orderId } = useParams();

    const [data, setData] = useState({});
    const [order, setOrder] = useState({});
    const [ dataSavedSuccess, setDataSavedSuccess ] = useState(false);

    const getOrder = async () => {
        try {
            const response = await client.graphql({
                query: /* GraphQL */ `
                query GetOrder($id: ID!) {
                    getOrder(id: $id) {
                    id
                    orderNumber
                    status
                    paymentStatus
                    deliveryStatus
                    lineItems {
                        items {
                         product {
                            id
                            name {
                                language
                                text
                            }
                            description{
                                language
                                text
                            }
                            price
                            sku
                        }
                        }
                        nextToken
                    }
                    notes
                    deliveryAddress {
                        firstName
                        lastName
                        title
                        salutation
                        street
                        street2
                        streetNumber
                        company
                        contactPerson
                        city
                        zip
                        countryCode
                        __typename
                    }
                    billingAddress {
                        firstName
                        lastName
                        title
                        salutation
                        street
                        street2
                        streetNumber
                        company
                        contactPerson
                        city
                        zip
                        countryCode
                        __typename
                    }
                    billingEmail
                    createdAt
                    companyOrdersId
                    userOrdersId
                   
                    __typename
                    }
                }
                `,
                variables: { id: orderId }
            });
            setOrder(response.data.getOrder);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (orderId) getOrder();
    }, [orderId]);

    const updateOrder = async () => {
    try {
      const response = await client.graphql({
        query: /* GraphQL */ `
        mutation UpdateOrder($input: UpdateOrderInput!) {
          updateOrder(input: $input) {
            id
          }
        }
      `,
        variables: {
          input: {
            id: orderId,
           data: JSON.stringify(data)
          }
        }
      })
      //console.log(response)
        setDataSavedSuccess(true)
    }
    catch (error) {
      console.error(error)
    }
    }

    const handleUpdateOrder = () => {
    updateOrder();
    }


  return (
<>
<MDBRow className="d-flex align-items-center m-0" style={{height: "90vh"}}>
    <MDBCol size="12" className="d-flex align-items-center justify-content-center" style={{height: "100%"}}>
        <MDBCard className="col-md-10 col-xl-9 col-xxl-8 mt-xl-5">
            <MDBCardBody>
                <div>
                    <h1>{t("Thank You For Your Order")}</h1>
                    <p>{t("Your order has been received and is currently being processed. We will send you an email confirmation shortly.")}</p>
                    {order.lineItems && order.lineItems.items[0].product.sku === "cp-company" && 
                    <>
                    <p className="fw-bold">{t("Your order contains a product that requires further information")}</p>
                    <p>{t("Please fill out the form below to provide us with the necessary information to complete your order.")}</p>
                    <CompanyProfile
                        data={data}
                        setData={setData}
                        handleUpdateOrder={handleUpdateOrder}
                        dataSavedSuccess={dataSavedSuccess}
                    />
                    </>
                    }
                    <Link to="/bookings"><MDBBtn color="primary" href="/user/orders">View Orders</MDBBtn></Link>
                    <p className="text-muted mt-5">{t("Order Id")}: {order.id}</p>
                </div>
            </MDBCardBody>
        </MDBCard>
    </MDBCol>
</MDBRow>
</>
    
  );
}