import { useTranslation } from "react-i18next";
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
const client = generateClient();
export default function ResendInvitation(props) {
    const { t, i18n } = useTranslation();
    const { companyId, employee, isActive } = props;

    const resendInvitation = async () => {
    }


  return (
    <div className="text-center">
    <p>
        {t("Are you sure you want to send the invitation email again?")}
    </p>
    {employee && 
      <div className='d-flex align-items-center'>
    {employee?.image?.fileName ?
      <img
          src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/"+employee.image.fileName}
          className="rounded-circle border"
          style={{ height: "50px", width: "50px", objectFit: "contain" }}
          alt={employee.image.alt}
      />
      :
      <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="user-alt" />
      
    }
      <div className='ms-3'>
      <p className='fw-bold mb-1'>{employee.user.title ? employee.user.title + " " : ""}{employee.user.firstName} {employee.user.middleName} {employee.user.lastName}</p>
      <p className='text-muted mb-0'>{employee.user.email}</p>
      </div>
    </div>
    }
     <MDBBtn size="sm" className="mt-4">
        {t("Send invitation email")}
      </MDBBtn>
    </div>
  )
}
