import { Eventcalendar, getJson, formatDate, localeDe, Draggable, Dropcontainer } from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBInput, MDBSelect, MDBCol, MDBCheckbox, MDBDatepicker } from "mdb-react-ui-kit";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import lectureSchema from '../../validation/LectureValidation';
import {onCreateLecture, onUpdateLecture, onDeleteLecture} from '../../graphql/subscriptions';
import { type } from "@testing-library/user-event/dist/type";
import usePrevious from "../../utils/usePrevious";
import getEventDays from "../../utils/getEventDays";
import moment from 'moment-timezone';


const client = generateClient();
export default function PromotionTimes() {
    const { t, i18n } = useTranslation();

    const config = {
        eventId: "882c49b6-ba4b-4c66-8a35-987d04af08a5",
        openTime: "09:00",
        closeTime: "18:00",
    }


    const [lectures, setLectures] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [event, setEvent] = useState({});
    const [eventDays, setEventDays] = useState([]); // Event days

    const [isLoading, setIsLoading] = useState(false);

    const prepareAndSetSessions = (lectures) => {
        // Schritt 1: Vorbereitung der Daten
        const sessions = lectures.reduce((acc, lecture) => {
            const { dateTimes, company, title } = lecture; // Extrahieren der benötigten Daten
    
            // Überprüfen, ob dateTimes existiert und ein Array ist
            if (Array.isArray(dateTimes) && dateTimes.length) {
                // Für jede Zeit des Vortrags, ein Objekt erstellen und zum Array hinzufügen
                const lectureSessions = dateTimes.map(dateTime => ({
                    time: dateTime,
                    ...lecture
                }));
    
                acc = acc.concat(lectureSessions);
            }
            return acc;
        }, []);
    
        // Schritt 2: Sortierung der Daten nach Zeit
        sessions.sort((a, b) => new Date(a.time) - new Date(b.time));
    
        // Schritt 3: Aktualisierung des States
        setSessions(sessions);
    };
    


    const getLectures = async () => {
        try {
            setIsLoading(true);


            const lectures = await client.graphql({
                variables: {
                    filter: {
                        type: {matchPhrasePrefix: "promotion"}
                    },
                    limit: 1000
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                        title {
                            language
                            text
                        }
                        speakers {
                            items {
                                employee {
                                    image {
                                    fileName
                                    alt
                                    }
                                }
                            }
                        }
                        subjects {
                            id
                            name {
                                language
                                text
                            }
                        } 
                        topics {
                            items {
                              topic {
                                id
                                name {
                                  language
                                  text
                                }
                              }
                            }
                        }        
                        content {
                            language
                            text
                        }
                        conditions {
                            name
                            accepted
                        }
                        image {
                            fileName
                            alt
                        }
                        pdfDe {
                            url
                            name   
                        }
                        pdfEn {
                            url
                            name   
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        conditions {
                            name
                            accepted
                        }
                        id
                        adminComment
                        companyBooth
                        session
                        dateTimes
                        room
                        type
                        presentationLanguage
                        createdAt
                        updatedAt
                        companyLecturesId
                        eventLecturesId
                        lectureMainSpeakerId
                        approved
                        status
                        __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            setLectures(lectures.data.searchLectures.items.map(lecture => ({...lecture, topics: lecture.topics.items.map(topic => topic.topic)})));
            prepareAndSetSessions(lectures.data.searchLectures.items);

            let tempRooms = [];
            lectures.data.searchLectures.items.forEach(lecture => lecture.companyBooth && tempRooms.push(lecture.companyBooth));
            // Create set and sort alphabetically
            tempRooms = [...new Set(tempRooms)].sort((a, b) => a.localeCompare(b));
            setRooms(tempRooms.map((room, index) => ({id: room, name: room})));
            setIsLoading(false);
        } catch (error) {
            console.log("error on fetching lectures", error);
            if (error.data?.searchLectures?.items) {
                setLectures(error.data.searchLectures.items);
            }
            setIsLoading(false);
        }
    };

 // Get Event
 const getEvent = async () => {
    try {
        setIsLoading(true);
        const eventData = await client.graphql({
            query: /* GraphQL */ `
            query GetEvent($id: ID!) {
              getEvent(id: $id) {
                id
                name {
                  language
                  text
                  __typename
                }
                description
                status
                image {
                  fileName
                  alt
                  x4UUID
                  __typename
                }
                date
                startDate
                endDate
                location {
                  __typename
                }
                attendees {
                  nextToken
                  __typename
                }
                availableBundleBooths {
                  nextToken
                  __typename
                }  
                x4Id
                createdAt
                updatedAt
                __typename
              }
            }
          `,
            variables: {
                id: config.eventId
            }
        });
        setEvent(eventData.data.getEvent);
        setEventDays(getEventDays(eventData.data.getEvent));
        console.log("eventdays", getEventDays(eventData.data.getEvent))
        
        setIsLoading(false);
    } catch (error) {
        console.error(error);
        setIsLoading(false);
    }
};



useEffect(() => {
        getLectures();
        getEvent();
}, []); // Abhängigkeit von den Filters und filtersInitialized

const updateLectureOnSubscription = (lecture) => {
    if(!lecture) return;
    setLectures(currentLectures => currentLectures.find(l => l.id === lecture.id) ? currentLectures.map(l => l.id === lecture.id ? lecture : l) : [...currentLectures, lecture]);
    prepareAndSetSessions(lectures.find(l => l.id === lecture.id) ? lectures.map(l => l.id === lecture.id ? lecture : l) : [...lectures, lecture]);
};

const subscribeUpdateLecture = () => {
    console.log("subscribe update lecture")
    const subscription = client.graphql({
        query: /* GraphQL */ `
        subscription OnUpdateLecture($filter: ModelSubscriptionLectureFilterInput) {
          onUpdateLecture(filter: $filter) {
            title {
                language
                text
            }
            speakers {
                items {
                    employee {
                        image {
                        fileName
                        alt
                        }
                    }
                }
            }
            subjects {
                id
                name {
                    language
                    text
                }
            } 
            topics {
                items {
                  topic {
                    id
                    name {
                      language
                      text
                    }
                  }
                }
            }        
            content {
                language
                text
            }
            conditions {
                name
                accepted
            }
            image {
                fileName
                alt
            }
            pdfDe {
                url
                name   
            }
            pdfEn {
                url
                name   
            }
            company {
                id
                name
                image {
                    main {
                        fileName
                        alt
                    }
                }
            }
            conditions {
                name
                accepted
            }
            id
            adminComment
            companyBooth
            session
            dateTimes
            room
            type
            presentationLanguage
            createdAt
            updatedAt
            companyLecturesId
            eventLecturesId
            lectureMainSpeakerId
            approved
            status
            __typename
          }
        }
        `
    }).subscribe({
        next: ({ data }) => {
            // Update the lecture in the list of lectures, found by data.onUpdateLecture.id
            console.log("update", data);
            //getLectures();
            updateLectureOnSubscription(data.onUpdateLecture);

        },
        error: (error) => console.warn(error)
    });

    return {
        unsubscribe: () => subscription.unsubscribe()
    };
};

useEffect(() => {
    // Starten des Abonnements
    const subscription = subscribeUpdateLecture();

    // Bereinigungsfunktion
    return () => {
        // Beenden des Abonnements
        subscription.unsubscribe();
    };
}, []);

const customScheduleEvent = useCallback((data) => {
    const lecture = data.original.lecture;
    if(!lecture) return null;
      return (
        <MDBCard className="shadow-3 h-100" style={{backgroundColor: data.color }} onDoubl>
            <MDBCardBody className="p-1">
            <div className='d-flex align-items-center'>
            
              {lecture.company?.image?.main?.fileName ?
                  <div className='d-flex align-items-center'>
                      {lecture.company.image?.main?.fileName ?
                          <img
                              src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                              className="rounded-circle"
                              style={{ height: "50px", width: "50px", objectFit: "contain" }}
                              alt={lecture.company.image.alt}
                          />
                      :
                          <MDBIcon className="rounded-circle" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                  
                      }
                      
                  </div>
                  :
                  <MDBIcon
                      className="rounded-circle"
                      style={{
                          height: "50px",
                          width: "50px",
                          textAlign: "center",
                          paddingTop: "12px",
                          fontSize: "20px",
                      }}
                      fas
                      icon="user-alt"
                  />
              }
             <div className="ms-2">
             <span className="fw-bold" style={{lineHeight: "1", display: "block", fontSize: "12px", color: "#2b2b2b"}}>{(lecture.title.find((title) => title.language === i18n.language)?.text || lecture.title[0]?.text).substring(0, 50)}</span>
             <span className="d-block mt-1" style={{lineHeight: "1", fontSize: "10px", color: "#2b2b2b"}}>{lecture.company.name.substring(0, 50)}</span>
            </div>
             </div>
            </MDBCardBody>
          </MDBCard>
      );
    
  }, []);

    return (
        <>

{eventDays.map((day, index) => (
                <div key={index} style={{height: "1250px", overflowX: "scroll", overflowY: "hidden"}}>
                <Eventcalendar
                    style={{width: "1200px"}}
                
                    theme="ios"
                    themeVariant="light"
                    data={
                        sessions.map((session, index) => {
                        return {
                            id: session.id + index.toString(),
                            title: session.title?.find((title) => title.language === i18n.language)?.text,
                            start: new Date(session.time),
                            // End 15 minutes after start
                            end: new Date(new Date(session.time).getTime() + 30 * 60000),
                            resource: session.companyBooth,
                            color:"#fff",
                            lecture: session
                        }
                    } )
                    }
                    clickToCreate={true}
                    dragToCreate={true}
                    dragToMove={true}
                    dragToResize={true}
                    eventDelete={true}
                    externalDrop={true}
                    externalDrag={true}
                    locale={localeDe}
                    invalid={[{
                        start: '00:00',
                        end: config.openTime,
                        recurring: {
                            repeat: 'daily'
                        }
                    }, {
                        start: config.closeTime,
                        end: '23:59',
                        recurring: {
                            repeat: 'daily'
                        }
                    }]}
                    
                    view={{
                        schedule: {
                            type: 'day',
                            timeCellStep: 60,
                            timeLabelStep: 30,
                            startTime: config.openTime,
                            endTime: config.closeTime
                        }
                    }}
                    resources={rooms}
                   renderScheduleEvent={customScheduleEvent}
                    groupBy="date"
                    selectedDate={day}
                />
                </div>
            ))}
        <MDBCard className="w-auto">
            <MDBCardBody className="flex-column">
                <MDBCardTitle>
                    {t("Promotions")}
                   
                </MDBCardTitle>
              <MDBTable>
                    <MDBTableHead>
                        <tr>
                            <th>{t("Date")}</th>
                            <th>{t("Time")}</th>
                            <th>{t("Company")}</th>
                            <th>{t("Title")}</th>
                            <th>{t("Speaker")}</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {sessions.map((session, index) => {
                            return (
                                <tr key={index}>
                                    <td>{new Date(session.time).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}</td>
                                    <td>{new Date(session.time).toLocaleTimeString('de-DE')}</td>
                                   
                                    <td><>
                                        {session.company?.image?.main?.fileName ?
                                            <div className='d-flex align-items-center'>
                                                {session.company.image?.main?.fileName ?
                                                    <img
                                                        src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+session.company.image.main.fileName}
                                                        className="rounded-circle border"
                                                        style={{ height: "50px", width: "50px", objectFit: "contain" }}
                                                        alt={session.company.image.alt}
                                                    />
                                                :
                                                    <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                            
                                                }
                                                <div className='ms-3'>
                                                <p className='fw-bold mb-1'>{session.company.name}</p>
                                                <p className='text-muted mb-0'>{session.companyBooth}</p>
                                                </div>
                                            </div>
                                            :
                                            <MDBIcon
                                                className="rounded-circle border"
                                                style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    textAlign: "center",
                                                    paddingTop: "12px",
                                                    fontSize: "20px",
                                                }}
                                                fas
                                                icon="user-alt"
                                            />
                                        }
                                    </>
                                    </td>
                                    <td>{session.title?.find(t => t.language === i18n.language)?.text || session.title?.[0]?.text}</td>
                                    <td>{session.speakers?.items?.map((speaker, index) => <>
                                        {speaker?.employee?.image?.fileName ?
                                            <img
                                                key={index}
                                                src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                                className="rounded-circle border"
                                                style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    objectFit: "cover",
                                                }}
                                                alt={speaker.employee?.image.alt}
                                            />
                                            :
                                            <MDBIcon
                                            key={index}
                                                className="rounded-circle border"
                                                style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    textAlign: "center",
                                                    paddingTop: "12px",
                                                    fontSize: "20px",
                                                }}
                                                fas
                                                icon="user-alt"
                                            />
                                        }
                                    </>)}</td>
                                   
                                </tr>
                            );
                        })}
                    </MDBTableBody>
              </MDBTable>
            </MDBCardBody>
        </MDBCard>

         
        </>
    )
}
