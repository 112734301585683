const config = {
    signUpAttributes: [
        'email',
        'family_name',
        'gender',
        'given_name',
        'phone_number'
    ],
    formFields: {
        signUp: {
          phone_number: {
            dialCode: '+49',
            order: 5
          },
          email: {
            order: 4
          },
          family_name: {
            order: 3
          },
          given_name: {
            order: 2
          },
          gender: {
            placeholder: 'Anrede eingeben',
            label: 'Anrede',
            order: 1
          },
          password: {
            order: 6
          },
          confirm_password: {
            order: 7
          }
        },
      },
      translations: {
        de: {
          'Enter your Email': 'Email Adresse eingeben',
          'Enter your Password': 'Passwort eingeben',
          "Please enter a valid email": "Bitte eine gültige Email Adresse eingeben",
          "Please enter a valid password": "Bitte ein gültiges Passwort eingeben",
          "Please confirm your password": "Bitte Passwort bestätigen",
          "Enter your Phone Number": "Telefonnummer eingeben",
          "Given Name": "Vorname",
          "Enter your Given Name": "Vorname eingeben",
          "Enter your Family Name": "Nachname eingeben",
          "Family Name": "Nachname",
          "Gender": "Anrede",
        }
      }
}

export default config;