import React from 'react';
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBInput,
  MDBTextArea,
  MDBBtn,
  MDBIcon,
  MDBInputGroup,
  MDBCard,
  MDBCardBody,
} from 'mdb-react-ui-kit';
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from 'react-i18next';
import { getLocalizedText } from '../../../utils/localizedText';

export default function DatesTab({ seminarData, setSeminarData }) {
  const { t, i18n } = useTranslation();

  const handleAddDay = () => {
    setSeminarData(prev => ({
      ...prev,
      seminarDays: [
        ...(prev.seminarDays || []),
        {
          date: '',
          startTime: '',
          endTime: '',
          notice: []
        }
      ]
    }));
  };

  const handleRemoveDay = (index) => {
    setSeminarData(prev => ({
      ...prev,
      seminarDays: prev.seminarDays.filter((_, i) => i !== index)
    }));
  };

  const handleDayChange = (index, field, value) => {
    setSeminarData(prev => ({
      ...prev,
      seminarDays: prev.seminarDays.map((day, i) => 
        i === index ? { ...day, [field]: value } : day
      )
    }));
  };

  const handleDayNoticeChange = (index, language, value) => {
    setSeminarData(prev => ({
      ...prev,
      seminarDays: prev.seminarDays.map((day, i) => 
        i === index ? {
          ...day,
          notice: [
            ...(day.notice?.filter(n => n.language !== language) || []),
            { language, text: value }
          ]
        } : day
      )
    }));
  };

  const handleLocationChange = (field, value) => {
    setSeminarData(prev => ({
      ...prev,
      location: {
        ...(prev.location || {}),
        [field]: value
      }
    }));
  };

  const handleLocationNameChange = (language, value) => {
    setSeminarData(prev => ({
      ...prev,
      location: {
        ...(prev.location || {}),
        name: [
          ...(prev.location?.name?.filter(n => n.language !== language) || []),
          { language, text: value }
        ]
      }
    }));
  };

  const handleAddressChange = (field, value) => {
    setSeminarData(prev => ({
      ...prev,
      location: {
        ...(prev.location || {}),
        address: {
          ...(prev.location?.address || {}),
          [field]: value
        }
      }
    }));
  };

  return (
    <div className="p-4">
      {/* Seminar Days Section */}
      <MDBRow className="mb-4">
        <MDBCol size="12">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <MDBTypography tag="h5">{t("Seminar Days")}</MDBTypography>
            <MDBBtn 
              color="primary" 
              className="px-3 py-2"
              onClick={handleAddDay}
            >
              <MDBIcon fas icon="plus" className="me-2" />
              {t("Add Day")}
            </MDBBtn>
          </div>

          {seminarData.seminarDays?.map((day, index) => (
            <MDBCard key={index} className="mb-3">
              <MDBCardBody>
                <div className="d-flex justify-content-between align-items-start mb-3">
                  <MDBTypography tag="h6">{t("Day")} {index + 1}</MDBTypography>
                  <MDBBtn 
                    color="danger" 
                    className="px-2 py-1" 
                    floating
                    onClick={() => handleRemoveDay(index)}
                  >
                    <MDBIcon fas icon="times" />
                  </MDBBtn>
                </div>

                <MDBRow className="mb-3">
                  <MDBCol size="12" md="4">
                    <MDBInput
                      type="date"
                      label={t("Date")}
                      value={day.date || ''}
                      onChange={(e) => handleDayChange(index, 'date', e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <MDBInput
                      type="time"
                      label={t("Start Time")}
                      value={day.startTime || ''}
                      onChange={(e) => handleDayChange(index, 'startTime', e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <MDBInput
                      type="time"
                      label={t("End Time")}
                      value={day.endTime || ''}
                      onChange={(e) => handleDayChange(index, 'endTime', e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>

                <MDBTypography tag="h6" className="mb-2">{t("Notice")}</MDBTypography>
                <MDBRow>
                  <MDBCol size="12" md="6" className="mb-3">
                    <MDBInputGroup>
                      <div className="input-group-text">
                        <ReactCountryFlag countryCode="de" svg style={{width: "20px", height: "20px"}} />
                      </div>
                      <MDBInput
                        label={t("German Notice")}
                        value={getLocalizedText(day.notice?.filter(n => n.language === "de")) || ""}
                        onChange={(e) => handleDayNoticeChange(index, 'de', e.target.value)}
                      />
                    </MDBInputGroup>
                  </MDBCol>
                  <MDBCol size="12" md="6">
                    <MDBInputGroup>
                      <div className="input-group-text">
                        <ReactCountryFlag countryCode="gb" svg style={{width: "20px", height: "20px"}} />
                      </div>
                      <MDBInput
                        label={t("English Notice")}
                        value={getLocalizedText(day.notice?.filter(n => n.language === "en")) || ""}
                        onChange={(e) => handleDayNoticeChange(index, 'en', e.target.value)}
                      />
                    </MDBInputGroup>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          ))}
        </MDBCol>
      </MDBRow>

      {/* Location Section */}
      <MDBRow>
        <MDBCol size="12">
          <MDBTypography tag="h5" className="mb-3">{t("Location")}</MDBTypography>
          
          {/* Location Name */}
          <MDBRow className="mb-4">
            <MDBCol size="12" md="6" className="mb-3">
              <MDBInputGroup>
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="de" svg style={{width: "20px", height: "20px"}} />
                </div>
                <MDBInput
                  label={t("German Location Name")}
                  value={getLocalizedText(seminarData.location?.name?.filter(n => n.language === "de")) || ""}
                  onChange={(e) => handleLocationNameChange('de', e.target.value)}
                />
              </MDBInputGroup>
            </MDBCol>
            <MDBCol size="12" md="6">
              <MDBInputGroup>
                <div className="input-group-text">
                  <ReactCountryFlag countryCode="gb" svg style={{width: "20px", height: "20px"}} />
                </div>
                <MDBInput
                  label={t("English Location Name")}
                  value={getLocalizedText(seminarData.location?.name?.filter(n => n.language === "en")) || ""}
                  onChange={(e) => handleLocationNameChange('en', e.target.value)}
                />
              </MDBInputGroup>
            </MDBCol>
          </MDBRow>

          {/* Address */}
          <MDBCard>
            <MDBCardBody>
              <MDBTypography tag="h6" className="mb-3">{t("Address")}</MDBTypography>
              <MDBRow>
                <MDBCol size="12" md="6" className="mb-3">
                  <MDBInput
                    label={t("Street")}
                    value={seminarData.location?.address?.street || ""}
                    onChange={(e) => handleAddressChange('street', e.target.value)}
                  />
                </MDBCol>
                <MDBCol size="12" md="6" className="mb-3">
                  <MDBInput
                    label={t("Street Number")}
                    value={seminarData.location?.address?.streetNumber || ""}
                    onChange={(e) => handleAddressChange('streetNumber', e.target.value)}
                  />
                </MDBCol>
                <MDBCol size="12" md="6" className="mb-3">
                  <MDBInput
                    label={t("ZIP Code")}
                    value={seminarData.location?.address?.zip || ""}
                    onChange={(e) => handleAddressChange('zip', e.target.value)}
                  />
                </MDBCol>
                <MDBCol size="12" md="6" className="mb-3">
                  <MDBInput
                    label={t("City")}
                    value={seminarData.location?.address?.city || ""}
                    onChange={(e) => handleAddressChange('city', e.target.value)}
                  />
                </MDBCol>
                <MDBCol size="12" md="6">
                  <MDBInput
                    label={t("Country Code")}
                    value={seminarData.location?.address?.countryCode || ""}
                    onChange={(e) => handleAddressChange('countryCode', e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </div>
  );
}