// Lecture Type
// type Lecture
//   @model
//   @auth(rules: [
//     { allow: private, operations: [read] },
//     { allow: groups, groups: ["Admin, Moderator"], operations: [create, read, update, delete] }])
// {
//   id: ID!
//   startTime: AWSDateTime!
//   endTime: AWSDateTime!
//   event: Event @belongsTo
//   company: Company @hasOne
//   speakers: [Employee] @hasMany
//   session: String
//   room: String
//   title: [MultiLangString!]!
//   subjects: [MultiLangStringList]
//   content: MultiLangString
//   presentationLanguage: String
//   image: Image
//   pdfDe: File
//   pdfEn: File
// }

import { generateClient } from 'aws-amplify/api';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBTable, MDBTableBody, MDBTableHead, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import lectureSchema from '../../validation/LectureValidation';

const client = generateClient();
export default function AllLectures() {
    const { t, i18n } = useTranslation();
    const [basicActive, setBasicActive] = useState('tab1');

    const handleBasicClick = (value) => {
        if (value === basicActive) {
            return;
        }

        setBasicActive(value);
    };

    const companyid = window.location.pathname.includes("mycompany")
        ? window.location.pathname.split("/")[2]
        : window.location.pathname.split("/")[3];

    const [lectures, setLectures] = useState([]);
    const [totalLectures, setTotalLectures] = useState(0);


    const [validationErrors, setValidationErrors] = useState([]);

    const validateLectures = (lectures) => {
        const errors = lectures.map((lecture) => ({
            id: lecture.id,
            errors: lectureSchema.validate(lecture).map((error) => ({
                field: error.path,
                message: error.message
            }))
        }));
        errors.forEach((error) => {
            console.log(error)
        })

        setValidationErrors(errors);
    }

    const getLectures = async () => {
        try {
            const lectures = await client.graphql({
                variables: {
                    filter: {createdAt: {gt: "2023-12-01T07:52:35.423Z"}}
                },
                query: /* GraphQL */ `
                query SearchLectures(
                  $filter: SearchableLectureFilterInput
                  $sort: [SearchableLectureSortInput]
                  $limit: Int
                  $nextToken: String
                  $from: Int
                  $aggregates: [SearchableLectureAggregationInput]
                ) {
                  searchLectures(
                    filter: $filter
                    sort: $sort
                    limit: $limit
                    nextToken: $nextToken
                    from: $from
                    aggregates: $aggregates
                  ) {
                    items {
                        title {
                            language
                            text
                        }
                        speakers {
                            items {
                                employee {
                                    image {
                                    fileName
                                    alt
                                    }
                                }
                            }
                        }
                        subjects {
                            id
                            name {
                                language
                                text
                            }
                        }                
                        content {
                            language
                            text
                        }
                        conditions {
                            name
                            accepted
                        }
                        image {
                            fileName
                            alt
                        }
                        pdfDe {
                            url
                            name   
                        }
                        pdfEn {
                            url
                            name   
                        }
                        company {
                            id
                            name
                            image {
                                main {
                                    fileName
                                    alt
                                }
                            }
                        }
                        id
                        session
                        room
                        type
                        presentationLanguage
                        createdAt
                        updatedAt
                        companyLecturesId
                        eventLecturesId
                        lectureMainSpeakerId
                        __typename
                    }
                    nextToken
                    total
                    aggregateItems {
                      name
                      result {
                        ... on SearchableAggregateScalarResult {
                          value
                        }
                        ... on SearchableAggregateBucketResult {
                          buckets {
                            key
                            doc_count
                            __typename
                          }
                        }
                      }
                      __typename
                    }
                    __typename
                  }
                }
              `
            });
            setLectures(lectures.data.searchLectures.items);
            setTotalLectures(lectures.data.searchLectures.total);
        } catch (error) {
            console.log("error on fetching lectures", error);
            if (error.data?.searchLectures?.items) setLectures(error.data.searchLectures.items);
        }
    };

    useEffect(() => {
        getLectures();
        
    }, []);

    useEffect(() => {
        if(lectures.length > 0) {
            setTimeout(() => {
                validateLectures(lectures)
            }, 500);
        }
    }, [lectures]);


    const [deleteLectureModal, setDeleteLectureModal] = useState(false);
    const toggleDeleteLectureModal = () => setDeleteLectureModal(!deleteLectureModal);

    const [selectedLectureRemove, setSelectedLectureRemove] = useState(null);

    const deleteLecture = async (id) => {
        try {
            const deletedLecture = await client.graphql({
                variables: {
                    input: {
                        id: id
                    }
                },
                query: /* GraphQL */ `
                mutation DeleteLecture(
                  $input: DeleteLectureInput!
                  $condition: ModelLectureConditionInput
                ) {
                  deleteLecture(input: $input, condition: $condition) {
                    id
                  }
                }
              `
            });
            console.log("deleted lecture", deletedLecture);
            setLectures(lectures.filter((lecture) => lecture.id !== id));
            setSelectedLectureRemove(null);
            toggleDeleteLectureModal();
        } catch (error) {
            console.log("error on deleting lecture", error);
        }
    }


    



    return (
        <><MDBCard className="w-auto">
            <MDBCardBody className="flex-column">
                <MDBCardTitle>
                    {t("Lectures")}
                </MDBCardTitle>
                <MDBTabs className='mb-3'>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
                            Lounges 2024 Karlsruhe ({totalLectures})
                        </MDBTabsLink>
                    </MDBTabsItem>
                </MDBTabs>

                <MDBTabsContent>
                    <MDBTabsPane open={basicActive === 'tab1'}>
                        <MDBTable>
                            <MDBTableHead>
                                <tr>
                                    <th>{t("Company")}</th>
                                    <th>{t("Type")}</th>
                                    <th>{t("Title")}</th>
                                    <th>{t("Speakers")}</th>
                                    <th>{t("Errors")}</th>
                                    <th>{t("Actions")}</th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                                {lectures.length > 0 ?
                                    lectures.map((lecture, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><>
                                                    {lecture.company?.image?.main?.fileName ?
                                                        <div className='d-flex align-items-center'>
                                                            {lecture.company.image?.main?.fileName ?
                                                                <img
                                                                    src={"https://iec-cp-public.s3.eu-central-1.amazonaws.com/companyImages/"+lecture.company.image.main.fileName}
                                                                    className="rounded-circle border"
                                                                    style={{ height: "50px", width: "50px", objectFit: "cover" }}
                                                                    alt={lecture.company.image.alt}
                                                                />
                                                            :
                                                                <MDBIcon className="rounded-circle border" style={{ height: "50px", width: "50px", textAlign: "center", paddingTop: "12px", fontSize: "20px" }} fas icon="company-alt" />
                                                                        
                                                            }
                                                            <div className='ms-3'>
                                                            <p className='fw-bold mb-1'>{lecture.company.name}</p>
                                                            <p className='text-muted mb-0'><a href={lecture.company.website} target="_blank" rel="noreferrer">{lecture.company.website}</a></p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <MDBIcon
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                    }
                                                </>
                                                </td>
                                                <td>{lecture.type}</td>
                                                <td>{lecture.title?.find(t => t.language === i18n.language)?.text || lecture.title?.[0]?.text}</td>
                                                <td>{lecture.speakers?.items?.map((speaker, index) => <>
                                                    {speaker?.employee?.image?.fileName ?
                                                        <img
                                                            key={index}
                                                            src={`https://iec-cp-public.s3.eu-central-1.amazonaws.com/userImages/${speaker.employee.image.fileName}`}
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                objectFit: "cover",
                                                            }}
                                                            alt={speaker.employee?.image.alt}
                                                        />
                                                        :
                                                        <MDBIcon
                                                        key={index}
                                                            className="rounded-circle border"
                                                            style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                textAlign: "center",
                                                                paddingTop: "12px",
                                                                fontSize: "20px",
                                                            }}
                                                            fas
                                                            icon="user-alt"
                                                        />
                                                    }
                                                </>)}</td>
                                                <td>{
                                                    validationErrors.find(error => error.id === lecture.id) ?
                                                        [...new Set(validationErrors.find(error => error.id === lecture.id).errors.map(error => error.message))].join(", ")
                                                        :
                                                        <p className="text-success">{t("No errors")}</p>
                                                }</td>
                                                <td>
                                                    <MDBBtnGroup shadow="0">
                                                        {/* <MDBBtn color='link' style={{color: "black"}} className="px-2" >{t("Upload Documents")}</MDBBtn> */}
                                                        <Link to={`/mycompany/${lecture.company?.id}/register-lecture/${lecture.id}`}>
                                                            <MDBBtn color='link' className="px-2">{t("Edit")}</MDBBtn>
                                                        </Link>
                                                        <MDBBtn color='link' style={{ color: "red" }} className="px-2" onClick={() => { setSelectedLectureRemove(lecture.id); toggleDeleteLectureModal() }}>{t("Delete")}</MDBBtn>
                                                    </MDBBtnGroup>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7">
                                            {t("No lectures found")}
                                        </td>
                                    </tr>
                                }
                            </MDBTableBody>
                        </MDBTable>
                    </MDBTabsPane>
                </MDBTabsContent>
            </MDBCardBody>
        </MDBCard>

            <MDBModal open={deleteLectureModal} onClose={() => setDeleteLectureModal(false)}>
                <MDBModalDialog centered size="sm">
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>{t("Remove Lecture")}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleDeleteLectureModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className="text-center">
                            <p>{t("Are you sure you want to remove this lecture?")}</p>
                            <p className="fw-bold">{selectedLectureRemove && (lectures.find(l => l.id === selectedLectureRemove)?.title.find(t => t.language === i18n.language)?.text || lectures.find(l => l.id === selectedLectureRemove).title[0].text)}</p>
                            <MDBBtn color='danger' onClick={() => deleteLecture(selectedLectureRemove)}>{t("Remove")}</MDBBtn>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    )
}
