
import TabPersons from "../../components-user/manage-company/Persons";

export default function EditCompany() {


    const companyid = window.location.pathname.split("/")[2];

 
  return (
<>
<TabPersons company={{id: companyid}} loading={false} tabActive={true} />
</>
   
  )
}
